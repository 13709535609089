import functions from '../functions'

const { rescheduleConsult } = functions.cloudFunctions
const rescheduleConsultService = async data => {
  try {
    return rescheduleConsult(data)
  } catch (error) {
    return error
  }
}

export default rescheduleConsultService
