import PropTypes from 'prop-types'

import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { ImageCustom, LabelCustom } from '../../components'
import { useForm } from '../../utils'
import Images from '../../assets'
import { useState } from 'react'

const accordionBloodType = ({ bloodTypeValue, medicalRecord, uid }) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const bloodyGroups = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']
  const { dropsBloodIcon, arrowDown } = Images
  const [data, handleInputChange] = useForm({
    bloodType: bloodTypeValue,
  })

  const handleOnClick = () => {
    if (!data.bloodType) {
      setErrorAlert({
        show: true,
        message: 'Hay que seleccionar un tipo de sangre',
      })
      return false
    }
    medicalRecord({ method: 'POST', uid, data })
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={dropsBloodIcon}
              className="is-size-drops-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Tipo de sangre</span>
            <span className="are-color-mynd-grey ml-4 has-text-weight-light">
              ({bloodTypeValue})
            </span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="py-3">
        {errorAlert.show && (
          <div className="notification is-danger mt-3">
            {errorAlert.message}
          </div>
        )}
        <div className="control">
          {bloodyGroups.map((bloodyType, index) => (
            <LabelCustom
              className="radio  ml-0 is-margin-input-blood"
              key={index}
            >
              <input
                type="radio"
                className="is-radio-input-drop mr-3"
                name="bloodType"
                value={bloodyType}
                onChange={handleInputChange}
                defaultChecked={bloodTypeValue === bloodyType}
              />
              {bloodyType}
            </LabelCustom>
          ))}
        </div>
        <div className="buttons mt-2">
          <button
            className="button has-text-white is-mnd-primary mt-2"
            onClick={handleOnClick}
          >
            Guardar
          </button>
        </div>
      </AccordionItemPanel>
    </>
  )
}

accordionBloodType.propTypes = {
  bloodTypeValue: PropTypes.string,
  medicalRecord: PropTypes.func,
  uid: PropTypes.string,
}

export default accordionBloodType
