import PropTypes from 'prop-types'
// components
import {
  TitleCustom,
  ButtonCustom,
  TextCustom,
  DoctorCard,
  CustomButton,
  ImageCustom,
} from '../../components'
import Images from '../../assets'
import { useRouter } from '../../utils'
import { formatToMoney } from '../../utils/functions/currencyFormat'

const PurchaseSummary = ({
  isLoading,
  amount,
  typeConsult,
  selectMethod,
  coupons,
  paymentMethod,
  next,
  coupon,
  setCoupon,
  priceWithoutCoupon,
  doctor,
  date,
}) => {
  const { photoURL, career, fullname } = doctor
  const { dateConsult, selectedHour } = date
  const { doctorDummy, right_arrow } = Images
  const { discountType, amount: discountAmount, code } = coupon?.data ?? {}
  const { history } = useRouter()

  const onChangeCoupon = ({ target }) => {
    const selected = coupons.find(c => c.id === target.value)
    setCoupon(selected)
  }

  const handleBackButton = () => {
    history.goBack()
  }
  return (
    <div>
      <div className="box orders">
        {fullname && (
          <>
            <DoctorCard
              image={photoURL || doctorDummy}
              fullname={fullname}
              career={career}
            />
          </>
        )}

        {typeConsult === 'fast-service' && (
          <div className="level is-mobile" id="time">
            <TextCustom className="has-text-weight-bold ">
              {'Fecha: '}
              <span className="has-text-weight-light">{dateConsult}</span>
            </TextCustom>
            <TextCustom className="has-text-weight-bold ">
              {'Hora: '}
              <span className="has-text-weight-light">{selectedHour}</span>
            </TextCustom>
          </div>
        )}
        {amount !== 0 && (
          <>
            <div className="level is-mobile" id="payment">
              <TextCustom className="has-text-weight-bold">
                Pagar con:
                <a className="has-text-weight-light" onClick={selectMethod}>
                  {paymentMethod
                    ? paymentMethod.method === 'balance' ||
                      paymentMethod.method === 'card'
                      ? paymentMethod.value
                      : paymentMethod.details
                    : 'Elija un método de pago'}

                  <span className="icon are-color-mynd-grey order-arrow">
                    <ImageCustom
                      img={right_arrow}
                      className="is-size-arrow-buy"
                    />
                  </span>
                </a>
              </TextCustom>
            </div>
            <label className="label mh30">Seleccionar cupón</label>
            <select
              className="select-color"
              id="selector"
              onChange={e => onChangeCoupon(e)}
            >
              <option value={0}></option>
              {coupons &&
                coupons.map(coupon => {
                  return (
                    <option value={coupon.id} key={coupon.id}>
                      {coupon.data.code}
                    </option>
                  )
                })}
            </select>
          </>
        )}
        <TitleCustom className="is-size-5 mt-5 are-color-mynd-grey mh30">
          Resumen de compra
        </TitleCustom>
        <div className="level is-mobile mh30">
          <TextCustom>Sesión</TextCustom>
          <TextCustom>{`${
            priceWithoutCoupon > 0
              ? `${formatToMoney(priceWithoutCoupon)}`
              : 'gratis'
          }`}</TextCustom>
        </div>
        {coupon && (
          <div>
            <div className="level mb-2 mh30 ">
              <TextCustom>
                {discountType !== '%'
                  ? discountType === '-'
                    ? `Descuento de $${formatToMoney(discountAmount)}`
                    : 'Descuento'
                  : 'Descuento'}
              </TextCustom>
              <TextCustom>
                {discountType !== '%' ? formatToMoney(discountAmount) : amount}
              </TextCustom>
            </div>
            <TextCustom className="has-text-primary is-size-7 mh30">
              {`Cupon utilizado ${code}`}
            </TextCustom>
          </div>
        )}
        <hr className="mt-2 mb-5 is-dottedLine"></hr>
        <div className="level is-mobile mh30">
          <TitleCustom className="is-size-5 are-color-mynd-grey mb-4">
            Precio total
          </TitleCustom>
          <TitleCustom className="is-size-5 are-color-mynd-grey mb-4">
            {`${amount > 0 ? `${formatToMoney(amount)}` : '¡Gratis!'}`}
          </TitleCustom>
        </div>
      </div>

      {paymentMethod && (
        <div className="orders buttons">
          <div className="column is-3">
            <ButtonCustom
              handleOnClick={handleBackButton}
              buttonClassName="button is-text-color is-inverted px-0 level-item"
            >
              Anterior
            </ButtonCustom>
          </div>
          <div className="column is-9">
            <CustomButton isPrimary isLoading={isLoading} handleClick={next}>
              Confirmar pago
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  )
}

PurchaseSummary.propTypes = {
  amount: PropTypes.string,
  typeConsult: PropTypes.string,
  doctor: PropTypes.object,
  selectMethod: PropTypes.func,
  coupons: PropTypes.array,
  paymentMethod: PropTypes.object,
  next: PropTypes.func,
  date: PropTypes.object,
  isLoading: PropTypes.bool,
  coupon: PropTypes.object,
  setCoupon: PropTypes.func,
  priceWithoutCoupon: PropTypes.string,
}

export default PurchaseSummary
