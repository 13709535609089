export default (auth, ui) => {
  const { uid, data } = auth.user
  const { fullname, phone, email } = data
  const { loading, errors } = ui
  return {
    uid,
    fullname,
    phone,
    email,
    loading,
    errors,
  }
}
