import functions from '../functions'

const { handleOrderPaypalBalanceRechargeFunction } = functions.cloudFunctions

const handleOrderPaypalBalanceRecharge = async data => {
  try {
    return await handleOrderPaypalBalanceRechargeFunction(data)
  } catch (error) {
    console.log('error: ', error)
  }
}

export default handleOrderPaypalBalanceRecharge
