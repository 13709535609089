import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import rootReducer from '../reducers'

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
)

const persistor = persistStore(store)

export { store, persistor }
