export default (ui, auth) => {
  const { loading, errors } = ui
  const { error } = auth
  const { message } = error
  return {
    loading,
    message,
    errors,
  }
}
