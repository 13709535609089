// import PropTypes from 'prop-types'
import { TextCustom, MakeYourInquiryBox } from '../../components/'

const EmptyTreatmentRoute = () => {
  return (
    <div className="without-route">
      <div>
        <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4">
          Te invitamos a que inicies tu ruta del tratamiento agendando tu primer
          cita
        </TextCustom>
      </div>

      <MakeYourInquiryBox isEmpty />
    </div>
  )
}

EmptyTreatmentRoute.propTypes = {}

export default EmptyTreatmentRoute
