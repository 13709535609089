import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AuthRouter } from './AuthRouter'

import { LoggedRoute } from './LoggedRoute'
import { Dashboard, UpdateUser } from '../containers'
import PublicRoute from './PublicRoute'
import { useLoggedIn } from '../utils'
import { useEffect, useState } from 'react'
import { getAddressService } from '../services'
import SignInRoute from './SignInRoute'
import { authActions } from '../actions'
const { removeAuthErrorsAction } = authActions.creators

const AppRouter = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [forgottenToken, setForgottenToken] = useState('')
  // const [refresh, setRefresh] = useState(false)
  const { user, isSignIn } = useSelector(state => state.auth)
  const { isNewUser } = user
  const [checking, isLoggedIn] = useLoggedIn(user)

  window.onpopstate = function () {
    if (location.pathname === '/user' && !isLoggedIn) {
      history.go(0)
    }
    if (location.pathname === '/auth/login' && !isLoggedIn) {
      history.pushState({}, '', '/')
    }
    if (location.pathname === '/' && !isLoggedIn) {
      history.go(0)
    }
  }

  useEffect(() => {
    dispatch(getAddressService(user.uid))
    if (isLoggedIn && !isNewUser && !isSignIn) setShow(true)
    else setShow(false)
    if (location.pathname === '/auth/forgotten_password') {
      setForgottenToken(`/auth/forgotten_password${location.search}`)
    } else if (location.pathname === '/auth/login') {
      setForgottenToken('')
    }
    return () => {}
  }, [isLoggedIn, isNewUser, isSignIn])

  useEffect(() => {
    dispatch(removeAuthErrorsAction())
    return () => {}
  }, [])
  if (checking) {
    return <h1>Espere...</h1>
  }
  return (
    <Router>
      <Switch>
        <Dashboard show={show}>
          <div className="dashboard-children">
            {!isLoggedIn && (
              <PublicRoute
                isAuth={isLoggedIn}
                forgottenToken={forgottenToken}
                path="/auth"
                component={AuthRouter}
              />
            )}
            {isLoggedIn && isSignIn && (
              <SignInRoute
                isSignIn={isSignIn}
                path="/signIn"
                component={UpdateUser}
              />
            )}

            {isLoggedIn && !isSignIn && <LoggedRoute isLoggedIn={isLoggedIn} />}

            <Redirect
              to={
                !isLoggedIn
                  ? '/auth/login'
                  : isLoggedIn && isSignIn
                  ? '/signIn'
                  : '/user'
              }
            />
          </div>
        </Dashboard>
      </Switch>
    </Router>
  )
}

export default AppRouter
