// components
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import { Header, Footer, Loading, CreateAccount } from '../../components'
import dispatchers from './dispatchers'
import selectors from './selectors'
import { useEffect, useState } from 'react'
import { useForm } from '../../utils'

const RegisterScreen = () => {
  const dispatch = useDispatch()
  const {
    addError,
    createUser,
    removeError,
    signInWithProvider,
    removeAuthErrors,
  } = dispatchers(dispatch)
  const { ui, auth } = useSelector(state => state)
  const { loading, message, errors } = selectors(ui, auth)
  const [isLoading, setIsLoading] = useState(false)
  const [{ email, password, password2 }, handleInputChange] = useForm({
    email: '',
    password: '',
    password2: '',
  })

  const handleRegister = async () => {
    setIsLoading(true)
    if (!validator.isEmail(email)) {
      setIsLoading(false)
      return addError('Ingrese un email correcto')
    }
    if (password !== password2 || password.length < 5) {
      setIsLoading(false)
      return addError(
        'Contraseña debe de ser mayor a 5 y concidir ambas contraseñas',
      )
    }
    createUser(email, password)
    setIsLoading(false)
  }

  useEffect(() => {
    if (
      (email !== '' || password2 !== '' || password !== '') &&
      (message || errors)
    ) {
      removeError()
      removeAuthErrors()
    }
    return () => {}
  }, [email, password2, password])

  return (
    <section className="hero is-fullheight">
      <div className="hero-head pt-4 pl-4">
        <Header />
      </div>
      <div className="hero-body  pb-5 pt-5 is-flex is-align-items-start ">
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              {!loading ? (
                <CreateAccount
                  handleInputChange={handleInputChange}
                  handleRegister={handleRegister}
                  message={message}
                  email={email}
                  password={password}
                  password2={password2}
                  isLoading={isLoading}
                  errors={errors}
                  signInWithProvider={signInWithProvider}
                />
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hero-foot is-align-content-end">
        <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
      </div>
    </section>
  )
}

export default RegisterScreen
