import { SwitchButton } from '../../components'

const ConfigurationScreen = () => {
  return (
    <div className="container is-mobile mt-5">
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <h1 className="title">Configuration</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus nec iaculis mauris.
              </p>

              <div className="field">
                <label className="label">
                  <span>Tema</span>
                </label>
                <div className="control">
                  <SwitchButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigurationScreen
