// import PropTypes from 'prop-types'
import { TitleCustom, TextCustom, ButtonCustom, CustomButton } from '..'

const modalCodeConfirmation = ({ data, closeModal, onPress }) => {
  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card is-modal-border-radius">
        <section className="modal-card-body">
          <div className="level-right mb-2 is-mobile">
            <ButtonCustom
              icon="far fa-times-circle"
              buttonClassName="button is-inverted is-border-button-modal"
              iconClassName="are-color-mynd-grey is-size-4"
              onClick={closeModal}
            />
          </div>

          <div className="container">
            <TitleCustom className="are-color-mynd-grey has-text-weight-semibold has-text-centered is-size-5 mb-3">
              Alerta
            </TitleCustom>
            <TextCustom className="are-color-mynd-grey  has-text-centered mb-4 mt-0">
              {data.msg}
            </TextCustom>
            <CustomButton
              isPrimary
              handleClick={data.success ? onPress : closeModal}
            >
              Aceptar
            </CustomButton>
          </div>
        </section>
      </div>
    </div>
  )
}

modalCodeConfirmation.propTypes = {}

export default modalCodeConfirmation
