import PropTypes from 'prop-types'
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { useState } from 'react'
import { ImageCustom, LabelCustom, Input } from '../../components'
import { useForm } from '../../utils'
import Images from '../../assets'
import moment from 'moment'

const AccordionMyBody = ({ data, medicalRecord, uid }) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const { bodyWeight, bodyHeight, sex, lastMenstruation } = data
  console.log(`{bodyWeight, bodyHeight, sex}`, { bodyWeight, bodyHeight, sex })
  const { personIcon, arrowDown } = Images
  const genders = ['m', 'f']

  const [
    { bodyHeightValue, bodyWeightValue, sexValue, lastMenstruationValue },
    handleInputChange,
  ] = useForm({
    bodyHeightValue: bodyHeight,
    bodyWeightValue: bodyWeight,
    sexValue: sex,
    lastMenstruationValue: '',
  })

  const isValid = () => {
    // validation
    if (isNaN(bodyHeightValue)) {
      setErrorAlert({
        show: true,
        message: 'Introduce tu altura en formato numérico',
      })
      return false
    }
    if (isNaN(bodyWeightValue)) {
      setErrorAlert({
        show: true,
        message: 'Introduce tu peso en formato numérico',
      })
      return false
    }
    if (sexValue === '') {
      setErrorAlert({ show: true, message: 'Hay que seleccionar un sexo' })
      return false
    }
    if (sexValue === 'f' && lastMenstruationValue === '') {
      setErrorAlert({ show: true, message: 'Hay que seleccionar un dia' })
      return false
    }

    const data = {
      bodyHeight: String(Number(bodyHeightValue)),
      bodyWeight: String(Number(bodyWeightValue)),
      sex: sexValue,
    }
    // save data
    if (sexValue === 'f') {
      const lastMenstruationFormatted = new Date(lastMenstruationValue)
      lastMenstruationFormatted.toISOString()
      data.lastMenstruation = lastMenstruationFormatted
    }

    medicalRecord({ method: 'POST', uid, data })
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={personIcon}
              className="is-size-person-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Mi cuerpo</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 ">
        {errorAlert.show && (
          <div className="notification is-danger mt-3">
            {errorAlert.message}
          </div>
        )}
        <div className="columns is-mobile my-0">
          <div className="column is-6 pb-0">
            <LabelCustom className="has-text-weight-light mt-2 mr-2">
              Altura (cm):
            </LabelCustom>
            <Input
              placeholder={'Altura en centimetros'}
              className="input"
              name="bodyHeightValue"
              value={bodyHeightValue}
              onChange={handleInputChange}
            />
          </div>
          <div className="column is-6 pb-0">
            <LabelCustom className="has-text-weight-light mt-2 mr-2">
              Peso (kg)
            </LabelCustom>
            <Input
              placeholder={'Peso en kg'}
              className="input"
              name="bodyWeightValue"
              value={bodyWeightValue}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="is-flex is-flex-wrap-wrap mb-3">
          <LabelCustom className="mt-5 mr-3">Género:</LabelCustom>
          <div className="control mt-1">
            {genders.map((gender, key) => (
              <LabelCustom className="radio mr-3 mt-3 " key={key}>
                <input
                  type="radio"
                  className="is-radio-input-drop mr-3 "
                  name="sexValue"
                  value={gender}
                  onChange={handleInputChange}
                  defaultChecked={gender === sexValue}
                />
                {gender === 'm' ? 'Hombre' : 'Mujer'}
              </LabelCustom>
            ))}
          </div>
        </div>

        {sexValue === 'f' && (
          <>
            <LabelCustom className=" mr-3 mt-3">
              Última mesntruacion:
              {`${
                lastMenstruation
                  ? moment(new Date(lastMenstruation.seconds * 1000))
                      .add(1, 'days')
                      .format('DD/MM/YYYY')
                  : ' No se ha registrado ninguna fecha'
              }`}
            </LabelCustom>
            <Input
              className="input mt-4"
              type="date"
              name="lastMenstruationValue"
              onChange={handleInputChange}
            />
          </>
        )}
        <button
          className="button has-text-white is-mnd-primary mt-2"
          onClick={isValid}
        >
          Guardar
        </button>
      </AccordionItemPanel>
    </>
  )
}

AccordionMyBody.propTypes = {
  data: PropTypes.object,
  handleInputChange: PropTypes.func,
  medicalRecord: PropTypes.func,
  uid: PropTypes.string,
}

export default AccordionMyBody
