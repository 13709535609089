import functions from '../functions'

const { createDiary } = functions.cloudFunctions
const createDiaryService = async () => {
  try {
    const response = await createDiary({})
    return response.data
  } catch (e) {
    return e
  }
}
export default createDiaryService
