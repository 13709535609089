import { uiActions } from '../../actions'
import { addCouponsService, getCouponsService } from '../../services'
const { addError, removeError, addSuccess } = uiActions.creators
export default dispatch => {
  return {
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
    addCoupons: code => dispatch(addCouponsService(code)),
    getCoupons: uid => dispatch(getCouponsService(uid)),
    addSuccess: success => dispatch(addSuccess(success)),
  }
}
