import PropTypes from 'prop-types'

import { TextCustom } from '..'

const drugAditionalInfo = ({ info }) => {
  const aditionalInfo =
    info ||
    `Moje el cabello completamente, aplique el shampoo generosamente, luego
    enjuague completamente. Usar cada 3 a 4 días hasta por 8 semanas,
    según lo indicado por el doctor. Luego úselo solamente según sea
    necesario para controlar la caspa.`
  return (
    <div className="mx-5">
      <TextCustom className="mx-4 is-flex my-4">Modo de uso:</TextCustom>
      <TextCustom>
        <span>{aditionalInfo}</span>
      </TextCustom>
    </div>
  )
}
drugAditionalInfo.propTypes = {
  info: PropTypes.string,
}
export default drugAditionalInfo
