export default (ui, auth) => {
  const { loading, errors } = ui
  const { error } = auth
  let { message } = error

  if (error.code === 'auth/email-already-in-use') {
    message = 'Este correo ya está en uso. Por favor inténta con otro.'
  }
  return {
    loading,
    message,
    errors,
  }
}
