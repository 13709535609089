import functions from '../functions'

const { generateAsyncBuyDrugList } = functions.cloudFunctions

const generateAsyncBuyDrugListService = ({ consultId, values }) => {
  return async dispatch => {
    try {
      const response = await generateAsyncBuyDrugList({
        consultId,
        values,
      })
      return response
    } catch (error) {
      return error
    }
  }
}

export default generateAsyncBuyDrugListService
