import { usersF, firebase } from '../../config'

const deleteDoctorFav = ({ userId, id }) => {
  return async dispatch => {
    const field = firebase.firestore.FieldValue
    try {
      await usersF.doc(userId).update(
        {
          favorities: field.arrayRemove(id),
        },
        { merge: true },
      )
    } catch (error) {
      console.log(error)
    }
  }
}

export default deleteDoctorFav
