import functions from '../functions'
const { generateAsynchronousPaymentReceipt } = functions.cloudFunctions
const generateAsyncPaymentReceiptService = ({ consultId, amount, type }) => {
  return async dispatch => {
    try {
      const response = await generateAsynchronousPaymentReceipt({
        consultId,
        amount,
        type,
      })
      return response
    } catch (error) {
      return error
    }
  }
}

export default generateAsyncPaymentReceiptService
