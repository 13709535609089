import PropTypes from 'prop-types'
import { ImageCustom, CouponContainer, ShareCode } from '../../components'
import Images from '../../assets'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import selectors from './selectors.js'

const addCoupons = ({
  coupons,
  addCoupons,
  addError,
  removeError,
  addSuccess,
  setLoading,
}) => {
  const {
    cuponIcon,
    personWithLaptop,
    //  womanOnLaptop
  } = Images
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { auth } = useSelector(state => state)
  const { specialty, codeFriend } = selectors(auth)

  const after = response => {
    setCode('')
    setLoading(true)
    // await getCoupons(uid)
    setIsLoading(false)
    addSuccess(
      `Cupón ${code} agregado ${
        response.data && response.data.specialty
          ? ' para la especialidad ' + response.data.specialty
          : ''
      } `,
    )
  }

  const addNewCoupon = async () => {
    removeError()
    if (!code) {
      addError('Primero debes ingresar un código')
      return
    }
    setIsLoading(true)
    const response = await addCoupons(code)
    if (response.error) {
      addError(response.error)
      setIsLoading(false)
    } else {
      setTimeout(() => {
        after(response)
      }, 1500)
    }
  }

  const filterCouponBySpeciality = coupon => {
    if (
      (!coupon.data.specialty && !specialty) ||
      coupon.data.specialty === specialty
    ) {
      return true
    } else {
      return false
    }
  }

  const onChange = e => {
    removeError()
    setCode(e.target.value.toUpperCase())
  }

  return (
    <>
      <div className="field has-addons is-position-coupon">
        <div className="control has-icons-left is-size-input-coupon">
          <input
            className="input is-medium "
            type="text"
            placeholder="Ingresa tu cupón"
            value={code}
            onChange={onChange}
          />
          <span className="icon is-small is-left">
            <ImageCustom img={cuponIcon} className="is-size-coupon-img" />
          </span>
        </div>
        <div className="control">
          <button
            className={`button is-colour-button-coupon is-medium ${
              isLoading && 'is-loading'
            }`}
            onClick={addNewCoupon}
          >
            Añadir
          </button>
        </div>
      </div>
      {codeFriend && <ShareCode codeFriend={codeFriend} />}
      {coupons &&
        coupons
          .filter(coupon => filterCouponBySpeciality(coupon))
          .map(coupon => {
            return (
              <CouponContainer
                key={coupon.id}
                img={personWithLaptop}
                coupon={coupon}
              />
            )
          })}
      {/*
      <CouponContainer img={personWithLaptop} />
      <CouponContainer img={womanOnLaptop} /> */}
    </>
  )
}

addCoupons.propTypes = {
  coupons: PropTypes.array,
  addCoupons: PropTypes.func,
  getCoupons: PropTypes.func,
  uid: PropTypes.string,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  addSuccess: PropTypes.func,
  setLoading: PropTypes.func,
}

export default addCoupons
