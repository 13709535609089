import PropTypes from 'prop-types'

import {
  LabelCustom,
  ImageCustom,
  Input,
  ButtonCustom,
} from '../../../components'

// img
import Images from '../../../assets'
import { useEffect, useState } from 'react'

const ContainerUsageTime = ({ handleInputChange }) => {
  const { clockIcon } = Images
  const allDaysSelected = {
    lunes: true,
    martes: true,
    miercoles: true,
    jueves: true,
    viernes: true,
    sabado: true,
    domingo: true,
  }
  const initialState = {
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,
  }

  const [days, setDays] = useState(initialState)

  const [disableSomeDays, setDisableSomeDays] = useState(false)
  const [any, setAny] = useState(true)

  const toggle = (name, value) => {
    const newState = { ...days, [name]: value }
    setDays(newState)
  }

  const selectAll = () => {
    setDays(allDaysSelected)
  }

  useEffect(() => {
    const a = JSON.stringify(allDaysSelected)
    const b = JSON.stringify(days)
    const c = JSON.stringify(initialState)
    a === b ? setDisableSomeDays(true) : setDisableSomeDays(false)
    b === c ? setAny(false) : setAny(true)
    handleInputChange({ target: { name: 'useDays', value: days } })
  }, [days])

  return (
    <div className="box  mx-3 ">
      <div className="is-flex mb-3">
        <span className="icon mr-2">
          <ImageCustom img={clockIcon} />
        </span>
        <div className="is-size-field-nane-product">
          <LabelCustom className="label are-color-mynd-grey">
            Tiempo de uso
          </LabelCustom>
          <Input
            className="input is-select-size is-fullwidth"
            type="text"
            placeholder="Tiempo de uso(ej. 2 semanas, 1 mes, etc.)"
            name="useTime"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <LabelCustom className="radio are-color-mynd-grey">
        <Input
          type="radio"
          className="is-radio-input-time-diary  mr-2 mb-0"
          name="days"
          onChange={() => selectAll()}
          checked={disableSomeDays}
        />
        Todos los días
      </LabelCustom>
      <LabelCustom className="radio are-color-mynd-grey ml-4">
        <Input
          type="radio"
          className="is-radio-input-time-diary  mr-2 mb-0"
          name="days"
          disabled={disableSomeDays}
          checked={!disableSomeDays && any}
          readOnly
        />
        Algunos días
      </LabelCustom>
      <div className="buttons  is-justify-content-space-between mt-4 mb-1">
        <ButtonCustom
          buttonClassName={`button button-size-days ${
            days.lunes ? 'is-day-selected' : ''
          }`}
          handleOnClick={() => toggle('lunes', !days.lunes)}
        >
          Lunes
        </ButtonCustom>
        <ButtonCustom
          buttonClassName={`button button-size-days ${
            days.martes ? 'is-day-selected' : ''
          }`}
          handleOnClick={() => toggle('martes', !days.martes)}
        >
          Martes
        </ButtonCustom>
        <ButtonCustom
          buttonClassName={`button button-size-days ${
            days.miercoles ? 'is-day-selected' : ''
          }`}
          handleOnClick={() => toggle('miercoles', !days.miercoles)}
        >
          Miércoles
        </ButtonCustom>
      </div>
      <div className="buttons  is-justify-content-space-between mb-1">
        <ButtonCustom
          buttonClassName={`button button-size-days ${
            days.jueves ? 'is-day-selected' : ''
          }`}
          handleOnClick={() => toggle('jueves', !days.jueves)}
        >
          Jueves
        </ButtonCustom>
        <ButtonCustom
          buttonClassName={`button button-size-days ${
            days.viernes ? 'is-day-selected' : ''
          }`}
          handleOnClick={() => toggle('viernes', !days.viernes)}
        >
          Viernes
        </ButtonCustom>
        <ButtonCustom
          buttonClassName={`button button-size-days ${
            days.sabado ? 'is-day-selected' : ''
          }`}
          handleOnClick={() => toggle('sabado', !days.sabado)}
        >
          Sábado
        </ButtonCustom>
      </div>
      <ButtonCustom
        buttonClassName={`button button-size-days ${
          days.domingo ? 'is-day-selected' : ''
        }`}
        handleOnClick={() => toggle('domingo', !days.domingo)}
      >
        Domingo
      </ButtonCustom>
    </div>
  )
}

ContainerUsageTime.propTypes = { handleInputChange: PropTypes.func }

export default ContainerUsageTime
