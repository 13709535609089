import PropTypes from 'prop-types'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import createOrderPaypalBalanceRecharge from '../../services/balance/createOrderPaypalBalanceRecharge'
import handleOrderPaypalBalanceRecharge from '../../services/balance/handleOrderPaypalBalanceRecharge'

const Paypal = ({ handleCreateOrder, amount }) => {
  return (
    <PayPalScriptProvider
      options={{
        'client-id':
          'Ady-GFoxQkgUNILdRDrH95fazXIDM3NGvfivcBm8L3n3BuHckf_ig46QOGTzPgc-vByFPnmxLtepoEhs',
        currency: 'MXN',
      }}
    >
      <PayPalButtons
        createOrder={async () => {
          const response = await createOrderPaypalBalanceRecharge({ amount })
          console.log('response.data.id', response)
          return response.data.id
        }}
        onApprove={async (data, actions) => {
          const response = await handleOrderPaypalBalanceRecharge({
            orderId: data.orderID,
          })
          console.log('response', response)
          handleCreateOrder()
        }}
        onError={error => console.error(error)}
        style={{
          shape: 'rect',
          color: 'white',
          layout: 'horizontal',
          label: 'paypal',
          tagline: false,
          outerHeight: 20,
        }}
      />
    </PayPalScriptProvider>
  )
}

Paypal.propTypes = {
  handleCreateOrder: PropTypes.func,
  amount: PropTypes.string,
  createOrder: PropTypes.func,
}

export default Paypal
