import functions from '../functions'

const { schedulePractiService } = functions.cloudFunctions
const schedulePractiServiceService = async data => {
  try {
    return schedulePractiService(data)
  } catch (error) {
    return error
  }
}

export default schedulePractiServiceService
