// import PropTypes from 'prop-types'

// Components
import { MyAddresses } from '../../components'

const Directions = () => {
  return (
    <div className="container mt-5 mb-5  is-size-container-shoping">
      <MyAddresses />
    </div>
  )
}

Directions.propTypes = {}

export default Directions
