import { Switch, Route, Redirect } from 'react-router-dom'

import {
  LoginScreen,
  ForgotPassword,
  Register,
  ForgottenPassword,
  PhoneAuth,
} from '../containers'

// import Survey from '../components/auth/Survey'

export const AuthRouter = () => {
  // *  path="/auth/forgot_password?mode=:action&oobCode=:code
  return (
    <Switch>
      <Route exact path="/auth/login" component={LoginScreen} />
      <Route exact path="/auth/register" component={Register} />
      <Route exact path="/auth/forgot_password" component={ForgotPassword} />
      <Route
        exact
        path="/auth/forgotten_password"
        component={ForgottenPassword}
      />
      <Route exact path="/auth/phoneLogin" component={PhoneAuth} />
      {/* 

          <Route exact path="/auth/survey" component={Survey} /> */}

      <Redirect to="/auth" />
    </Switch>
  )
}
