export default state => {
  const { errors, loading } = state.ui
  const { currentConsult, prices, categories } = state.consult
  const { data: questions } = state.questionnaire
  const selectedCost = typeConsult => prices[typeConsult]
  const selectedCategories = typeConsult =>
    categories
      .filter(
        category =>
          (category.type === 'both' || category.type === typeConsult) &&
          category.key !== 'general',
      )
      .sort((a, b) => a.priority - b.priority)
  const { user } = state.auth
  const { specialty } = user.data
  return {
    selectedCost,
    selectedCategories,
    questions,
    currentConsult,
    errors,
    loading,
    specialty,
  }
}
