import { updateUser } from '../../services'
import { authActions } from '../../actions'

const { signUpRequestAction, signUpSuccessAction, signOutAction } =
  authActions.creators

export default dispatch => {
  return {
    signOut: () => dispatch(signOutAction()),
    updateUser: (data, uid) => dispatch(updateUser(data, uid)),
    signUpRequest: () => dispatch(signUpRequestAction()),
    signUpSuccess: () => dispatch(signUpSuccessAction()),
  }
}
