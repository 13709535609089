import { authActions } from '../../actions'
import { verifyIsNew, getProvider } from '../../utils'
import { updatePhoneAuth, handleErrors } from '../../services'
import { auth } from '../../config'
const {
  signInWithSocialProviderAction,
  signInWithSocialProviderSuccessAction,
  signInWithSocialProviderFailureAction,
  signUpRequestAction,
} = authActions.creators

const signInWinPopupService = provider => {
  return async dispatch => {
    try {
      dispatch(signInWithSocialProviderAction(provider))
      const result = await getProvider(provider)
      const {
        user: { uid },
        additionalUserInfo: { isNewUser },
      } = result
      const getUser = await verifyIsNew(result, provider)
      if (getUser) {
        dispatch(
          signInWithSocialProviderSuccessAction({
            uid,
            isNewUser,
            data: getUser,
          }),
        )
        dispatch(signUpRequestAction())
        const currentUser = auth.currentUser
        if (!isNewUser && !currentUser.phoneNumber && getUser.phone) {
          const phone = getUser.phone
          await updatePhoneAuth(phone, uid)
        }
      }
    } catch (error) {
      const response = await handleErrors(error, provider)
      dispatch(signInWithSocialProviderFailureAction({ ...response }))
    }
  }
}
export default signInWinPopupService
