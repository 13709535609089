import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useField, useForm } from '../../utils'
// Images
import Images from '../../assets'

// Components
import {
  RegisterBox,
  TitleCustom,
  LabelCustom,
  Input,
  InputSelectCustom,
  ImageCustom,
  CustomButton,
} from '../../components'
import dispatchers from './dispatchers'
import selectors from './selectors'
import { sendCode } from '../../services'
import { useEffect, useState } from 'react'

const PostPhoneNumber = ({ isNeed, setStep, children, token, onClick }) => {
  const dispatch = useDispatch()
  const { addError, removeError } = dispatchers(dispatch)
  const { ui } = useSelector(state => state)
  const { errors } = selectors(ui)
  const { usa, mx, co, userVerifyWhite } = Images
  const ladas = {
    mx: '+52',
    usa: '+1',
    co: '+57',
  }
  const [isLoading, setIsLoading] = useState(false)
  const [cleanup, setCleanup] = useState(false)
  const [{ lada, numberPhone }, handleInputChange] = useForm({
    lada: '+52',
    numberPhone: '',
  })

  const flagImage = () => {
    switch (lada) {
      case '+52':
        return mx
      case '+1':
        return usa
      case '+57':
        return co
      default:
        return mx
    }
  }

  const handleNumberPhone = async e => {
    const numbers = /^[0-9]+$/
    if (e.target.value.match(numbers) || e.target.value === '') {
      handleInputChange(e)
    }
  }

  const fullNumber = lada + numberPhone
  const verifyNumber = async () => {
    const validation = await sendCode(fullNumber)
    if (validation === true) {
      return addError('already exist this number')
    }
    setStep()
  }

  const phoneField = useField({
    className: 'input',
    type: 'text',
    name: 'numberPhone',
    value: numberPhone,
    maxLength: 10,
    onChange: handleNumberPhone,
    placeholder: 'Número telefónico',
  })

  const handleRegister = async () => {
    if (!cleanup) setIsLoading(true)
    if (!lada.trim()) {
      setIsLoading(false)
      return addError('Ingresa tu clave Lada')
    }
    if (!numberPhone.trim() || numberPhone.length < 10) {
      setIsLoading(false)
      return addError('Ingresa tu número correctamente')
    }
    if (!token) {
      setIsLoading(false)
      return addError('Resuelva el CAPTCHA primero')
    }
    removeError()
    if (isNeed) {
      await verifyNumber()
    }
    await onClick(fullNumber)
    setIsLoading(false)
  }

  useEffect(() => {
    if (errors && (numberPhone || lada)) removeError()
  }, [numberPhone, lada])

  useEffect(() => {
    return () => {
      setCleanup(true)
    }
  }, [])

  return (
    <RegisterBox className="px-6">
      <TitleCustom className="title is-5 has-text-centered are-color-text mt-2">
        Validar número de celular
      </TitleCustom>
      <figure className="is-item-centered mt-2 mb-4">
        <ImageCustom img={userVerifyWhite} />
      </figure>

      <p className="has-text-centered is-size-6 mb-3 mt-5 are-color-text">
        Ingresa tu número para que el especialista puede comunicarse contigo.
      </p>

      {errors && <div className="notification is-danger">{errors}</div>}
      <div className="auth-phone-container">
        <div>
          <LabelCustom className="are-color-text">País</LabelCustom>
          <p className="control has-icons-left">
            <span className="select ">
              <InputSelectCustom
                name="lada"
                handleInputChange={handleInputChange}
                defaultValue="+52"
                withoutNull
              >
                {ladas}
              </InputSelectCustom>
            </span>
            {lada !== ' ' && (
              <span className="icon is-small is-left">
                <ImageCustom img={flagImage()} width="30" />
              </span>
            )}
          </p>
        </div>
        <div className="auth-phone-number">
          <LabelCustom className="are-color-text">Celular</LabelCustom>
          <Input {...phoneField} />
        </div>
      </div>
      {children}
      <CustomButton
        isPrimary
        isLoading={isLoading}
        handleClick={handleRegister}
      >
        Validar número
      </CustomButton>
    </RegisterBox>
  )
}

PostPhoneNumber.propTypes = {
  isNeed: PropTypes.bool,
  setStep: PropTypes.func,
  children: PropTypes.object,
  token: PropTypes.string,
  onClick: PropTypes.func,
}

export default PostPhoneNumber
