// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { countries, mxStates, usaStates, coStates } from '../../utils/constants'

import { useEffect, useState } from 'react'
// compents
import { Input, LabelCustom, InputSelectCustom, ButtonCustom } from '..'
import moment from 'moment'
import selectors from './selectors'

const containerForm = ({ data, handleUpdateProfile }) => {
  const { auth, ui } = useSelector(state => state)
  const { errors } = selectors(auth, ui)
  const [stateList, setStateList] = useState({})
  const [country, setCountry] = useState(data.location.country || ' ')
  const [state, setState] = useState(data.location.state || ' ')
  const [fullname, setfullName] = useState(data.fullname)
  const countryList = Object.values(countries)

  const countryStates = {
    ' ': [],
    México: mxStates,
    'United States': usaStates,
    Colombia: coStates,
  }

  const stateListSelector = () => {
    if (country) {
      setStateList(countryStates[country])
    }
  }

  useEffect(() => {
    stateListSelector()
  }, [country])

  return (
    <>
      <div className="">
        <div className="field ">
          <LabelCustom className="label are-color-mynd-grey">
            Nombre y apellido
          </LabelCustom>
          <Input
            value={fullname}
            className="input is-select-size"
            type="text"
            placeholder="Juan Castellanos Lopez"
            onChange={e => setfullName(e.target.value)}
          />
        </div>
        <div className="is-spacing">
          <LabelCustom className="label are-color-mynd-grey">
            Correo:
          </LabelCustom>
          <Input
            disabled
            value={data.email}
            className="input is-select-size"
            type="email"
            placeholder="chava@gmail.com"
          />
        </div>
        {data.birthday && (
          <div className="field is-spacing">
            <LabelCustom className="label are-color-mynd-grey">
              Fecha de nacimiento:
            </LabelCustom>
            <Input
              value={moment(new Date(data.birthday.seconds * 1000)).format(
                'DD/MM/YYYY',
              )}
              disabled
              className="input is-select-size"
              type="text"
            />
          </div>
        )}
        {errors && <div className="notification is-danger mt-3">{errors}</div>}
        <div className="field is-spacing">
          <LabelCustom className="label are-color-mynd-grey">País</LabelCustom>
          <div className="select is-select-size is-medium">
            <div className="select is-fullwidth">
              <InputSelectCustom
                value={country}
                className="are-color-text"
                name="country"
                handleInputChange={({ target }) => {
                  setState(' ')
                  setCountry(target.value)
                }}
              >
                {countryList || []}
              </InputSelectCustom>
            </div>
          </div>
        </div>
        <div className="field  is-spacing mb-5">
          <LabelCustom className="label are-color-mynd-grey">
            Región
          </LabelCustom>
          <div className="select is-select-size is-medium">
            <div className="select is-fullwidth">
              <InputSelectCustom
                value={state}
                name="state"
                handleInputChange={({ target }) => setState(target.value)}
              >
                {stateList || []}
              </InputSelectCustom>
            </div>
          </div>
        </div>
        <ButtonCustom
          handleOnClick={() => {
            handleUpdateProfile(fullname, { country, state })
          }}
          buttonClassName="button is-fullwidth has-text-white is-mnd-primary is-button-address"
        >
          Actualizar información
        </ButtonCustom>
      </div>
    </>
  )
}

containerForm.propTypes = {}

export default containerForm
