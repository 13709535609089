import PropTypes from 'prop-types'
import { ImageCustom } from '..'
import Images from '../../assets'

const address = ({ address, index, disableLine }) => {
  const { description } = address
  const { homeIcon } = Images
  return (
    <>
      <div
        className=" is-flex mb-3 mt-3 is-justify-content-space-between"
        key={index}
      >
        <div className="is-flex is-justify-content-space-between	">
          <ImageCustom img={homeIcon} className="is-size-img-address" />
          {description && <p className="has-text-center">{description}</p>}
        </div>
      </div>
      {!disableLine && <hr className="is-line address"></hr>}
    </>
  )
}

address.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  disableLine: PropTypes.bool,
}

export default address
