import { clinicalRecordActions } from '../../actions'
const {
  GET_MEDICAL_RECORD,
  GET_MEDICAL_RECORD_SUCCESS,
  GET_MEDICAL_RECORD_FAILURE,
  SET_MEDICAL_RECORD,
  SET_MEDICAL_RECORD_SUCCESS,
  SET_MEDICAL_RECORD_FAILURE,
  UPDATE_MEDICAL_RECORD,
  UPDATE_MEDICAL_RECORD_SUCCESS,
  UPDATE_MEDICAL_RECORD_FAILURE,
  DELETE_MEDICAL_RECORD,
  DELETE_MEDICAL_RECORD_SUCCESS,
  DELETE_MEDICAL_RECORD_FAILURE,
  CLEAR_CLINICAL_RECORDS,
} = clinicalRecordActions.types

const initialState = {
  isFetching: false,
  data: {},
  error: {},
}

const medicalRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICAL_RECORD:
    case SET_MEDICAL_RECORD:
    case UPDATE_MEDICAL_RECORD:
    case DELETE_MEDICAL_RECORD:
      return {
        ...state,
        isFetching: true,
      }

    case GET_MEDICAL_RECORD_SUCCESS:
    case SET_MEDICAL_RECORD_SUCCESS:
    case UPDATE_MEDICAL_RECORD_SUCCESS:
    case DELETE_MEDICAL_RECORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      }
    case GET_MEDICAL_RECORD_FAILURE:
    case SET_MEDICAL_RECORD_FAILURE:
    case UPDATE_MEDICAL_RECORD_FAILURE:
    case DELETE_MEDICAL_RECORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_CLINICAL_RECORDS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default medicalRecordReducer
