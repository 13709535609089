import PropTypes from 'prop-types'
const HeroFooter = ({ title }) => {
  return (
    <div className="hero-footer is-mobile ">
      <div className="has-text-centered">
        <span className="subtitle-is-desktop is-4 has-text-black  has-text-weight-bold">
          {title}
        </span>
      </div>
    </div>
  )
}

HeroFooter.propTypes = {
  title: PropTypes.string.isRequired,
}

export default HeroFooter
