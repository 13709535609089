import { functions } from '../../config'

const updatePhoneUser = async (phone, uid) => {
  try {
    const updatePhoneData = functions.httpsCallable('updatePhone')
    const response = updatePhoneData({
      phoneNumber: phone,
      uid: uid,
    })
    return response
  } catch (error) {
    return error
  }
}

export default updatePhoneUser
