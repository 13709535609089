import functions from '../functions'

const { getAvailableHoursPrivate } = functions.cloudFunctions
const getAvailableHoursServicePrivate = data => {
  return async dispatch => {
    try {
      const response = await getAvailableHoursPrivate(data)
      return response
    } catch (error) {
      console.log(error)
    }
  }
}

export default getAvailableHoursServicePrivate
