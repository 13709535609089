// import PropTypes from 'prop-types'

// components

const CouponsUsedContainer = props => {
  return (
    <div className="is-flex is-justify-content-center">
      <span className="has-text-centered is-size-4 are-color-mynd-grey">
        Próximamente...
      </span>
    </div>
  )
}

CouponsUsedContainer.propTypes = {}

export default CouponsUsedContainer
