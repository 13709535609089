import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useToggle } from '../../utils'
import { filterBlocks } from '../../utils/constants'
import selectors from './selectors.js'

// components
import { TextCustom, CardDoctor } from '..'

// helpers
import { validateDoctorItem, validateFilterDoctor } from '../../utils/sections'

const DoctorsList = ({ doctors, idDocsFav }) => {
  const { auth } = useSelector(state => state)
  const { specialty } = selectors(auth)
  const filterBlocksList = Object.values(filterBlocks)
  const [workModelIndividual, setWorkModelIndividual] = useToggle(true)
  const [workModelCouple, setWorkModelCouple] = useToggle(true)
  const [sexMasculine, setSexMasculine] = useToggle(true)
  const [sexFeminine, setSexFeminine] = useToggle(true)
  const [blocks, setBlocks] = useState(0)

  const buildList = () => {
    if (validateFilterDoctor()) {
      const filterObj = {
        workModel: {
          individual: workModelIndividual,
          couple: workModelCouple,
        },
        sex: {
          masculine: sexMasculine,
          feminine: sexFeminine,
        },
        consultDuration: blocks,
      }

      return doctors.filter(item =>
        validateDoctorItem(specialty, item, filterObj),
      )
    } else {
      return doctors.filter(item => validateDoctorItem(specialty, item))
    }
  }

  return (
    <div className="doctor-list-view">
      {validateFilterDoctor() && (
        <div className="doctor-list-view filters">
          <TextCustom className="are-color-mynd-grey has-text-weight-bold is-size-5  is-spacing-category">
            Filtros
          </TextCustom>

          <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mt-2">
            Modelo de trabajo:
          </TextCustom>
          <div className="field flex-field">
            <label className="label">
              <span>Individual</span>
            </label>
            <div className="control">
              <label className="switch">
                <input
                  type="checkbox"
                  id="workModelIndividual"
                  checked={workModelIndividual}
                  onClick={setWorkModelIndividual}
                />
                <span className="slider1 round"></span>
              </label>
            </div>
          </div>
          <div className="field flex-field">
            <label className="label">
              <span>Pareja</span>
            </label>
            <div className="control">
              <label className="switch">
                <input
                  type="checkbox"
                  id="workModelCouple"
                  checked={workModelCouple}
                  onClick={setWorkModelCouple}
                />
                <span className="slider1 round"></span>
              </label>
            </div>
          </div>

          <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mt-2">
            Genero del psicólogo:
          </TextCustom>
          <div className="field flex-field">
            <label className="label">
              <span>Masculino</span>
            </label>
            <div className="control">
              <label className="switch">
                <input
                  type="checkbox"
                  id="sexMasculine"
                  checked={sexMasculine}
                  onClick={setSexMasculine}
                />
                <span className="slider1 round"></span>
              </label>
            </div>
          </div>
          <div className="field flex-field">
            <label className="label">
              <span>Femenino</span>
            </label>
            <div className="control">
              <label className="switch">
                <input
                  type="checkbox"
                  id="sexFeminine"
                  checked={sexFeminine}
                  onClick={setSexFeminine}
                />
                <span className="slider1 round"></span>
              </label>
            </div>
          </div>

          <div className="field is-spacing padding-box">
            <label className="label">
              <span>Duración de la consulta</span>
            </label>
            <div className="select is-select-size is-medium">
              <div className="select is-fullwidth">
                <select
                  className="is-fullwidth are-color-text"
                  onChange={e => setBlocks(e.target.value)}
                >
                  {Object.entries(filterBlocksList).map(([key, value]) => {
                    return (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`doctor-list-view ${
          validateFilterDoctor() ? 'list-partial' : 'list-full'
        }`}
      >
        <div className="doctor-cards-list mt-4">
          {buildList().map((doctor, key) => {
            return (
              <CardDoctor
                key={key}
                doctorId={doctor.id}
                doctor={doctor}
                idDocsFav={idDocsFav}
                specialty={specialty}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

DoctorsList.propTypes = {
  doctors: PropTypes.array,
  idDocsFav: PropTypes.array,
}

export default DoctorsList
