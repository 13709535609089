import PropTypes from 'prop-types'

const TextCustom = ({ className, children }) => (
  <p className={`are-color-mynd-grey ${className}`}>{children}</p>
)

TextCustom.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export default TextCustom
