import PropTypes from 'prop-types'
import validator from 'validator'
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import {
  ImageCustom,
  LabelCustom,
  TextCustom,
  ButtonCustom,
  Input,
} from '../../components'
import Images from '../../assets'
import { useForm } from '../../utils'
import { useState } from 'react'

const AccordionAllergies = ({
  withoutAllergyProp,
  allergiesProp,
  uid,
  medicalRecord,
}) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const [addNew, setAddNew] = useState(false)
  const { bacteriumIcon, arrowDown } = Images
  const isAllergy = withoutAllergyProp
  console.log(`isAllergy`, isAllergy)
  const [{ name, intensity, withoutAllergy }, handleInputChange, , , reset] =
    useForm({
      name: '',
      intensity: '',
      withoutAllergy: isAllergy ? 'true' : 'false',
    })

  const optionAllergies = [
    {
      value: true,
      label: 'Sin alergias',
      name: 'withoutAllergy',
    },
    {
      value: false,
      label: 'Con alergias',
      name: 'withoutAllergy',
    },
  ]

  const optionIntensity = [
    {
      value: 'low',
      label: 'Baja',
    },
    {
      value: 'medium',
      label: 'Media',
    },
    {
      value: 'high',
      label: 'Alta',
    },
  ]

  const deleteItem = index => {
    const data = {
      allergies: [],
    }

    if (allergiesProp.length > 1) {
      const listItems = [...allergiesProp]
      listItems.splice(index, 1)
      data.allergies = listItems
    }
    if (allergiesProp.length === 1) {
      data.withoutAllergy = true
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
    reset()
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (validator.isEmpty(name)) {
      setErrorAlert({ show: true, message: 'Ingresa tu tipo de alergia' })
      return false
    }
    if (validator.isEmpty(intensity)) {
      setErrorAlert({
        show: true,
        message: 'Hay que seleccionar la intensidad',
      })
      return false
    }

    const data = {
      allergies: [
        ...allergiesProp,
        {
          name,
          intensity,
        },
      ],
      withoutAllergy: false,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
    reset()
  }

  const handleOnClick = () => {
    const data = {
      allergies: [],
      withoutAllergy: true,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={bacteriumIcon}
              className="is-size-bacterium-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Alergias</span>
            <span className="are-color-mynd-grey ml-4 has-text-weight-light">
              ({allergiesProp ? allergiesProp.length : 0} tipos)
            </span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        <div className="control">
          {optionAllergies.map(({ value, label, name }, index) => (
            <LabelCustom className="radio mr-3 ml-0 is-size-6" key={index}>
              <input
                type="radio"
                className="is-radio-input-drop mr-3"
                name={name}
                defaultValue={value}
                onChange={handleInputChange}
                defaultChecked={isAllergy === value}
              />
              {label}
            </LabelCustom>
          ))}
        </div>
        {withoutAllergy === 'false' && (
          <>
            {allergiesProp &&
              allergiesProp.map(({ name, intensity }, index) => (
                <div
                  className="is-background-box-allergies mt-3 px-3 "
                  key={index}
                >
                  <div className="level mb-1 is-mobile">
                    <div className="level-left"></div>
                    <div className="level-right ">
                      <ButtonCustom
                        buttonClassName="button is-button-close"
                        icon="fas fa-times"
                        onClick={() => deleteItem(index)}
                      />
                    </div>
                  </div>
                  <Input
                    className="input"
                    type="text"
                    placeholder="Describir alergia"
                    defaultValue={name}
                    readOnly={true}
                  />
                  <div className="control mt-3">
                    <TextCustom className="px-0 py-2 ">
                      <span>Intentsidad: </span>
                      {(intensity === 'high' && 'Alta') ||
                        (intensity === 'medium' && 'Media') ||
                        (intensity === 'low' && 'Baja')}
                    </TextCustom>
                  </div>
                </div>
              ))}
          </>
        )}
        {withoutAllergy === 'false' && addNew && (
          <form onSubmit={handleOnSubmit}>
            <div className="is-background-box-allergies mt-3 px-3">
              {errorAlert.show && (
                <div className="notification is-danger mt-3">
                  {errorAlert.message}
                </div>
              )}
              <div className="level mb-1 is-mobile">
                <div className="level-left">
                  <TextCustom className="are-color-mynd-grey  px-1 is-size-6"></TextCustom>
                </div>
                <div className="level-right ">
                  <ButtonCustom
                    buttonClassName="button is-button-close"
                    icon="fas fa-times"
                    onClick={() => {
                      setErrorAlert({ show: false, message: '' })
                      setAddNew(false)
                    }}
                  />
                </div>
              </div>
              <Input
                className="input"
                type="text"
                placeholder="Describir alergia"
                name="name"
                onChange={handleInputChange}
              />
              <div className="control mt-3">
                <TextCustom className="px-0 py-2 ">
                  <span>Intentsidad</span>
                </TextCustom>
                {optionIntensity.map(({ label, value }, index) => (
                  <LabelCustom
                    className="radio mr-3 ml-0 is-size-6"
                    key={index}
                  >
                    <input
                      type="radio"
                      className="is-radio-input-drop mr-3"
                      name="intensity"
                      value={value}
                      onChange={handleInputChange}
                    />
                    {label}
                  </LabelCustom>
                ))}
              </div>
              <button className="button has-text-white is-mnd-primary mt-2">
                Guardar
              </button>
            </div>
          </form>
        )}
        {withoutAllergy === 'false' && !addNew && (
          <button
            className="button has-text-white is-mnd-primary mt-2 mr-2"
            onClick={() => setAddNew(true)}
          >
            Agregar alergia
          </button>
        )}
        {withoutAllergy === 'true' && (
          <button
            className="button has-text-white is-mnd-primary mt-2"
            onClick={handleOnClick}
          >
            Guardar
          </button>
        )}
      </AccordionItemPanel>
    </>
  )
}

AccordionAllergies.propTypes = {
  withoutAllergyProp: PropTypes.bool,
  allergiesProp: PropTypes.array,
  uid: PropTypes.string,
  medicalRecord: PropTypes.func,
}

export default AccordionAllergies
