import {
  getCategoriesForSpecialtyService,
  setUserSpecialty,
} from '../../services'

export default dispatch => {
  return {
    setUserSpecialty: (specialty, uid) =>
      dispatch(setUserSpecialty(specialty, uid)),
    getCategoriesForSpecialty: specialty =>
      dispatch(getCategoriesForSpecialtyService(specialty)),
  }
}
