import PropTypes from 'prop-types'

// Components
import { TitleCustom, ImageCustom, TextCustom, ButtonCustom } from '..'
import CheckTransparentn from '../../assets/CheckTransparent.png'

const PaymentCompleted = ({ formbyStep }) => {
  return (
    <div className="columns is-centered">
      <div className="column is-4">
        <div className="box ">
          <TitleCustom className="is-color-size-title has-text-centered is-size-4">
            Pago completo
          </TitleCustom>
          <div className="columns is-centered is-mobile">
            <div className="columnis-half is-offset-one-quarter is-mobile">
              <ImageCustom
                img={CheckTransparentn}
                width="40"
                heigth="25"
                className="mr-2"
              />
            </div>
          </div>
          <TitleCustom className="are-color-mynd-grey has-text-weight-light has-text-centered is-size-5">
            Pago total
          </TitleCustom>
          <TitleCustom className=" has-text-centered is-size-2 has-text-primary has-text-weight-light">
            $3000.00
          </TitleCustom>
          <TextCustom className="has-text-weight-bold my-2">
            Servicio:<span className="has-text-weight-light">FastService</span>
          </TextCustom>
          <TextCustom className="has-text-weight-bold  my-2">
            Especialidad: <span className="has-text-weight-light">Cabello</span>
          </TextCustom>
          <TextCustom className="has-text-weight-bold  my-2">
            Doctor:
            <span className="has-text-weight-light">
              Dr. Guillermo Alfredo De la Cruz Pacheco
            </span>
          </TextCustom>
          <TextCustom className="has-text-weight-bold  my-2">
            Fecha:
            <span className="has-text-weight-light">Fecha: 17 de Abril</span>
          </TextCustom>
          <TextCustom className="has-text-weight-bold  my-2">
            Hora: <span className="has-text-weight-light">05:30 p.m</span>
          </TextCustom>
          <TextCustom className="has-text-weight-bold  my-2">
            Cupón:<span className="has-text-weight-light"> ENRIQUE1200</span>
          </TextCustom>
          <TextCustom className="has-text-weight-bold  my-2">
            Pagado con:
            <span className="has-text-weight-light">
              Visa 4573 XXXX XXXX XXXX
            </span>
          </TextCustom>
        </div>
        <div className="columns">
          <div className="column">
            <ButtonCustom
              buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary"
              onClick={formbyStep}
            >
              Pagar
            </ButtonCustom>
          </div>
        </div>
      </div>
    </div>
  )
}

PaymentCompleted.propTypes = {
  formbyStep: PropTypes.func,
}

export default PaymentCompleted
