import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './store'
import AppRouter from './navigation/AppRouter'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { stripeKey } from './config'
import './styles/css/myStyles.css'

const stripePromise = loadStripe(stripeKey)

const App = () => {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
  }

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme')

    if (currentTheme) {
      const htmlElement = document.documentElement
      htmlElement.setAttribute('theme', currentTheme)
    }
  }, [])

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Elements>
    </Provider>
  )
}

export default App
