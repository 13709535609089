import { usersF } from '../../config'

import { clinicalRecordActions } from '../../actions'

const {
  getMedicalRecordAction,
  getMedicalRecordSuccessAction,
  getMedicalRecordFailureAction,
} = clinicalRecordActions.creators

const patientDefault = {
  allergies: [],
  sicks: [],
  suregies: [],
  emergencyContacts: [],
  expedientFiles: [],
  addictions: [],
  withoutAddictions: true,
  withoutAllergy: true,
  withoutSicks: true,
  withoutSurgery: true,
}

const checkClinicalRecord = async uid => {
  try {
    console.log(`Data from checkClinicalRecord: ${uid}`)
    const response = await usersF.doc(uid).get()
    const data = response.data()
    return data?.patient || false
  } catch (error) {
    return 'Error getClientRecord'
  }
}

const createPatientField = async uid => {
  try {
    console.log(`Data from createPatientField: ${uid}`)
    await usersF.doc(uid).set({ patient: patientDefault }, { merge: true })
    return await checkClinicalRecord(uid)
  } catch (error) {
    return 'Error createPatientField'
  }
}

const updateClientRecord = async (uid, data) => {
  try {
    console.log(`Data from updateClientRecord: ${uid}, ${data}`)
    await usersF.doc(uid).set(
      {
        patient: data,
      },
      { merge: true },
    )
    return await checkClinicalRecord(uid)
  } catch (error) {
    return `Error from updateClientRecord: ${error}`
  }
}

const getClinicalRecord = async uid => {
  try {
    console.log(`Data from getClinicalRecord: ${uid}`)
    const existClinicalRecord = await checkClinicalRecord(uid)
    if (existClinicalRecord) {
      console.log('Exist Clinical Record')
      return existClinicalRecord
    }
    if (!existClinicalRecord) {
      console.log('No exist Clinical Record')
      const isCreatePatientField = await createPatientField(uid)
      return isCreatePatientField
    }
  } catch (error) {
    return 'Error getClinicalRecord'
  }
}

const clinicalRecord = ({ method = 'GET', uid, data = {} }) => {
  return async dispatch => {
    console.log(`Data from client record: ${method}, ${uid}, ${data}`)
    try {
      if (method === 'GET') {
        dispatch(getMedicalRecordAction({ method }))
        const clinicalRecord = await getClinicalRecord(uid)
        return dispatch(getMedicalRecordSuccessAction(clinicalRecord))
      }

      if (method === 'POST') {
        dispatch(getMedicalRecordAction({ method }))
        const updatedClinicalRecord = await updateClientRecord(uid, data)
        return dispatch(getMedicalRecordSuccessAction(updatedClinicalRecord))
      }
    } catch (error) {
      dispatch(getMedicalRecordFailureAction(error))
    }
  }
}

export default clinicalRecord
