import { AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL } from '../../utils/constants'

export default (auth, ui) => {
  const { error, sendResetPasswordEmailError } = auth

  const { loading, errors } = ui
  const { message } = error
  const exist = message === AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL
  const credential = error.credential
  const email = error.email
  return {
    sendResetPasswordEmailError,
    message,
    exist,
    errors,
    credential,
    email,
    loading,
  }
}
