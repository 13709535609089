import functions from '../../services/functions'
const { oxxoPayment } = functions.cloudFunctions

const oxxoPaymentService = async (stripe, amount, fullname, email) => {
  try {
    return await oxxoPayment({ amount: amount * 100 }).then(async response => {
      return await stripe.confirmOxxoPayment(response.data.client_secret, {
        payment_method: {
          billing_details: {
            name: fullname,
            email: email || 'pagos@mynextderma.com',
          },
        },
      })
    })
  } catch (error) {
    return error
  }
}

export default oxxoPaymentService
