import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useForm, useField, useRouter, useToggle } from '../../utils'
import { LabelCustom, ErrorNotification } from '../../components'
import Button from '../buttons/Button'
import { useEffect, useState } from 'react'
import Images from '../../assets'

const LoginBox = ({
  signInWithEmail,
  signInWithProvider,
  rememberMe,
  loginWithEmailError,
}) => {
  const { history } = useRouter()
  const [checkBox, setCheckBox] = useToggle(false)
  const [errors, setErrors] = useState()
  const [{ email, password }, handleInputChange] = useForm({
    email: '',
    password: '',
  })
  const { t } = useTranslation()
  const { googleIcon, facebookIcon, phoneIcon } = Images

  useEffect(() => {
    setErrors(loginWithEmailError)
  }, [loginWithEmailError])

  const onChange = e => {
    handleInputChange(e)
    setErrors()
  }
  const emailField = useField({
    className: 'input',
    type: 'email',
    name: 'email',
    value: email,
    onChange: onChange,
    placeholder: t('email'),
  })

  const passwordField = useField({
    className: 'input',
    type: 'password',
    name: 'password',
    value: password,
    onChange: onChange,
    placeholder: t('password'),
  })

  const googleField = useField({
    img: googleIcon,
    imgClassName: 'is-size-icon-google mr-2',
    className: 'button is-fullwidth is-danger',
    textClassName: 'has-text-white',
    onClick: () => signInWithProvider('GOOGLE_PROVIDER'),
  })

  const facebookField = useField({
    img: facebookIcon,
    imgClassName: 'is-size-icon-google mr-2',
    textClassName: 'has-text-white',
    className: 'button is-fullwidth is-facebook',
    onClick: () => signInWithProvider('FACEBOOK_PROVIDER'),
  })

  const phoneField = useField({
    img: phoneIcon,
    imgClassName: 'is-size-icon-google mr-2',
    textClassName: 'has-text-white',
    className: 'button is-fullwidth is-success',
    onClick: () => history.push('/auth/phoneLogin'),
  })

  const signInField = useField({
    className: 'button is-mnd-primary is-fullwidth is-color-button-log-in',
    disabled: !email || !password || password.length < 6,
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (email === '' && password === '') {
      setErrors('Ingrese un email y una contraseña')
      return
    }
    if (email === '' && password !== '') {
      setErrors('Ingrese un email')
      return
    }
    if (email !== '' && password === '') {
      setErrors('Ingrese una contraseña')
      return
    }
    signInWithEmail(email, password)
  }

  const onChangeCheck = () => {
    rememberMe(!checkBox)
    setCheckBox()
  }

  return (
    <div className="box is-margin-box-log-in is-box-shadow">
      <h6 className="title is-6 has-text-centered">{t('title')}</h6>
      <div className="buttons mx-6">
        <Button {...googleField}>{t('google')}</Button>
        <Button {...facebookField}>{t('facebook')}</Button>
        <Button {...phoneField}>{t('phone')}</Button>
      </div>
      <h6 className="subtitle is-6 has-text-centered m-0 mb-1">
        {t('subtitle')}
      </h6>
      {errors && <ErrorNotification message={errors} />}
      <form className="mx-5" onSubmit={handleSubmit}>
        <div className="field">
          <LabelCustom className="are-color-text"> {t('email')}</LabelCustom>
          <div className="control">
            <input {...emailField} />
          </div>
        </div>
        <div className="field">
          <LabelCustom className="are-color-text">{t('password')}</LabelCustom>
          <div className="control">
            <input {...passwordField} />
          </div>
          <div className="field mt-1 has-text-centered">
            <div className="has-text-centered pt-2">
              <label className="checkbox is-text-size-checkbox login-checkbox">
                <input
                  type="checkbox"
                  value={checkBox}
                  onChange={onChangeCheck}
                  checked={checkBox}
                />
                {t('checkbox')}
              </label>
            </div>
            <div className="has-text-centered pt-2">
              <Link
                to="/auth/forgot_password"
                className="is-text-color is-text-size-checkbox level-item-is-mobile is-table"
              >
                {t('recoveryPassword')}
              </Link>
            </div>
            <div className="has-text-centered pt-2">
              <Link
                to={{
                  pathname: 'https://mynextderma.com/app/aviso-privacidad/',
                }}
                target="_blank"
                className="has-text-dark"
              >
                <p className="is-text-color ">{t('policy-privacy')}</p>
              </Link>
            </div>
          </div>
          <Button {...signInField}>{t('buttonSignIn')}</Button>
          <div className="has-text-centered pt-2">
            <Link to="/auth/register" className="has-text-dark">
              {t('getAccount')}
              <p className="is-text-color ">{t('register')}</p>
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}
LoginBox.propTypes = {
  signInWithEmail: PropTypes.func,
  signInWithProvider: PropTypes.func,
  rememberMe: PropTypes.func,
  loginWithEmailError: PropTypes.string,
}

export default LoginBox
