import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Slider = ({ DoctorWoman }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="slider">
        <ul>
          <li>
            <figure className="imagen is-item-centered is-position-img-slider ">
              <DoctorWoman />
            </figure>
            <p className="is-size-5 has-text-centered">{t('subtitleSlider')}</p>
            <p className="is-size-5 has-text-centered">
              {t('subtitleSlider2')}
            </p>
            <h4 className="subtitle is-4 py-3 has-text-centered">
              <b className="is-color-size-title">{t('subtitleSlider3')}</b>
            </h4>
          </li>
          <li>
            <figure className="imagen is-item-centered is-position-img-slider">
              <DoctorWoman />
            </figure>
            <p className="is-size-5 has-text-centered">{t('subtitleSlider')}</p>
            <p className="is-size-5 has-text-centered">
              {t('subtitleSlider2')}
            </p>
            <h4 className="subtitle is-4 py-3 has-text-centered">
              <b className="is-color-size-title">{t('subtitleSlider3')}</b>
            </h4>
          </li>
          <li>
            <figure className="imagen is-item-centered is-position-img-slider">
              <DoctorWoman />
            </figure>
            <p className="is-size-5 has-text-centered">{t('subtitleSlider')}</p>
            <p className="is-size-5 has-text-centered">
              {t('subtitleSlider2')}
            </p>
            <h4 className="subtitle is-4 py-3 has-text-centered">
              <b className="is-color-size-title">{t('subtitleSlider3')}</b>
            </h4>
          </li>
          <li>
            <figure className="imagen is-item-centered is-position-img-slider">
              <DoctorWoman />
            </figure>
            <p className="is-size-5 has-text-centered">{t('subtitleSlider')}</p>
            <p className="is-size-5 has-text-centered">
              {t('subtitleSlider2')}
            </p>
            <h4 className="subtitle is-4 py-3 has-text-centered">
              <b className="is-color-size-title"> {t('subtitleSlider3')}</b>
            </h4>
          </li>
        </ul>
      </div>
    </>
  )
}

Slider.propTypes = {
  DoctorWoman: PropTypes.string.isRequired,
  Dairy: PropTypes.string,
}

export default Slider
