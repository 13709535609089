import {
  getDrugsDataService,
  buyDrugListService,
  generateAsyncBuyDrugListService,
  generateAsynchronousPaymentReceiptService,
} from '../../services'

export default dispatch => {
  return {
    getDrugs: drugID => dispatch(getDrugsDataService(drugID)),
    buyDrug: (uid, values) => dispatch(buyDrugListService(uid, values)),
    generateAsyncBuyDrugList: ({ consultId, values }) =>
      dispatch(
        generateAsyncBuyDrugListService({
          consultId,
          values,
        }),
      ),
    generateAsynchronousPaymentReceipt: ({ type, amount, consultId }) =>
      dispatch(
        generateAsynchronousPaymentReceiptService({ type, amount, consultId }),
      ),
  }
}
