// import PropTypes from 'prop-types'

// Components
import { FrequentQuestions } from '../../components'

const Questions = () => {
  return (
    <div className="container mt-5 mb-5">
      <FrequentQuestions />
    </div>
  )
}

Questions.propTypes = {}

export default Questions
