import {
  googleProvider,
  facebookProvider,
  appleAuthProvider,
  auth,
} from '../../config'
import {
  GOOGLE_PROVIDER,
  FACEBOOK_PROVIDER,
  APPLE_PROVIDER,
} from '../constants'

const getProvider = provider => {
  switch (provider) {
    case 'GOOGLE_PROVIDER':
    case GOOGLE_PROVIDER:
      return auth.signInWithPopup(googleProvider)
    case 'FACEBOOK_PROVIDER':
    case FACEBOOK_PROVIDER:
      return auth.signInWithPopup(facebookProvider)
    case 'APPLE_PROVIDER':
    case APPLE_PROVIDER:
      return auth.signInWithPopup(appleAuthProvider)
    default:
      return false
  }
}
export default getProvider
