export default (auth, ui) => {
  const { user } = auth
  const { errors } = ui
  const { data } = user
  const { uid } = user
  const cards = data.cards ? data.cards : []
  const coupons = data.coupons ? data.coupons : []
  const balance = data.balance ? data.balance : 0
  const fullname = data.fullname
  const email = data.email
  return {
    uid,
    cards,
    coupons,
    balance,
    fullname,
    email,
    errors,
  }
}
