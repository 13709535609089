// import PropTypes from 'prop-types'

// components
import { ImageCustom, TextCustom } from '../../components'

const couponContainer = ({ img, coupon }) => {
  const { code, amount, discountType, validity } = coupon.data
  const exp = validity.split('-')

  return (
    <div className="box is-size-card-coupon mt-3">
      <div className="is-flex">
        <ImageCustom img={img} className="ís-size-person-with-laptop" />
        <div>
          <TextCustom className="has-text-weight-bold  are-color-mynd-grey ml-6 is-colour-text-coupon">
            {`Cupón ${code}`}
          </TextCustom>
          <TextCustom className="ml-6 is-colour-text-coupon">
            Recibe un descuento <br /> especial.
          </TextCustom>
          <TextCustom className="has-text-weight-bold  are-color-mynd-grey ml-6 mt-2 is-colour-text-coupon">
            {discountType === '-'
              ? `Próxima consulta con descuento de $${amount}`
              : discountType === '='
              ? ` Próxima consulta con precio de ${amount}`
              : `Próxima consulta con descuento de ${amount}%`}
          </TextCustom>
        </div>
      </div>
      <hr className="line-dotted-coupon my-2"></hr>
      <div className="is-flex is-justify-content-space-between">
        <TextCustom className="are-color-mynd-grey mt-3 has-text-weight-bold is-size-coupon-date">
          Fecha límite:
          <span className="has-text-weight-light">
            {` Válido hasta el ${exp[2]}/${exp[1]} del ${exp[0]}`}
          </span>
        </TextCustom>
        {/* <TextCustom className="has-text-weight-bold is-colour-text-detail-coupon mt-3">
          Ver detalle
        </TextCustom> */}
      </div>
    </div>
  )
}

couponContainer.propTypes = {}

export default couponContainer
