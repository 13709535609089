import { storage } from '../../config'
const bucket = process.env.REACT_APP_STORAGE_BUCKET

const getImage = async (collection, idRef, uri) => {
  try {
    const ref = storage.refFromURL(
      'gs://' + bucket + '/' + collection + '/' + idRef + '/' + uri,
    )
    const url = await ref.getDownloadURL()
    return url
  } catch (err) {
    return { error: err }
  }
}

export default getImage
