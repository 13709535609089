import PropTypes from 'prop-types'
// accordion
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

// components
import {
  ImageCustom,
  LabelCustom,
  TextCustom,
  ButtonCustom,
  Input,
} from '../../components'
import Images from '../../assets'
import { useForm } from '../../utils'
import { useState } from 'react'

const AccordionDiseases = ({
  addictionsProp,
  withoutAddictions,
  medicalRecord,
  uid,
}) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const [addNew, setAddNew] = useState(false)
  const [{ name, frequency, isAddicted }, handleInputChange] = useForm({
    name: '',
    frequency: '',
    isAddicted: withoutAddictions ? 'true' : 'false',
  })
  const { alertIcon, arrowDown } = Images

  const additionOptions = [
    {
      label: 'Sin adictiones',
      value: 'true',
    },
    {
      label: 'Con adiciones',
      value: 'false',
    },
  ]

  const frequencyOptions = [
    { label: 'Muy frecuente', value: 'very frequently' },
    { label: 'Frecuente', value: 'frequently' },
    { label: 'No muy frecuente', value: 'not frequently' },
  ]

  const deleteItem = index => {
    const data = {
      adictions: [],
    }

    if (addictionsProp.length > 1) {
      const listItems = [...addictionsProp]
      listItems.splice(index, 1)
      data.adictions = listItems
    }
    if (addictionsProp.length === 1) {
      data.withoutAddictions = true
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleWithAddictions = () => {
    console.log(addictionsProp)
    if (name === '') {
      setErrorAlert({
        show: true,
        message: 'Hay que ingresar el nombre',
      })
      return false
    }
    if (frequency === '') {
      setErrorAlert({
        show: true,
        message: 'Hay que ingresar la frecuencia',
      })
      return false
    }

    const data = {
      adictions: [...(addictionsProp ?? []), { name, frequency }],
      withoutAddictions: false,
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleWithOutAddictions = () => {
    const data = {
      adictions: [],
      withoutAddictions: true,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom img={alertIcon} className="is-size-alert-icon mr-3" />
            <span className="are-color-mynd-grey "> Adicciones</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        <div className="control">
          {additionOptions.map(({ label, value }, index) => (
            <LabelCustom className="radio mr-3 ml-0 is-size-6" key={index}>
              <input
                type="radio"
                className="is-radio-input-drop mr-3"
                name="isAddicted"
                value={value}
                onChange={handleInputChange}
                checked={isAddicted === value}
              />
              {label}
            </LabelCustom>
          ))}
        </div>
        {isAddicted === 'false' && (
          <>
            {addictionsProp &&
              addictionsProp.map(({ name, frequency }, index) => (
                <div
                  className="is-background-box-allergies mt-3  px-3 "
                  key={index}
                >
                  <div className="level mb-1 is-mobile">
                    <div className="level-left">
                      <TextCustom className="are-color-mynd-grey  px-1 is-size-6"></TextCustom>
                    </div>
                    <div className="level-right ">
                      <ButtonCustom
                        buttonClassName="button is-button-close"
                        icon="fas fa-times"
                        onClick={() => deleteItem(index)}
                      />
                    </div>
                  </div>
                  <Input
                    className="input mb-2"
                    type="text"
                    placeholder="Describir adicción"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                  <div className="control">
                    {(frequency === 'very frequently' && 'Muy frecuente') ||
                      (frequency === 'frequently' && 'Frecuente') ||
                      (frequency === 'not frequently' && 'No muy frecuente')}
                  </div>
                </div>
              ))}
          </>
        )}
        {isAddicted === 'false' && addNew && (
          <div className="is-background-box-allergies mt-3  px-3 ">
            {errorAlert.show && (
              <div className="notification is-danger mt-3">
                {errorAlert.message}
              </div>
            )}
            <div className="level mb-1 is-mobile">
              <div className="level-left">
                <TextCustom className="are-color-mynd-grey  px-1 is-size-6"></TextCustom>
              </div>
              <div className="level-right ">
                <ButtonCustom
                  buttonClassName="button is-button-close"
                  icon="fas fa-times"
                  onClick={() => {
                    setErrorAlert({ show: false, message: '' })
                    setAddNew(false)
                  }}
                />
              </div>
            </div>
            <Input
              className="input mb-2"
              type="text"
              placeholder="Describir adicción"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
            <div className="control">
              {frequencyOptions.map(({ label, value }, index) => (
                <LabelCustom className="radio mr-3 ml-0 is-size-6" key={index}>
                  <Input
                    type="radio"
                    className="is-radio-input-drop mr-3"
                    name="frequency"
                    value={value}
                    onChange={handleInputChange}
                  />
                  {label}
                </LabelCustom>
              ))}
            </div>
            <ButtonCustom
              buttonClassName="button   has-text-white is-mnd-primary mt-2"
              handleOnClick={handleWithAddictions}
            >
              Guardar
            </ButtonCustom>
          </div>
        )}
        {isAddicted === 'false' && !addNew && (
          <button
            className="button has-text-white is-mnd-primary mt-2 mr-2"
            onClick={() => setAddNew(true)}
          >
            Agregar adiccion
          </button>
        )}
        {isAddicted === 'true' && (
          <button
            className="button has-text-white is-mnd-primary mt-2"
            onClick={handleWithOutAddictions}
          >
            Guardar
          </button>
        )}
      </AccordionItemPanel>
    </>
  )
}

AccordionDiseases.propTypes = {
  addictionsProp: PropTypes.array,
  withoutAddictions: PropTypes.bool,
  medicalRecord: PropTypes.func,
  uid: PropTypes.string,
}

export default AccordionDiseases
