import functions from '../../services/functions'
const { paynetPayment } = functions.cloudFunctions

const paynetPaymentService = async amount => {
  try {
    return await paynetPayment({ amount: amount }).then(async response => {
      return await response.data[0]
    })
  } catch (error) {
    return error
  }
}

export default paynetPaymentService
