import PropTypes from 'prop-types'
// components
import { OnthewayCard } from '..'

const onthewayContainer = ({ onTheWayOrders, handleSelectOrder }) => {
  return (
    <div className="columns is-centered">
      <div className="column is-size-container-shoping">
        {onTheWayOrders &&
          onTheWayOrders.map(order => (
            <OnthewayCard
              key={order.id}
              order={order}
              handleSelectOrder={handleSelectOrder}
            />
          ))}
        {onTheWayOrders.length === 0 && 'Aún no tienes órdenes'}
      </div>
    </div>
  )
}

onthewayContainer.propTypes = {
  orders: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSelectOrder: PropTypes.func.isRequired,
}

export default onthewayContainer
