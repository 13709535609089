import { cancelFC } from '../../config'

const cancelReasonsService = async () => {
  try {
    const reasonsSnapshot = await cancelFC.where('active', '==', true).get()
    let reasons = []
    reasonsSnapshot.forEach(reason => {
      reasons = [...reasons, { id: reason.id, name: reason.data().description }]
    })
    return reasons
  } catch (error) {
    return { error }
  }
}

export default cancelReasonsService
