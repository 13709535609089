import { firebase, auth } from '../../config'
import { authActions } from '../../actions/index'
import { verifyIsNew } from '../../utils'
import { updatePhoneAuth } from '..'

const {
  signInWithEmailAndPasswordAction,
  signInWithEmailAndPasswordSuccessAction,
  signInWithEmailAndPasswordFailureAction,
  signUpRequestAction,
} = authActions.creators

const signInWithEmailAndPasswordService = (email, password) => {
  return async dispatch => {
    try {
      dispatch(signInWithEmailAndPasswordAction())

      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
      const {
        user: { uid },
        additionalUserInfo: { isNewUser },
      } = result
      const getUser = await verifyIsNew(result, 'EMAIL_PROVIDER')
      if (getUser) {
        dispatch(
          signInWithEmailAndPasswordSuccessAction({
            uid,
            isNewUser,
            data: getUser,
          }),
        )
        dispatch(signUpRequestAction())
        const currentUser = auth.currentUser
        if (!isNewUser && !currentUser.phoneNumber && getUser.phone) {
          const phone = getUser.phone
          await updatePhoneAuth(phone, uid)
        }
      }
    } catch (e) {
      const finalError = { message: '' }
      switch (e.code) {
        case 'auth/user-not-found':
          finalError.message =
            'No hay registro de usuario correspondiente a este identificador. Es posible que el usuario haya sido eliminado.'
          break
        case 'auth/wrong-password':
          finalError.message =
            'La contraseña no es válida o el usuario no tiene contraseña.'
          break
        default:
          finalError.message = e.message
          break
      }
      dispatch(signInWithEmailAndPasswordFailureAction(finalError))
    }
  }
}

export default signInWithEmailAndPasswordService
