import {
  oxxoPaymentService,
  speiPaymentService,
  paynetPaymentService,
  getTokenOpenPayService,
  schedulePractiServiceService,
  scheduleFastServiceService,
  // getPriceService,
  generateAsynchronousPaymentService,
  getDeferredPriceService,
} from '../../services'

import { uploadFiles } from '../../utils'
import { uiActions } from '../../actions'

const { addError, removeError } = uiActions.creators

export default dispatch => {
  return {
    generateAsyncPayment: ({ type, amount, consultId }) =>
      dispatch(generateAsynchronousPaymentService({ type, amount, consultId })),
    oxxoPayment: (stripe, amount) => oxxoPaymentService(stripe, amount),
    speiPayment: amount => speiPaymentService(amount),
    paynetPayment: amount => paynetPaymentService(amount),
    getToken: () => getTokenOpenPayService(),
    schedulePractiService: data => schedulePractiServiceService(data),
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
    uploadConsultImages: (consultId, images) => uploadFiles(consultId, images),
    scheduleFastService: data => scheduleFastServiceService(data),
    getPrice: ({
      typeConsult,
      category,
      specialty,
      privateFlag = false,
      doctorId = null,
    }) =>
      dispatch(
        getDeferredPriceService({
          typeConsult,
          category,
          specialty,
          privateFlag,
          doctorId,
        }),
      ),
  }
}
