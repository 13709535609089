import PropTypes from 'prop-types'
import { useField, useRouter } from '../../utils'
import Images from '../../assets'
import {
  LabelCustom,
  TitleCustom,
  Button,
  RegisterBox,
  ErrorNotification,
  CustomButton,
  Input,
} from '..'

const CreateAccount = ({
  handleRegister,
  handleInputChange,
  email,
  password,
  password2,
  message,
  isLoading,
  errors,
  signInWithProvider,
}) => {
  const { history } = useRouter()
  const { googleIcon, facebookIcon, phoneIcon } = Images
  const emailField = useField({
    className: 'input is-medium',
    type: 'email',
    name: 'email',
    value: email,
    onChange: handleInputChange,
    placeholder: 'Correo electrónico',
  })
  const passwordField = useField({
    className: 'input is-medium',
    type: 'password',
    name: 'password',
    value: password,
    onChange: handleInputChange,
    placeholder: '*********',
  })
  const confirmPaswordField = useField({
    className: 'input is-medium',
    type: 'password',
    name: 'password2',
    value: password2,
    onChange: handleInputChange,
    placeholder: '*********',
  })
  const googleButton = useField({
    className: 'button is-danger is-rounden-icon-social',
    type: 'button',
    img: googleIcon,
    imgClassName: 'is-size-social-network',
    onClick: () => signInWithProvider('GOOGLE_PROVIDER'),
  })
  const facebookButton = useField({
    className: 'button is-facebook is-rounden-icon-social',
    type: 'button',
    img: facebookIcon,
    imgClassName: 'is-size-social-network',
    onClick: () => signInWithProvider('FACEBOOK_PROVIDER'),
  })
  const phoneField = useField({
    className: 'button is-success is-rounden-icon-social',
    type: 'button',
    img: phoneIcon,
    imgClassName: 'is-size-social-network',
    onClick: () => history.push('/auth/phoneLogin'),
  })

  return (
    <RegisterBox className="px-6 ">
      <TitleCustom className="is-5 has-text-centered are-color-text">
        Crear cuenta
      </TitleCustom>
      {(message || errors) && <ErrorNotification message={message || errors} />}
      <div className="field">
        <LabelCustom className="are-color-text">Correo electrónico</LabelCustom>
        <Input {...emailField} />
      </div>
      <div className="field">
        <LabelCustom className="are-color-text">Contraseña</LabelCustom>
        <Input {...passwordField} />
      </div>
      <div className="field">
        <LabelCustom className="are-color-text">
          Confirma contraseña
        </LabelCustom>
        <Input {...confirmPaswordField} />
      </div>
      <div className="buttons">
        <CustomButton
          isPrimary
          isLoading={isLoading}
          handleClick={handleRegister}
        >
          Siguente
        </CustomButton>
      </div>
      <TitleCustom className="is-6 has-text-centered mt-5 are-color-text">
        También puedes ingresar con:
      </TitleCustom>
      <div className="buttons is-centered mb-2">
        <Button {...googleButton} />
        <Button {...facebookButton} />
        <Button {...phoneField} />
      </div>
    </RegisterBox>
  )
}

CreateAccount.propTypes = {
  handleRegister: PropTypes.func,
  handleInputChange: PropTypes.func,
  email: PropTypes.string,
  password: PropTypes.string,
  password2: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  errors: PropTypes.string,
  signInWithProvider: PropTypes.func,
}

export default CreateAccount
