import PropTypes from 'prop-types'

import { TextCustom, AddPhotoContainer } from '../../components'
import moment from 'moment'

const addDiary = ({ onHandle, imageLeft, imageRight, imageFront }) => {
  const today = moment().locale('es').format('LL')
  return (
    <div className="without-products-container">
      <TextCustom className="is-size-5 are-color-mynd-grey has-text-weight-bold has-text-centered">
        Agregar Fotos a Diario
      </TextCustom>
      <>
        <TextCustom className="is-size-6 has-text-weight-semibold mx-6">
          {today}
        </TextCustom>
        <TextCustom className="mx-6">¡Es hora de empezar!</TextCustom>
        <AddPhotoContainer
          isDiary={true}
          onHandle={onHandle}
          imageLeft={imageLeft}
          imageRight={imageRight}
          imageFront={imageFront}
        />
      </>
    </div>
  )
}

addDiary.propTypes = {
  increment: PropTypes.func,
  onHandle: PropTypes.func,
  imageFront: PropTypes.any,
  imageLeft: PropTypes.any,
  imageRight: PropTypes.any,
}

export default addDiary
