const processUserData = async user => {
  const { fullname, email, phone, photoURL } = user.data()
  const { emailAuth, phoneAuth } = user
  const userData = user.data()
  userData.id = user.id
  userData.photoURL = photoURL
  userData.isNewUser = !(email && fullname && phone && photoURL)
  userData.email = email || emailAuth
  userData.phone = phone || phoneAuth
  userData.coupons = []
  userData.cards = userData.cards || []
  if (userData.privateFlag) {
    userData.privateDoctor = user.data().privateDoctor.id
  }
  return userData
}
export default processUserData
