import { authActions } from '../../actions'
import { forgotPasswordService, signInWinPopup } from '../../services'
const { unsetSendResetPasswordEmailSuccessAction } = authActions.creators

export default dispatch => {
  return {
    resetSuccess: () => dispatch(unsetSendResetPasswordEmailSuccessAction()),
    forgot: email => dispatch(forgotPasswordService(email)),
    signInWithProvider: provider => dispatch(signInWinPopup(provider)),
  }
}
