import PropTypes from 'prop-types'

// components
import { DeliveredCard } from '..'

const deliveredContainer = ({ deliveredOrders, handleSelectOrder }) => {
  return (
    <>
      <div className="columns is-centered">
        <div className="column is-size-container-shoping">
          {deliveredOrders &&
            deliveredOrders.map(order => (
              <DeliveredCard
                key={order.id}
                order={order}
                handleSelectOrder={handleSelectOrder}
              />
            ))}

          {Object.keys(deliveredOrders).length === 0 && 'Aún no tienes órdenes'}
        </div>
      </div>
    </>
  )
}

deliveredContainer.propTypes = {
  deliveredOrders: PropTypes.instanceOf(Object).isRequired,
  handleSelectOrder: PropTypes.func,
}

export default deliveredContainer
