import functions from '../functions'

const { diaryUpdate } = functions.cloudFunctions
const updateDiaryService = async (values, uid) => {
  try {
    const response = await diaryUpdate({ values, uid })
    return response.data
  } catch (e) {
    return e
  }
}
export default updateDiaryService
