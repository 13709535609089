import functions from '../functions'
const { getBlockedDaysPrivate } = functions.cloudFunctions
const getBlockedDaysServicePrivate = data => {
  return async dispatch => {
    try {
      const blockedDays = await getBlockedDaysPrivate(data)
      return blockedDays
    } catch (error) {
      return error
    }
  }
}

export default getBlockedDaysServicePrivate
