import {
  addWarranty,
  uploadImageTicket,
  getTikets,
  getDiaryService,
} from '../../services'

export default dispatch => {
  return {
    addWarranty: values => addWarranty(values),
    uploadImageTicket: (image, collection, id) =>
      uploadImageTicket(image, collection, id),
    getTikets: uid => getTikets(uid),
    getDiary: uid => getDiaryService(uid),
  }
}
