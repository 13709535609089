import PropTypes from 'prop-types'
import { ButtonCustom } from '..'

const buyControls = ({
  count,
  drugInfo,
  handleOnChangeCount,
  increment,
  decrement,
  items,
}) => {
  return (
    <div className="drug-quantity-container">
      {count > 0 && (
        <ButtonCustom
          buttonClassName="button drug-buttons"
          handleOnClick={decrement}
          icon="fas fa-minus"
        />
      )}
      <label>
        <span>{count}</span>
      </label>
      {(!drugInfo.isControlled || count < items) && (
        <ButtonCustom
          buttonClassName="button drug-buttons"
          handleOnClick={increment}
          icon="fas fa-plus"
        />
      )}
    </div>
  )
}

buyControls.propTypes = {
  count: PropTypes.number,
  handleOnChangeCount: PropTypes.func,
  increment: PropTypes.func,
  decrement: PropTypes.func,
  items: PropTypes.number,
  drugInfo: PropTypes.any,
}

export default buyControls
