import PropTypes from 'prop-types'
import { TextCustom, ImageCustom, ButtonCustom } from '..'
import Images from '../../assets'
import { useState } from 'react'

const shareCode = ({ codeFriend }) => {
  const { groupOfPeopleAnnouncing } = Images
  const [clicked, setClicked] = useState(false)

  return (
    <div className="is-share-code-box  p-4 is-flex is-justify-content-space-between">
      <div>
        <TextCustom className="has-text-white has-text-weight-semibold">
          Refiere a un amigo
        </TextCustom>
        <TextCustom className="has-text-white mt-3 is-size-text-code">
          Comparte tu código
          <br /> de referido {codeFriend} y <br /> podrás ganar descuentos.
        </TextCustom>
      </div>
      <div>
        <ImageCustom
          img={groupOfPeopleAnnouncing}
          className="is-size-group-of-people-announcing"
        />
        <ButtonCustom
          buttonClassName="button p-0 is-size-button-share"
          onClick={() => {
            setClicked(true)
            navigator.clipboard.writeText(codeFriend)
          }}
        >
          {clicked ? 'Código copiado!' : 'Compartir mi código'}
        </ButtonCustom>
      </div>
    </div>
  )
}

shareCode.propTypes = {
  codeFriend: PropTypes.string,
}

export default shareCode
