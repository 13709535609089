import PropTypes from 'prop-types'
import Images from '../../assets'
// componentes
import { TextCustom, ImageCustom, TreatmentInfoCard } from '..'

const treatmentCards = ({ status, date, images }) => {
  const { RoundArrow, MagnifyingGlassOnDocument } = Images
  return (
    <>
      {status === 'close' && (
        <>
          <TextCustom className="is-colour-text-detail-section has-text-centered">
            {`Consulta realizada el día: ${date}`}
          </TextCustom>
          <TreatmentInfoCard images={images} />
          <TextCustom className="is-colour-text-detail-section has-text-centered mt-4">
            Sesión anterior
          </TextCustom>
        </>
      )}
      {status === 'current' && (
        <>
          <TextCustom className="is-colour-text-detail-section has-text-centered">
            {`Consulta agendada para el: ${date}`}
          </TextCustom>
          <TreatmentInfoCard images={images} />
          <ImageCustom
            img={RoundArrow}
            className="is-size-img-arrow-sections"
          />
          <TextCustom className="is-colour-text-detail-section-current has-text-centered mt-3 ">
            En curso
          </TextCustom>
        </>
      )}
      {status === 'scheduled' && (
        <>
          <TextCustom className="is-colour-text-detail-section has-text-centered">
            {`Próxima consulta para el día: ${date}`}
          </TextCustom>
          <TreatmentInfoCard images={images} />
          <TextCustom className="is-colour-text-detail-section-next has-text-centered has-text-weight-bold mt-3">
            {`Próxima sesión agendada para el día: ${date}`}
          </TextCustom>
        </>
      )}
      {status === 'pendient' && (
        <>
          <ImageCustom
            img={MagnifyingGlassOnDocument}
            className="is-size-img-MagnifyingGlassOnDocument"
          />
          <TextCustom className="is-colour-text-detail-section-next has-text-centered has-text-weight-bold mt-3">
            Por definir
          </TextCustom>
        </>
      )}
    </>
  )
}

treatmentCards.propTypes = {
  status: PropTypes.string,
  date: PropTypes.string,
  images: PropTypes.array,
}

export default treatmentCards
