import PropTypes from 'prop-types'
import validator from 'validator'
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import {
  ImageCustom,
  LabelCustom,
  TextCustom,
  ButtonCustom,
  Input,
} from '../../components'
import Images from '../../assets'
import { useForm } from '../../utils'
import { useState } from 'react'

const AccordionDiseases = ({
  medicalRecord,
  sicksProp,
  withoutDiseasesProp,
  uid,
}) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const [addNew, setAddNew] = useState(false)
  const isSick = withoutDiseasesProp

  const { hospitalGurneyIcon, arrowDown } = Images
  const [
    { name, date, isHereditary, isItCronical, withoutSicks },
    handleInputChange,
    handleCheckedChange,
  ] = useForm({
    name: '',
    date: '',
    isHereditary: false,
    isItCronical: false,
    withoutSicks: isSick ? 'true' : 'false',
  })

  const optionsSicks = [
    {
      value: true,
      label: 'Sin enfermedades',
      name: 'withoutSicks',
    },
    {
      value: false,
      label: 'Con enfermdades',
      name: 'withoutSicks',
    },
  ]

  const radio = [
    {
      name: 'isItCronical',
      label: 'Cronico',
    },
    {
      name: 'isHereditary',
      label: 'Hereditario',
    },
  ]

  const deleteItem = index => {
    const data = {
      sicks: [],
    }

    if (sicksProp.length > 1) {
      const listItems = [...sicksProp]
      listItems.splice(index, 1)
      data.sicks = listItems
    }
    if (sicksProp.length === 1) {
      data.withoutSicks = true
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (validator.isEmpty(name)) {
      setErrorAlert({ show: true, message: 'Hay que ingresar el nombre' })
      return false
    }
    if (validator.isEmpty(date)) {
      setErrorAlert({
        show: true,
        message: 'Hay que ingresar una fecha',
      })
      return false
    }

    const data = {
      sicks: [
        ...sicksProp,
        {
          name,
          date,
          isHereditary,
          isItCronical,
        },
      ],
      withoutSicks: false,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleClick = () => {
    const data = {
      withoutSicks: true,
      sicks: [],
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={hospitalGurneyIcon}
              className="is-size-hospitalgurney-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Enfermedades</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        <div className="control">
          {optionsSicks.map(({ value, label, name }, index) => (
            <LabelCustom className="radio mr-3 ml-0 is-size-6" key={index}>
              <input
                type="radio"
                className="is-radio-input-drop mr-3"
                name={name}
                value={value}
                onChange={handleInputChange}
                defaultChecked={isSick === value}
              />
              {label}
            </LabelCustom>
          ))}
        </div>
        {withoutSicks === 'false' && (
          <>
            {sicksProp &&
              sicksProp.map(
                ({ name, isHereditary, isItCronical, date }, index) => (
                  <>
                    <div
                      className="is-background-box-allergies mt-3 px-3 "
                      key={index}
                    >
                      <div className="level mb-0 is-mobile">
                        <div className="level-left">
                          <TextCustom className="are-color-mynd-grey is-button-close px-1 is-size-6"></TextCustom>
                        </div>
                        <div className="level-right mx-0 px-0">
                          <ButtonCustom
                            buttonClassName="button is-button-close"
                            icon="fas fa-times"
                            onClick={() => deleteItem(index)}
                          />
                        </div>
                      </div>
                      <Input
                        className="input mb-3"
                        type="text"
                        placeholder="Ej: Gripe"
                        name="name"
                        value={name}
                        readOnly={true}
                      />
                      <p>
                        <strong>Crónica:</strong> {isItCronical ? 'Sí' : 'No'}
                      </p>
                      <p>
                        <strong>Hereditaria:</strong>{' '}
                        {isHereditary ? 'Sí' : 'No'}
                      </p>
                      <LabelCustom className="mt-2">Fecha</LabelCustom>
                      <Input
                        className="input"
                        type="date"
                        placeholder="ej: 33- 18 - 36- 3655"
                        value={date}
                        name="date"
                        readOnly={true}
                      />
                    </div>
                  </>
                ),
              )}
          </>
        )}
        {withoutSicks === 'false' && addNew && (
          <form onSubmit={handleOnSubmit}>
            <div className="is-background-box-allergies mt-3 px-3 ">
              {errorAlert.show && (
                <div className="notification is-danger mt-3">
                  {errorAlert.message}
                </div>
              )}
              <div className="level mb-0 is-mobile">
                <div className="level-left">
                  <TextCustom className="are-color-mynd-grey is-button-close px-1 is-size-6"></TextCustom>
                </div>
                <div className="level-right mx-0 px-0">
                  <ButtonCustom
                    buttonClassName="button is-button-close"
                    icon="fas fa-times"
                    onClick={() => {
                      setErrorAlert({ show: false, message: '' })
                      setAddNew(false)
                    }}
                  />
                </div>
              </div>
              <Input
                className="input mb-3"
                type="text"
                placeholder="Ej: Gripe"
                name="name"
                value={name}
                onChange={handleInputChange}
              />
              {radio.map((item, index) => (
                <div className="field" key={index}>
                  <div className="control">
                    <LabelCustom
                      className="radio  ml-0 is-margin-input-blood"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        className="is-radio-input-drop mr-3"
                        name={item.name}
                        onChange={handleCheckedChange}
                      />
                      {item.label}
                    </LabelCustom>
                  </div>
                </div>
              ))}
              <LabelCustom className="mt-2">Fecha</LabelCustom>
              <Input
                className="input"
                type="date"
                placeholder="ej: 33- 18 - 36- 3655"
                value={date}
                name="date"
                onChange={handleInputChange}
              />
              <button className="button has-text-white is-mnd-primary mt-2">
                Guardar
              </button>
            </div>
          </form>
        )}
        {withoutSicks === 'false' && !addNew && (
          <button
            className="button has-text-white is-mnd-primary mt-2 mr-2"
            onClick={() => setAddNew(true)}
          >
            Agregar enfermedad
          </button>
        )}
        {withoutSicks === 'true' && (
          <button
            className="button has-text-white is-mnd-primary mt-2"
            onClick={handleClick}
          >
            Guardar
          </button>
        )}
      </AccordionItemPanel>
    </>
  )
}

AccordionDiseases.propTypes = {
  withoutDiseasesProp: PropTypes.bool,
  medicalRecord: PropTypes.func,
  sicksProp: PropTypes.array,
  uid: PropTypes.string,
}

export default AccordionDiseases
