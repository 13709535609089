import functions from '../functions'
const { getBlockedDays } = functions.cloudFunctions
const getBlockedDaysService = data => {
  return async dispatch => {
    try {
      const blockedDays = await getBlockedDays(data)
      return blockedDays
    } catch (error) {
      return error
    }
  }
}

export default getBlockedDaysService
