import PropTypes from 'prop-types'

// img
import Images from '../../../assets'

// components
import { ImageCustom, LabelCustom, Input } from '../../../components'
import { dateFormat } from '../../../utils'

const DateofPurchaseField = ({ handleInputChange, date }) => {
  const { maxDate, minDate } = dateFormat({ minYearDifference: 1 })
  const { calendarIcon } = Images
  return (
    <div className="box  mx-3 is-flex">
      <span className="icon mr-2">
        <ImageCustom img={calendarIcon} />
      </span>
      <div className="is-size-field-nane-product">
        <LabelCustom className="label are-color-mynd-grey">
          Fecha de compra
        </LabelCustom>
        <Input
          className="input is-select-size is-fullwidth"
          type="date"
          placeholder="Marca del producto"
          name="date"
          min={minDate}
          max={maxDate}
          onChange={handleInputChange}
          required={date}
        />
      </div>
    </div>
  )
}

DateofPurchaseField.propTypes = {
  handleInputChange: PropTypes.func,
  date: PropTypes.array,
}

export default DateofPurchaseField
