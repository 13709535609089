import {
  createConsultService,
  updateConsultService,
  getBlockedDaysService,
  getBlockedDaysServicePrivate,
  getAvailableHoursService,
  getAvailableHoursServicePrivate,
  getQuestionnairesService,
  getDeferredPriceService,
  getCategoriesForSpecialtyService,
  getNutritionQuestionnaireService,
  rescheduleConsultService,
} from '../../services'
import { uiActions, consultActions } from '../../actions'

const { addError, removeError } = uiActions.creators
const { createConsultRequestAction, createConsultSuccessAction } =
  consultActions.creators

export default dispatch => {
  return {
    getQuestionnaires: key => dispatch(getQuestionnairesService(key)),
    getNutritionQuestionnaire: () =>
      dispatch(getNutritionQuestionnaireService()),
    getAvailableHours: data => dispatch(getAvailableHoursService(data)),
    getAvailableHoursPrivate: data =>
      dispatch(getAvailableHoursServicePrivate(data)),
    getBlockedDays: (year, month) =>
      dispatch(getBlockedDaysService(year, month)),
    getBlockedDaysPrivate: (year, month) =>
      dispatch(getBlockedDaysServicePrivate(year, month)),
    updateConsult: (consultId, data) =>
      dispatch(updateConsultService(consultId, data)),
    createConsult: () => dispatch(createConsultService()),
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
    preScheadule: data => {
      dispatch(createConsultRequestAction())
      dispatch(createConsultSuccessAction(data))
    },
    getDeferredPrice: () => dispatch(getDeferredPriceService()),
    getCategoriesForSpecialty: ({ specialty }) =>
      dispatch(getCategoriesForSpecialtyService({ specialty })),
    rescheduleConsult: data => rescheduleConsultService(data),
  }
}
