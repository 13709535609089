export default auth => {
  const { error, user } = auth
  const { message } = error
  const { uid } = user

  const { privateFlag, specialty } = user.data
  return {
    message,
    privateFlag,
    specialty,
    uid,
  }
}
