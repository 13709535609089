import {
  getDeferredPriceService,
  getCategoriesForSpecialtyService,
} from '../../services'
export default dispatch => {
  return {
    getDeferredPrice: () => dispatch(getDeferredPriceService()),
    getCategoriesForSpecialty: specialty =>
      dispatch(getCategoriesForSpecialtyService(specialty)),
  }
}
