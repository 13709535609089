import PropTypes from 'prop-types'
import moment from 'moment'

const CalendarDays = ({
  isFetching,
  matrix,
  blockedMatrix,
  activeDate,
  setActiveDate,
}) => {
  const today = moment().toDate()

  const handleOnClick = day => {
    if (day !== -1) {
      setActiveDate(new Date(activeDate.setDate(day)))
    }
  }

  const isPastDay = day => {
    const currentDay = new Date(
      activeDate.getFullYear(),
      activeDate.getMonth(),
      day,
    )
    return today > currentDay
  }

  return (
    <>
      <div className="calendar__container__days">
        {!isFetching ? (
          matrix.map((row, index) => (
            <div className="calendar__container__days__row" key={index}>
              {row.map((day, index) => (
                <div
                  className={`calendar__container__days__row__day ${
                    blockedMatrix.includes(day) &&
                    'calendar__blocked__day has-text-grey-light'
                  } ${isPastDay(day) && 'calendar__past__day'} ${
                    day === activeDate.getDate() && 'calendar__active__day'
                  }`}
                  key={index}
                  onClick={() => handleOnClick(day)}
                >
                  {day === -1 ? '' : day}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center  is-active">
            <div className="loader is-loading"></div>
          </div>
        )}
      </div>
    </>
  )
}
// isFetching, matrix, blockedMatrix, activeDate
CalendarDays.propTypes = {
  isFetching: PropTypes.bool,
  matrix: PropTypes.array,
  blockedMatrix: PropTypes.array,
  activeDate: PropTypes.object,
  setActiveDate: PropTypes.func,
}

export default CalendarDays
