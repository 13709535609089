import PropTypes from 'prop-types'
// componets
import { TextCustom, ImageCustom } from '..'

// img
import Images from '../../assets'

const shippingProcess = ({ automatic_shipping_data, orderDate }) => {
  const { checkIcon, iconShedule, iconTrunkTransparent, iconBoxTransparente } =
    Images
  return (
    <div className="ml-3 mt-2 is-flex is-size-box-address">
      <div>
        <div className="is-background-icon">
          <ImageCustom img={checkIcon} className="is-size-img-check" />
        </div>
        <div className="is-vertical-line" />
        <div className="is-background-icon">
          <ImageCustom img={iconShedule} className="is-size-img-shedule" />
        </div>
        <div className="is-vertical-line" />
        <div className="is-background-icon">
          <ImageCustom
            img={iconTrunkTransparent}
            className="is-size-img-trunk"
          />
        </div>
        <div className="is-vertical-line" />
        <div className="is-background-icon-box">
          <ImageCustom img={iconBoxTransparente} className="is-size-img-box" />
        </div>
      </div>
      <div className="ml-6 is-flex ">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <TextCustom className="are-color-mynd-grey has-text-weight-bold">
            Orden creada
          </TextCustom>
          <TextCustom>{orderDate}</TextCustom>
          <br />
          <TextCustom
            className={
              automatic_shipping_data && automatic_shipping_data.createdAt
                ? 'are-color-mynd-grey'
                : 'has-text-grey-light	' + 'has-text-weight-bold mt-2'
            }
          >
            Preparando envío
          </TextCustom>
          <TextCustom>
            {(automatic_shipping_data && automatic_shipping_data.createdAt) ||
              'Esperando actualizacion'}
          </TextCustom>
          <br />
          <TextCustom
            className={
              automatic_shipping_data && automatic_shipping_data.deliveredAt
                ? 'are-color-mynd-grey'
                : 'has-text-grey-light	' + 'has-text-weight-bold mt-2'
            }
          >
            En camino
          </TextCustom>
          <TextCustom>
            {(automatic_shipping_data &&
              automatic_shipping_data.eventHistory[
                automatic_shipping_data.eventHistory.length - 1
              ].date) ||
              'Esperando actualizacion'}
            <br />
            {automatic_shipping_data &&
              automatic_shipping_data.eventHistory[
                automatic_shipping_data.eventHistory.length - 1
              ].event}
          </TextCustom>
          <br />
          <TextCustom
            className={
              automatic_shipping_data && automatic_shipping_data.deliveredAt
                ? 'are-color-mynd-grey'
                : 'has-text-grey-light	' + 'has-text-weight-bold mt-2'
            }
          >
            Entregada
          </TextCustom>
          <TextCustom>
            {(automatic_shipping_data && automatic_shipping_data.deliveredAt) ||
              'Esperando actualizacion'}
          </TextCustom>
        </div>
      </div>
    </div>
  )
}

shippingProcess.propTypes = {
  automatic_shipping_data: PropTypes.instanceOf(Object).isRequired,
}

export default shippingProcess
