import { diaryFC, storage, usersF } from '../../config'
import moment from 'moment'
const bucket = process.env.REACT_APP_STORAGE_BUCKET

const getImage = async ({ idRef, uri, collection = '/daily/' }) => {
  try {
    const ref = storage.refFromURL(
      'gs://' + bucket + collection + idRef + '/' + uri,
    )
    return await ref.getDownloadURL()
  } catch (error) {
    return { error }
  }
}

const getDiaryService = async uid => {
  const userRef = usersF.doc(uid)
  const diaryItems = await diaryFC
    .where('patient', '==', userRef)
    .where('status', '==', 'close')
    .orderBy('created', 'desc')
    .get()

  const { docs } = diaryItems
  if (docs.length === 0) {
    return
  }

  const diaryList = await Promise.all(
    docs.map(async item => {
      const items = item.data()
      if (!items.uri || !items.uriLeft || !items.uriRight) {
        return
      }
      const idRef = item.ref.id
      const tempList = []
      tempList.ref = item.ref.id
      const time_data = items.created.toDate()
      tempList.date = moment(time_data).locale('es').format('LL')
      tempList.time = moment(moment(time_data).toDate()).format('LT')
      // const dayNumber = time_data.getDay();
      const url = await getImage({ idRef, uri: items.uri })
      if (!url?.error) {
        tempList.uri = url
        tempList.id = items.uri
      }
      const urlLeft = await getImage({ idRef, uri: items.uriLeft })
      if (!urlLeft?.error) {
        tempList.uriLeft = urlLeft
        tempList.idLeft = items.uriLeft
      }
      const urlRight = await getImage({ idRef, uri: items.uriRight })
      if (!urlRight?.error) {
        tempList.uriRight = urlRight
        tempList.idRight = items.uriRight
      }
      if (items?.products && items.products.length !== 0) {
        let products = []
        await Promise.all(
          items.products.map(async product => {
            const productUri = await getImage({
              idRef: uid,
              uri: product.uri,
              collection: '/products/',
            })
            products = products.concat({ uri: productUri, id: product.uri })
            return products
          }),
        )
        tempList.products = products
      } else {
        tempList.products = ''
      }
      return tempList
    }),
  )
  return diaryList
}
export default getDiaryService
