import { firebase } from '../../config'
import { authActions, uiActions } from '../../actions'
import { verifyIsNew } from '../../utils'

const {
  createUserWithEmailAndPasswordAction,
  createUserWithEmailAndPasswordSuccessAction,
  createUserWithEmailAndPasswordFailureAction,
  signUpRequestAction,
} = authActions.creators
const { startLoading, stopLoading } = uiActions.creators
const createUserWithEmailAndPasswordService = (email, password) => {
  return async dispatch => {
    try {
      dispatch(createUserWithEmailAndPasswordAction())
      dispatch(startLoading())
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
      const {
        user: { uid },
        additionalUserInfo: { isNewUser },
      } = result
      const user = await verifyIsNew(result, 'EMAIL_PROVIDER')
      if (user) {
        dispatch(
          createUserWithEmailAndPasswordSuccessAction({
            uid,
            isNewUser,
            data: user,
          }),
        )
        dispatch(signUpRequestAction())
        dispatch(stopLoading())
      }
    } catch (error) {
      dispatch(createUserWithEmailAndPasswordFailureAction(error))
      dispatch(stopLoading())
      return { error }
    }
  }
}

export default createUserWithEmailAndPasswordService
