import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/messaging'

// Initialize Firebase
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

firebase.initializeApp(config)

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com')
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()
const facebookProvider = new firebase.auth.FacebookAuthProvider()
const firestore = firebase.firestore()
const firestoreFiled = firebase.firestore
const functions = firebase.functions()
const auth = firebase.auth()
const usersF = firebase.firestore().collection('users')
const consultRef = firebase.firestore().collection('consult')
const notificationsRef = firebase.firestore().collection('notifications')
const questionnairesRef = firestore.collection('questionnaires')
const configurationRef = firestore.collection('configuration')
const current = firebase.auth().currentUser
const phoneAuth = firebase.auth.PhoneAuthProvider
const stripeKey = 'pk_test_pTMEkOYWPYhEpjX7Uvs9WgCk00oNjJFLfb'
const storage = firebase.storage()
const storageRef = storage.ref()
const drugsFC = firebase.firestore().collection('drugs')
const storageTask = firebase.storage.TaskState
const storageEvent = firebase.storage.TaskEvent
const productsFC = firebase.firestore().collection('products')
const diaryFC = firebase.firestore().collection('daily')
const timestamp = firebase.firestore.FieldValue.serverTimestamp()
const cancelFC = firestore.collection('cancel_reasons')
// const consult = firebase.firestore().collection('consult')

export {
  auth,
  current,
  firebase,
  firestore,
  firestoreFiled,
  googleAuthProvider,
  appleAuthProvider,
  facebookAuthProvider,
  phoneAuthProvider,
  functions,
  googleProvider,
  facebookProvider,
  usersF,
  phoneAuth,
  stripeKey,
  consultRef,
  notificationsRef,
  questionnairesRef,
  configurationRef,
  storageRef,
  storage,
  drugsFC,
  storageTask,
  storageEvent,
  productsFC,
  diaryFC,
  timestamp,
  cancelFC,
}
