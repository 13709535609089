import { uiActions, authActions } from '../../actions'
const { signOutAction } = authActions.creators
const { addError, removeError } = uiActions.creators

export default dispatch => {
  return {
    signOut: () => dispatch(signOutAction()),
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
  }
}
