import React, { useEffect, useState } from 'react'
import Images from '../../assets'

import { ImageCustom, TextCustom } from '..'

const drugCard = ({ drug, drugInfo, percentage }) => {
  const { fast } = Images
  const {
    drugName,
    drugImg,
    drugConsumeTime,
    drugDuring,
    drugTratamentTime,
    drugInterval,
    // items,
  } = drug
  const [price, setPrice] = useState()
  useEffect(() => {
    if (drugInfo && drugInfo.clientPrice && !percentage) {
      setPrice(`$ ${drugInfo.clientPrice}`)
    }
    if (drugInfo && drugInfo.clientPrice && percentage) {
      setPrice(
        `de $ ${drugInfo.clientPrice} a ${
          (drugInfo.clientPrice * (100 - percentage)) / 100
        }`,
      )
    }
    return () => {}
  }, [percentage, drugInfo])

  return (
    <>
      <div className="drug-image">
        <ImageCustom img={drugImg || fast} className="image" />
      </div>
      <div className="drug-info">
        <TextCustom className="is-flex my-4">{drugName}</TextCustom>
        <span
          className={`status status-${
            (!drugInfo?.isSold && 'close') ||
            (drugInfo?.isControlled && 'controlled') ||
            'ready'
          }`}
        >
          {(!drugInfo?.isSold && 'No disponible') ||
            (drugInfo?.isControlled && 'Controlado') ||
            'Disponible'}
        </span>
        <TextCustom>
          Uso: <span> {`Cada: ${drugInterval} ${drugConsumeTime}(s)`}</span>
        </TextCustom>
        <TextCustom>
          Durante: <span> {`Cada: ${drugDuring} ${drugTratamentTime}(s)`}</span>
        </TextCustom>
        <TextCustom>{`${price || 'Medicina no disponible'}`}</TextCustom>
      </div>
    </>
  )
}

export default drugCard
