import { BillingForm } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import dispatchers from './dispatchers'
import selectors from './selectors'
import usePreventBack from '../../utils/hooks/usePreventBack'

const Billing = () => {
  const dispatch = useDispatch()
  const { auth } = useSelector(state => state)
  const { uid, user } = selectors(auth)

  usePreventBack(() => {
    console.log('Doing my stuff')
  })

  const { updateBillingInfo } = dispatchers(dispatch)
  const handleEditBillingInformation = async data => {
    const newProfileInformation = {
      billing: {
        rfc: data.rfc,
        email: data.email,
        legal_name: data.name,
        phone: data.phone,
        street: data.address,
        zip: data.zipCode,
        ext: data.externalNumber,
        int: data.internalNumber,
        neighborhood: data.colony,
        use: data.cfdiType,
      },
    }
    console.log('usersd', newProfileInformation)

    try {
      await updateBillingInfo(newProfileInformation, uid)
      alert('Información actualizada')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="container mt-5 mb-5">
      <BillingForm
        {...user.data.billing}
        handleEditBillingInformation={handleEditBillingInformation}
      />
    </div>
  )
}

Billing.propTypes = {}

export default Billing
