import PropTypes from 'prop-types'

// components
import { TextCustom, ImageCustom } from '..'
import Images from '../../assets'

const YarnCommentDoctorProfile = ({ replies }) => {
  const { reply } = replies
  const { user_default } = Images
  return (
    <div className="columns my-4">
      <div className="column">
        <article className="media ml-2 ">
          <div className="media-left">
            <figure className="image">
              <ImageCustom
                img={user_default}
                className="is-rounded image is-24x24"
              />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <TextCustom className="mb-1 are-color-mynd-grey has-text-weight-bold is-text-name-yarn-comment">
                Pablo Ramirez
              </TextCustom>
              <TextCustom className="is-italic is-text-comment">
                “{reply}”.
              </TextCustom>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}

YarnCommentDoctorProfile.propTypes = {
  replies: PropTypes.array,
}

export default YarnCommentDoctorProfile
