import { usersF, firebase } from '../../config'
import { myAddressActions } from '../../actions'

const { addNewAddressRequest, addNewAddressSuccess } = myAddressActions.creators

const addNewAddressService = (uid, newAddress) => {
  return async dispatch => {
    try {
      dispatch(addNewAddressRequest())
      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .update(
          {
            locations: [
              ...(await firebase
                .firestore()
                .collection('users')
                .doc(uid)
                .get()
                .then(doc => doc.data().locations)),
              {
                description: newAddress.description ?? '',
                street: newAddress.street ?? '',
                number: newAddress.number ?? '',
                int_number: newAddress.int_number ?? '',
                vicinity: newAddress.vicinity ?? '',
                postal_code: newAddress.postal_code ?? '',
                locality: newAddress.locality ?? '',
                state: newAddress.state ?? '',
                country: newAddress.country ?? '',
                coords: newAddress.coords ?? '',
                isSelected: newAddress.isSelected ?? false,
              },
            ],
          },
          { merge: true },
        )
      const user = await usersF.doc(uid).get()
      const { locations } = user.data()
      dispatch(addNewAddressSuccess(locations))
    } catch (error) {
      console.log(error)
    }
  }
}

export default addNewAddressService
