import functions from '../functions'

const { generateAsynchronousPayment } = functions.cloudFunctions

const generateAyncPaymentService = ({ type, amount, consultId: consult }) => {
  return async dispatch => {
    try {
      const response = await generateAsynchronousPayment({
        type,
        amount,
        consult,
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }
}

export default generateAyncPaymentService
