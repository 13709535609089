import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { firebase } from '../../config/index'
import { useSelector } from 'react-redux'
import selectors from '../../containers/DoctorScreen/selectors'
// Images
import Images from '../../assets'
// Components
import { TextCustom, ImageCustom } from '..'

const CardDoctor = ({ doctorId, doctor, idDocsFav }) => {
  const { photo, name, id, specialty, reviewsCount } = doctor
  const state = useSelector(state => state)
  const { userId } = selectors(state)

  const specialtyText = {
    derma: 'Dermatológica',
    psychology: 'Psicológica',
    nutrition: 'Nutricional',
    medicine: 'General',
  }

  const { Heart, userDefaultDoctor, comentIcon } = Images

  // onSnapshot
  const filterFav = () => {
    if (!idDocsFav) {
      return false
    }
    return idDocsFav.includes(id)
  }
  const union = filterFav()

  const addDoctorFav = async () => {
    const field = firebase.firestore.FieldValue
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .update(
          {
            favorities: union ? field.arrayRemove(id) : field.arrayUnion(id), // arrayUnion=> si el doctor no es favorito, arrayRemove=> si el doctor ya fue seleccionado
          },
          { merge: true },
        )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="box-doctor box-fav mb-5">
      <Link className="box box-full" to={`/user/doctor/${doctorId}`}>
        <article className="media">
          <div className="media-left is-img-size-favourites">
            <ImageCustom
              img={photo || userDefaultDoctor}
              className="img-doctor"
            />
          </div>
          <div className="media-content is-mobile">
            <div className="content">
              <div className="is-flex is-justify-content-space-between mb-4">
                <div className="">
                  <TextCustom className="are-color-mynd-grey is-size-5 is-spacing-text">
                    {name}
                  </TextCustom>
                </div>
              </div>
              <TextCustom className="is-color-specialist">
                Especialidad:
                <span className="are-color-mynd-grey">
                  {' '}
                  {specialtyText[specialty || 'derma']}
                </span>
              </TextCustom>
            </div>
            <div className="level is-mobile">
              <div className="level-left">
                <div className="" aria-label="reply">
                  <span className="icon is-small mr-2">
                    <ImageCustom img={comentIcon} />
                  </span>
                </div>
                <TextCustom className="level-item is-size-text-comments">
                  {reviewsCount} comentarios
                </TextCustom>
              </div>
            </div>
          </div>
        </article>
      </Link>
      <div className={specialty === 'psychology' ? 'fav-icon-psy' : 'fav-icon'}>
        <a
          className={union ? 'heart-selected' : 'heart'}
          onClick={() => addDoctorFav()}
        >
          <Heart />
        </a>
      </div>
    </div>
  )
}

CardDoctor.propTypes = {
  doctorId: PropTypes.string,
  doctor: PropTypes.object,
  idDocsFav: PropTypes.array,
}

export default CardDoctor
