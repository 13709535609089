export default (auth, doctors, address, ui) => {
  const { data: allDoctors } = doctors
  const { error, user } = auth
  const { errors, loading, success } = ui
  const { message } = error
  const { uid } = user
  const { specialty } = user.data
  const { data: allAddress } = address
  return {
    uid,
    user,
    errors,
    success,
    message,
    loading,
    allDoctors,
    allAddress,
    specialty,
  }
}
