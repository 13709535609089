const ADD_ARTICLE = 'ADD_ARTICLE'
const REMOVE_ARTICLE = 'REMOVE_ARTICLE'
const REMOVE_ALL_ARTICLES = 'REMOVE_ALL_ARTICLES'

const addArticleAction = (payload = {}) => ({
  type: ADD_ARTICLE,
  payload,
})

const removeArticleAction = (payload = {}) => ({
  type: REMOVE_ARTICLE,
  payload,
})

const removeAllArticlesAction = (payload = {}) => ({
  type: REMOVE_ALL_ARTICLES,
  payload,
})

export default {
  types: {
    ADD_ARTICLE,
    REMOVE_ARTICLE,
    REMOVE_ALL_ARTICLES,
  },
  creators: {
    addArticleAction,
    removeArticleAction,
    removeAllArticlesAction,
  },
}
