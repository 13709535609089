import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TitleCustom, ScheduleService } from '../../components'
import { useRouter } from '../../utils'
import dispatchers from './dispatchers'
import selectors from './selectors'
import moment from 'moment'

const RescheduleScreen = () => {
  const [selectedDate, setSelectedDate] = useState({})
  const { query, history } = useRouter()
  const { specialty, consultId, id } = query
  const dispatch = useDispatch()
  const {
    addError,
    removeError,
    updateConsult,
    getBlockedDays,
    getBlockedDaysPrivate,
    getAvailableHours,
    getAvailableHoursPrivate,
    rescheduleConsult,
  } = dispatchers(dispatch)
  const state = useSelector(state => state)
  const { errors, currentConsult, loading } = selectors(state)

  const goToOrders = async () => {
    if (Object.keys(selectedDate).length > 0) {
      const selectedDateSchedule = selectedDate.selectedDate
      const selectedHourSchedule = selectedDate.selectedHour

      let fullDate = moment(selectedDateSchedule).format('YYYY-MM-DD')
      const fullTime = selectedHourSchedule.split(' ')
      const hours = fullTime[0].split(':')
      if (fullTime[1] === 'pm') {
        hours[0] = parseInt(hours[0]) + 12
      }
      fullDate = `${fullDate} ${hours[0]}:${hours[1]}`

      await rescheduleConsult({
        uid: consultId,
        values: {
          scheduled_date: fullDate,
        },
      })

      history.push('/user/consults')
    }
  }

  return (
    <section className="hero is-fullheight">
      <div className="hero-body is-flex is-align-items-start pt-0 ">
        <div className="container mt-6">
          <TitleCustom className="has-text-centered is-size-3 is-text-color">
            Reagendar cita
          </TitleCustom>
          <div className="columns is-mobile is-centered">
            <div className="column  is-size-box-practi-service">
              <ScheduleService
                specialtyScheadule={specialty}
                typeConsult={'fast-service'}
                setScheadule={setSelectedDate}
                currentConsult={currentConsult}
                loading={loading}
                getAvailableHours={getAvailableHours}
                getAvailableHoursPrivate={getAvailableHoursPrivate}
                updateConsult={updateConsult}
                getBlockedDays={getBlockedDays}
                getBlockedDaysPrivate={getBlockedDaysPrivate}
                addError={addError}
                removeError={removeError}
                errors={errors}
                doctorId={id}
                disableBack={true}
                goToOrders={goToOrders}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RescheduleScreen
