import pkjson from '../../../package.json'

const goToOfficialSite = () => window.open('https://mynextderma.com/', '_blank')
const goToPrivacyPolicy = () =>
  window.open('https://mynextderma.com/app/aviso-privacidad/', '_blank')

const AboutScreen = () => {
  return (
    <div
      className="px-6"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '2rem',
      }}
    >
      <h1 className="title">Acerca de: </h1>
      <div className="content ">
        <p>
          Gracias por confiar en nosotros y por utilizar nuestros version web.
          Nuestra mision es ayudar a las personas a conseguir la mejor version
          de ellos, ayudarlos a generar confianza y poder inspirarlos para tener
          un mejor futuro.
        </p>
      </div>
      <h1 className="subtitle">Version: {pkjson.version}</h1>
      <div className="buttons are-medium is-flex is-justify-content-space-around">
        <button className="button is-mnd-primary" onClick={goToPrivacyPolicy}>
          <span className="bold-font" style={{ color: '#FFF' }}>
            Aviso de privacidad
          </span>
        </button>
        <button className="button is-mnd-primary" onClick={goToOfficialSite}>
          <span className="bold-font" style={{ color: '#FFF' }}>
            Ir a nuestro sitio oficial
          </span>
        </button>
      </div>
    </div>
  )
}

export default AboutScreen
