import PropTypes from 'prop-types'

import {
  // ButtonCustom,
  ImageCustom,
  TextCustom,
} from '../../components'

import Images from '../../assets'
import Button from '../buttons/Button'

const ModalPromotions = ({ handleOnClick, expired }) => {
  const { promotionsIcon, closeIcon } = Images
  return (
    <div className="modal is-absolute z-index-0">
      <div className="modal-background"></div>
      <div className="modal-card p-4 promotion-modal-card">
        <section className="modal-card-body is-border-radius">
          <div className="columns is-centered intro-step is-relative">
            <div className="level-right promotion-button-position ">
              <Button
                img={closeIcon}
                className="button is-inverted is-border-button-modal"
                imgClassName="are-color-mynd-grey image is-24x24"
                onClick={handleOnClick}
              />
            </div>
            <div className="box  modal-promotions">
              {!expired ? (
                <>
                  <ImageCustom
                    img={promotionsIcon}
                    className="is-size-img-promotions"
                  />
                  <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4 first-promotion">
                    15% de descuento
                  </TextCustom>
                  <TextCustom className="has-text-centered first-promotion">
                    Al comprar tu producto en los primeros 30 minutos.
                  </TextCustom>

                  <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4 second-promotion">
                    10% de descuento
                  </TextCustom>
                  <TextCustom className="has-text-centered second-promotion">
                    Al comprar tu producto en la primera hora.
                  </TextCustom>

                  <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4 third-promotion">
                    5% de descuento
                  </TextCustom>
                  <TextCustom className="has-text-centered third-promotion">
                    Al comprar tu producto dentro del primer día.
                  </TextCustom>

                  <TextCustom className="has-text-centered are-color-mynd-grey is-size-7 mt-4">
                    * Las promociones mostradas son ejemplos, pueden variar y no
                    siempre se enceuntran disponibles.
                  </TextCustom>
                </>
              ) : (
                <>
                  <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4 ">
                    Tu descuento ha expirado.
                  </TextCustom>
                  <TextCustom className="has-text-centered ">
                    Cierra este modal para actualizar tus promociones.
                  </TextCustom>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

ModalPromotions.propTypes = {
  handleOnClick: PropTypes.func,
  expired: PropTypes.bool,
}

export default ModalPromotions
