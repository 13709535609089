import PropTypes from 'prop-types'

const InputSelectCustom = ({
  children,
  handleInputChange,
  withoutNull,
  ...otherProps
}) => (
  <select className="is-fullwidth" onChange={handleInputChange} {...otherProps}>
    {!withoutNull && <option value=" "></option>}
    {Object.entries(children).map(([key, value]) => {
      return <option key={key}>{value}</option>
    })}
  </select>
)

InputSelectCustom.propTypes = {
  children: PropTypes.any,
  handleInputChange: PropTypes.func,
  withoutNull: PropTypes.bool,
}

export default InputSelectCustom
