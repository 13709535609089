import { usersF } from '../../config'
import { uiActions, authActions } from '../../actions'
import { processData } from '../../utils'

// const { signUpRequestAction } = authActions.creators
const { startLoading, stopLoading } = uiActions.creators
const {
  updateUserRequestAction,
  updateUserSuccessAction,
  updateUserFailureAction,
} = authActions.creators

const updateUserProfile = (data, uid) => {
  return async dispatch => {
    try {
      dispatch(updateUserRequestAction())
      dispatch(startLoading())

      await usersF.doc(uid).set(data, { merge: true })
      const user = await usersF.doc(uid).get()
      const newUserData = await processData(user)

      dispatch(updateUserSuccessAction(newUserData))
    } catch (error) {
      dispatch(updateUserFailureAction(error))
      return error
    } finally {
      dispatch(stopLoading())
    }
  }
}

export default updateUserProfile
