import { storage } from '../../config'
import { v4 } from 'uuid'
import { setImagesService } from '../../services'

const putStorageItem = async (image, consultId) => {
  try {
    const fileName = `${v4()}.jpeg`
    await storage.ref().child(`consults/${consultId}/${fileName}`).put(image)
    return { url: fileName }
  } catch (e) {
    return e
  }
}

const uploadFileNamesService = async (images, consultId) => {
  try {
    const fileNames = await Promise.all(
      images.map(file => putStorageItem(file, consultId)),
    )
    return fileNames
  } catch (e) {
    return 'Hubo un error al intentar crear tu consulta. '
  }
  // TODO: find a way to upload files to firebase without using promises
}

const setImageService = async ({ uid: consultId, images: fileNames }) => {
  try {
    await setImagesService({
      uid: consultId,
      images: fileNames,
    })
    return 'Tu consulta ha sido agendada, y la podrás revisar en tu sección "Sesiones"'
  } catch (error) {
    return 'Tu consulta ha sido creada, sin embargo un problema nos impidió capturar tus imágenes de evidencia. Nuestro equipo se pondrá en contacto contigo para solicitarte más información'
  }
}

const uploadFiles = async (consultId, images) => {
  try {
    const fileNames = await uploadFileNamesService(images, consultId)
    const response = await setImageService({
      uid: consultId,
      images: fileNames,
    })
    return response
  } catch (e) {
    return e
  }
}

export default uploadFiles
