import { orderDrugsActions } from '../../actions'

const {
  ADD_CURRENT_RECEIPT,
  ADD_CURRENT_RECEIPT_SUCCESS,
  ADD_CURRENT_RECEIPT_FAILURE,
  BUY_RECEIPT_REQUEST,
  BUY_RECEIPT_SUCCESS,
  BUY_RECEIPT_FAILURE,
  GET_ALL_ORDERS_REQUEST,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAILURE,
  CLEAR_ORDER_DRUGS,
} = orderDrugsActions.types

const initialState = {
  isLoading: false,
  currentReceipt: [],
  recipe_date: '',
  consultId: '',
  allReceipts: [],
  allOrders: [],
  error: '',
}

const orderDrugsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CURRENT_RECEIPT:
    case BUY_RECEIPT_REQUEST:
    case GET_ALL_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    case GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allOrders: action.payload,
      }
    case ADD_CURRENT_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentReceipt: action.payload.currentReceipt,
        consultId: action.payload.consultId,
        recipe_date: action.payload.recipe_date,
      }
    case BUY_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_CURRENT_RECEIPT_FAILURE:
    case BUY_RECEIPT_FAILURE:
    case GET_ALL_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case CLEAR_ORDER_DRUGS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default orderDrugsReducer
