import PropTypes from 'prop-types'

import { ButtonCustom, DiaryCard } from '../../../components'

const diaryItems = ({ diaryList, handleOnClick }) => {
  return (
    <div className="columns  is-centered">
      <div className="column is-size-container-products-diary">
        {diaryList.map(diary => {
          return <DiaryCard key={diary.ref} diary={diary} />
        })}
      </div>
      <div className="is-position-button-flotante ">
        <ButtonCustom
          icon="fas fa-plus"
          buttonClassName="button is-large is-colour-button-add-product "
          handleOnClick={handleOnClick}
        />
      </div>
    </div>
  )
}

diaryItems.propTypes = {
  diaryList: PropTypes.array,
  handleOnClick: PropTypes.func,
}

export default diaryItems
