import PropTypes from 'prop-types'

// Components
import { BoxPicture } from '../../components'

const ContainerBoxPicture = ({ fileUrl }) => (
  <>
    <div className="is-flex is-flex-direction-column is-align-content-center is-align-self-center ">
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start my-6">
        <BoxPicture img={fileUrl[0]} />
        <BoxPicture img={fileUrl[1]} />
        <BoxPicture img={fileUrl[2]} />
        <BoxPicture img={fileUrl[3]} />
        <BoxPicture img={fileUrl[4]} />
      </div>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start my-3">
        <BoxPicture img={fileUrl[5]} />
        <BoxPicture img={fileUrl[6]} />
        <BoxPicture img={fileUrl[7]} />
        <BoxPicture img={fileUrl[8]} />
        <BoxPicture img={fileUrl[9]} />
      </div>
    </div>
  </>
)

ContainerBoxPicture.propTypes = {
  fileUrl: PropTypes.array,
}

export default ContainerBoxPicture
