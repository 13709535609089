import { firebase } from '../../config'

import { authActions } from '../../actions'

const {
  changePasswordAction,
  changePasswordSuccessAction,
  changePasswordFailureAction,
} = authActions.creators

const changePasswordService = (code, password) => {
  return async dispatch => {
    dispatch(changePasswordAction())
    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        firebase
          .auth()
          .confirmPasswordReset(code, password)
          .then(() => {
            dispatch(changePasswordSuccessAction())
          })
          .catch(error => {
            dispatch(changePasswordFailureAction(error.message))
          })
      })
      .catch(error => {
        dispatch(changePasswordFailureAction(error.message))
      })
  }
}

export default changePasswordService
