import PropTypes from 'prop-types'
import { ButtonCustom, ConsultCard, TextCustom } from '..'

const SessionsContainer = ({
  consultList,
  getDrugs,
  loading,
  handleOnCLickBuyDrugs,
  handleOnClickPage,
  handleOnClickInvoice,
  handleOnClickSendInvoice,
  modalState,
  toggleModal,
  setModalState,
  showMore,
  getCancelReasons,
  uid,
  cancelSessionService,
}) => {
  const length = consultList.length
  return (
    <div id="consults_container" className="column consults-container">
      {length === 0 && (
        <TextCustom className="has-text-centered">
          No tienes consultas en esta sección
        </TextCustom>
      )}
      {consultList.map((consult, key) => {
        return (
          <ConsultCard
            loading={loading}
            consult={consult}
            key={consult.id}
            getDrugs={getDrugs}
            handleOnClickInvoice={handleOnClickInvoice}
            handleOnClickSendInvoice={handleOnClickSendInvoice}
            handleOnCLickBuyDrugs={handleOnCLickBuyDrugs}
            toggleModal={toggleModal}
            modalState={modalState}
            setModalState={setModalState}
            getCancelReasons={getCancelReasons}
            uid={uid}
            cancelSessionService={cancelSessionService}
          />
        )
      })}
      {showMore && (
        <ButtonCustom
          buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-form mt-5"
          onClick={handleOnClickPage}
        >
          Ver más
        </ButtonCustom>
      )}
    </div>
  )
}

SessionsContainer.propTypes = {
  consultList: PropTypes.array,
  getDrugs: PropTypes.func,
  handleOnCLickBuyDrugs: PropTypes.func,
  handleOnClickInvoice: PropTypes.func,
  handleOnClickPage: PropTypes.func,
  handleOnClickSendInvoice: PropTypes.func,
  modalState: PropTypes.bool,
  toggleModal: PropTypes.func,
  setModalState: PropTypes.func,
  showMore: PropTypes.bool,
  getCancelReasons: PropTypes.func,
  uid: PropTypes.string,
  loading: PropTypes.bool,
  cancelSessionService: PropTypes.func,
}

export default SessionsContainer
