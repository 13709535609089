// import PropTypes from 'prop-types'
import { TextCustom, ImageCustom } from '..'
import { Link } from 'react-router-dom'
import Images from '../../assets'

import { useToggle } from '../../utils'

const cardMyFavourites = ({ doctorFav, deleteDoctorFav, userId, reviews }) => {
  const { photoURL, fullname, id } = doctorFav
  const [favorite, setFavorite] = useToggle(true)
  const { Heart, user_default, comentIcon } = Images

  const deleteDocFav = async () => {
    setFavorite()
    await deleteDoctorFav({ userId, id })
    setFavorite(true)
  }

  return (
    <div className="box-doctor box-fav mb-5">
      <Link className="box box-full" to={`/user/doctor/${doctorFav.id}`}>
        <article className="media">
          <div className="media-left is-img-size-favourites">
            <ImageCustom
              img={photoURL || user_default}
              className="img-doctor"
            />
          </div>
          <div className="media-content is-mobile">
            <div className="content">
              <div className="is-flex is-justify-content-space-between mb-4">
                <div className="">
                  <TextCustom className="are-color-mynd-grey is-size-5 is-spacing-text">
                    {fullname}
                  </TextCustom>
                </div>
              </div>
              <TextCustom className="is-color-specialist">
                Especialista:
                <span className="are-color-mynd-grey">
                  El área de Piel y Cabello.
                </span>
              </TextCustom>
            </div>
            <div className="level is-mobile">
              <div className="level-left">
                <a className="" aria-label="reply">
                  <span className="icon is-small mr-2">
                    <ImageCustom img={comentIcon} />
                  </span>
                </a>
                <TextCustom className="level-item is-size-text-comments">
                  {reviews} comentarios
                </TextCustom>
              </div>
            </div>
          </div>
        </article>
      </Link>
      <div className="fav-icon">
        <a
          className={favorite ? 'heart-selected' : 'heart'}
          onClick={() => deleteDocFav()}
        >
          <Heart />
        </a>
      </div>
    </div>
  )
}

cardMyFavourites.propTypes = {}

export default cardMyFavourites
