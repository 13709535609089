// import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Header,
  Footer,
  Loading,
  ProgressContainer,
  QuestionNumber,
  SurveyHome,
  QuestionSun,
  QuestionFood,
  QuestionTreatment,
  QuestionSkin,
} from '../../components'

const Survey = () => {
  const [step, setStep] = useState(1)
  const formbyStep = step => {
    setStep(prevStep => prevStep + 1)
  }
  return (
    <section className="hero is-fullheight">
      <div className="hero-head pt-4 pl-4">
        <Header />
      </div>
      <div className="hero-body is-flex is-align-items-start pt-0">
        <div className="container">
          <div className="columns is-centered">
            <QuestionNumber step={step} />
          </div>
          {step === 1 || step === 6 ? null : <ProgressContainer step={step} />}
          <div className="columns is-centered">
            <div className="column is-two-fifths pt-4">
              {step === 1 && <SurveyHome formbyStep={formbyStep} />}
              {step === 2 && <QuestionSun formbyStep={formbyStep} />}
              {step === 3 && <QuestionFood formbyStep={formbyStep} />}
              {step === 4 && <QuestionSkin formbyStep={formbyStep} />}
              {step === 5 && <QuestionTreatment formbyStep={formbyStep} />}
            </div>
          </div>
          {step === 6 && <Loading />}
        </div>
      </div>
      <div className="hero-foot is-align-content-end mb-4">
        <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
      </div>
    </section>
  )
}

export default Survey
