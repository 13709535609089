import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useCounter, useRouter } from '../../utils'
import { useStripe } from '@stripe/react-stripe-js'
import {
  ButtonCustom,
  DrugsBuying,
  PaymentMethod,
  ReceiptSummary,
} from '../../components'
import CustomButton from '../custom-button/custom-button.component'
import { formatToMoney } from '../../utils/functions/currencyFormat'

const recipeBuying = ({
  fullname,
  consultId,
  buyDrug,
  getDrugs,
  drugsList,
  allAddress,
  cards,
  balance,
  email,
  phone,
  generateAsyncBuyDrugList,
  generateAsynchronousPaymentReceipt,
  recipe_date,
  num,
  setNum,
}) => {
  const { history } = useRouter()
  const stripe = useStripe()
  const [isLoadingPayment, setIsLoadingPayment] = useState(false)
  const [selectMethod, setSelectMethod] = useState('')
  const [shippingMethod, setShippingMethod] = useState('')
  const [selectAddress, setSelectAddress] = useState('')
  const { count, increment, decrement } = useCounter()
  const [order, setOrder] = useState()
  const [selectPlan, setSelectPlan] = useState('')
  const [totalCost, setTotalCost] = useState(0)

  const handleOrder = item => {
    const newOrder = { ...order, [item.id]: item }
    setOrder(newOrder)
  }

  const shippingPlans = [
    {
      name: 'nacional',
      price: 120,
      label: 'Envío Nacional',
    },
  ]

  const getTotalCost = () => {
    const total = Object.values(order)
      .filter(item => item.isSold)
      .reduce((acc, item) => {
        if (item.order_quantity) {
          return acc + item.order_quantity * item.clientPrice
        }
        return acc
      }, 0)
    setTotalCost(total)
  }

  useEffect(() => {
    if (order) getTotalCost()
    return () => {}
  }, [order])

  const handleAvailableForFreeShipping = () => 200 - totalCost <= 0

  const handleOnBuyReceipt = async () => {
    setIsLoadingPayment(true)
    try {
      const handleRequestItems = Object.values(order)
      const requestedItems = {}

      for (let i = 0; i < handleRequestItems.length; i++) {
        requestedItems[handleRequestItems[i].id] =
          handleRequestItems[i].order_quantity ?? 0
      }
      const handleSelectedAddress = allAddress.filter(
        value => value.description === selectAddress,
      )
      const values = {
        order: {
          status: 'new',
          coords: true,
          total: handleAvailableForFreeShipping() ? totalCost : totalCost + 120,
          send_address: {
            street: handleSelectedAddress[0].street,
            number: handleSelectedAddress[0].number,
            int_number: handleSelectedAddress[0].int_number,
            vicinity: handleSelectedAddress[0].vicinity,
            postal_code: handleSelectedAddress[0].postal_code,
            locality: handleSelectedAddress[0].locality,
            state: handleSelectedAddress[0].state,
            country: handleSelectedAddress[0].country,
            coords: handleSelectedAddress[0].coords,
          },
          sendOption: 'nacional',
          requestedItems,
          free_shipping_flag: handleAvailableForFreeShipping(),
          shipping_cost_order: parseInt(shippingMethod),
          email: email,
          phone: phone,
          references: '',
          months: selectPlan,
        },
        cardToken: selectMethod.method === 'card' ? selectMethod.id : null,
      }
      if (selectMethod.method === 'card' || selectMethod.method === 'balance') {
        await buyDrug(consultId, values)
        history.push('/user/consults')
      }

      if (selectMethod.method === 'alternative') {
        const response = await generateAsyncBuyDrugList({ consultId, values })
        const { consultId: cid, total } = response.data
        if (selectMethod.details === 'oxxo') {
          const response2 = await generateAsynchronousPaymentReceipt({
            consultId: cid,
            amount: total * 100,
            type: 'oxxo',
          })
          const { client_secret } = response2.data
          await stripe.confirmOxxoPayment(client_secret, {
            payment_method: {
              billing_details: {
                name: fullname,
                email: email,
              },
            },
          })
          return history.push('/user/consults')
        }
        if (
          selectMethod.details === 'spei' ||
          selectMethod.details === 'paynet'
        ) {
          const response3 = await generateAsynchronousPaymentReceipt({
            consultId,
            amount: total,
            type: selectMethod.details,
          })
          if (response3?.data?.charge?.instructions)
            window.open(response3.data.charge.instructions)
          return history.push('/user/consults')
        }
      }
    } catch (error) {
      console.log('Error', error)
    } finally {
      setIsLoadingPayment(false)
    }
  }

  const validationBuyButton = () => {
    if (shippingMethod === '' || selectAddress === '') {
      return true
    }
    if (
      selectMethod.method === 'card' &&
      selectMethod.funding === 'credit' &&
      selectPlan === ''
    ) {
      return true
    }
    return false
  }

  return (
    <div className="white-box">
      {count === 1 && (
        <DrugsBuying
          drugsList={drugsList}
          getDrugs={getDrugs}
          handleOrder={handleOrder}
          recipe_date={recipe_date}
          num={num}
          setNum={setNum}
        />
      )}
      {count === 2 && (
        <ReceiptSummary
          count={count}
          selectMethod={selectMethod}
          isOrderDrug
          allAddress={allAddress}
          setSelectAddress={setSelectAddress}
          setSelectPlan={setSelectPlan}
          shippingPlans={shippingPlans}
          shippingMethod={shippingMethod}
          selectAddress={selectAddress}
          selectPlan={selectPlan}
          setShippingMethod={setShippingMethod}
          increment={increment}
          totalCost={totalCost}
          paymentMethod={selectMethod}
        />
      )}
      {count === 3 && (
        <PaymentMethod
          isReceiptOrConsult
          cards={cards}
          balance={balance}
          setPaymentMethod={setSelectMethod}
          next={decrement}
          previous={decrement}
        />
      )}
      {count === 1 && (
        <ButtonCustom
          buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-form"
          onClick={increment}
          disabled={totalCost === 0}
        >
          {`Comprar ${formatToMoney(totalCost)}`}
        </ButtonCustom>
      )}
      {selectMethod === '' && count === 2 && (
        <ButtonCustom
          buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-form"
          onClick={increment}
        >
          Método de pago
        </ButtonCustom>
      )}
      {selectMethod !== '' && count === 2 && (
        <CustomButton
          isPrimary
          isLoading={isLoadingPayment}
          handleClick={handleOnBuyReceipt}
          disabled={validationBuyButton()}
        >
          Finalizar compra
        </CustomButton>
      )}
    </div>
  )
}

recipeBuying.propTypes = {
  consultId: PropTypes.string.isRequired,
  buyDrug: PropTypes.func.isRequired,
  getDrugs: PropTypes.func,
  setShowModal: PropTypes.func,
  drugsList: PropTypes.array,
  allAddress: PropTypes.array,
  cards: PropTypes.instanceOf(Array).isRequired,
  balance: PropTypes.number,
  name: PropTypes.string,
  phone: PropTypes.string,
  isLoading: PropTypes.bool,
  generateAsyncBuyDrugList: PropTypes.func,
  generateAsynchronousPaymentReceipt: PropTypes.func,
  fullname: PropTypes.string,
  recipe_date: PropTypes.string,
  num: PropTypes.number,
  setNum: PropTypes.func,
}

export default recipeBuying
