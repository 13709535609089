// import PropTypes from 'prop-types'
import { ImageCustom } from '..'
import Images from '../../assets'

const ratings = ({
  stars,
  fiveStarts,
  forStarts,
  threStarts,
  twoStarts,
  oneStarts,
}) => {
  const {
    startFiveIcon,
    startsFourIcon,
    startsThreeIcon,
    startsTwoIcon,
    startsOneIcon,
  } = Images
  return (
    <>
      <div className="is-flex is-justify-content-center ml-1 mb-1">
        <div className="is-size-progrss">
          <progress
            className="progress is-info is-colour-progress"
            value={fiveStarts.length}
            max={stars.length}
          ></progress>
        </div>

        <div>
          <span className="icon-text">
            <ImageCustom
              img={startFiveIcon}
              className="is-size-starts-icon mr-1"
            />
            <span className="is-number-comments">({fiveStarts.length})</span>
          </span>
        </div>
      </div>

      <div className="is-flex is-justify-content-center mb-1">
        <div className="is-size-progrss">
          <progress
            className="progress is-info is-colour-progress"
            value={forStarts.length}
            max={stars.length}
          ></progress>
        </div>

        <div>
          <span className="icon-text is-size-star">
            <ImageCustom
              img={startsFourIcon}
              className="is-size-starts-icon mr-1"
            />
            <span className="is-number-comments">({forStarts.length})</span>
          </span>
        </div>
      </div>

      <div className="is-flex is-justify-content-center mb-1">
        <div className="is-size-progrss">
          <progress
            className="progress is-info is-colour-progress"
            value={threStarts.length}
            max={stars.length}
          ></progress>
        </div>
        <div>
          <span className="icon-text is-size-star">
            <ImageCustom
              img={startsThreeIcon}
              className="is-size-starts-icon mr-1"
            />
            <span className="is-number-comments">({threStarts.length})</span>
          </span>
        </div>
      </div>

      <div className="is-flex is-justify-content-center mb-1">
        <div className="is-size-progrss">
          <progress
            className="progress is-info is-colour-progress"
            value={twoStarts.length}
            max={stars.length}
          ></progress>
        </div>

        <div>
          <span className="icon-text is-size-star">
            <ImageCustom
              img={startsTwoIcon}
              className="is-size-starts-icon mr-1"
            />
            <span className="is-number-comments">({twoStarts.length})</span>
          </span>
        </div>
      </div>

      <div className="is-flex is-justify-content-center mb-1">
        <div className="is-size-progrss">
          <progress
            className="progress is-info is-colour-progress"
            value={oneStarts.length}
            max={stars.length}
          ></progress>
        </div>

        <div>
          <span className="icon-text is-size-star">
            <ImageCustom
              img={startsOneIcon}
              className="is-size-starts-icon mr-1"
            />
            <span className="is-number-comments">({oneStarts.length})</span>
          </span>
        </div>
      </div>
    </>
  )
}

ratings.propTypes = {}

export default ratings
