import { verifyIsNew } from '../../utils'
import { phoneAuth, auth } from '../../config'
import { authActions, uiActions } from '../../actions/index'
const {
  signInWithPhoneAction,
  signInWithPhoneSuccessAction,
  signInWithPhoneFailureAction,
  signUpRequestAction,
} = authActions.creators
const { startLoading, stopLoading, addError } = uiActions.creators
const phoneAuthWhitCredential = async (verificationId, verificationCode) => {
  try {
    const authWithCredential = phoneAuth.credential(
      verificationId,
      verificationCode,
    )
    const response = await auth.signInWithCredential(authWithCredential)
    return response
  } catch (error) {
    return { error: error.message }
  }
}

const singInWithPhone = (verificationId, verificationCode) => {
  return async dispatch => {
    try {
      dispatch(signInWithPhoneAction())
      dispatch(startLoading())
      const result = await phoneAuthWhitCredential(
        verificationId,
        verificationCode,
      )
      const { error } = result
      if (!error) {
        const {
          user: { uid },
          additionalUserInfo: { isNewUser },
        } = result
        const getUser = await verifyIsNew(result, 'PHONE_PROVIDER')
        const { error: getUserError } = getUser
        if (!getUserError) {
          dispatch(
            signInWithPhoneSuccessAction({ uid, isNewUser, data: getUser }),
          )
          dispatch(signUpRequestAction())
          dispatch(stopLoading())
        } else {
          dispatch(addError(getUserError))
          dispatch(stopLoading())
        }
      } else {
        dispatch(addError(error))
        dispatch(stopLoading())
      }
    } catch (error) {
      dispatch(signInWithPhoneFailureAction(error))
      dispatch(stopLoading())
    }
  }
}
export default singInWithPhone
