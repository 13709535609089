import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import {
  TitleCustom,
  AddPhotoContainer,
  ProductContainerForm,
} from '../../../components'

import { useForm, useImageForm } from '../../../utils'
import CustomButton from '../../custom-button/custom-button.component'
import moment from 'moment'

const addProducts = ({
  createProduct,
  updateProduct,
  decrement,
  uploadImage,
  uid,
  setProductsSelected,
  productsSelected,
}) => {
  const [
    { imageProduct, imageIngredients, imageAditional },
    handleProductImageInputChange,
    resetProductImages,
  ] = useImageForm({
    imageProduct: '',
    imageIngredients: '',
    imageAditional: '',
  })
  const [
    {
      productName,
      brand,
      categories,
      price,
      badge,
      date,
      placeOfPurchase,
      notes,
      isSelected,
      useDays,
      useTime,
    },
    handleInputChange,
    ,
    ,
    reset,
  ] = useForm({
    productName: '',
    brand: '',
    categories: '',
    price: '',
    badge: '',
    date: new Date(),
    placeOfPurchase: '',
    notes: '',
    useTime: '',
    useDays: '',
    isSelected: true,
  })

  useEffect(() => {
    reset()
    resetProductImages()
  }, [])

  const time = moment().format()
  const [isLoading, setIsLoading] = useState(false)

  const createNewProduct = async () => {
    setIsLoading(true)
    if (!imageProduct) {
      return
    }
    const productId = await createProduct()
    if (!productId?.error) {
      const { id } = productId
      const uri = await uploadImage(imageProduct.file, 'products', uid)
      const productUri = imageIngredients
        ? await uploadImage(imageIngredients.file, 'products', uid)
        : false
      const aditionalUri = imageAditional
        ? await uploadImage(imageAditional.file, 'products', uid)
        : false
      if (uri.status !== 'error') {
        const values = {
          uri: uri.fileName,
          productName,
          brand,
          categories,
          price,
          badge,
          date: moment(date).format(),
          placeOfPurchase,
          notes,
          isSelected,
          useDays,
          useTime,
          ingredients: {
            productUri: productUri.fileName,
            aditionalUri: aditionalUri.fileName,
          },
          created: time,
        }
        await updateProduct(values, id)
        setProductsSelected([...productsSelected, { uri: uri.fileName }])
        setIsLoading(false)
        reset()
        resetProductImages()
        decrement()
      } else {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <TitleCustom className="are-color-mynd-grey has-text-centered is-title-size">
        Agregar productos
      </TitleCustom>

      <div className="is-size-container-add-product">
        <AddPhotoContainer
          imageLeft={imageProduct}
          imageRight={imageAditional}
          imageFront={imageIngredients}
          onHandle={handleProductImageInputChange}
        />
        <ProductContainerForm
          handleInputChange={handleInputChange}
          date={date}
        />
      </div>

      <div className="diary-buttons">
        <div className="diary-buttons-divs">
          <CustomButton isPrimary handleClick={decrement}>
            Cancelar
          </CustomButton>
        </div>
        <div className="diary-buttons-divs">
          <CustomButton
            isPrimary
            isLoading={isLoading}
            isSubmit
            handleClick={createNewProduct}
            disabled={!(productName && imageProduct)}
          >
            Subir a mis productos
          </CustomButton>
        </div>
      </div>
    </>
  )
}

addProducts.propTypes = {
  increment: PropTypes.func,
  decrement: PropTypes.func,
  uploadImage: PropTypes.func,
  uid: PropTypes.string,
  setProductsSelected: PropTypes.func,
  productsSelected: PropTypes.array,
}

export default addProducts
