export default (auth, doctors, id) => {
  const { data } = doctors
  const { user } = auth
  const { uid } = user
  const doctor = data.find(d => d.id === id)
  const {
    consult_tracker,
    name,
    photo,
    career,
    profesionalIds,
    awards,
    lastJob,
    specialty,
    extraInfo,
    score,
    reviews,
    reviewsCount,
    about,
  } = doctor
  return {
    uid,
    name,
    photo,
    career,
    profesionalIds,
    awards,
    lastJob,
    specialty,
    extraInfo,
    consult_tracker,
    reviews: JSON.parse(reviews),
    reviewsCount,
    score: score || 0,
    about,
  }
}
