import PropTypes from 'prop-types'
import { ButtonCustom, TitleCustom } from '../../components'

const QuestionSkin = ({ formbyStep }) => {
  return (
    <div className="columns mb-6">
      <div className="column">
        <div className="box is-box-shadow px-6">
          <TitleCustom className="has-text-centered is-5 are-color-mynd-grey pt-5">
            ¿Que tipo de piel crees que tienes?
          </TitleCustom>
          <p className="buttons is-centered are-large are-color-mynd-grey ">
            <ButtonCustom buttonClassName=" is-button-form is-fullwidth mx-4 my-4 are-color-mynd-grey ">
              Piel equilibrada
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              Piel seca
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              Piel grasa
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              Piel mixta
            </ButtonCustom>
          </p>
          <p className="buttons is-centered">
            <ButtonCustom
              buttonClassName=" is-mnd-primary is-fullwidth mx-3 my-4 has-text-white are-color-mynd-grey is-medium is-widescreen"
              onClick={formbyStep}
            >
              Siguiente
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-text-color is-inverted mt-2">
              Omitir
            </ButtonCustom>
          </p>
        </div>
      </div>
    </div>
  )
}

QuestionSkin.propTypes = {
  formbyStep: PropTypes.func,
}

export default QuestionSkin
