import PropTypes from 'prop-types'
import { TextCustom, ButtonCustom } from '../../components/'

const EmptyDiary = ({ handleOnClick }) => {
  return (
    <div className="empty-diary">
      <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4">
        {`Te invitamos a que inicies tu diario, después agrega tus productos
         y al final presiona el botón "Añadir a mi diario". ¿Listo para iniciar?`}
      </TextCustom>
      <div className="is-position-button-flotante ">
        <ButtonCustom
          icon="fas fa-plus"
          buttonClassName="button is-large is-colour-button-add-product "
          handleOnClick={handleOnClick}
        />
      </div>
    </div>
  )
}

EmptyDiary.propTypes = {
  handleOnClick: PropTypes.func,
}

export default EmptyDiary
