// helpers
const getImageFromHtml = ({ html }) => {
  const urls = []
  let m1
  const rex1 = /<img[^>]+src="http:\/\/18.191.223.221([^">]+)/g
  let m2
  const rex2 = /<img[^>]+src="https:\/\/mynextderma.com([^">]+)/g
  let m3
  const rex3 = /<img[^>]+src="http:\/\/35.86.131.127([^">]+)/g
  while ((m1 = rex1.exec(html))) {
    urls.push('https://mynextderma.com' + m1[1])
  }
  while ((m2 = rex2.exec(html))) {
    urls.push('https://mynextderma.com' + m2[1])
  }
  while ((m3 = rex3.exec(html))) {
    urls.push('https://mynextderma.com' + m3[1])
  }
  return urls
}

const getCategories = async ({ id }) => {
  try {
    const categoryResult = await fetch(
      `https://mynextderma.com/wp-json/wp/v2/categories/${id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(json => {
        return json
      })
      .catch(error => {
        return error
      })
    return categoryResult
  } catch (error) {
    return error
  }
}

const getBlogArticles = async ({ limit, parm, articles }) => {
  try {
    const url = articles
      ? `https://mynextderma.com/wp-json/wp/v2/posts?include=${articles}`
      : `https://mynextderma.com/wp-json/wp/v2/posts?per_page=${limit}&${parm}`
    const finalResult = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(async posts => {
        const newlistPost = []
        for (let i = 0; i < posts.length; i++) {
          const image = await getImageFromHtml({
            html: posts[i].content.rendered,
          })[0]
          const category = await getCategories({
            id: posts[i].categories[posts[i].categories.length - 1],
          })
          newlistPost.push({
            post: posts[i],
            category,
            image,
          })
        }
        return newlistPost
      })
      .catch(error => {
        return error
      })
    return finalResult
  } catch (error) {
    return error
  }
}

export default getBlogArticles
