// components
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useField, getBlogArticles } from '../../utils'
import {
  Input,
  TitleCustom,
  TextCustom,
  Button,
  LabelCustom,
  ArticleItem,
} from '../../components'
import selectors from './selectors'
import { Link } from 'react-router-dom'

const Blog = ({ location }) => {
  const { blog } = useSelector(state => state)
  const { savedArticles, categoryOfInterest } = selectors(blog)

  const [showLists, setshowLists] = useState('default')
  const [searchText, setSearchText] = useState('')
  const [listLastNews, setListLastNews] = useState([])
  const [listPopular, setPopular] = useState([])
  const [listFourYou, setListFourYou] = useState([])
  const [myArticles, setMyArticles] = useState([])
  const [listSearchedPost, setListSearchedPost] = useState([])

  const getArticles = async (parms, listKey) => {
    const list = await getBlogArticles(parms)
    switch (listKey) {
      case 1:
        setListLastNews(list)
        break
      case 2:
        setPopular(list)
        break
      case 3:
        setListFourYou(list)
        break
      case 4:
        setMyArticles(list)
        break
      case 5:
        setListSearchedPost(list)
        break
    }
  }

  useEffect(() => {
    // if has previus search
    if (location.state) {
      setSearchText(location.state.search)
      searchArticle()
    }
    // default lists
    getArticles({ limit: 5, parm: 'orderby=date' }, 1)
    getArticles({ limit: 5, parm: 'orderby=date' }, 2)
    if (categoryOfInterest.length > 0) {
      getArticles({ limit: 5, parm: `categories=${categoryOfInterest}` }, 3)
    }
    // saved lists
    if (savedArticles.length > 0) {
      getArticles({ articles: savedArticles }, 4)
    }
  }, [])

  const articlesBtn = useField({
    icon:
      showLists === 'saved' ? 'fa fa-bookmark fa-lg' : 'fa fa-bookmark-o fa-lg',
    iconRight: true,
    iconClassName: 'is-medium',
    className: 'button is-success',
    onClick: () => {
      if (showLists === 'saved' || savedArticles.length > 0) {
        setshowLists(showLists === 'saved' ? 'default' : 'saved')
      } else {
        alert('No tienes articulos guardados')
      }
    },
  })

  const retunBtn = useField({
    icon: 'fa fa-newspaper-o fa-lg',
    iconRight: true,
    iconClassName: 'is-medium',
    className: 'button is-danger',
    onClick: () => {
      setshowLists('default')
    },
  })

  const searchArticle = () => {
    setshowLists('search')
    getArticles({ limit: 5, parm: `search=${searchText}` }, 5)
  }

  const renderArticlesList = () => {
    if (showLists === 'default') {
      return (
        <div className="articles-list">
          {listLastNews.length > 0 && (
            <>
              <LabelCustom className="label are-color-mynd-grey">
                Últimas noticias
              </LabelCustom>
              <div className="horizontal-scroll">
                {listLastNews.map((item, index) => (
                  <Link
                    key={`${index}_listLastNews`}
                    to={{
                      pathname: '/user/blogDetail',
                      state: { article: item },
                    }}
                  >
                    <ArticleItem
                      size="large"
                      category={item.category.name}
                      title={item.post.title.rendered}
                      description={item.post.excerpt.rendered}
                      date={item.post.date}
                      content={item.image}
                    />
                  </Link>
                ))}
              </div>
            </>
          )}
          {listPopular.length > 0 && (
            <>
              <LabelCustom className="label are-color-mynd-grey">
                Más populares
              </LabelCustom>
              <div className="horizontal-scroll">
                {listPopular.map((item, index) => (
                  <Link
                    key={`${index}_listPopular`}
                    to={{
                      pathname: '/user/blogDetail',
                      state: { article: item },
                    }}
                  >
                    <ArticleItem
                      size="large"
                      category={item.category.name}
                      title={item.post.title.rendered}
                      description={item.post.excerpt.rendered}
                      date={item.post.date}
                      content={item.image}
                    />
                  </Link>
                ))}
              </div>
            </>
          )}
          {listFourYou.length > 0 && (
            <>
              <LabelCustom className="label are-color-mynd-grey">
                Para tí
              </LabelCustom>
              <div className="horizontal-scroll">
                {listFourYou.map((item, index) => (
                  <Link
                    key={`${index}_listFourYou`}
                    to={{
                      pathname: '/user/blogDetail',
                      state: { article: item },
                    }}
                  >
                    <ArticleItem
                      size="large"
                      category={item.category.name}
                      title={item.post.title.rendered}
                      description={item.post.excerpt.rendered}
                      date={item.post.date}
                      content={item.image}
                    />
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      )
    } else if (showLists === 'saved') {
      return (
        <div>
          <LabelCustom className="label are-color-mynd-grey">
            Actividades guardadas
          </LabelCustom>
          {myArticles.map((item, index) => (
            <Link
              key={`${index}_myArticles`}
              to={{ pathname: '/user/blogDetail', state: { article: item } }}
            >
              <ArticleItem
                size="list"
                category={item.category.name}
                title={item.post.title.rendered}
                description={item.post.excerpt.rendered}
                date={item.post.date}
                content={item.image}
              />
            </Link>
          ))}
        </div>
      )
    } else {
      return (
        <div>
          <LabelCustom className="label are-color-mynd-grey">
            Resultados de busqueda
          </LabelCustom>
          {listSearchedPost.map((item, index) => (
            <Link
              key={`${index}_listSearchedPost`}
              to={{ pathname: '/user/blogDetail', state: { article: item } }}
            >
              <ArticleItem
                size="list"
                category={item.category.name}
                title={item.post.title.rendered}
                description={item.post.excerpt.rendered}
                date={item.post.date}
                content={item.image}
              />
            </Link>
          ))}
        </div>
      )
    }
  }

  return (
    <div className="container is-margin-container mt-5">
      <TitleCustom className="has-text-centered are-color-mynd-grey is-size-title">
        My derma blog
      </TitleCustom>
      <div className="field is-centered search-container">
        <TextCustom className="control has-icons-left has-icons-right search-size">
          <>
            <Input
              className="input is-fullwidth are-color-mynd-grey"
              type="text"
              placeholder="Buscar en my derma blog (enter para buscar)"
              value={searchText}
              onChange={({ target }) => setSearchText(target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchText) {
                  searchArticle()
                }
              }}
            />
            <span className="icon is-small is-right">
              <i className="fas fa-search"></i>
            </span>
          </>
        </TextCustom>
      </div>
      <div className="field btn-article-container">
        {showLists === 'search' ? (
          <Button {...retunBtn}>Regresar a los artículos</Button>
        ) : (
          <Button {...articlesBtn}>Ver artículos guardados</Button>
        )}
      </div>
      {renderArticlesList()}
    </div>
  )
}

Blog.propTypes = {
  location: PropTypes.any,
}

export default Blog
