import { doctorsActions } from '../../actions'

const {
  CREATE_DOCTORS_REQUEST,
  CREATE_DOCTORS_SUCCESS,
  CREATE_DOCTORS_FAILURE,
  CLEAR_DOCTORS,
} = doctorsActions.types

const initialState = {
  data: [],
}

const doctorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DOCTORS_REQUEST:
      return {
        ...state,
      }
    case CREATE_DOCTORS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case CREATE_DOCTORS_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_DOCTORS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default doctorsReducer
