import { firebase } from '../../config'
import { authActions, uiActions } from '../../actions'
import { verifyIsNew } from '../../utils'
const {
  fetchSignInAccountsAction,
  fetchSignInAccountsSuccessAction,
  fetchSignInAccountsFailureAction,
  signUpRequestAction,
} = authActions.creators
const { startLoading, stopLoading } = uiActions.creators

const fetchSignInMethodsForEmailService = ({ credential, email, password }) => {
  return async dispatch => {
    try {
      dispatch(startLoading())
      dispatch(fetchSignInAccountsAction())
      const fetching = await firebase.auth().fetchSignInMethodsForEmail(email)
      if (fetching) {
        await new Promise(resolve => setTimeout(resolve, 500))
        try {
          const response = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
          const { user } = response
          if (response) {
            if (user) {
              const result = await user.linkWithCredential(credential)
              const getUser = await verifyIsNew(result, 'PHONE_PROVIDER')
              if (getUser) {
                dispatch(
                  fetchSignInAccountsSuccessAction({
                    uid: user.uid,
                    isNewUser: false,
                    data: getUser,
                  }),
                )
              }
              dispatch(signUpRequestAction())
              dispatch(stopLoading())
            }
          }
        } catch (error) {
          dispatch(stopLoading())
          return dispatch(fetchSignInAccountsFailureAction(error))
        }
      }
    } catch (error) {
      dispatch(stopLoading())
      return dispatch(fetchSignInAccountsFailureAction(error))
    }
  }
}
export default fetchSignInMethodsForEmailService
