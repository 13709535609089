const formatTimePromotions = seconds => {
  const day = 86400
  const hour = 3600
  const minute = 60

  const daysOut = Math.floor(seconds / day)
  const hoursOut = Math.floor((seconds - daysOut * day) / hour)
  const minutesOut = Math.floor(
    (seconds - daysOut * day - hoursOut * hour) / minute,
  )
  const secondsout =
    seconds - daysOut * day - hoursOut * hour - minutesOut * minute

  return `${daysOut}d ${hoursOut}h ${minutesOut}m ${secondsout}s`
}

export default formatTimePromotions
