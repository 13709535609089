import PropTypes from 'prop-types'

const TitleCustom = ({ children, className }) => (
  <h1 className={`title ${className}`}> {children} </h1>
)

TitleCustom.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
}

export default TitleCustom
