export default (auth, session) => {
  const { error, user } = auth
  const { expiry } = session
  const { message } = error
  const { fullname, photoURL, privateFlag, privateDoctor } = user.data
  const { uid } = user
  return {
    message,
    fullname,
    uid,
    photoURL,
    privateFlag,
    privateDoctor,
    expiry,
  }
}
