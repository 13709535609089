import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const SignInRoute = ({ isSignIn, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={props =>
        isSignIn ? <Component {...props} /> : <Redirect to="/user" />
      }
    ></Route>
  )
}

SignInRoute.propTypes = {
  isSignIn: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
}

export default SignInRoute
