import { useSelector, useDispatch } from 'react-redux'
import { TitleCustom, Steps, ScheduleService } from '../../components'
import { FAST_SERVICE } from '../../utils/constants'
import { useCounter } from '../../utils'
import dispatchers from './dispatchers'
import selectors from './selectors'

const index = () => {
  const dispatch = useDispatch()
  const { addError, removeError, getBlockedDays, getAvailableHours } =
    dispatchers(dispatch)
  const state = useSelector(state => state)
  const { errors, loading } = selectors(state)
  const { count, increment, decrement } = useCounter(1)
  return (
    <section className="hero is-fullheight">
      <div className="hero-body s-flex is-align-items-start pt-0">
        <div className="container">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <TitleCustom className="mt-5 has-text-centered is-size-3 is-text-color">
                Live
              </TitleCustom>
              <div className="box">
                <Steps typeService={FAST_SERVICE} step={count} />
                <ScheduleService
                  loading={loading}
                  getAvailableHours={getAvailableHours}
                  getBlockedDays={getBlockedDays}
                  increment={increment}
                  decrement={decrement}
                  addError={addError}
                  removeError={removeError}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default index
