import { useSelector, useDispatch } from 'react-redux'
import validator from 'validator'
import { useForm, useToggle, useRouter } from '../../utils'
import { Header, Footer } from '../../components'
import successLogo from '../../assets/Success-Image-Password.png'
import dispatchers from './dispatchers'
import selectors from './selectors'
// url: www.mynextderma.com/rc-password?oobCode=5198SADSADSADsdasd?
const ForgottenPasswordScreen = () => {
  const dispatch = useDispatch()
  const { changePasswordFailure, changePasswordSuccess, changePassword } =
    dispatchers(dispatch)
  const { auth } = useSelector(state => state)
  const { errors, resetPassword, resetPasswordError } = selectors(auth)
  const { query, history } = useRouter()
  const { oobCode } = query
  const [{ password, passwordConfirm }, handleInputChange] = useForm({
    password: '',
    passwordConfirm: '',
  })
  const [isVisible, setIsVisible] = useToggle()

  const handleGoTo = () => {
    changePasswordSuccess()
    history.push('/auth/login')
  }

  const isFormValid = () => {
    if (validator.isEmpty(password) || validator.isEmpty(passwordConfirm)) {
      changePasswordFailure(
        'Se requiere contraseña y confirmación de contraseña',
      )
      return false
    }

    if (!validator.equals(password, passwordConfirm)) {
      changePasswordFailure(
        'La contraseña y la confirmación de contraseña deben coincidir',
      )
      return false
    }

    if (!validator.isLength(password, 8, 20)) {
      changePasswordFailure('La contraseña debe tener entre 8 y 20 caracteres')
      return false
    }

    return true
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (isFormValid()) {
      changePassword(oobCode, password)
    }
  }

  return (
    <>
      <section className="hero is-fullheight">
        <Header />
        <div className="hero-body">
          <div className="container is-max-widescreen">
            <div className="columns">
              <div className="column is-half is-offset-one-quarter">
                {!resetPassword ? (
                  <div className="box is-box-shadow is-border-radius">
                    <form onSubmit={handleSubmit}>
                      {resetPasswordError && (
                        <div className="notification is-danger mt-3">
                          {resetPasswordError}
                        </div>
                      )}
                      <h5 className="subtitle is-5 has-text-centered has-text-dark p-5">
                        Ingresa tu nueva contraseña
                      </h5>
                      <div className="field py-4 mx-6">
                        <label>Contraseña</label>
                        <div className="control has-icons-right">
                          <input
                            className={
                              errors
                                ? 'input is-danger is-medium'
                                : 'input is-medium'
                            }
                            type={isVisible ? 'text' : 'password'}
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            placeholder="*********"
                          />
                          <span className="icon is-right">
                            <i
                              onClick={setIsVisible}
                              className="fas fa-eye"
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div className="field py-4 mx-6">
                        <label>Confirmar contraseña</label>
                        <div className="control has-icons-right">
                          <input
                            className={
                              errors
                                ? 'input is-danger is-medium'
                                : 'input is-medium'
                            }
                            type={isVisible ? 'text' : 'password'}
                            name="passwordConfirm"
                            value={passwordConfirm}
                            onChange={handleInputChange}
                            placeholder="*********"
                          />
                          <span className="icon is-right">
                            <i
                              onClick={setIsVisible}
                              className="fas fa-eye"
                            ></i>
                          </span>
                        </div>
                        <div className="checkbox mt-4">
                          <input
                            type="checkbox"
                            className="mr-2"
                            onClick={setIsVisible}
                          />
                          Mostrar contraseñas
                        </div>
                        <p className="has-text-danger">{errors}</p>
                      </div>
                      <div className="buttons are-medium is-centered py-6 mx-6">
                        <button className="button is-mnd-primary has-text-white is-fullwidth">
                          Recuperar Contraseña
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="box is-box-shadow is-border-radius">
                    <h5 className="subtitle is-5 has-text-centered">
                      ¡Tu contraseña ha sido restablecida correctamente!
                    </h5>
                    <figure className="is-item-centered">
                      <img src={successLogo} />
                    </figure>
                    <div className="buttons are-medium is-centered py-6">
                      <button
                        className="button has-text-white is-mnd-primary mx-6 is-fullwidth"
                        onClick={handleGoTo}
                      >
                        Iniciar Sesión
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
      </section>
    </>
  )
}

export default ForgottenPasswordScreen
