import {
  getConsultsService,
  getDrugsDataService,
  addCurrentReceiptService,
  generateInvoice,
  resendInvoice,
  getCancelReasonsService,
  cancelSessionService,
} from '../../services'
import { uiActions } from '../../actions'

const { addError, removeError, removeSuccess } = uiActions.creators

export default dispatch => {
  return {
    addCurrentReceipt: (receipt, consultId, recipe_date) =>
      dispatch(addCurrentReceiptService(receipt, consultId, recipe_date)),
    getConsults: (uid, page, filter) =>
      dispatch(getConsultsService(uid, page, filter)),
    getDrugs: drugID => dispatch(getDrugsDataService(drugID)),
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
    removeSuccess: () => dispatch(removeSuccess()),
    generateInvoice: data => {
      dispatch(generateInvoice(data))
    },
    resendInvoice: data => {
      dispatch(resendInvoice(data))
    },
    getCancelReasons: () => getCancelReasonsService(),
    cancelSessionService: data => cancelSessionService(data),
  }
}
