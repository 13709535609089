import { storage } from '../../config'
import { v4 } from 'uuid'

const uploadImage = async (image, collection, ref) => {
  if (!image) {
    return { status: 'error' }
  }
  const fileName = `${v4()}.jpeg` // Generate unique name
  try {
    const response = await storage
      .ref()
      .child(`${collection}/${ref}/${fileName}`)
    await response.put(image)
    return response
  } catch (error) {
    return { status: 'error' }
  }
}

export default uploadImage
