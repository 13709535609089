import { useEffect, useState } from 'react'

const useDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false)
  window.addEventListener(
    'resize',
    function () {
      if (window.innerWidth < 1000 && isDesktop) {
        setIsDesktop(false)
      }
      if (window.innerWidth > 1000 && !isDesktop) {
        setIsDesktop(true)
      }
    },
    true,
  )

  useEffect(() => {
    if (window.innerWidth < 1000 && isDesktop) {
      setIsDesktop(false)
    }
    if (window.innerWidth > 1000 && !isDesktop) {
      setIsDesktop(true)
    }
    return () => {}
  }, [])
  return { isDesktop }
}

export default useDesktop
