import PropTypes from 'prop-types'
// Components
import {
  ImageCustom,
  TitleCustom,
  Button,
  ModalUploadImage,
} from '../../components'
import Images from '../../assets'

const ProfilePicture = ({
  fullname,
  toggleModal,
  modalState,
  setModalState,
  uid,
  sendProfileImage,
  uploadImageProfile,
  refresh,
  photoURL,
}) => {
  const { camera_icon, user_default } = Images
  return (
    <div className="profile-picture-container" id="profile_picture">
      <Button
        img={camera_icon}
        className="button is-size-button-camera-profile "
        imgClassName="is-size-camara-icon-profile"
        onClick={() => setModalState(true)}
      />
      <ImageCustom img={photoURL || user_default} className="profile-picture" />
      <TitleCustom className="is-size-6 are-color-mynd-grey">
        {fullname}
      </TitleCustom>
      {modalState && (
        <ModalUploadImage
          closeModal={toggleModal}
          uid={uid}
          sendProfileImage={sendProfileImage}
          uploadImageProfile={uploadImageProfile}
          refresh={refresh}
        />
      )}
    </div>
  )
}

ProfilePicture.propTypes = {
  fullname: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  toggleModal: PropTypes.func,
  modalState: PropTypes.bool,
  setModalState: PropTypes.func,
  uid: PropTypes.string,
  sendProfileImage: PropTypes.func,
  uploadImageProfile: PropTypes.func,
  getDataProfile: PropTypes.func,
  profileImg: PropTypes.object,
  refresh: PropTypes.func,
  photoURL: PropTypes.string,
}

export default ProfilePicture
