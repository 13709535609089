import PropTypes from 'prop-types'

// Imgs
import soda_burger from '../../assets/Soda-and Burger.png'
import Zanahoria_group from '../../assets/Zanahorias_group.png'
import food_pyramid from '../../assets/food_pyramid.png'

// Componets
import { ButtonCustom, TitleCustom } from '../../components'

const QuestionFood = ({ formbyStep }) => {
  return (
    <div className="columns">
      <div className="column">
        <div className="box is-box-shadow px-6">
          <TitleCustom className="has-text-centered is-5 are-color-mynd-grey pt-5">
            ¿Qué tipo de alimentación sueles llevar?
          </TitleCustom>
          <p className="buttons is-centered are-large ">
            <ButtonCustom
              buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey py-5  "
              imgClassName="pl-2 is-icon-food-hide"
              img={soda_burger}
            >
              Como solo junk food
            </ButtonCustom>
            <ButtonCustom
              buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey  py-5"
              imgClassName="pl-6 is-icon-food-hide"
              img={soda_burger}
            >
              Adoro los dulces
            </ButtonCustom>
            <ButtonCustom
              buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey py-5"
              img={Zanahoria_group}
              imgClassName="pl-6 is-icon-food-hide"
            >
              Soy vegetariano
            </ButtonCustom>
            <ButtonCustom
              buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey"
              img={food_pyramid}
              imgClassName="pl-2"
            >
              Llevo dieta balanceada
            </ButtonCustom>
          </p>
          <p className="buttons is-centered">
            <ButtonCustom
              buttonClassName=" is-mnd-primary is-fullwidth mx-3 my-3 has-text-white is-medium"
              onClick={formbyStep}
            >
              Siguiente
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-text-color is-inverted mt-2">
              Omitir
            </ButtonCustom>
          </p>
        </div>
      </div>
    </div>
  )
}

QuestionFood.propTypes = {
  formbyStep: PropTypes.func,
}

export default QuestionFood
