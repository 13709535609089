// import PropTypes from 'prop-types'

import { TextCustom } from '..'

const IndicationsRecipeBox = props => {
  return (
    <>
      <TextCustom className="is-size-5 has-text-weight-bold">
        Indicaciones:
      </TextCustom>
      <div className="box mt-4 is-border-box-indications">
        <TextCustom>
          <span className="has-text-weight-bold">
            Hidrata la piel cada día:
          </span>{' '}
          Una crema hidratante puede ser de gran ayuda para evitar la sequedad
          de la piel. Es importante aplicar estas cremas después del lavado, con
          la piel todavía húmeda, para obtener una mayor absorción y que la
          hidratación funcione de maravilla.
        </TextCustom>
        <TextCustom className="mt-5">
          <span className="has-text-weight-bold">Haz una limpieza suave:</span>{' '}
          Mantener el equilibrio de la piel y no atacar a la misma es muy
          importante, por eso se recomienda utilizar desmaquillantes
          fisiológicos
        </TextCustom>
      </div>
    </>
  )
}

IndicationsRecipeBox.propTypes = {}

export default IndicationsRecipeBox
