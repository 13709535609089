import { Switch, Redirect } from 'react-router-dom'
import {
  Reschedule,
  PracticeServices,
  FastService,
  Home,
  Survey,
  PaymentMethods,
  Orders,
  Proceedings,
  Directions,
  PersonalInfo,
  Favourites,
  Billing,
  Questions,
  MedicalRecordsScreen,
  CouponScreen,
  MyDiaryScreen,
  Doctors,
  Blog,
  BlogDetail,
  LiveService,
  Consults,
  DoctorProfile,
  OrderDrugs,
  TreatmentRoute,
  Shopping,
  WarrantyScreen,
  Configuration,
  AboutScreen,
} from '../containers'
import PrivateRoute from './PrivateRoute'
import SuccessHandler from '../components/SuccessHandler'

export const LoggedRoute = props => {
  const isLoggedIn = true
  return (
    <div>
      <SuccessHandler />
      <Switch>
        <PrivateRoute exact isAuth={isLoggedIn} path="/user" component={Home} />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/orders/:id?"
          component={Orders}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/payment_methods"
          component={PaymentMethods}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/service/:consult/:id?"
          component={PracticeServices}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/reschedule/:specialty/:consultId/:id?"
          component={Reschedule}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/survey"
          component={Survey}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/shopping/"
          component={Shopping}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/shopping/:uid"
          component={Proceedings}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/directions"
          component={Directions}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/personal_information"
          component={PersonalInfo}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/my_favourites"
          component={Favourites}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/medical_proceedings"
          component={MedicalRecordsScreen}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/billing"
          component={Billing}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/frecuent_questions"
          component={Questions}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/coupon_screen"
          component={CouponScreen}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/mydiaryScreen"
          component={MyDiaryScreen}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/doctors"
          component={Doctors}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/live_service"
          component={LiveService}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/fast_service"
          component={FastService}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/consults"
          component={Consults}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/doctor/:id"
          component={DoctorProfile}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/order-drugs/"
          component={OrderDrugs}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/treatment_route"
          component={TreatmentRoute}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/blog"
          component={Blog}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/blogDetail"
          component={BlogDetail}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/warranty"
          component={WarrantyScreen}
        />

        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/configuration"
          component={Configuration}
        />
        <PrivateRoute
          exact
          isAuth={isLoggedIn}
          path="/user/about-us"
          component={AboutScreen}
        />
        <Redirect to="/user" />
      </Switch>
    </div>
  )
}
