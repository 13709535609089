import PropTypes from 'prop-types'
import { TextCustom, ImageCustom } from '..'

const treatmentInfoCard = ({ images }) => {
  return (
    <>
      <TextCustom className="is-colour-text-detail-section has-text-centered">
        {`${
          images
            ? 'Las fotos registardas en la consulta son las siguientes:'
            : 'Sin fotos registradas.'
        }`}
      </TextCustom>
      <div className="horizontal-scroll mb-4">
        {images &&
          images.map(image => {
            return (
              <ImageCustom
                key={image}
                img={image}
                className="all-sessions-image-card"
              />
            )
          })}
      </div>
    </>
  )
}

treatmentInfoCard.propTypes = {
  images: PropTypes.array,
}

export default treatmentInfoCard
