import PropTypes from 'prop-types'
import { ProfilePicture, TopFeatures, Features } from '../../components'

// import Images from '../../assets'

const Sidebar = ({
  fullname,
  removeAllArticles,
  signOut,
  uid,
  refresh,
  isOpen,
  clinicalRecord,
  getAllOrders,
  toggleModal,
  modalState,
  setModalState,
  sendProfileImage,
  uploadImageProfile,
  activePage,
  photoURL,
}) => {
  //  const { user_default } = Images
  return (
    <div
      className={`column background-white lateral-space ${
        !isOpen ? 'bar-functions' : ''
      }`}
      id="bar-functions"
    >
      <div
        className={`dashboard-h100 right-gray-border ${
          !isOpen ? 'bar-functions' : ''
        }`}
      >
        <ProfilePicture
          fullname={fullname}
          toggleModal={toggleModal}
          modalState={modalState}
          setModalState={setModalState}
          uid={uid}
          refresh={refresh}
          sendProfileImage={sendProfileImage}
          uploadImageProfile={uploadImageProfile}
          photoURL={photoURL}
        />
        <TopFeatures
          uid={uid}
          refresh={refresh}
          clinicalRecord={clinicalRecord}
        />
        <Features
          signOut={signOut}
          uid={uid}
          refresh={refresh}
          getAllOrders={getAllOrders}
          removeAllArticles={removeAllArticles}
          activePage={activePage}
        />
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  fullname: PropTypes.string,
  removeAllArticles: PropTypes.func,
  signOut: PropTypes.func,
  uid: PropTypes.string,
  refresh: PropTypes.func,
  isOpen: PropTypes.bool,
  clinicalRecord: PropTypes.func,
  getAllOrders: PropTypes.func,
  photoURL: PropTypes.string,
  toggleModal: PropTypes.func,
  modalState: PropTypes.bool,
  setModalState: PropTypes.func,
  sendProfileImage: PropTypes.func,
  uploadImageProfile: PropTypes.func,
  getDataProfile: PropTypes.func,
  activePage: PropTypes.string,
}

export default Sidebar
