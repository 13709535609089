import { consultRef } from '../../config'
import functions from '../functions'
import { uiActions } from '../../actions'

const { billingCreate, addToCollection, getBilling } = functions.cloudFunctions
const { startLoading, stopLoading, addError, addSuccess } = uiActions.creators

const generateInvoice = data => {
  return async dispatch => {
    try {
      dispatch(startLoading())
      const result = await consultRef.doc(data.consultId).get()
      const consult = result.data()
      const amount = consult.charge.amount / 100
      const payMethod = consult.charge.charges
        ? consult.charge.charges.data[0].payment_method_details.card.funding
        : consult.charge.internal
        ? 'internal'
        : consult.charge.funding
      const items = [
        {
          product: {
            description: 'Consulta medica',
            product_key: '85121600',
            price: amount,
            unit_key: 'E48',
            taxes: [
              {
                withholding: false,
                type: 'IVA',
                factor: 'Exento',
                rate: 0,
              },
            ],
          },
        },
      ]
      const billingR = await billingCreate({
        ...data.billing,
        name: data.billing.legal_name,
        use: data.billing.use.substring(0, 3),
        items,
        payMethod,
      })
      if (billingR.data != null) {
        await consultRef.doc(data.consultId).set(
          {
            isBillingConsult: true,
            invoiceConsultID: billingR.data.id,
            billingConsultStatus: billingR.data.status,
          },
          { merge: true },
        )
        if (billingR.data.status === 'valid') {
          await addToCollection({
            id: data.consultId,
            invoiceID: billingR.data.id,
          })
          const billing = await getBilling({
            invoiceID: billingR.data.id,
          })
          dispatch(addSuccess('Factura generada con éxito'))
          return billing
        } else {
          throw new Error('No se pudo generar la factura')
        }
      }
    } catch (error) {
      dispatch(
        addError(
          'Ocurrió un error generando tu factura, por favor inténtalo más tarde',
        ),
      )
    } finally {
      dispatch(stopLoading())
    }
  }
}

export default generateInvoice
