const CREATE_CONSULT_REQUEST = 'CREATE_CONSULT_REQUEST'
const CREATE_CONSULT_SUCCESS = 'CREATE_CONSULT_SUCCESS'
const CREATE_CONSULT_FAILURE = 'CREATE_CONSULT_FAILURE'

const GET_ALL_CONSULTS_REQUEST = 'GET_ALL_CONSULTS_REQUEST'
const GET_ALL_CONSULTS_SUCCESS = 'GET_ALL_CONSULTS_SUCCESS'
const GET_ALL_CONSULTS_FAILURE = 'GET_ALL_CONSULTS_FAILURE'

const GET_ALL_PRICE_FOR_CONSULT_REQUEST = 'GET_ALL_PRICE_FOR_CONSULT_REQUEST'
const GET_ALL_PRICE_FOR_CONSULT_SUCCESS = 'GET_ALL_PRICE_FOR_CONSULT_SUCCESS'
const GET_ALL_PRICE_FOR_CONSULT_FAILURE = 'GET_ALL_PRICE_FOR_CONSULT_FAILURE'

const GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST =
  'GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST'
const GET_ALL_CATEGORIES_FOR_SPECIALTY_SUCCESS =
  'GET_ALL_CATEGORIES_FOR_SPECIALTY_SUCCESS'
const GET_ALL_CATEGORIES_FOR_SPECIALTY_FAILURE =
  'GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST'

const CLEAR_CONSULTS = 'CLEAR_CONSULTS'

const createConsultRequestAction = () => ({
  type: CREATE_CONSULT_REQUEST,
})

const createConsultSuccessAction = (payload = {}) => ({
  type: CREATE_CONSULT_SUCCESS,
  payload,
})

const createConsultFailureAction = (payload = {}) => ({
  type: CREATE_CONSULT_FAILURE,
  payload,
})

const getAllConsultsRequestAction = () => ({
  type: GET_ALL_CONSULTS_REQUEST,
})

const getAllConsultsSuccessAction = (payload = {}) => ({
  type: GET_ALL_CONSULTS_SUCCESS,
  payload,
})

const getAllConsultsFailureAction = (payload = {}) => ({
  type: GET_ALL_CONSULTS_FAILURE,
  payload,
})

const getAllPriceForConsultRequestAction = () => ({
  type: GET_ALL_PRICE_FOR_CONSULT_REQUEST,
})

const getAllPriceForConsultSuccessAction = (payload = {}) => ({
  type: GET_ALL_PRICE_FOR_CONSULT_SUCCESS,
  payload,
})

const getAllPriceForConsultFailureAction = (payload = {}) => ({
  type: GET_ALL_PRICE_FOR_CONSULT_FAILURE,
  payload,
})

const getAllCategoriesForSpecialtyRequestAction = () => ({
  type: GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST,
})

const getAllCategoriesForSpecialtySuccessAction = (payload = {}) => ({
  type: GET_ALL_CATEGORIES_FOR_SPECIALTY_SUCCESS,
  payload,
})

const getAllCategoriesForSpecialtyFailureAction = (payload = {}) => ({
  type: GET_ALL_CATEGORIES_FOR_SPECIALTY_FAILURE,
  payload,
})

const clearConsultsAction = () => ({
  type: 'CLEAR_CONSULTS',
})

export default {
  types: {
    CREATE_CONSULT_REQUEST,
    CREATE_CONSULT_SUCCESS,
    CREATE_CONSULT_FAILURE,
    GET_ALL_CONSULTS_REQUEST,
    GET_ALL_CONSULTS_SUCCESS,
    GET_ALL_CONSULTS_FAILURE,
    GET_ALL_PRICE_FOR_CONSULT_REQUEST,
    GET_ALL_PRICE_FOR_CONSULT_SUCCESS,
    GET_ALL_PRICE_FOR_CONSULT_FAILURE,
    GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST,
    GET_ALL_CATEGORIES_FOR_SPECIALTY_SUCCESS,
    GET_ALL_CATEGORIES_FOR_SPECIALTY_FAILURE,
    CLEAR_CONSULTS,
  },
  creators: {
    createConsultRequestAction,
    createConsultSuccessAction,
    createConsultFailureAction,
    getAllConsultsRequestAction,
    getAllConsultsSuccessAction,
    getAllConsultsFailureAction,
    getAllPriceForConsultRequestAction,
    getAllPriceForConsultSuccessAction,
    getAllPriceForConsultFailureAction,
    getAllCategoriesForSpecialtyRequestAction,
    getAllCategoriesForSpecialtySuccessAction,
    getAllCategoriesForSpecialtyFailureAction,
    clearConsultsAction,
  },
}
