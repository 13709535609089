export default (auth, ui) => {
  const { error, user } = auth
  const { errors, loading } = ui
  const { message } = error
  const { uid } = user
  const { privateFlag } = user.data
  return {
    message,
    user,
    privateFlag,
    uid,
    loading,
    errors,
  }
}
