import { blogActions } from '../../actions'

const { ADD_ARTICLE, REMOVE_ARTICLE, REMOVE_ALL_ARTICLES } = blogActions.types

const initialState = {
  savedArticles: [],
  categoryOfInterest: [],
}

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ARTICLE:
      return {
        ...state,
        savedArticles: action.payload.savedArticles,
        categoryOfInterest: action.payload.categoryOfInterest,
      }
    case REMOVE_ARTICLE:
      return {
        ...state,
        savedArticles: action.payload.savedArticles,
        categoryOfInterest: action.payload.categoryOfInterest,
      }
    case REMOVE_ALL_ARTICLES:
      return {
        ...state,
        savedArticles: [],
        categoryOfInterest: [],
      }
    default:
      return state
  }
}

export default blogReducer
