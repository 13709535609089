import PropTypes from 'prop-types'

const ErrorNotification = ({ message }) => (
  <div className="notification is-danger">{message}</div>
)

ErrorNotification.propTypes = {
  message: PropTypes.string,
}

export default ErrorNotification
