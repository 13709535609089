import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import dispatchers from './dispatchers'
import selectors from './selectors.js'
import { configurationRef } from '../../config'
import Images from '../../assets'

// components
import { ButtonCustom, TextCustom, TitleCustom, ModalSoon } from '..'

const SpecialitySelecter = ({ alwaysOpen }) => {
  const dispatch = useDispatch()
  const { setUserSpecialty } = dispatchers(dispatch)
  const { auth } = useSelector(state => state)
  const { dermaIcon, psicologyIcon, nutririonIcon, generalDoctorIcon } = Images
  const { uid } = selectors(auth)
  const [expanded, setExpanded] = useState(false)
  const [showModaSoon, setShowModalSoon] = useState(false)
  const [specialtiesActives, setSpecialtiesActives] = useState([])

  useEffect(() => {
    getActives()
    if (alwaysOpen) {
      setExpanded(true)
    }
  }, [])

  const getActives = async () => {
    const specialtieSnapshot = await configurationRef.doc('specialties').get()
    setSpecialtiesActives(
      specialtieSnapshot.data().active ? specialtieSnapshot.data().active : [],
    )
  }

  const validateSpecialty = specialty => {
    if (specialtiesActives.includes(specialty)) {
      setUserSpecialty(specialty, uid)
    } else {
      setShowModalSoon(true)
    }
  }

  return (
    <article className="question doctor-code-article column is-full-desktop">
      <header>
        <TitleCustom
          onClick={() => setExpanded(!expanded)}
          className="is-size-6 m-0 are-color-mynd-grey"
        >
          Selecciona tu especialidad
        </TitleCustom>
        {!alwaysOpen && (
          <ButtonCustom
            buttonClassName="btn xd"
            onClick={() => setExpanded(!expanded)}
            iconClassName={
              expanded
                ? 'fas fa-angle-up is-size-5'
                : 'fas fa-angle-down is-size-5'
            }
          >
            {}
          </ButtonCustom>
        )}
      </header>

      {expanded && (
        <div className="lol">
          <hr className="is-colour-hr mt-2 mb-4" />
          <TextCustom>
            Realiza una consulta con un especialista de forma online
          </TextCustom>
          <div className="field ">
            <div className="columns">
              <div className="speciality-select-item">
                <img src={dermaIcon} width="100" />
                <ButtonCustom
                  buttonClassName="button is-medium has-text-white is-mnd-primary is-button-address mt-2"
                  onClick={() => validateSpecialty('derma')}
                >
                  Dermatológica
                </ButtonCustom>
              </div>
              <div className="speciality-select-item">
                <img src={psicologyIcon} width="100" />
                <ButtonCustom
                  buttonClassName="button is-medium has-text-white is-mnd-primary is-button-address mt-2"
                  onClick={() => validateSpecialty('psychology')}
                >
                  Psicológica
                </ButtonCustom>
              </div>
            </div>
            <div className="columns">
              <div className="speciality-select-item">
                <img src={nutririonIcon} width="100" />
                <ButtonCustom
                  buttonClassName="button is-medium has-text-white is-mnd-primary is-button-address mt-2"
                  onClick={() => validateSpecialty('nutrition')}
                >
                  Nutricional
                </ButtonCustom>
              </div>
              <div className="speciality-select-item">
                <img src={generalDoctorIcon} width="100" />
                <ButtonCustom
                  buttonClassName="button is-medium has-text-white is-mnd-primary is-button-address mt-2"
                  onClick={() => validateSpecialty('medicine')}
                >
                  General
                </ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModaSoon && <ModalSoon closeModal={() => setShowModalSoon(false)} />}
    </article>
  )
}

SpecialitySelecter.propTypes = {
  alwaysOpen: PropTypes.bool,
}

SpecialitySelecter.defaultProps = {
  alwaysOpen: false,
}

export default SpecialitySelecter
