export default (auth, coupons, ui) => {
  const { user } = auth
  const { uid } = user
  const { errors } = ui
  const { data: couponList } = coupons

  return {
    uid,
    couponList,
    errors,
  }
}
