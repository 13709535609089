import {
  oxxoPaymentService,
  speiPaymentService,
  paynetPaymentService,
  getTokenOpenPayService,
  addBalanceService,
} from '../../services'
import { uiActions } from '../../actions'

const { addError, removeError } = uiActions.creators

export default dispatch => {
  return {
    oxxoPayment: (stripe, amount, fullname, email) =>
      oxxoPaymentService(stripe, amount, fullname, email),
    speiPayment: amount => speiPaymentService(amount),
    paynetPayment: amount => paynetPaymentService(amount),
    getToken: uid => getTokenOpenPayService(uid),
    addBalance: ({ token, amount, uid }) =>
      dispatch(addBalanceService({ token, amount, uid })),
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
  }
}
