import PropTypes from 'prop-types'

import { RecipeContainer, TextCustom } from '../../components'

const drugsBuying = ({
  getDrugs,
  drugsList,
  handleOrder,
  recipe_date,
  num,
  setNum,
}) => {
  return (
    <>
      <TextCustom className="mx-4 is-flex my-4 is-justify-content-center">
        {`Receta: ${drugsList.length} producto(s)`}
      </TextCustom>
      {drugsList.map(drug => {
        return (
          <RecipeContainer
            isBuying
            drug={drug}
            getDrugs={getDrugs}
            key={drug.drugID}
            handleOrder={handleOrder}
            recipe_date={recipe_date}
            num={num}
            setNum={setNum}
          />
        )
      })}
    </>
  )
}

drugsBuying.propTypes = {
  getDrugs: PropTypes.func,
  drugsList: PropTypes.array,
  handleOrder: PropTypes.func,
  recipe_date: PropTypes.string,
  num: PropTypes.number,
  setNum: PropTypes.func,
}

export default drugsBuying
