const DoctorsNotification = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8286 4.28571V4.38571H31.9286H36.2143C38.526 4.38571 40.4 6.25972 40.4 8.57142V35.7143C40.4 38.026 38.526 39.9 36.2143 39.9H4.78571C2.47401 39.9 0.6 38.026 0.6 35.7143V8.57142C0.6 6.25972 2.47401 4.38571 4.78571 4.38571H9.07142H9.17142V4.28571V1.4286C9.17142 0.694824 9.76632 0.1 10.5 0.1C11.2337 0.1 11.8285 0.694817 11.8285 1.4286V4.28571V4.38571H11.9285H29.0714H29.1714V4.28571V1.4286C29.1714 0.694821 29.7662 0.1 30.5 0.1C31.2338 0.1 31.8286 0.694821 31.8286 1.4286V4.28571ZM3.35711 17.0428H3.25711V17.1428V35.7143C3.25711 36.5585 3.94148 37.2429 4.78571 37.2429H36.2142C37.0584 37.2429 37.7428 36.5585 37.7428 35.7143V17.1428V17.0428H37.6428H3.35711ZM3.25711 14.2857V14.3857H3.35711H37.6428H37.643H37.743V14.2857V8.57142C37.743 7.72719 37.0586 7.04282 36.2144 7.04282H31.9287H31.8287V7.14282V9.99994C31.8287 10.7337 31.2338 11.3285 30.5001 11.3285C29.7663 11.3285 29.1715 10.7337 29.1715 9.99994V7.14282V7.04282H29.0715H11.9286H11.8286V7.14282V9.99994C11.8286 10.7337 11.2338 11.3285 10.5 11.3285C9.76624 11.3285 9.17142 10.7337 9.17142 9.99994V7.14282V7.04282H9.07142H4.78571C3.94148 7.04282 3.25711 7.72719 3.25711 8.57142V14.2857Z"
        fill="#e80175"
        stroke="#e80175"
        sstrokewidth="0.5"
      />
      <path
        d="M17.5717 30.9072L17.6424 30.978L17.7131 30.9072L28.1305 20.4899C28.1307 20.4897 28.1309 20.4895 28.1312 20.4893C28.646 19.9927 29.4617 19.9928 29.9764 20.4899C30.5039 20.9995 30.5187 21.84 30.0096 22.3679C30.0094 22.3681 30.0092 22.3683 30.009 22.3685L18.5817 33.7958C18.5817 33.7958 18.5817 33.7958 18.5817 33.7958C18.0629 34.3144 17.222 34.3144 16.7031 33.7958C16.7031 33.7958 16.7031 33.7958 16.7031 33.7958L10.9895 28.0821C10.4928 27.5672 10.493 26.7514 10.9901 26.2368C11.4996 25.7092 12.3402 25.6944 12.868 26.2036L17.5717 30.9072Z"
        fill="#e80175"
        stroke="#e80175"
        sstrokewidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1"
          y1="8.93277"
          x2="20"
          y2="8.93277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F658B1"></stop>
          <stop offset="1" stopColor="#FD9456"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default DoctorsNotification
