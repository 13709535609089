import moment from 'moment'

const getCouponDetails = ref => {
  return new Promise((resolve, reject) => {
    ref
      .get()
      .then(querySnapshot => {
        resolve(querySnapshot)
      })
      .catch(error => {
        const { message } = error
        reject(new Error({ message }))
      })
  })
}

const getCoupons = async coupons => {
  return await Promise.all(
    coupons.map(async coupon => {
      const couponDetails = await getCouponDetails(coupon)
      const tempCoupon = {
        data: couponDetails.data(),
        id: couponDetails.id,
      }
      tempCoupon.data.created = moment(tempCoupon.data.created).format()
      tempCoupon.data.blacklist = []
      return tempCoupon
    }),
  )
}

export default getCoupons
