import PropTypes from 'prop-types'
import { useState } from 'react'

import { CustomButton, Button, TextCustom, ImageCustom, Input } from '..'

// img
import Images from '../../assets'

const ModalUploadImage = ({
  closeModal,
  uid,
  sendProfileImage,
  uploadImageProfile,
  refresh,
}) => {
  const { imgIcon, closeIcon } = Images
  const [image, setImage] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [fileImg, setFile] = useState('')

  const uploadImage = async () => {
    try {
      if (image === '') {
        setError('Ingrese una imagen de su galeria')
        return
      }
      setIsLoading(true)
      setError(null)
      const file = await uploadImageProfile(fileImg, 'profile/users', uid)
      const imageUrl = await file.getDownloadURL()
      const response = await sendProfileImage(uid, imageUrl)
      await refresh(uid)
      console.log(response)
      closeModal()
    } catch (error) {
      console.log(error)
    }
  }

  const onSelect = ({ target }) => {
    const imageFile = target.files[0]
    const imageURL = window.URL.createObjectURL(imageFile)
    setFile(imageFile)
    setImage(imageURL)
  }
  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card is-modal-border-radius">
        <section className="modal-card-body">
          <div className="level">
            <div className="level-left">
              <TextCustom className="are-color-mynd-grey has-text-weight-bold is-size-5">
                Actualizar foto del perfil
              </TextCustom>
            </div>
            <div className="level-right">
              <Button
                img={closeIcon}
                className="button is-inverted is-border-button-modal"
                imgClassName="are-color-mynd-grey image  is-24x24"
                onClick={closeModal}
              />
            </div>
          </div>
          {error && <div className="notification is-danger">{error}</div>}
          {image !== '' && (
            <ImageCustom img={image} className="is-size-img-profile mb-2" />
          )}
          <label className="file-label  mb-4">
            <Input
              className="file-input"
              type="file"
              accept="image/*"
              onChange={onSelect}
            />
            <span className="file-cta is-size-input-add-pictur-profile">
              <span className="file-icon">
                <ImageCustom
                  img={imgIcon}
                  className="is-size-icon-modal-diary"
                />
              </span>
              <span className="file-label are-color-mynd-grey ml-3">
                Escoger de mi dispositivo
              </span>
            </span>
          </label>

          <CustomButton
            isPrimary
            handleClick={uploadImage}
            isLoading={isLoading}
          >
            Guardar
          </CustomButton>
        </section>
      </div>
    </div>
  )
}

ModalUploadImage.propTypes = {
  closeModal: PropTypes.func,
  uid: PropTypes.string,
  sendProfileImage: PropTypes.func,
  uploadImageProfile: PropTypes.func,
  refresh: PropTypes.func,
}

export default ModalUploadImage
