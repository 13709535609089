// import PropTypes from 'prop-types'

// img
import BoxTransparent from '../../assets/Box-Transparent.png'

// components
import { TextCustom, ImageCustom } from '..'

const deliveredCard = ({ order, handleSelectOrder }) => {
  const { total, requestedItems, automatic_shipping_data } = order
  return (
    <div className="box is-size-box" onClick={() => handleSelectOrder(order)}>
      <div className="level mb-2 is-mobile">
        <div className="level-left">
          <TextCustom className="are-color-mynd-grey">Nº 454346</TextCustom>
        </div>
        <div className="level-right">
          <TextCustom className="is-text-color-card has-text-weight-bold">
            ${total}
          </TextCustom>
        </div>
      </div>
      <hr className="line-colour my-2"></hr>
      <div className="columns is-mobile">
        <div className="column">
          <TextCustom className="are-color-mynd-grey has-text-weight-bold">
            Productos My NextDerma <br /> para tratamiento derma...
          </TextCustom>
          <TextCustom className="are-color-mynd-grey mt-1">
            Productos: ({requestedItems && requestedItems.length})
          </TextCustom>
        </div>
        <div className="column">
          <figure className="image is-size-img-box-shopping ">
            <ImageCustom img={BoxTransparent} />
          </figure>
          <TextCustom className="has-text-right is-text-trunk are-color-mynd-grey mt-1">
            Entregado el:
          </TextCustom>
          <TextCustom className="has-text-right is-text-trunk are-color-mynd-grey mt-1 mr-2">
            {automatic_shipping_data && automatic_shipping_data.deliveredAt}
          </TextCustom>
        </div>
      </div>
    </div>
  )
}

deliveredCard.propTypes = {}

export default deliveredCard
