import PropTypes from 'prop-types'
import { ImageCustom } from '../../components'
import Images from '../../assets/index'

const FeatureRow = ({ children, title, onClick, activePage, rowName }) => {
  const { right_arrow } = Images
  const active = activePage && rowName && activePage === rowName
  return (
    <div className={`${active ? 'feature-row-active' : ''}`}>
      <button
        className="button dashboard-item background-white"
        onClick={onClick}
        disabled={active}
      >
        <div className="vertical-center item-flex-grow-5">
          <div className="dashboard-item-icon">{children}</div>
          <span>{title}</span>
        </div>
        <ImageCustom img={right_arrow} className="dashboard-row-icon" />
      </button>
    </div>
  )
}

FeatureRow.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
  activePage: PropTypes.string,
  rowName: PropTypes.string,
}

export default FeatureRow
