import functions from '../functions'

const { updateProduct } = functions.cloudFunctions
const updateProductService = async (products, uid) => {
  try {
    const response = await updateProduct({ products, uid })
    return response.data
  } catch (e) {
    return e
  }
}
export default updateProductService
