import PropTypes from 'prop-types'
import { LoginHeader, Sidebar, BottomBarNav, Footer } from '../../components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dispatchers from './dispatchers'
import selectors from './selectors.js'
import { useDesktop, useRouter } from '../../utils'
import moment from 'moment'

const Dashboard = ({ children, show }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [readyDoctorPrivate, setReadyDoctorPrivate] = useState(false)
  const [modalState, setModalState] = useState()
  const { isDesktop } = useDesktop()
  const { history } = useRouter()
  const dispatch = useDispatch()
  const {
    signOut,
    refresh,
    clinicalRecord,
    getAllOrders,
    removeAllArticles,
    sendProfileImage,
    uploadImageProfile,
    rememberMe,
    getPrivateDoctor,
  } = dispatchers(dispatch)
  const { auth, session } = useSelector(state => state)
  const { fullname, uid, photoURL, privateFlag, privateDoctor, expiry } =
    selectors(auth, session)

  useEffect(() => {
    const now = moment().unix()
    if (!expiry) rememberMe(false)
    if (expiry && expiry < now) signOut()
  }, [])

  useEffect(() => {
    if (isDesktop) setIsOpen(true)
    else setIsOpen(false)
  }, [isDesktop, history.location.pathname])

  useEffect(() => {
    if (privateDoctor) {
      getPrivate()
    } else {
      setReadyDoctorPrivate(false)
    }
  }, [privateDoctor])

  const getPrivate = async () => {
    const privateData = await getPrivateDoctor(privateDoctor)
    if (privateData.length > 0) {
      setReadyDoctorPrivate(true)
    }
  }

  const toggleModal = () => {
    setModalState(false)
  }

  const onClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <section className="hero is-fullheight">
      {show ? (
        <>
          <div className="dashboard-container">
            <div className="hero-head head">
              <LoginHeader
                uid={uid}
                readyDoctorPrivate={readyDoctorPrivate}
                privateFlag={privateFlag}
                privateDoctor={privateDoctor}
                refresh={refresh}
                setIsOpen={onClick}
                isOpen={isOpen}
              />
            </div>
            <div className="columns" id="columns-dashboard">
              <Sidebar
                photoURL={photoURL}
                fullname={fullname}
                removeAllArticles={removeAllArticles}
                signOut={signOut}
                uid={uid}
                refresh={refresh}
                clinicalRecord={clinicalRecord}
                getAllOrders={getAllOrders}
                isOpen={isOpen}
                toggleModal={toggleModal}
                modalState={modalState}
                setModalState={setModalState}
                sendProfileImage={sendProfileImage}
                uploadImageProfile={uploadImageProfile}
                activePage={history.location.pathname}
              />
              <div
                className={`column children ${isOpen && 'render-space'}`}
                id="render-space"
              >
                {children}
                <BottomBarNav />
                <div className="hero-foot is-align-content-end">
                  <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </section>
  )
}

Dashboard.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  clinicalRecord: PropTypes.func,
  uploadImageProfile: PropTypes.func,
}

export default Dashboard
