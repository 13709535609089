import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FeatureHigh } from '../../components'
import { useRouter } from '../../utils'
import Images from '../../assets'
import { getAddressService } from '../../services'

// helpers
import { validateMenuExpediente } from '../../utils/sections'

const TopFeatures = ({ uid, refresh, clinicalRecord }) => {
  const dispatch = useDispatch()
  const { directionsIcon, walletIcon, proceedingsIcon } = Images
  const { history } = useRouter()
  const goTo = direction => {
    history.push(direction)
  }

  return (
    <div
      className="white-box is-mobile three-features-container bottom-gray-border"
      id="bar-top-features"
    >
      {validateMenuExpediente() && (
        <FeatureHigh
          image={proceedingsIcon}
          title={'Expediente clínico'}
          onClick={() => {
            goTo('/user/medical_proceedings')
            clinicalRecord({ uid })
          }}
        />
      )}
      <FeatureHigh
        image={walletIcon}
        title={'Métodos de pago'}
        onClick={() => {
          goTo('/user/payment_methods')
        }}
      />
      <FeatureHigh
        image={directionsIcon}
        title={'Mis direcciones'}
        onClick={() => {
          goTo('/user/directions')
          dispatch(getAddressService(uid))
        }}
      />
    </div>
  )
}

TopFeatures.propTypes = {
  uid: PropTypes.string,
  refresh: PropTypes.func,
  clinicalRecord: PropTypes.func,
}

export default TopFeatures
