import { useDispatch, useSelector } from 'react-redux'
import EmailIcon from '../../assets/email-icon.png'
import {
  Header,
  Footer,
  Button,
  Input,
  CustomButton,
  TitleCustom,
} from '../../components'
import { useField, useForm, useRouter } from '../../utils'
import Images from '../../assets'
import dispatchers from './dispatchers'
import selectors from './selectors'
import { useState } from 'react'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { forgot, resetSuccess, signInWithProvider } = dispatchers(dispatch)
  const { auth } = useSelector(state => state)
  const { sendResetPasswordEmail, sendResetPasswordEmailError } =
    selectors(auth)
  const { history } = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const { googleIcon, facebookIcon, phoneIcon } = Images

  const [{ email }, handleInputChange] = useForm({
    email: '',
  })

  const handleSubmit = e => {
    setIsLoading(true)
    e.preventDefault()
    forgot(email)
    setIsLoading(false)
  }

  const handleGoLogin = () => {
    resetSuccess()
    history.replace('/auth/login')
  }

  const emailField = useField({
    type: 'email',
    name: 'email',
    value: email,
    onChange: handleInputChange,
    className: 'input is-medium',
    placeholder: 'Correo electrónico',
    autoComplete: 'off',
  })

  const recoveryPasswordButton = useField({
    type: 'submit',
    className: 'button is-mnd-primary has-text-white is-fullwidth mx-5',
  })

  const backToLoginButton = useField({
    type: 'button',
    className:
      'button is-medium is-mnd-primary has-text-white is-fullwidth mx-5',
    onClick: handleGoLogin,
  })

  const googleButton = useField({
    className: 'button is-danger is-rounden-icon-social',
    type: 'button',
    img: googleIcon,
    imgClassName: 'is-size-social-network',
    onClick: () => signInWithProvider('GOOGLE_PROVIDER'),
  })

  const facebookButton = useField({
    className: 'button is-facebook is-rounden-icon-social',
    type: 'button',
    img: facebookIcon,
    imgClassName: 'is-size-social-network',
    onClick: () => signInWithProvider('FACEBOOK_PROVIDER'),
  })

  const phoneField = useField({
    className: 'button is-success is-rounden-icon-social',
    type: 'button',
    img: phoneIcon,
    imgClassName: 'is-size-social-network',
    onClick: () => history.push('/auth/phoneLogin'),
  })

  return (
    <div className="hero is-fullheight">
      <Header />
      <div className="hero-body">
        <div className="container is-max-widescreen">
          <div className="columns is-mobile">
            <div className="column">
              <div className="columns">
                <div className="column is-half is-offset-one-quarter">
                  <div className="box is-box-shadow is-border-radius mx-6">
                    {!sendResetPasswordEmail ? (
                      <div>
                        <h5 className="subtitle is-gray has-text-weight-bold is-5 has-text-centered">
                          ¿Olvidaste tu contraseña?
                        </h5>
                        <p className="has-text-centered p-4 is-size-6">
                          Por favor, coloca tu dirección de correo, recibirás un
                          link para crear una nueva contraseña
                        </p>
                        {sendResetPasswordEmailError && (
                          <div className="notification is-danger mt-3">
                            {sendResetPasswordEmailError}
                          </div>
                        )}
                        <form onSubmit={handleSubmit}>
                          <div className="field p-4">
                            <label className="label">Correo electrónico</label>
                            <Input {...emailField} />
                          </div>
                          <div className="buttons are-medium p-4">
                            <CustomButton
                              {...recoveryPasswordButton}
                              isPrimary
                              isLoading={isLoading}
                            >
                              Recuperar contraseña
                            </CustomButton>
                          </div>
                        </form>
                        <div className="py-6 mx-5">
                          <div className="is-divider" />
                          <TitleCustom className="is-6 has-text-centered mt-5 are-color-text">
                            También puedes ingresar con:
                          </TitleCustom>
                          <div className="buttons is-centered mb-2">
                            <Button {...googleButton} />
                            <Button {...facebookButton} />
                            <Button {...phoneField} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="field">
                          <p className="has-text-gray has-text-centered is-size-5 py-5">
                            Te hemos enviado las instrucciones para poder
                            actualizar tu contraseña
                          </p>
                        </div>
                        <figure className=" is-logo-email is-item-centered ">
                          <img src={EmailIcon} />
                        </figure>
                        <div className="py-6 mx-5">
                          <div className="is-divider"></div>
                        </div>
                        <div className="field py-4">
                          <div className="has-text-gray has-text-centered is-size-5 py-5">
                            Si tienes alguna duda o problema escribenos al
                            correo:
                            <p className="has-text-pink">
                              contacto@mynextderma.com
                            </p>
                          </div>
                        </div>
                        <p className="buttons">
                          <Button {...backToLoginButton}>
                            Volver al inicio
                          </Button>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
    </div>
  )
}

export default ForgotPassword
