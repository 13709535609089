import PropTypes from 'prop-types'

// components
import {
  TextCustom,
  CommentStars,
  ImageCustom,
  YarnCommentDoctorProfile,
} from '..'
import Images from '../../assets'

const commentDoctorProfile = ({ review }) => {
  const { comment, score, replies, patient } = review
  const { user_default } = Images

  const renderCustomerName = () => {
    try {
      const { fullname } = patient
      if (fullname.length > 20) {
        return fullname.substring(0, 20)
      }
      return fullname
    } catch (error) {
      return ''
    }
  }

  const renderPhoto = () => {
    try {
      const { photoURL } = patient
      return photoURL || user_default
    } catch (error) {
      return user_default
    }
  }

  return (
    <>
      <div className="columns my-4">
        <div className="column">
          <article className="media ml-2">
            <div className="media-left">
              <figure className="image">
                <ImageCustom
                  img={renderPhoto()}
                  className="is-rounded image is-48x48"
                />
              </figure>
            </div>
            <div className="media-content">
              <div className="content">
                <TextCustom className="mb-1 are-color-mynd-grey has-text-weight-bold">
                  {renderCustomerName()}
                  <span className="icon-text is-size-comment-star ml-3">
                    <CommentStars score={score} />
                  </span>
                </TextCustom>
                <TextCustom className="is-italic is-text-comment">
                  “{comment}”.
                </TextCustom>
              </div>
              {replies
                ? replies.map((replies, key) => {
                    return (
                      <YarnCommentDoctorProfile replies={replies} key={key} />
                    )
                  })
                : ''}
            </div>
          </article>
        </div>
      </div>
      <hr className="line-bottom my-3"></hr>
    </>
  )
}

commentDoctorProfile.propTypes = {
  score: PropTypes.number,
}

export default commentDoctorProfile
