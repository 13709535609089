import PropTypes from 'prop-types'
import { useState } from 'react'

// img
import Images from '../../../assets'

// components
import { ModalProductPhoto, ButtonCustomWithBorder } from '../../../components'

const addPhotoContainer = ({
  isDiary,
  onHandle,
  imageLeft,
  imageRight,
  imageFront,
}) => {
  const isDiaryStep = isDiary || false
  const buttonStyle = `button box is-diary-box-image 
  ${isDiaryStep ? '' : 'product-image-container'}`
  const firstContainer = isDiaryStep ? 'imageLeft' : 'imageProduct'
  const secondContainer = isDiaryStep ? 'imageFront' : 'imageIngredients'
  const thirdContainer = isDiaryStep ? 'imageRight' : 'imageAditional'
  const firstText = isDiaryStep
    ? 'de la parte izquierda de tu cara'
    : 'del producto'
  const secondText = isDiaryStep
    ? 'de la parte frontal de tu cara'
    : 'de los ingredientes'
  const thirdText = isDiaryStep
    ? 'de la parte derecha de tu cara'
    : 'adicional (opcional)'
  const [modalState, setModalState] = useState()
  const { pictureIcon } = Images
  const [name, setName] = useState()

  const closeModal = () => {
    setModalState(false)
    setName()
  }

  const onPressAdd = pressed => {
    setModalState(true)
    onHandle(pressed, '')
    setName(pressed)
  }

  return (
    <>
      {modalState && (
        <ModalProductPhoto
          closeModal={closeModal}
          onHandle={onHandle}
          name={name}
        />
      )}
      <div className="is-flex is-justify-content-space-evenly p-4">
        <ButtonCustomWithBorder
          buttonClassName={buttonStyle}
          handleOnClick={() => onPressAdd(firstContainer)}
          img={imageLeft.uri}
          imgClassName="is-size-simple-picture wh100"
          icon={pictureIcon}
          iconClassName="is-size-simple-picture"
          label={`Añade una foto ${firstText}`}
          labelClassName="are-color-mynd-grey has-text-centered pb-0 is-line-heigth-diary mt-2"
        />
        <ButtonCustomWithBorder
          buttonClassName={buttonStyle}
          handleOnClick={() => onPressAdd(secondContainer)}
          img={imageFront.uri}
          imgClassName="is-size-simple-picture wh100"
          icon={pictureIcon}
          iconClassName="is-size-simple-picture"
          label={`Añade una foto ${secondText}`}
          labelClassName="are-color-mynd-grey has-text-centered pb-0 is-line-heigth-diary mt-2"
        />
        <ButtonCustomWithBorder
          buttonClassName={buttonStyle}
          handleOnClick={() => onPressAdd(thirdContainer)}
          img={imageRight.uri}
          imgClassName="is-size-simple-picture wh100"
          icon={pictureIcon}
          iconClassName="is-size-simple-picture"
          label={`Añade una foto ${thirdText}`}
          labelClassName="are-color-mynd-grey has-text-centered pb-0 is-line-heigth-diary mt-2"
        />
      </div>
    </>
  )
}

addPhotoContainer.propTypes = {
  isDiary: PropTypes.bool,
  onHandle: PropTypes.func,
  imageFront: PropTypes.any,
  imageLeft: PropTypes.any,
  imageRight: PropTypes.any,
}

export default addPhotoContainer
