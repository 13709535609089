// import PropTypes from 'prop-types'
import { TextCustom } from '..'

const studiesBox = ({ studie }) => {
  const { title, school, number, year } = studie
  return (
    <div className="block my-2">
      <TextCustom className="are-color-mynd-grey has-text-weight-bold  mb-0">
        {title}
      </TextCustom>
      <TextCustom className="are-color-mynd-grey my-0">{school}</TextCustom>
      <TextCustom className="are-color-mynd-grey my-0">{year}</TextCustom>
      <TextCustom className="are-color-mynd-grey">{number}</TextCustom>
    </div>
  )
}

studiesBox.propTypes = {}

export default studiesBox
