import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useField, dateFormat, verifyEmail } from '../../utils'
import {
  LabelCustom,
  TitleCustom,
  Button,
  Input,
  InputSelectCustom,
  RegisterBox,
  RegisterForm,
  ButtonCustom,
} from '../../components'
import { countries, mxStates, usaStates, coStates } from '../../utils/constants'
import dispatcher from './dispatchers'
import selectors from './selectors'

const AccountDataScreen = ({ setStep, setData, authEmail }) => {
  const maxYearDifference = 18
  const { maxDate } = dateFormat({ maxYearDifference })

  const dispatch = useDispatch()
  const { addError, removeError, signOut } = dispatcher(dispatch)
  const { ui } = useSelector(state => state)
  const { errors } = selectors(ui)
  const countryList = Object.values(countries)
  const [stateList, setStateList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [{ name, birthday, email, country, state }, handleInputChange] =
    useForm({
      name: '',
      birthday: '',
      email: '',
      country: '',
      state: '',
    })
  const stateListSelector = () => {
    setStateList({})
    if (country === 'México') {
      setStateList(Object.values(mxStates))
    }
    if (country === 'Colombia') {
      setStateList(Object.values(coStates))
    }
    if (country === 'United States') {
      setStateList(Object.values(usaStates))
    }
  }

  const nameField = useField({
    className: 'input is-medium',
    type: 'text',
    name: 'name',
    value: name,
    onChange: handleInputChange,
  })

  const emailField = useField({
    className: 'input is-medium',
    type: 'email',
    name: 'email',
    value: email,
    onChange: handleInputChange,
    placeholder: 'Correo electrónico',
  })

  const dataField = useField({
    className: 'input is-medium',
    type: 'date',
    max: maxDate,
    min: '1940-01-01',
    name: 'birthday',
    value: birthday,
    onChange: handleInputChange,
  })

  const checkboxField = useField({
    type: 'checkbox',
    name: 'terms',
    onChange: handleInputChange,
    required: true,
  })

  const buttonField = useField({
    className: `button is-mnd-primary  is-color-button-log-in is-fullwidth mx-3 mb-3 ${
      isLoading && 'is-loading'
    }`,
    type: 'submit',
  })

  useEffect(() => {
    stateListSelector()
  }, [country])

  const handleRegister = async e => {
    e.preventDefault()

    if (!name.trim()) {
      return addError('Ingresa tu Nombre')
    }
    if (!authEmail && !email.trim()) {
      return addError('Ingresa tu correo')
    }
    if (!birthday.trim()) {
      return addError('Ingrese una Fecha')
    }

    if (!country.trim()) {
      return addError('Ingrese un País')
    }
    if (!state.trim()) {
      return addError('Ingrese un Estado')
    }
    removeError()
    if (!authEmail && email.trim()) {
      setIsLoading(true)
      const response = await verifyEmail(email)
      setIsLoading(false)
      if (response.error) {
        addError('Hubo un error al verificar el correo')
        return
      }
      if (!response.isNew) {
        addError('El correo ya esta registrado')
        return
      }
    }

    setData({
      name,
      birthday,
      email: authEmail || email,
      location: {
        country,
        state,
      },
    })
    setStep()
  }

  return (
    <RegisterBox className="px-6 py-5">
      <RegisterForm
        handleOnSubmit={handleRegister}
        title={
          <TitleCustom className="is-5 has-text-centered are-color-text">
            Crear cuenta
          </TitleCustom>
        }
      >
        {errors && <div className="notification is-danger">{errors}</div>}
        <div className="field">
          <LabelCustom className="are-color-text">Nombre completo:</LabelCustom>
          <Input {...nameField} />
        </div>
        {!authEmail && (
          <div className="field">
            <LabelCustom className="are-color-text">Email:</LabelCustom>
            <Input {...emailField} />
          </div>
        )}
        <div className="field">
          <LabelCustom className="are-color-text">
            Fecha de nacimiento:
          </LabelCustom>
          <Input {...dataField} />
        </div>

        <div className="is-justify-content-space-between is-flex mb-4">
          <div className="is-item-centered mr-4">
            <LabelCustom className="are-color-text">País</LabelCustom>
            <div className="select is-fullwidth">
              <InputSelectCustom
                className="are-color-text"
                name="country"
                handleInputChange={handleInputChange}
              >
                {countryList}
              </InputSelectCustom>
            </div>
          </div>
          <div className="is-item-centered">
            <LabelCustom className="are-color-text">Estado</LabelCustom>
            <div className="select is-fullwidth">
              <InputSelectCustom
                name="state"
                handleInputChange={handleInputChange}
              >
                {stateList}
              </InputSelectCustom>
            </div>
          </div>
        </div>

        <div className="field">
          <div className="control pl-3">
            <LabelCustom className="mr-6 are-color-text">
              <Input {...checkboxField} />
              {' Aceptar términos y condiciones'}
            </LabelCustom>
          </div>
        </div>
        <Button {...buttonField}>Siguiente</Button>
        <ButtonCustom
          buttonClassName="button is-text-color is-inverted px-0 level-item is-fullwidth mx-3 mb-3"
          onClick={signOut}
        >
          Continuar en otro momento
        </ButtonCustom>
      </RegisterForm>
    </RegisterBox>
  )
}

AccountDataScreen.propTypes = {
  setStep: PropTypes.func,
  setData: PropTypes.func,
  data: PropTypes.array,
  authEmail: PropTypes.string,
}

export default AccountDataScreen
