import PropTypes from 'prop-types'
import { Accordion, AccordionItem } from 'react-accessible-accordion'

import {
  AccordionBloodType,
  AccordionMyBody,
  AccordionAllergies,
  AccordionSurgeries,
  AccordionDiseases,
  AccordionAddictions,
  AccordionWine,
  AccordionContacts,
  AccordionAdditionalInformation,
  AccordionSmoke,
} from '../../components'

const accordionMedicalRecords = ({
  isFetching,
  data,
  error,
  medicalRecord,
  uploadFiles,
  uid,
}) => {
  return (
    <>
      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <Accordion allowZeroExpanded>
          <AccordionItem className="box py-0 px-5">
            <AccordionBloodType
              bloodTypeValue={data.bloodType}
              medicalRecord={medicalRecord}
              uid={uid}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionMyBody
              data={data}
              medicalRecord={medicalRecord}
              uid={uid}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionAllergies
              allergiesProp={data.allergies}
              withoutAllergyProp={data.withoutAllergy}
              medicalRecord={medicalRecord}
              uid={uid}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionSurgeries
              surgeriesProp={data.suregies}
              withoutSurgeryProp={data.withoutSurgery}
              medicalRecord={medicalRecord}
              uid={uid}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionDiseases
              sicksProp={data.sicks}
              withoutDiseasesProp={data.withoutSicks}
              uid={uid}
              medicalRecord={medicalRecord}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionAddictions
              addictionsProp={data.adictions}
              withoutAddictions={data.withoutAddictions}
              uid={uid}
              medicalRecord={medicalRecord}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionSmoke
              smokeProp={data.smoke}
              uid={uid}
              medicalRecord={medicalRecord}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionWine
              drinkProp={data.drink}
              uid={uid}
              medicalRecord={medicalRecord}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionContacts
              emergencyContactsProp={data.emergencyContacts}
              uid={uid}
              medicalRecord={medicalRecord}
            />
          </AccordionItem>
          <AccordionItem className="box py-0 px-5">
            <AccordionAdditionalInformation
              expedientFilesProp={data.expedientFiles}
              additionalInformationProp={data.additionalInformation}
              medicalRecord={medicalRecord}
              uploadFiles={uploadFiles}
              uid={uid}
            />
          </AccordionItem>
        </Accordion>
      )}
    </>
  )
}

accordionMedicalRecords.propTypes = {
  data: PropTypes.object,
  error: PropTypes.object,
  medicalRecord: PropTypes.func,
  uid: PropTypes.string,
  uploadFiles: PropTypes.func,
}

export default accordionMedicalRecords
