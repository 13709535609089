import PropTypes from 'prop-types'
// Components
const BoxPicture = ({ img }) => (
  <div className="is-box-picture full-center">
    <img className="evidence__size" src={img} />
  </div>
)

BoxPicture.propTypes = {
  img: PropTypes.any,
}

export default BoxPicture
