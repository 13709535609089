import { authActions } from '../../actions'
import { processData } from '../../utils'
import { usersF } from '../../config'
const { refreshUser, refreshUserSuccessAction, refreshUserFailureAction } =
  authActions.creators

const refreshUserService = uid => {
  return async dispatch => {
    try {
      dispatch(refreshUser('refresh'))
      const userData = await usersF.doc(uid).get()
      const newData = await processData(userData)
      dispatch(
        refreshUserSuccessAction({ uid, isNewUser: false, data: newData }),
      )
    } catch (error) {
      dispatch(refreshUserFailureAction(error))
    }
  }
}
export default refreshUserService
