import PropTypes from 'prop-types'
import { ButtonCustom, TextCustom, CustomButton, TextAreaCustom } from '..'

import { useEffect, useState } from 'react'

const CancelSession = ({
  toggleCancelModal,
  cancelReasons,
  uid,
  id,
  cancelSessionService,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState()
  const [additionalInformation, setAdditionalInformation] = useState()
  const [error, setError] = useState()

  const onPress = async () => {
    setIsLoading(true)
    if (!reason) {
      setError('Selecciona una opción primero')
      setIsLoading(false)
      return
    }
    if (!additionalInformation) {
      setError('Escribe una breve descripción')
      setIsLoading(false)
      return
    }
    const data = {
      uid: id,
      values: {
        userId: uid,
        reason_id: reason,
        reason_text: additionalInformation,
      },
    }
    const response = await cancelSessionService(data)
    if (!response?.error) {
      setIsLoading(false)
      toggleCancelModal()
    }
  }

  const handleOnChange = (target, setData) => {
    setData(target.value)
  }

  useEffect(() => {
    if (error) {
      setError()
    }
  }, [reason, additionalInformation])

  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card p-4">
        <section className="modal-card-body cancel-modal">
          <div className="level">
            <div className="level-left">
              <TextCustom className="are-color-mynd-grey has-text-weight-bold is-size-5">
                Cancelar Consulta
              </TextCustom>
            </div>
            <div className="level-right">
              <ButtonCustom
                icon="far fa-times-circle"
                buttonClassName="button is-inverted is-border-button-modal"
                iconClassName="are-color-mynd-grey is-size-4"
                onClick={toggleCancelModal}
              />
            </div>
          </div>
          {error && <div className="notification is-danger">{error}</div>}
          <div className="level-left">
            <TextCustom className="are-color-mynd-grey is-size-6">
              Selecciona la razón de tu cancelación
            </TextCustom>
          </div>
          <div className="field">
            <div className="select">
              <select
                name="relation"
                onChange={({ target }) => handleOnChange(target, setReason)}
              >
                <option></option>
                {cancelReasons.map((cancel, index) => (
                  <option key={index} value={cancel.id}>
                    {cancel.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="level-left">
              <TextCustom className="are-color-mynd-grey is-size-6">
                Escribe una breve descripción de tu cancelación
              </TextCustom>
            </div>
            <TextAreaCustom
              onChange={({ target }) =>
                handleOnChange(target, setAdditionalInformation)
              }
              name="additionalInformation"
              value={additionalInformation}
            />
          </div>

          <CustomButton isPrimary handleClick={onPress} isLoading={isLoading}>
            Guardar
          </CustomButton>
        </section>
      </div>
    </div>
  )
}

CancelSession.propTypes = {
  toggleCancelModal: PropTypes.func,
  cancelReasons: PropTypes.array,
  uid: PropTypes.string,
  id: PropTypes.string,
  cancelSessionService: PropTypes.func,
}

export default CancelSession
