const GET_ALL_COUPONS_REQUEST = 'GET_ALL_COUPONS_REQUEST'
const GET_ALL_COUPONS_SUCCESS = 'GET_ALL_COUPONS_SUCCESS'
const GET_ALL_COUPONS_FAILURE = 'GET_ALL_COUPONS_FAILURE'
const CLEAR_COUPONS = 'CLEAR_COUPONS'
const getAllCouponsRequestAction = () => ({
  type: GET_ALL_COUPONS_REQUEST,
})

const getAllCouponsSuccessAction = (payload = {}) => ({
  type: GET_ALL_COUPONS_SUCCESS,
  payload,
})

const getAllCouponsFailureAction = (payload = {}) => ({
  type: GET_ALL_COUPONS_FAILURE,
  payload,
})

const clearCouponsAction = () => ({
  type: CLEAR_COUPONS,
})

export default {
  types: {
    GET_ALL_COUPONS_REQUEST,
    GET_ALL_COUPONS_SUCCESS,
    GET_ALL_COUPONS_FAILURE,
    CLEAR_COUPONS,
  },
  creators: {
    getAllCouponsRequestAction,
    getAllCouponsSuccessAction,
    getAllCouponsFailureAction,
    clearCouponsAction,
  },
}
