import { uploadImage } from '../../utils'

const uploadImagesService = async (image, collection, id) => {
  try {
    const response = await uploadImage(image, collection, id)
    return response
  } catch (e) {
    return e
  }
}
export default uploadImagesService
