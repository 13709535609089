// import PropTypes from 'prop-types'
import { TitleCustom, TextCustom, ImageCustom, Button } from '..'

import { Link } from 'react-router-dom'

// helpers
import {
  validateConsultFastService,
  validateConsultPractiService,
} from '../../utils/sections'
import Images from '../../assets'

const modalquey = ({ closeModal, doctor, isRoute }) => {
  const serviceRoute = serviceType => {
    if (doctor) {
      return `/user/service/${serviceType}-service/${doctor}`
    }
    return `/user/service/${serviceType}-service`
  }
  const { HoursService, LiveService, plusIconModal } = Images

  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card is-modal-border-radius">
        <section className="modal-card-body">
          <div className="level-right mb-2 is-mobile">
            <Button
              className="button is-inverted is-text-color level-left"
              imgClassName="are-color-mynd-grey image is-16x16"
              img={plusIconModal}
              onClick={closeModal}
            />
          </div>
          <div className="container">
            <TitleCustom className="are-color-mynd-grey has-text-weight-semibold has-text-centered is-size-5 mb-3">
              Realiza tu consulta
            </TitleCustom>
            {!isRoute && (
              <TextCustom className="are-color-mynd-grey  has-text-centered mb-4 mt-0">
                Tenemos dos opciones para tu comodidad
              </TextCustom>
            )}

            <div className="columns is-mobile is-centered mb-4">
              {validateConsultFastService() && (
                <div className="column is-5  is-margin-consult-modal mr-2">
                  <div className="is-size-box-modal-consult">
                    <ImageCustom img={LiveService} className="is-img-modal" />
                    <TitleCustom className="is-fastservice-modal has-text-centered p-0 mb-0 mt-2">
                      Live
                    </TitleCustom>
                    <hr className="mt-2 is-hr-fastservice mt-4 "></hr>
                    <TextCustom className="is-modal-text-fastservice mt-4">
                      Realiza una consulta <br /> con un especialista de
                      <br />
                      <span className="has-text-weight-semibold">
                        forma online.
                      </span>
                    </TextCustom>
                    <div className="columns mt-2">
                      <div className="column mx-5">
                        <Link
                          className="button is-fullwidth is-modal-button-fastservice "
                          to={serviceRoute('fast')}
                        >
                          Elegir
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isRoute && validateConsultPractiService() && (
                <div className="column is-5">
                  <div className="primary-box is-size-box-modal-consult regular-padding">
                    <ImageCustom img={HoursService} className="is-img-modal" />
                    <TitleCustom className="is-practiservice-modal has-text-centered p-0 mb-0 mt-2">
                      45 horas
                    </TitleCustom>
                    <hr className="mt-2 is-hr-practiservice mt-4 "></hr>
                    <TextCustom className="is-modal-text-fastservice mt-4">
                      Envía tu consulta y <br /> recibe un diagnóstico
                      <br />
                      <span className="has-text-weight-semibold">
                        dentro 45 hrs.
                      </span>
                    </TextCustom>
                    <div className="columns mt-2">
                      <div className="column mx-5">
                        <Link
                          className="button is-fullwidth is-modal-button-practiservice "
                          to={serviceRoute('practi')}
                        >
                          Elegir
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

modalquey.propTypes = {}

export default modalquey
