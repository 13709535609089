// import PropTypes from 'prop-types'

const tabsCoupon = ({ toggleTab, toggleState }) => {
  return (
    <div className="tabs is-fullwidth">
      <ul>
        <li>
          <a
            className={
              toggleState === 1
                ? 'is-color-title-tabs has-text-weight-bold'
                : 'has-text-grey-light has-text-weight-semibold'
            }
            onClick={() => toggleTab(1)}
          >
            <span>Cupones</span>
          </a>
        </li>
        <li>
          <a
            className={
              toggleState === 2
                ? 'is-color-title-tabs has-text-weight-bold'
                : ' has-text-grey-light has-text-weight-semibold'
            }
            onClick={() => toggleTab(2)}
          >
            <span>Utilizados</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

tabsCoupon.propTypes = {}

export default tabsCoupon
