import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { firebase } from '../../config'

const ReCaptcha = ({ setToken, setVerifier, size }) => {
  const recaptchaContainerRef = useRef()

  useEffect(() => {
    if (!recaptchaContainerRef) return
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: size,
        callback: response => {
          setToken(response)
        },
        'expired-callback': () => {
          console.log('need more time?')
        },
      },
    )
    if (setVerifier) setVerifier(recaptchaVerifier)
    recaptchaVerifier.render()
  }, [recaptchaContainerRef])

  return (
    <div
      id="recaptcha-container"
      ref={recaptchaContainerRef}
      className="is-recaptcha-container"
    />
  )
}

ReCaptcha.propTypes = {
  setToken: PropTypes.func,
  setVerifier: PropTypes.func,
  size: PropTypes.string,
}

export default ReCaptcha
