import PropTypes from 'prop-types'
import { ImageCustom, ButtonCustom } from '../../components'

const PaymentType = ({ image, value, onClick, isSelected }) => {
  return (
    <div>
      <hr className="line-bottom my-2"></hr>
      <a
        className={`level mb-0 is-mobile button is-fullwidth ${
          isSelected && 'border-color-pink'
        }`}
        onClick={() => onClick(value)}
      >
        <ImageCustom img={image} width="50" heigth="20" className="mr-2" />
        <ButtonCustom
          buttonClassName={`button is-inverted is-text-color button-add-PM `}
          iconClassName="are-color-mynd-grey is-size-5"
          icon="fas fa-plus"
        />
      </a>
    </div>
  )
}

PaymentType.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string,
  value: PropTypes.object,
  isSelected: PropTypes.bool,
}

export default PaymentType
