import { drugsFC } from '../../config'
const getDrugsDataService = drugID => {
  return async dispatch => {
    try {
      const drug = await drugsFC
        .doc(drugID)
        .get()
        .then(drugData => {
          return drugData.data()
        })
      return drug
    } catch (error) {
      console.log('drugs_error', error)
      return []
    }
  }
}

export default getDrugsDataService
