import functions from '../../services/functions'
const { speiPayment } = functions.cloudFunctions

const speiPaymentService = async amount => {
  try {
    return await speiPayment({ amount: amount }).then(async response => {
      return await response.data[0]
    })
  } catch (error) {
    return error
  }
}

export default speiPaymentService
