// import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ContainerWarranty,
  TitleCustom,
  TicketsContainer,
} from '../../components'
import dispatchers from './dispatchers'
import selectors from './selectors'
import { useCounter } from '../../utils'

const WarrantyScreen = () => {
  const dispatch = useDispatch()
  const { addWarranty, uploadImageTicket, getTikets } = dispatchers(dispatch)
  const { count, increment } = useCounter(1)
  const [modalState, setModalState] = useState()
  const [loading, setLoading] = useState(true)
  const [tikets, setTikets] = useState({})
  const { auth } = useSelector(state => state)
  const { uid } = selectors(auth)
  const toggleModal = () => {
    setModalState(false)
  }

  useEffect(() => {
    const list = async () => {
      setLoading(true)
      const response = await getTikets(uid)
      if (response.data !== []) {
        setTikets(response.data)
      }
      setLoading(false)
    }
    list()
  }, [count])

  return (
    <div className="container mt-5 mb-5 ">
      <TitleCustom className="are-color-mynd-grey has-text-centered is-size-title-">
        Garantia My NextDerma
      </TitleCustom>
      {count === 1 && (
        <ContainerWarranty
          addWarranty={addWarranty}
          uploadImageTicket={uploadImageTicket}
          increment={increment}
          modalState={modalState}
          toggleModal={toggleModal}
          setModalState={setModalState}
          uid={uid}
        />
      )}
      {count === 2 && (
        <TicketsContainer
          tikets={tikets}
          modalState={modalState}
          toggleModal={toggleModal}
          setModalState={setModalState}
          loading={loading}
        />
      )}{' '}
    </div>
  )
}

WarrantyScreen.propTypes = {}

export default WarrantyScreen
