const es = {
  buttonSignIn: 'Iniciar sesión',
  title: 'Iniciar sesión con',
  google: 'Google',
  facebook: 'Facebook',
  phone: 'Teléfono',
  password: 'Contraseña',
  email: 'Correo electrónico',
  subtitle: 'Tambien puedes iniciar sesión con',
  recoveryPassword: '¿Olvidaste la contraseña?',
  checkbox: 'Recuérdame',
  getAccount: '¿Aún no tienes cuenta?',
  register: 'Registrate ahora',
  titleSlider: 'Dianóstico en 45 horas',
  subtitleSlider: 'Si después de 6 meses ',
  subtitleSlider2: 'de traramiento no hay mejoras',
  subtitleSlider3: '¡Te regresamos el 100% de tus consultas!',
  titleFooter: '© 2020 My NextDerma. Todos los derechos reservados',
  'policy-privacy': 'Política de Privacidad',
}

export default es
