// import PropTypes from 'prop-types'
import { TextCustom, ImageCustom } from '..'
import Images from '../../assets'

const { user_default } = Images
const CardDoctorLastAppointment = props => {
  return (
    <div className="box mt-3">
      <article className="media">
        <div className="media-left is-img-size-favourites">
          <ImageCustom img={user_default} />
        </div>
        <div className="media-content">
          <div className="is-flex mb-2 is-justify-content-space-between">
            <div className="">
              <TextCustom className="is-size-title-doctor pb-0 mb-2">
                Dr. Guillermo Alfredo
              </TextCustom>
            </div>
            <div className="pr-3">
              <a className="" aria-label="reply">
                <span className="icon is-small is-size-5">
                  <i className="far fa-heart are-color-mynd-grey is-size-colour"></i>
                </span>
              </a>
            </div>
          </div>
          <TextCustom className="is-text-specialist pb-0 mb-2 mt-3">
            Especialista:
            <span className="are-color-mynd-grey">
              El área de Piel y Cabello.
            </span>
          </TextCustom>
          <span className="icon-text mt-3">
            <span className="icon is-color-icon-comment">
              <i className="far fa-comment-dots"></i>
            </span>
            <span className="is-colo-text-comments">20 comentarios</span>
          </span>
        </div>
      </article>
    </div>
  )
}

CardDoctorLastAppointment.propTypes = {}

export default CardDoctorLastAppointment
