import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PaymentMethod, SpeiResult } from '../../components'
import selectors from './selectors'
import dispatchers from './dispatchers'
import { useStripe } from '@stripe/react-stripe-js'
import { useToggle } from '../../utils'

const PaymentMethods = () => {
  const [isOpen, setIsOpen] = useToggle()
  const stripe = useStripe()
  const { auth, ui } = useSelector(state => state)
  const dispatch = useDispatch()
  const {
    oxxoPayment,
    speiPayment,
    paynetPayment,
    getToken,
    addBalance,
    addError,
    removeError,
  } = dispatchers(dispatch)
  const { uid, fullname, email, errors } = selectors(auth, ui)
  const [showAddBalance, setShowAddBalance] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState({})
  const [amount, setAmount] = useState('')
  const [spei, setSpei] = useState()
  const type = 'paymentMethods'
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getToken(uid)
  }, [])

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        removeError()
      }, 3000)
    }
  }, [errors])

  useEffect(() => {
    if (!showAddBalance && amount !== '') setAmount('')
  }, [showAddBalance])

  const onAddBalance = async () => {
    setIsLoading(true)
    if (!paymentMethod) {
      setIsLoading(false)
      return
    }
    const { method, details, customer } = paymentMethod

    let response
    if (method === 'card' && amount >= 50) {
      response = await addBalance({
        token: customer,
        amount: parseInt(amount),
        uid,
      })
    }
    if (details === 'oxxo' && amount >= 50) {
      response = await oxxoPayment(stripe, amount, fullname, email)
    }
    if (details === 'spei' && amount >= 50) {
      response = await speiPayment(amount)
      if (response) {
        setSpei(response)
        setIsOpen()
      }
    }
    if (details === 'paynet' && amount >= 50) {
      response = await paynetPayment(amount)
      if (response?.instructions) window.open(response.instructions)
    }
    if (response.error && details === 'oxxo') {
      setIsLoading(false)
      if (response.error.param === 'owner[name]') {
        addError(
          'El nombre que usted tiene es invalido para usar oxxo por favor usa su nombre completo',
        )
      } else {
        addError(
          'Hubo un error al generar tu consulta. Por favor inténtalo más tarde',
        )
      }
    } else if (response) {
      setIsLoading(false)
      setShowAddBalance(false)
    }
  }

  return (
    <div className="py-6">
      {errors && <div className="notification is-danger">{errors}</div>}
      <PaymentMethod
        isLoading={isLoading}
        next={() => {}}
        previous={() => {}}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        type={type}
        amount={amount}
        setAmount={setAmount}
        onAddBalance={onAddBalance}
        setShowAddBalance={setShowAddBalance}
        showAddBalance={showAddBalance}
      />
      {isOpen && <SpeiResult closeModal={setIsOpen} data={spei} />}
    </div>
  )
}

PaymentMethods.propTypes = {}

export default PaymentMethods
