import { NavLink } from 'react-router-dom'
import Images from '../../assets'
const BottomBarNav = () => {
  const {doctorIcon, HomeIconSubNav, camera_icon_sub_nav} = Images 
  return (
    <nav
      className="navbar navbar-background is-link is-fixed-bottom is-hidden-tablet"
      role="navigation"
    >
      <div className="navbar-brand navbar-background">
        <NavLink
          className="navbar-item is-expanded is-block has-text-centered"
          to="/user/consults"
        >
         <img src={camera_icon_sub_nav} />
          <p className="is-size-7">Sesiones</p>
        </NavLink>
        <NavLink
          className="navbar-item is-expanded is-block has-text-centered"
          to="/user/home"
        >
         <img src={HomeIconSubNav} />
          <p className="is-size-7">Home</p>
        </NavLink>
        <NavLink
          className="navbar-item is-expanded is-block has-text-centered"
          to="/user/doctors"
        >
          <img src={doctorIcon} />
          <p className="is-size-7">Doctores</p>
        </NavLink>
      </div>
    </nav>
  )
}

export default BottomBarNav
