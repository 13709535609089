import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LngDetector from 'i18next-browser-languagedetector'

import translationEn from './resources/en'
import translationEs from './resources/es'

const languages = ['es']

const resources = {
  en: { translation: translationEn },
  es: { translation: translationEs },
}

i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['navigator'] },
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: resources,
    whitelist: languages,
  })

export default i18n
