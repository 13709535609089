import { authActions, uiActions } from '../../actions'
import {
  signInWithEmailAndPassword,
  signInWinPopup,
  fetchSignInMethodsForEmail,
  rememberMeService,
} from '../../services'
const {
  signOutAction,
  removeAuthErrorsAction,
  signInWithEmailAndPasswordFailureAction,
} = authActions.creators
const { removeError, addError } = uiActions.creators

export default dispatch => {
  return {
    signOut: () => dispatch(signOutAction()),
    signInWithEmail: (email, password) =>
      dispatch(signInWithEmailAndPassword(email, password)),
    signInWithProvider: provider => dispatch(signInWinPopup(provider)),
    fetchMethods: ({ credential, email, password }) =>
      dispatch(fetchSignInMethodsForEmail({ credential, email, password })),
    removeError: () => dispatch(removeError()),
    addError: error => dispatch(addError(error)),
    removeAuthErrors: () => dispatch(removeAuthErrorsAction()),
    removeSingInError: () =>
      dispatch(signInWithEmailAndPasswordFailureAction({})),
    rememberMe: rememberMe => dispatch(rememberMeService(rememberMe)),
  }
}
