import { doctorsActions } from '../../actions'
import functions from '../functions'

const { getAvailableDoctors } = functions.cloudFunctions
const {
  createDoctorsFailureAction,
  createDoctorsRequestAction,
  createDoctorsSuccessAction,
} = doctorsActions.creators

const getDoctors = (specialty = 'derma') => {
  return async dispatch => {
    dispatch(createDoctorsRequestAction())
    try {
      const doctors = await getAvailableDoctors({ specialty })
      dispatch(createDoctorsSuccessAction(doctors.data))
      return doctors.data
    } catch (error) {
      dispatch(createDoctorsFailureAction(error))
      return error
    }
  }
}

export default getDoctors
