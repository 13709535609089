import functions from '../functions'

const { getPrice } = functions.cloudFunctions
const getPriceService = session => {
  console.log(session)
  return async dispatch => {
    try {
      const response = await getPrice({ session })
      if (response !== 'missed type session') {
        return (response.data.price / 100).toFixed(2)
      }
      return response
    } catch (e) {
      console.log(e)
      return e
    }
  }
}

export default getPriceService
