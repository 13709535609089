import { uiActions, authActions } from '../../actions'
import {
  createUserWithEmailAndPassword,
  signInWinPopup,
  fetchSignInMethodsForEmail,
} from '../../services'
const { addError, removeError } = uiActions.creators
const { removeAuthErrorsAction } = authActions.creators
export default dispatch => {
  return {
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
    createUser: (email, password) =>
      dispatch(createUserWithEmailAndPassword(email, password)),
    signInWithProvider: provider => dispatch(signInWinPopup(provider)),
    fetchMethods: ({ credential, email, password }) =>
      dispatch(fetchSignInMethodsForEmail({ credential, email, password })),
    removeAuthErrors: () => dispatch(removeAuthErrorsAction()),
  }
}
