import { consultRef } from '../../config'

const updateConsultService = (consultId, data) => {
  return async dispatch => {
    try {
      const response = await consultRef
        .doc(consultId)
        .set(data, { merge: true })
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }
}

export default updateConsultService
