import functions from '../functions'
const { getTokenOpenPay } = functions.cloudFunctions

const getTokenOpenPayService = async uid => {
  try {
    return await getTokenOpenPay({ uid })
  } catch (error) {
    return error
  }
}

export default getTokenOpenPayService
