import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { consultRef, firestore } from '../../config'
import moment from 'moment'
import 'moment/locale/es-mx'

const useOnSnapshotConsults = () => {
  const { uid } = useSelector(state => state.auth.user)
  const [listSessions, setListSessions] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const userRef = firestore.collection('users').doc(uid)
    const unsuscribe = consultRef
      .where('patient', '==', userRef)
      .orderBy('created', 'desc')
      .onSnapshot(async result => {
        setIsLoading(true)
        if (result.empty) {
          setListSessions({ empty: true })
          setIsLoading(false)
        } else {
          const { docs } = result
          const tempSessions = await Promise.all(
            docs.map(async d => {
              const session = d.data()
              const tempData = session
              tempData.id = d.id
              const doctor = session.doctor
                ? await session.doctor.get().then(doctor => {
                    return doctor
                  })
                : null
              const clinic = session.clinic
                ? await session.clinic.get().then(clinic => {
                    return clinic
                  })
                : null
              const coupon = session.coupon
                ? await session.coupon.get().then(coupon => {
                    return coupon
                  })
                : null
              if (session.doctor) {
                tempData.doctor = doctor.data()
              }
              if (session.clinic) {
                tempData.clinic = clinic.data()
                tempData.clinic.id = clinic.id
              }
              if (session.coupon) {
                tempData.coupon = coupon.data()
              }
              const created_backup = session.created.seconds
              session.created_date = moment(
                new Date(created_backup * 1000),
              ).format()
              session.created = moment(new Date(created_backup * 1000)).format(
                'DD/MMMM/YYYY',
              )
              session.hours_backup = {
                created: moment(new Date(created_backup * 1000)).format(
                  'hh:mm A',
                ),
              }
              if (session.scheduled_date) {
                const scheduled_backup = session.scheduled_date.seconds
                session.hours_backup.scheduled_date = moment(
                  new Date(scheduled_backup * 1000),
                ).format('hh:mm A')
                const schedule = moment(new Date(scheduled_backup * 1000))
                session.scheduled_date = schedule.format('DD/MMMM/YYYY')
                const isReScheduled = schedule.subtract(24, 'hours').unix()
                session.isReScheduled = moment().unix() < isReScheduled
                session.isCancellable = moment().unix() < schedule.unix()
              }
              if (session.recipe_date) {
                session.recipe_date = moment(
                  new Date(session.recipe_date.seconds * 1000),
                )
              }

              return tempData
            }),
          )
          const cancelled = tempSessions.filter(d => d.status === 'canceled')
          const close = tempSessions.filter(d => d.status === 'close')
          const onCall = tempSessions.filter(d => d.status === 'onCall')
          const scheduled = tempSessions.filter(
            d =>
              d.status !== 'canceled' &&
              d.status !== 'close' &&
              d.status !== 'onCall',
          )
          setListSessions({ cancelled, close, onCall, scheduled, empty: false })
          setIsLoading(false)
        }
      })

    return () => {
      unsuscribe()
    }
  }, [])
  return [listSessions, isLoading]
}

export default useOnSnapshotConsults
