import PropTypes from 'prop-types'

// components
import {
  ProductNameField,
  BrandoftheProductField,
  ProductCategory,
  DateofPurchaseField,
  StoreNameField,
  ContainerUsageTime,
  NotesField,
  CoinAndMoney,
} from '../../../components'

const ProductContainerForm = ({ handleInputChange, date }) => {
  return (
    <>
      <ProductNameField handleInputChange={handleInputChange} />
      <BrandoftheProductField handleInputChange={handleInputChange} />
      <ProductCategory handleInputChange={handleInputChange} />
      <DateofPurchaseField handleInputChange={handleInputChange} date={date} />
      <CoinAndMoney handleInputChange={handleInputChange} />
      <StoreNameField handleInputChange={handleInputChange} />
      <ContainerUsageTime handleInputChange={handleInputChange} />
      <NotesField handleInputChange={handleInputChange} />
    </>
  )
}

ProductContainerForm.propTypes = {
  handleInputChange: PropTypes.func,
  date: PropTypes.any,
}

export default ProductContainerForm
