import PropTypes from 'prop-types'
import { ImageCustom, NotificationsBox } from '../../components'
import Images from '../../assets'
import { Link } from 'react-router-dom'

const LoginHeader = ({
  uid,
  privateFlag,
  privateDoctor,
  refresh,
  setIsOpen,
  isOpen,
  readyDoctorPrivate,
}) => {
  const { Doctors, Hamburger, Home, Sessions, logoHorizontalBeginning } = Images

  const doctorBtn = () => {
    if (privateFlag) {
      return '/user/doctor/' + privateDoctor
    } else {
      return '/user/doctors'
    }
  }

  return (
    <nav className="level is-mobile white-box pb-2 pt-2 bottom-gray-border background-white">
      <div className="level-left">
        <div className="level-item">
          <a
            className={`hamburger full-center ${
              isOpen && 'hamburger-selected'
            }`}
            onClick={setIsOpen}
          >
            <Hamburger />
          </a>
        </div>
        <div className="level-item">
          <ImageCustom
            img={logoHorizontalBeginning}
            width="150"
            heigth="20"
            className="mr-2"
          />
        </div>
      </div>
      <div className="level-right" id="">
        <div className="level-item">
          <Link className="navi-button is-hidden-mobile" to="/user">
            <div className="full-center  mx-3">
              <Home />
            </div>
          </Link>
        </div>
        <div className="level-item">
          <Link className="navi-button is-hidden-mobile" to="/user/consults">
            <div className="full-center mx-3">
              <Sessions />
            </div>
          </Link>
        </div>
        {(!privateFlag || (readyDoctorPrivate && privateDoctor)) && (
          <div className="level-item">
            <Link className="navi-button is-hidden-mobile" to={doctorBtn()}>
              <div className="full-center mx-3">
                <Doctors />
              </div>
            </Link>
          </div>
        )}
        <div className="level-item">
          <div className="navi-button is-hidden-mobile">
            <div className="full-center mx-3">
              <NotificationsBox uid={uid} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

LoginHeader.propTypes = {
  uid: PropTypes.string,
  privateFlag: PropTypes.bool,
  privateDoctor: PropTypes.any,
  refresh: PropTypes.func,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  readyDoctorPrivate: PropTypes.bool,
}

export default LoginHeader
