import PropTypes from 'prop-types'
import { ButtonCustom, TextCustom, ImageCustom } from '..'
import Images from '../../assets'

const informationWarranty = ({ closeModal }) => {
  const { warranty } = Images
  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card is-modal-border-radius">
        <section className="modal-card-body">
          <div className="level">
            <div className="level-left">
              <TextCustom className="has-text-weight-bold is-size-4 has-text-centered">
                Garantia
              </TextCustom>
            </div>
            <div className="level-right">
              <ButtonCustom
                icon="far fa-times-circle"
                buttonClassName="button is-inverted is-border-button-modal"
                iconClassName="are-color-mynd-grey is-size-4"
                onClick={closeModal}
              />
            </div>
          </div>
          <ImageCustom img={warranty} className="is-size-logo-warranty " />
          <TextCustom className="has-text-centered are-color-mynd-grey is-size-5 my-4">
            Si despues de 6 meses de tratamiento por acne no ves mejoria
          </TextCustom>
          <TextCustom className="has-text-centered has-text-weight-bold is-color-pink is-size-4 my-3">
            TE DEVOLVDEMOS EL 100% DE TUS CONSULTAS*
          </TextCustom>
          <TextCustom className="mt-2 has-text-centered px-6">
            *El paciente deberá realizar las consultas en el tiempo indicado por
            el médico. Puede haber un máximo de 5 días de retraso en las
            consultas sobre la fecha solicitada por el médico. En caso de no
            haber adquirido el tratamiento dentro de la app, el paciente deberá
            demostrar que si adquirió el tratamiento en alguna otra farmacia
            mediante los tickets de compra. Únicamente será devuelto el total de
            pago por consultas, no del tratamiento.
          </TextCustom>
        </section>
      </div>
    </div>
  )
}

informationWarranty.propTypes = {
  closeModal: PropTypes.func,
}

export default informationWarranty
