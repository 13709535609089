import PropTypes from 'prop-types'

const Button = ({
  icon,
  iconRight,
  img,
  iconClassName,
  imgClassName,
  textClassName,
  children,
  ...otherProps
}) => {
  return (
    <button {...otherProps}>
      {icon && (
        <>
          {!iconRight && (
            <span className={`icon ${iconClassName}`}>
              <i className={icon}></i>
            </span>
          )}
          {children && (
            <span className="full-center bold-font">{children}</span>
          )}
          {iconRight && (
            <span className={`icon ${iconClassName}`}>
              <i className={icon}></i>
            </span>
          )}
        </>
      )}
      {img && (
        <>
          <figure className={`img  ${imgClassName}`}>
            <img src={img} width="107" />
          </figure>
          {children && (
            <p className={`${textClassName} bold-font`}>{children}</p>
          )}
        </>
      )}
      {!icon && !img && (
        <span className="full-center bold-font">{children}</span>
      )}
    </button>
  )
}

Button.propTypes = {
  icon: PropTypes.any,
  iconRight: PropTypes.bool,
  iconClassName: PropTypes.string,
  img: PropTypes.any,
  imgClassName: PropTypes.string,
  textClassName: PropTypes.string,
  children: PropTypes.any,
  otherProps: PropTypes.any,
}

export default Button
