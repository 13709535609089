import PropTypes from 'prop-types'

const Tittle = ({ children, ...otherProps }) => (
  <h1 {...otherProps}>{children}</h1>
)

Tittle.propTypes = {
  children: PropTypes.string,
  otherProps: PropTypes.object,
}

export default Tittle
