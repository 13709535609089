import PropTypes from 'prop-types'

const hours = ({ selectedHour, availableHours, setSelectedHour }) => {
  return (
    <>
      {availableHours.length === 0 ? (
        <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
          <p className="hours-loading-text">Cargando horas disponibles...</p>
        </div>
      ) : (
        availableHours.map((row, index) => (
          <div key={index} className="row__hours">
            {row.map((hours, index) => (
              <div
                key={index}
                className={`hours__item ${
                  selectedHour === hours ? 'hours-selected' : ''
                }`}
                defaultValue={hours}
                onClick={() => setSelectedHour(hours)}
              >
                {hours}
              </div>
            ))}
          </div>
        ))
      )}
    </>
  )
}

hours.propTypes = {
  availableHours: PropTypes.array,
  setSelectedHour: PropTypes.func,
}

export default hours
