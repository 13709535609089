import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  TitleCustom,
  QuestionNumber,
  SpecialtyService,
  ProgressContainer,
  ScheduleService,
  ConsulationService,
  QuestionService,
} from '../../components'
import { useCounter, useRouter } from '../../utils'
import dispatchers from './dispatchers'
import selectors from './selectors'

const PracticeServiceScreen = () => {
  const [type, setType] = useState('')
  const [selectedDate, setSelectedDate] = useState({})
  const [evidences, setEvidences] = useState([])
  const [answers, setAnswers] = useState({})
  const { query, history } = useRouter()
  const { consult, id } = query
  const dispatch = useDispatch()
  const {
    addError,
    removeError,
    updateConsult,
    getBlockedDays,
    getBlockedDaysPrivate,
    getAvailableHours,
    getAvailableHoursPrivate,
    getQuestionnaires,
    getNutritionQuestionnaire,
    preScheadule,
    getCategoriesForSpecialty,
  } = dispatchers(dispatch)
  const state = useSelector(state => state)
  const {
    errors,
    currentConsult,
    loading,
    questions,
    selectedCategories,
    specialty,
  } = selectors(state)
  const { count, increment, decrement } = useCounter(1)

  useEffect(async () => {
    if (specialty === 'nutrition') {
      getNutritionQuestionnaire()
    }
  }, [])

  useEffect(async () => {
    if (specialty === 'derma') {
      getCategoriesForSpecialty('derma')
    }
  }, [])

  const goToOrders = toService => {
    if (!specialty || specialty === 'derma') {
      if (toService === 'fast-service') {
        preScheadule({ type, selectedDate, answers, id, consult, evidences })
      } else {
        preScheadule({ type, evidences, answers, id, consult })
      }
      history.push(id ? `/user/orders/${id}` : '/user/orders')
    } else if (specialty === 'psychology') {
      preScheadule({ selectedDate, id, consult })
      if (Object.keys(selectedDate).length > 0) {
        history.push(id ? `/user/orders/${id}` : '/user/orders')
      }
    } else if (specialty === 'nutrition') {
      preScheadule({ selectedDate, answers, id, consult })
      history.push(id ? `/user/orders/${id}` : '/user/orders')
    }
  }

  // specialty steps
  const dermaPractiServiceStepsContent = {
    1: (
      <SpecialtyService
        selectedCategories={selectedCategories}
        typeConsult={consult}
        type={type}
        setType={setType}
        getQuestionnaires={getQuestionnaires}
        currentConsult={currentConsult}
        updateConsult={updateConsult}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
      />
    ),
    2: (
      <ConsulationService
        evidences={evidences}
        setEvidences={setEvidences}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
      />
    ),
    3: (
      <QuestionService
        typeConsult={consult}
        goToOrders={goToOrders}
        answers={answers}
        decrement={decrement}
        questions={questions}
        addError={addError}
        removeError={removeError}
        errors={errors}
        setAnswers={setAnswers}
      />
    ),
  }

  const dermaFastServiceStepsContent = {
    1: (
      <SpecialtyService
        selectedCategories={selectedCategories}
        typeConsult={consult}
        type={type}
        setType={setType}
        getQuestionnaires={getQuestionnaires}
        currentConsult={currentConsult}
        updateConsult={updateConsult}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
      />
    ),
    2: (
      <ScheduleService
        specialtyScheadule={specialty}
        setScheadule={setSelectedDate}
        currentConsult={currentConsult}
        loading={loading}
        getAvailableHours={getAvailableHours}
        getAvailableHoursPrivate={getAvailableHoursPrivate}
        updateConsult={updateConsult}
        getBlockedDays={getBlockedDays}
        getBlockedDaysPrivate={getBlockedDaysPrivate}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
        doctorId={id}
      />
    ),
    3: (
      <ConsulationService
        evidences={evidences}
        setEvidences={setEvidences}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
      />
    ),
    4: (
      <QuestionService
        typeConsult={consult}
        goToOrders={goToOrders}
        answers={answers}
        decrement={decrement}
        questions={questions}
        addError={addError}
        removeError={removeError}
        errors={errors}
        setAnswers={setAnswers}
      />
    ),
  }

  const psychologyFastServiceStepsContent = {
    1: (
      <ScheduleService
        specialtyScheadule={specialty}
        typeConsult={consult}
        setScheadule={setSelectedDate}
        currentConsult={currentConsult}
        loading={loading}
        getAvailableHours={getAvailableHours}
        getAvailableHoursPrivate={getAvailableHoursPrivate}
        updateConsult={updateConsult}
        getBlockedDays={getBlockedDays}
        getBlockedDaysPrivate={getBlockedDaysPrivate}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
        doctorId={id}
        disableBack={true}
        goToOrders={goToOrders}
      />
    ),
  }

  const nutritionFastServiceStepsContent = {
    1: (
      <ScheduleService
        specialtyScheadule={specialty}
        setScheadule={setSelectedDate}
        currentConsult={currentConsult}
        loading={loading}
        getAvailableHours={getAvailableHours}
        getAvailableHoursPrivate={getAvailableHoursPrivate}
        updateConsult={updateConsult}
        getBlockedDays={getBlockedDays}
        getBlockedDaysPrivate={getBlockedDaysPrivate}
        increment={increment}
        decrement={decrement}
        addError={addError}
        removeError={removeError}
        errors={errors}
        doctorId={id}
        disableBack={true}
      />
    ),
    2: (
      <QuestionService
        typeConsult={consult}
        goToOrders={goToOrders}
        answers={answers}
        decrement={decrement}
        questions={questions}
        addError={addError}
        removeError={removeError}
        errors={errors}
        setAnswers={setAnswers}
        disableSkip={true}
      />
    ),
  }

  const medicineFastServiceStepsContent = {
    1: (
      <QuestionService
        typeConsult={consult}
        goToOrders={goToOrders}
        answers={answers}
        decrement={decrement}
        questions={questions}
        addError={addError}
        removeError={removeError}
        errors={errors}
        setAnswers={setAnswers}
        disableBack={true}
      />
    ),
  }

  return (
    <section className="hero is-fullheight">
      <div className="hero-body is-flex is-align-items-start pt-0 ">
        <div className="container mt-6">
          <TitleCustom className="has-text-centered is-size-3 is-text-color">
            {consult === 'practi-service' ? '45 horas' : 'Live'}
          </TitleCustom>
          <div className="columns is-mobile is-centered">
            <div className="column  is-size-box-practi-service">
              <QuestionNumber
                specialty={specialty}
                typeConsult={consult}
                step={count}
                className="mb-5"
              />
              <ProgressContainer
                specialty={specialty}
                step={count}
                typeConsult={consult}
              />
              {/* derma */}
              {consult === 'practi-service' &&
                (!specialty || specialty === 'derma') &&
                dermaPractiServiceStepsContent[count]}
              {consult === 'fast-service' &&
                (!specialty || specialty === 'derma') &&
                dermaFastServiceStepsContent[count]}
              {/* psychology */}
              {consult === 'fast-service' &&
                specialty === 'psychology' &&
                psychologyFastServiceStepsContent[count]}
              {/* nutrition */}
              {consult === 'fast-service' &&
                specialty === 'nutrition' &&
                nutritionFastServiceStepsContent[count]}
              {/* medicine */}
              {consult === 'practi-service' &&
                specialty === 'medicine' &&
                medicineFastServiceStepsContent[count]}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default PracticeServiceScreen
