import PropTypes from 'prop-types'

// Img
import Group from '../../assets/Group.png'

// Componets
import { ButtonCustom } from '../../components'

const SurveyHome = ({ formbyStep }) => {
  return (
    <div className="box is-box-shadow px-6 mt-6">
      <figure className="image is-size-img is-item-centered mt-4 ">
        <img src={Group} />
      </figure>
      <div className="content mt-6 mb-5">
        <p className="has-text-centered is-size-6">
          <strong>Recuerda:</strong> Llenar el formulario nos ayudará a
          conocerte y darte el mejor tratamiento para tu piel.
        </p>
      </div>
      <p className="buttons is-centered">
        <ButtonCustom
          buttonClassName=" is-mnd-primary is-fullwidth mx-3 my-4 has-text-white  is-medium is-widescreen "
          onClick={formbyStep}
        >
          Siguiente
        </ButtonCustom>
        <ButtonCustom buttonClassName="is-text-color is-inverted mt-4">
          Omitir
        </ButtonCustom>
      </p>
    </div>
  )
}

SurveyHome.propTypes = {
  formbyStep: PropTypes.func,
}

export default SurveyHome
