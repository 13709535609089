import React, { useEffect, useState } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { useAgora, useRouter, useToggle } from '../../utils'
import { TextCustom } from '../../components/'
import MediaPlayer from '../../components/liveService/mediaPlayer'
import Images from '../../assets'
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min'
import { consultRef } from '../../config'

const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' })
const appid = '2521020163c043e08c54b17b392cd0e4'
const LiveService = () => {
  const [isBlocking, setIsBlocking] = useState(false)
  const { query, history } = useRouter()
  const { id } = query
  const [audioActive, setAudioActive] = useToggle(false)
  const [videoActive, setVideoActive] = useToggle(false)
  const [closeCall, setCloseCall] = useState(false)
  const { CamaraOn, MicOn, MicOff, CamaraOff } = Images
  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    remoteUsers,
    mutedVideo,
    mutedAudio,
  } = useAgora(client)
  console.log(localAudioTrack)
  const goTo = () => {
    history.push('/user/consults')
  }

  useEffect(() => {
    if (id) {
      setIsBlocking(true)
      join(appid, id)
    } else {
      console.log('error')
    }
    const unsuscribe = consultRef.doc(id).onSnapshot(result => {
      if (result.data().status === 'close') {
        setCloseCall(true)
      }
    })
    return () => {
      unsuscribe()
    }
  }, [])

  useEffect(() => {
    if (closeCall === true) {
      setIsBlocking(false)
      leave()
      goTo()
    }
    return () => {}
  }, [closeCall])

  useEffect(() => {
    mutedVideo(videoActive)
    return () => {}
  }, [videoActive])

  useEffect(() => {
    mutedAudio(audioActive)
    return () => {}
  }, [audioActive])

  return (
    <div className="container mt-5 mb-5 call-container">
      <Prompt when={isBlocking} message={leave} />
      <div className="player-container">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="remote-player-wrapper">
            {remoteUsers[0] ? (
              <MediaPlayer
                videoTrack={remoteUsers[0].videoTrack}
                audioTrack={remoteUsers[0].audioTrack}
                width={'430px'}
                height={'720px'}
              />
            ) : (
              <div className="empty-diary">
                <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4">
                  {`Tu doctor / asistente estará contigo en seguida`}
                </TextCustom>
              </div>
            )}
          </div>
          <div className="local-player-wrapper">
            <MediaPlayer
              videoTrack={localVideoTrack}
              audioTrack={null}
              width={'91px'}
              height={'130px'}
            ></MediaPlayer>
          </div>
        </div>
        <div className="call-buttons">
          <a
            className={`button call-bc call-buttons-${
              !audioActive ? 'active' : 'mute inactive-call-button'
            }`}
            onClick={setAudioActive}
          >
            {audioActive ? <MicOff /> : <MicOn />}
          </a>
          <a
            className={`button call-bc call-buttons-${
              !videoActive ? 'active' : 'mute inactive-call-button'
            }`}
            onClick={setVideoActive}
          >
            {videoActive ? <CamaraOff /> : <CamaraOn />}
          </a>
        </div>
      </div>
    </div>
  )
}

export default LiveService
