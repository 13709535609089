import PropTypes from 'prop-types'

// Components
import { TextCustom } from '../../components'

const QuestionNumber = ({ specialty, typeConsult, step }) => {
  // Sections by specialty
  // diseble for psychology & medicine
  if (specialty === 'psychology' || specialty === 'medicine') {
    return null
  }
  // nutrition for (fast-service)
  if (specialty === 'nutrition') {
    return (
      <>
        {step === 1 && (
          <TextCustom className="is-text-part-question mb-5">
            1/2 Escoge un horario
          </TextCustom>
        )}
        {step === 2 && (
          <TextCustom className="is-text-part-question mb-5">
            2/2 Preguntas de consulta
          </TextCustom>
        )}
      </>
    )
  }
  // derma for (practi-service & fast-service)
  return (
    <>
      {step === 1 && (
        <TextCustom className="is-text-part-question mb-5">
          {typeConsult === 'practi-service'
            ? '1/3 Tipo de tratamiento'
            : '1/4 Tipo de tratamiento'}
        </TextCustom>
      )}
      {step === 2 && (
        <TextCustom className="is-text-part-question mb-5">
          {typeConsult === 'practi-service'
            ? '2/3 Selecciona tus evidencias'
            : '2/4 Escoge un horario'}
        </TextCustom>
      )}
      {step === 3 && (
        <TextCustom className="is-text-part-question mb-5">
          {typeConsult === 'practi-service'
            ? '3/3 Preguntas de consulta'
            : '3/4 Selecciona tus evidencias'}
        </TextCustom>
      )}
      {step === 4 && typeConsult !== 'practi-service' && (
        <TextCustom className="is-text-part-question mb-5">
          4/4 Preguntas de consulta
        </TextCustom>
      )}
    </>
  )
}

QuestionNumber.propTypes = {
  specialty: PropTypes.string,
  typeConsult: PropTypes.string,
  step: PropTypes.number,
}

export default QuestionNumber
