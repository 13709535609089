import { useSelector, useDispatch } from 'react-redux'
import selectors from './selectors'
import dispatchers from './dipatchers'
import {
  TextCustom,
  TitleCustom,
  AccordionMedicalRecords,
} from '../../components'

const MedicalRecordsScreen = () => {
  const dispatch = useDispatch()
  const { clinicalRecord: medicalRecord, uploadFiles } = dispatchers(dispatch)
  const { clinicalRecord, auth } = useSelector(state => state)
  const { data, error, isFetching, uid } = selectors(clinicalRecord, auth)
  return (
    <div className="columns is-centered ">
      <div className="column is-size-box-medical-records my-5">
        <TitleCustom className="is-title-size are-color-mynd-grey has-text-centered mt-5">
          Expediente Clínico
        </TitleCustom>
        <TextCustom className="has-text-weight-semibold has-text-centered mb-2">
          Es necesario rellenar tu expediente para un mejor seguimiento
        </TextCustom>
        <AccordionMedicalRecords
          data={data}
          error={error}
          isFetching={isFetching}
          medicalRecord={medicalRecord}
          uploadFiles={uploadFiles}
          uid={uid}
        />
      </div>
    </div>
  )
}

export default MedicalRecordsScreen
