import functions from '../functions'
const { cancelComment, consultUpdate } = functions.cloudFunctions

const cancelSessionService = async data => {
  try {
    await cancelComment(data)
    return await consultUpdate({
      uid: data.uid,
      values: {
        status: 'canceled',
      },
    })
  } catch (error) {
    return { error }
  }
}

export default cancelSessionService
