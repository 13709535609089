import { ReactComponent as Dairy } from './svgs/dairy-icon.svg'
import { ReactComponent as Pharma } from './svgs/pharma-icon.svg'
import { ReactComponent as Shopping } from './svgs/shop-icon.svg'
import { ReactComponent as Treatment } from './svgs/treatment-icon.svg'
import { ReactComponent as Billing } from './svgs/billing-icon.svg'
import { ReactComponent as Config } from './svgs/config-icon.svg'
import { ReactComponent as Cuppons } from './svgs/coupon.svg'
import { ReactComponent as Favorites } from './svgs/heart-red-icon.svg'
import { ReactComponent as Personal } from './svgs/personal-icon.svg'
import { ReactComponent as Close } from './svgs/close-sesion-icon.svg'
import { ReactComponent as Comment } from './icons/comment.svg'
import { ReactComponent as Questions } from './svgs/question-icon.svg'
import { ReactComponent as Wa } from './svgs/whats-app-icon.svg'
import { ReactComponent as Score } from './svgs/score.svg'
import arrowIcon from './icons/chevron.svg'
import groupOfPeopleAnnouncing from './images/group-of-people-announcing.png'
import productosDiaryTransparent from './images/ProductosDiaryTransparent.png'
import scheduleTransparent from './images/schedule-Transparent-.png'
import cleanFace from './images/CleanFace.png'
import medicineTransparentGray from './images/medicine-transparent-gray.png'
import calendarIcon from './icons/calendar-icon.png'
import noteIcon from './icons/note-icon.png'
import cameraIcon from './icons/cameraIcon.png'
import live from './icons/live-icon.png'
import chat from './svgs/Chat.svg'
import microfono from './svgs/Microfono.svg'
import Hamburger from './svgs/Hamburger'
import Heart from './svgs/Heart'
import Home from './svgs/Home'
import Sessions from './svgs/Sessions'
import Doctors from './svgs/Doctors'
import DoctorsNotification from './svgs/DoctorsNotification'
import HoursService from './svgs/practi-service.svg'
import LiveService from './svgs/fast-service.svg'
import Notifications from './svgs/Notifications'
import AcnePimple from './svgs/AcnePimple.svg'
import RoundArrow from './svgs/RoundArrow.svg'
import MagnifyingGlassOnDocument from './svgs/MagnifyingGlassOnDocument.svg'
import Medicine from './svgs/Medicine.svg'
import Video from './svgs/Video'
import Microphone from './svgs/Microphone'
import user_default from './images/user_default.png'
import policy from './svgs/user-shield-solid.svg'
import warranty from './images/warranty-mynextderma.png'
import userDefaultDoctor from './images/user_default_doctor.png'
import mastercard from './icons/mastercard.jpg'
import americanExpress from './icons/american-express.jpg'
import sections_notification from './svgs/Section_notification.svg'
import usa from './icons/usa.png'
import mx from './icons/mx.png'
import co from './icons/co.png'
import { ReactComponent as DoctorWoman } from './svgs/doctor_woman.svg'
import userVerifyWhite from './images/user-verify-white.png'
import { ReactComponent as CamaraOff } from './svgs/camera-off.svg'
import { ReactComponent as MicOff } from './svgs/mic-off.svg'
import { ReactComponent as MicOn } from './svgs/mic.svg'
import { ReactComponent as CamaraOn } from './svgs/camera-on.svg'
import { ReactComponent as SingleCheck } from './svgs/single_check_pink.svg'
import camera_icon from './svgs/camera_icon.svg'
import walletIcon from './svgs/wallet_icon.svg'
import proceedingsIcon from './svgs/proceedings_icon.svg'
import directionsIcon from './svgs/directions_icon.svg'
import medicineIcon from './svgs/medicine_icon.svg'
import homeIcon from './svgs/home_icon.svg'
import truckIcon from './svgs/truck_icon.svg'
import visaIcon from './svgs/visa_icon.svg'
import cardIcon from './svgs/card_icon.svg'
import paypalIcon from './svgs/paypal.svg'
import oxxoIcon from './svgs/oxxo_Icon.svg'
import paynet from './svgs/paynet.svg'
import speiIcon from './svgs/spei_icon.svg'
import pictureIcon from './svgs/pictureicon.svg'
import checkIcon from './svgs/check_icon.svg'
import iconShedule from './svgs/iconshedule.svg'
import iconTrunkTransparent from './svgs/icon_trunk_transparent.svg'
import iconBoxTransparente from './svgs/icon_box_tranparent.svg'
import homeIconPink from './svgs/house_icon_pink.svg'
import personWithlaptopPaque from './svgs/person_with_laptop_paque.svg'
import personWithLaptop from './svgs/personWithLaptop.svg'
import cuponIcon from './svgs/cupon_icon.svg'
import dropsBloodIcon from './svgs/dropsBloodIcon.svg'
import personIcon from './svgs/personIcon.svg'
import bacteriumIcon from './svgs/bacteriumIcon.svg'
import bisturiShearsIcon from './svgs/bisturiShearsIcon.svg'
import hospitalGurneyIcon from './svgs/hospitalGurneyIcon.svg'
import alertIcon from './svgs/alertIcon.svg'
import cigarIcon from './svgs/cigarIcon.svg'
import bottleIcon from './svgs/bottleIcon.svg'
import personPlusIcon from './svgs/personPlusIcon.svg'
import exclamationMarkIcon from './svgs/exclamationMarkIcon.svg'
import dermaIcon from './svgs/derma_icon_background.svg'
import psicologyIcon from './svgs/psicology_icon_background.svg'
import nutririonIcon from './svgs/nutririon_icon_background.svg'
import generalDoctorIcon from './svgs/general_doctor_icon_background.svg'
import logoHorizontal from './svgs/logo-horizontal- .svg'
import logoHorizontalBeginning from './svgs/logo_horizontal_beginning.svg'
import arrowDown from './svgs/arrow_down.svg'
import right_arrow from './svgs/right_arrow.svg'
import googleIcon from './svgs/google_icon.svg'
import facebookIcon from './svgs/facebook_icon.svg'
import phoneIcon from './svgs/phone_icon.svg'
import imgIcon from './svgs/img-icon.svg'
import comentIcon from './svgs/coment_Icon.svg'
import doctorIcon from './svgs/doctor_icon.svg'
import HomeIconSubNav from './svgs/home_icon_sub_nav.svg'
import camera_icon_sub_nav from './svgs/camera_icon_sub_nav.svg'
import startIconCalcification from './svgs/start_calcification.svg'
import peopleIcon from './svgs/people_icon.svg'
import arrowComentsIcon from './svgs/arrow_ comments.svg'
import plusIconModal from './svgs/plus_icon_modal.svg'
import closeIcon from './svgs/close_icon.svg'
import warrantyIcon from './images/warranty-icon.png'
import cardVerifyIconWhite from './svgs/card_verify_icon_white.svg'
import startFiveIcon from './svgs/start_five_icon.svg'
import startsFourIcon from './svgs/starts_icon_four.svg'
import startsThreeIcon from './svgs/starts_three_icon.svg'
import startsTwoIcon from './svgs/starts_two_icon.svg'
import startsOneIcon from './svgs/starts_one_icon.svg'
import arrowNext from './svgs/arrow_next.svg'
import arrowBack from './svgs/arrow_back.svg'
import arrrowDouble from './svgs/double_arrow.svg'
import promotionsIcon from './svgs/promotions.svg'
export default {
  // icons
  Comment,
  Score,
  arrowIcon,
  live,

  groupOfPeopleAnnouncing,
  calendarIcon,
  noteIcon,
  imgIcon,
  cameraIcon,
  productosDiaryTransparent,
  scheduleTransparent,
  cleanFace,
  medicineTransparentGray,
  user_default,
  warranty,
  userDefaultDoctor,
  // svgs
  arrrowDouble,
  startFiveIcon,
  closeIcon,
  plusIconModal,
  arrowComentsIcon,
  peopleIcon,
  startIconCalcification,
  camera_icon_sub_nav,
  HomeIconSubNav,
  doctorIcon,
  comentIcon,
  phoneIcon,
  facebookIcon,
  googleIcon,
  right_arrow,
  arrowDown,
  logoHorizontalBeginning,
  logoHorizontal,
  generalDoctorIcon,
  nutririonIcon,
  psicologyIcon,
  dermaIcon,
  exclamationMarkIcon,
  personPlusIcon,
  bottleIcon,
  cigarIcon,
  alertIcon,
  hospitalGurneyIcon,
  bisturiShearsIcon,
  bacteriumIcon,
  personIcon,
  dropsBloodIcon,
  personWithLaptop,
  cuponIcon,
  personWithlaptopPaque,
  homeIconPink,
  iconBoxTransparente,
  iconTrunkTransparent,
  iconShedule,
  checkIcon,
  pictureIcon,
  speiIcon,
  paynet,
  oxxoIcon,
  paypalIcon,
  cardIcon,
  visaIcon,
  truckIcon,
  homeIcon,
  medicineIcon,
  directionsIcon,
  proceedingsIcon,
  walletIcon,
  camera_icon,
  DoctorWoman,
  sections_notification,
  microfono,
  chat,
  Hamburger,
  Heart,
  Home,
  Sessions,
  Doctors,
  DoctorsNotification,
  Notifications,
  AcnePimple,
  RoundArrow,
  MagnifyingGlassOnDocument,
  Medicine,
  Video,
  Microphone,
  SingleCheck,
  policy,
  Billing,
  Close,
  Config,
  Cuppons,
  Dairy,
  Favorites,
  Personal,
  Pharma,
  Questions,
  Shopping,
  Treatment,
  Wa,
  mastercard,
  americanExpress,
  usa,
  mx,
  co,
  HoursService,
  LiveService,
  userVerifyWhite,
  warrantyIcon,
  cardVerifyIconWhite,
  startsFourIcon,
  startsThreeIcon,
  startsTwoIcon,
  startsOneIcon,
  arrowNext,
  arrowBack,
  CamaraOff,
  CamaraOn,
  MicOff,
  MicOn,
  promotionsIcon,
}
