import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tittle, TextCustom, Button } from '../../components'

const SpecialtyService = ({
  typeConsult,
  type,
  setType,
  getQuestionnaires,
  increment,
  addError,
  removeError,
  errors,
  selectedCategories,
}) => {
  const [categories, setCategories] = useState([])
  const handlePractiService = e => {
    e.preventDefault()
    if (type === '') {
      addError('Por favor Selecciona una opcion')
      return false
    }
    removeError()
    handleGetQuestionnaires(type).then()
    increment()
  }

  const handleGetQuestionnaires = async type => {
    await getQuestionnaires(type)
  }

  const handleOnType = type => {
    setType(type)
  }
  const typeConsultToFunct =
    typeConsult === 'fast-service' ? 'live' : 'deferred'

  useEffect(() => {
    setCategories(selectedCategories(typeConsultToFunct))
  }, [])
  return (
    <>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start">
        <Tittle className="title is-size-5 are-color-mynd-grey ">
          Selecciona la especialidad a consultar
        </Tittle>
      </div>
      <div className="is-flex is-flex-direction-row is-justify-content-center mt-5">
        <TextCustom className="is-size-5">
          Para iniciar la consulta es importante indicar la
          <strong className="are-color-mynd-grey"> zona afectada </strong>
          tema para su evalucion
        </TextCustom>
      </div>
      {errors && <div className="notification is-danger mt-3">{errors}</div>}
      <form onSubmit={handlePractiService}>
        <div className="specialty-container">
          {categories.map(category => (
            <div
              key={category.key}
              className={`specialty-container-item ${
                type === category.key ? 'specialty-selected' : ''
              }`}
              onClick={() => handleOnType(category.key)}
            >
              <img src={category.image} width="100" />
              {category.description}
            </div>
          ))}
        </div>
        <div className="buttons is-centered">
          <Button
            className="button is-mnd-primary is-button-service has-text-white has-text-weight-bold is-size-6"
            type="submit"
          >
            Siguiente
          </Button>
        </div>
      </form>
    </>
  )
}

SpecialtyService.propTypes = {
  typeConsult: PropTypes.string.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func,
  getQuestionnaires: PropTypes.func.isRequired,
  increment: PropTypes.func,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  errors: PropTypes.string,
  selectedCategories: PropTypes.func,
}

export default SpecialtyService
