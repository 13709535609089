import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import selectors from './selectors'
import dispatchers from './dispatchers'
import { Myshopping, PurchaseDetail } from '../../components'
const Shopping = () => {
  const dispatch = useDispatch()
  const { getAllOrders } = dispatchers(dispatch)
  const { auth, orderDrugs } = useSelector(state => state)
  const { uid, orders, isLoading } = selectors(auth, orderDrugs)
  const [selectedOrder, setSelectedOrder] = useState({})
  const handleSelectOrder = order => {
    setSelectedOrder(order)
  }
  const handleGoBack = () => {
    setSelectedOrder({})
  }
  return (
    <>
      {Object.keys(selectedOrder).length === 0 ? (
        <div className="container mb-5  is-size-container-shoping">
          {isLoading && (
            <div className="waitng-amount">
              <div className="spinner" />
            </div>
          )}
          {!isLoading && (
            <Myshopping
              getAllOrders={getAllOrders}
              userId={uid}
              orders={orders}
              isLoading={isLoading}
              handleSelectOrder={handleSelectOrder}
            />
          )}
        </div>
      ) : (
        <div className="container mt-5 mb-5">
          <PurchaseDetail
            selectedOrder={selectedOrder}
            handleGoBack={handleGoBack}
          />
        </div>
      )}
    </>
  )
}

export default Shopping
