import moment from 'moment'

export default (auth, consult, doctors, coupons, ui) => {
  const { currentConsult } = consult
  const {
    id: idDoctor,
    answers: questionnaire,
    evidences: images,
    consult: typeConsult,
    selectedDate: date,
    type: typeCategory,
  } = currentConsult
  const { errors } = ui
  const { data: couponList } = coupons
  let selectedDate
  let selectedHour
  let dateConsult
  let fullDate
  if (date) {
    selectedDate = date.selectedDate
    selectedHour = date.selectedHour
    dateConsult = moment(selectedDate).locale('es').format('DD/MMMM')
    fullDate = moment(selectedDate).format('YYYY-MM-DD')
    const fullTime = selectedHour.split(' ')
    const hours = fullTime[0].split(':')
    if (fullTime[1] === 'pm') {
      hours[0] = parseInt(hours[0]) + 12
    }
    fullDate = `${fullDate} ${hours[0]}:${hours[1]}`
  }

  const { data: doctorData } = doctors
  const doctor = doctorData.find(d => d.id === idDoctor)
  const { fullname, photoURL, career } = doctor || {}
  const { user } = auth
  const { specialty, privateFlag } = user.data
  const { data: userData } = user
  const cards = userData.cards ? userData.cards : []
  const email = userData.email ?? ''
  const balance = userData.balance ? userData.balance : 0

  return {
    cards,
    couponList,
    balance,
    fullname,
    errors,
    photoURL,
    career,
    dateConsult,
    selectedHour,
    questionnaire,
    images,
    typeConsult,
    fullDate,
    email,
    typeCategory,
    specialty: specialty ?? 'derma',
    privateFlag,
  }
}
