// import PropTypes from 'prop-types'
import { TextCustom } from '..'

const infoSectionList = ({ title, list }) => {
  return (
    <div>
      <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mt-2">
        {title}
      </TextCustom>
      {list.map((item, key) => (
        <TextCustom key={key}>{item}</TextCustom>
      ))}
    </div>
  )
}

infoSectionList.propTypes = {}

export default infoSectionList
