import { useSelector, useDispatch } from 'react-redux'
import { DoctorProfile } from '../../components'
// hooks
import { useRouter } from '../../utils'
import selectors from './selectors'
import dispatchers from './dispatchers'

const DoctorProfileScreen = () => {
  const dispatch = useDispatch()
  const { getDeferredPrice, getCategoriesForSpecialty } = dispatchers(dispatch)
  const { doctors, auth } = useSelector(state => state)

  const { query } = useRouter()
  const { id } = query
  const {
    name,
    photo,
    career,
    profesionalIds,
    awards,
    lastJob,
    specialty,
    extraInfo,
    consult_tracker,
    uid,
    reviewsCount,
    reviews,
    score,
    about,
  } = selectors(auth, doctors, id)

  return (
    <div className="container is-margin-container mt-5">
      <DoctorProfile
        uid={uid}
        consult_tracker={consult_tracker}
        getCategoriesForSpecialty={getCategoriesForSpecialty}
        getDeferredPrice={getDeferredPrice}
        fullname={name}
        photoURL={photo}
        career={career}
        profesionalIds={profesionalIds}
        awards={awards}
        lastJob={lastJob}
        specialty={specialty}
        extraInfo={extraInfo}
        reviewsCount={reviewsCount}
        reviews={reviews}
        score={score}
        about={about}
      />
    </div>
  )
}

DoctorProfileScreen.propTypes = {}

export default DoctorProfileScreen
