import PropTypes from 'prop-types'

// components
import {
  ImageCustom,
  LabelCustom,
  Input,
  InputSelectCustom,
} from '../../../components'
import { divisas } from '../../../utils/constants'

// img
import Images from '../../../assets'
const coinAndMoney = ({ handleInputChange }) => {
  const { calendarIcon } = Images
  const divisaslist = Object.values(divisas)
  return (
    <div className="box  mx-3 is-flex">
      <span className="icon mr-2">
        <ImageCustom img={calendarIcon} />
      </span>
      <div className="is-size-field-currency mr-3">
        <LabelCustom className="label are-color-mynd-grey">Moneda</LabelCustom>
        <div className="select is-fullwidth ">
          <InputSelectCustom
            className="are-color-text is-large is-select-size"
            name="badge"
            handleInputChange={handleInputChange}
          >
            {divisaslist}
          </InputSelectCustom>
        </div>
      </div>
      <div className="is-size-field-currency">
        <LabelCustom className="label are-color-mynd-grey">Precio</LabelCustom>

        <Input
          className="input is-select-size is-fullwidth"
          type="number"
          name={'price'}
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

coinAndMoney.propTypes = { handleInputChange: PropTypes.func }

export default coinAndMoney
