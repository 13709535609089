import PropTypes from 'prop-types'

// img
import Images from '../../../assets'

// components
import { ImageCustom, LabelCustom, Input } from '../../../components'

const BrandoftheProductField = ({ handleInputChange }) => {
  const { tagProductIcon } = Images
  return (
    <div className="box  mx-3 is-flex">
      <span className="icon mr-2">
        <ImageCustom img={tagProductIcon} />
      </span>
      <div className="is-size-field-nane-product">
        <LabelCustom className="label are-color-mynd-grey">
          Marca del producto
        </LabelCustom>
        <Input
          className="input is-select-size is-fullwidth"
          type="text"
          placeholder="Marca del producto"
          name="brand"
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

BrandoftheProductField.propTypes = { handleInputChange: PropTypes.func }

export default BrandoftheProductField
