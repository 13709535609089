import { functions } from '../../config'
import moment from 'moment'

const sendSMS = async phone => {
  try {
    const time = new Date('01/01/1999')
    if (moment(time).isBefore(moment.now())) {
      const verificationCode = functions.httpsCallable('usuarioSendCode')
      verificationCode({ phone: phone }).then(result => {
        if (result.data.now) {
          console.log(result)
        } else {
          console.log(result.data.error)
        }
      })
    }
  } catch (error) {
    return error
  }
}

export default sendSMS
