// import PropTypes from 'prop-types'

import { TextCustom } from '..'

const datingDateBox = props => {
  return (
    <div className="box is-flex is-justify-content-space-between	">
      <div>
        <TextCustom className="is-size-5 has-text-weight-bold">
          Lun,28
        </TextCustom>
        <TextCustom className="is-size-5 ">4:00 PM</TextCustom>
      </div>
      <div>
        <TextCustom className="has-text-weight-bold">
          Sesión 6: Seguimiento
        </TextCustom>
        <TextCustom>Siguiente cita</TextCustom>
      </div>

      <TextCustom className="is-colour-number-sections-next has-text-weight-bold">
        05
      </TextCustom>
    </div>
  )
}

datingDateBox.propTypes = {}

export default datingDateBox
