import PropTypes from 'prop-types'

import { ButtonCustom, ModalQuey, TitleCustom } from '..'
import { useToggle } from '../../utils'

const MakeYourInquiryBox = ({ isEmpty }) => {
  const [toggleModal, setToggleModal] = useToggle()

  return (
    <div className={`column ${isEmpty ? 'no-flex-grow' : ''}`}>
      <TitleCustom className="are-color-mynd-grey is-title-size ">
        Realiza tu consulta
      </TitleCustom>
      <ButtonCustom
        buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary"
        handleOnClick={setToggleModal}
      >
        Agendar cita
      </ButtonCustom>
      {toggleModal && <ModalQuey closeModal={setToggleModal} isRoute />}
    </div>
  )
}

MakeYourInquiryBox.propTypes = { isEmpty: PropTypes.bool }

export default MakeYourInquiryBox
