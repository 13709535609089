import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { storageRef } from '../../config'

const consultImages = ({ images, id }) => {
  const [imagesList, setImagesList] = useState([])

  useEffect(() => {
    const temp = []
    images.map(async (img, index) => {
      const imgRef = storageRef.child(`consults/${id}/${img.url}`)
      const tempImg = await imgRef.getDownloadURL().then(imageUrl => imageUrl)
      temp.push(tempImg)
      if (images.length - 1 === index) {
        setImagesList(temp)
      }
    })
  }, [images, id])

  return (
    <header className="consults-container-header drug-box">
      <div className="m-5">
        <div className="diary-card-images-container-wrap">
          {imagesList.map((item, index) => (
            <div key={index} className="diary-card-image-wrap">
              <img src={item} />
            </div>
          ))}
        </div>
      </div>
    </header>
  )
}

consultImages.propTypes = {
  images: PropTypes.any,
  id: PropTypes.string,
}

export default consultImages
