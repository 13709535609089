import functions from '../functions'

const { createOrderPaypalBalanceRechargeFunction } = functions.cloudFunctions

const createOrderPaypalBalanceRecharge = async data => {
  try {
    return await createOrderPaypalBalanceRechargeFunction(data)
  } catch (error) {
    console.log('error', error)
  }
}

export default createOrderPaypalBalanceRecharge
