import { useEffect } from 'react'

const switchButton = () => {
  const switchTheme = e => {
    const htmlElement = document.documentElement
    if (e.target.checked) {
      htmlElement.setAttribute('theme', 'dark')
      localStorage.setItem('theme', 'dark')
    } else {
      htmlElement.setAttribute('theme', 'light')
      localStorage.setItem('theme', 'light')
    }
  }

  useEffect(() => {
    const toggleSwitch = document.querySelector('#temaCheckbox')
    const currentTheme = localStorage.getItem('theme')

    if (currentTheme) {
      // añadimos o cambiamos la etiqueta de la clase html al tema recuperado
      const htmlElement = document.documentElement
      htmlElement.setAttribute('theme', currentTheme)

      // Si es el tema oscuro lo marcamos
      if (currentTheme === 'dark') {
        toggleSwitch.checked = true
      }
    }

    toggleSwitch.addEventListener('change', switchTheme, false)

    return () => {
      toggleSwitch.removeEventListener('change', switchTheme, false)
    }
  }, [])

  return (
    <label className="switch">
      <input type="checkbox" id="temaCheckbox" />
      <span className="slider1 round"></span>
    </label>
  )
}

export default switchButton
