import { blogActions } from '../../actions'
const { addArticleAction } = blogActions.creators

const saveArticle = ({
  savedArticles,
  categoryOfInterest,
  blogId,
  category,
}) => {
  return async dispatch => {
    const tempSavedArticles = [...savedArticles]
    const tempCategoryOfInterest = [...categoryOfInterest]

    tempSavedArticles.push(blogId)
    if (tempCategoryOfInterest.indexOf(category) === -1) {
      tempCategoryOfInterest.push(category)
    }

    dispatch(
      addArticleAction({
        savedArticles: tempSavedArticles,
        categoryOfInterest: tempCategoryOfInterest,
      }),
    )
  }
}

export default saveArticle
