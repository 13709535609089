import { uiActions } from '../../actions'

const initialState = {
  loading: false,
  errors: '',
  success: '',
}

const {
  ADD_ERROR,
  REMOVE_ERROR,
  ADD_SUCCESS,
  REMOVE_SUCCESS,
  START_LOADING,
  STOP_LOADING,
} = uiActions.types

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
    case REMOVE_ERROR:
      return {
        ...state,
        errors: '',
      }
    case START_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ADD_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case REMOVE_SUCCESS:
      return {
        ...state,
        success: '',
      }
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default uiReducer
