import { uiActions } from '../../actions'

const { addError, removeError } = uiActions.creators

export default dispatch => {
  return {
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
  }
}
