import PropTypes from 'prop-types'
import { ImageCustom, TextCustom } from '..'

const SessionDoctorInfo = ({ doctor }) => {
  const { photoURL, fullname, specialty } = doctor
  const specialtyText = {
    derma: 'Dermatológica',
    psychology: 'Psicológica',
    nutrition: 'Nutricional',
    medicine: 'General',
  }

  return (
    <div className="consult-card-details-doctor">
      <ImageCustom img={photoURL} className="image is-48x48 is-rounded" />
      <div className="consult-card-doctor-info">
        <TextCustom>{fullname}</TextCustom>
        <TextCustom>
          <span>Especialista: </span> {specialtyText[specialty || 'derma']}
        </TextCustom>
      </div>
    </div>
  )
}

SessionDoctorInfo.propTypes = {
  doctor: PropTypes.object,
}
export default SessionDoctorInfo
