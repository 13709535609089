import moment from 'moment'

const dateFormat = ({
  maxYearDifference = 0,
  maxMonthDifference = 0,
  maxDayDifference = 0,
  minYearDifference = 0,
  minMonthDifference = 0,
  minDayDifference = 0,
}) => {
  return {
    maxDate: moment()
      .subtract(maxYearDifference, 'years')
      .subtract(maxMonthDifference, 'months')
      .subtract(maxDayDifference, 'days')
      .format('YYYY-MM-DD'),
    minDate: moment()
      .subtract(minYearDifference, 'years')
      .subtract(minMonthDifference, 'months')
      .subtract(minDayDifference, 'days')
      .format('YYYY-MM-DD'),
  }
}

export default dateFormat
