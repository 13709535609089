import { useDispatch, useSelector } from 'react-redux'
// components
import {
  Header,
  Footer,
  LoginBox,
  Slider,
  ConfirmLinkAccount,
  ErrorNotification,
  Loading,
} from '../../components'
import dispatchers from './dispatchers'
import selectors from './selectors'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Images from '../../assets'

const LoginScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    signOut,
    signInWithEmail,
    signInWithProvider,
    fetchMethods,
    removeError,
    rememberMe,
    removeSingInError,
  } = dispatchers(dispatch)
  const { auth, ui } = useSelector(state => state)
  const {
    message,
    exist,
    email,
    credential,
    loading,
    sendResetPasswordEmailError,
  } = selectors(auth, ui)
  const { DoctorWoman } = Images

  const [loginWithEmailError, setLoginWithEmailError] = useState('')

  useEffect(() => {
    if (sendResetPasswordEmailError.code) {
      if (
        sendResetPasswordEmailError.code === 'auth/user-not-found' ||
        sendResetPasswordEmailError.code === 'auth/wrong-password'
      ) {
        setLoginWithEmailError('Usuario y/o contraseña no válidos.')
      } else if (
        sendResetPasswordEmailError.code === 'auth/too-many-requests'
      ) {
        setLoginWithEmailError('Servicio no disponible. Inténtalo más tarde.')
      }
      setTimeout(() => {
        removeSingInError()
        setLoginWithEmailError('')
      }, 3000)
    }
  }, [])

  useEffect(() => {
    rememberMe(false)
    removeError()
    return () => {}
  }, [])
  return (
    <>
      {loading ? (
        <Loading
          title="Espera un momento."
          message="Estamos enlazando tus cuentas."
        />
      ) : (
        <section className="hero is-fullheight">
          <Header />
          <div className="hero-body pt-4 pb-4">
            <div className="container login-auth">
              <div className="columns">
                <div className="column is-half">
                  <h4 className="subtitle is-3 has-text-centered is-color-size-title mt-6">
                    {t('titleSlider')}
                  </h4>
                  <Slider DoctorWoman={DoctorWoman} />
                </div>
                <div className="column is-one-third">
                  <div className="columns">
                    <div className="column">
                      {exist && (
                        <ConfirmLinkAccount
                          fetchMethods={fetchMethods}
                          credential={credential}
                          email={email}
                        />
                      )}
                      {!exist && message !== '' && (
                        <div>
                          <ErrorNotification message={message} />
                          <button
                            className="button is-primary"
                            onClick={signOut}
                          >
                            Regresar
                          </button>
                        </div>
                      )}

                      {!message && (
                        <LoginBox
                          loginWithEmailError={loginWithEmailError}
                          signInWithEmail={signInWithEmail}
                          signInWithProvider={signInWithProvider}
                          rememberMe={rememberMe}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
        </section>
      )}
    </>
  )
}

export default LoginScreen
