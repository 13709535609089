// components
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import selectors from '../../containers/DoctorScreen/selectors'
import { DoctorList, TitleCustom } from '../../components'
import dispatchers from './dispatchers'
import { usersF } from '../../config'

const Doctors = () => {
  const [doctorsList, setDoctorsList] = useState([])
  const state = useSelector(state => state)
  const { userId, specialty } = selectors(state)
  const dispatch = useDispatch()

  const { doctorList } = dispatchers(dispatch)
  const [favoriteDoctors, setFavoriteDoctors] = useState([])

  const getDoctorList = async () => {
    const list = await doctorList(specialty)
    setDoctorsList(list)
  }

  useEffect(() => {
    const unsuscribe = usersF.doc(userId).onSnapshot(result => {
      const { favorities } = result.data()
      setFavoriteDoctors(favorities)
    })

    return () => {
      unsuscribe()
    }
  }, [])

  useEffect(() => {
    getDoctorList()
    return () => {
      setDoctorsList([])
    }
  }, [])

  return (
    <div className="container is-margin-container mt-5">
      <div className="field is-centered search-container"></div>
      <TitleCustom className="has-text-centered are-color-mynd-grey is-size-title">
        Doctores
      </TitleCustom>
      <DoctorList doctors={doctorsList} idDocsFav={favoriteDoctors} />
    </div>
  )
}

Doctors.propTypes = {}

export default Doctors
