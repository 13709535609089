import functions from '../functions/index'
import { authActions } from '../../actions'

const {
  sendResetPasswordEmailAction,
  sendResetPasswordEmailSuccessAction,
  sendResetPasswordEmailFailureAction,
} = authActions.creators
const { generatePasswordResetLink, sendEmailPasswordReset } =
  functions.cloudFunctions

const forgotPasswordService = email => {
  return async dispatch => {
    try {
      dispatch(sendResetPasswordEmailAction())
      const { data } = await generatePasswordResetLink({ email })
      if (data.codePrefix) {
        dispatch(sendResetPasswordEmailFailureAction('Este correo no existe'))
      } else {
        const response = await sendEmailPasswordReset({
          email: email,
          name: data.name,
          link: data.link,
        })
        if (response) {
          dispatch(sendResetPasswordEmailSuccessAction())
        }
      }
    } catch (error) {
      dispatch(sendResetPasswordEmailFailureAction(error))
    }
  }
}

export default forgotPasswordService
