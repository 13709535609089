import {
  createDiaryService,
  uploadImagesService,
  updateDiaryService,
  updateProductService,
  createProductService,
  getProductService,
  getDiaryService,
} from '../../services'

export default dispatch => {
  return {
    createDiary: () => createDiaryService(),
    uploadImage: (image, collection, id) =>
      uploadImagesService(image, collection, id),
    updateDiary: (values, uid) => updateDiaryService(values, uid),
    createProduct: () => createProductService(),
    updateProduct: (values, uid) => updateProductService(values, uid),
    getProducts: uid => getProductService(uid),
    getDiary: uid => getDiaryService(uid),
  }
}
