import PropTypes from 'prop-types'

const Tagcustom = ({
  tagClassName,
  handleOnClick,
  isSelected,
  value,
  title,
  ...otherProps
}) => (
  <a
    className={`${tagClassName} ${
      isSelected === value ? 'tag-is-selected' : ''
    }`}
    onClick={() => handleOnClick(value)}
    {...otherProps}
  >
    {title}
  </a>
)

Tagcustom.propTypes = {
  tagClassName: PropTypes.string,
  handleOnClick: PropTypes.func,
  isSelected: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
}

export default Tagcustom
