import { functions } from '../../config'

const verifyCodeSMS = async (code, uid) => {
  try {
    const verificationCode = functions.httpsCallable('verificationCode')
    const response = verificationCode({
      code: code,
      id: uid,
    }).then(result => {
      if (result.data.verified) {
        return true
      } else if (result.data.error) {
        return false
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export default verifyCodeSMS
