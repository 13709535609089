import functions from '../functions'

const { setImages } = functions.cloudFunctions

const setImagesService = async data => {
  try {
    return await setImages(data)
  } catch (error) {
    return error
  }
}

export default setImagesService
