import { firestore, usersF } from '../../config'
import { consultActions } from '../../actions'
const {
  getAllPriceForConsultRequestAction,
  getAllPriceForConsultSuccessAction,
  getAllConsultsFailureAction,
} = consultActions.creators
const getDeferredPriceService = ({
  typeConsult,
  category,
  specialty,
  privateFlag = false,
  doctorId = null,
}) => {
  return async dispatch => {
    try {
      dispatch(getAllPriceForConsultRequestAction())
      if (privateFlag) {
        const price = await usersF
          .doc(doctorId)
          .get()
          .then(result => result.data().price)
        return price
      } else {
        const deferredPrices = await firestore
          .collection('configuration')
          .doc('deferred_pricing')
          .get()
        const livePrices = await firestore
          .collection('configuration')
          .doc('pricing')
          .get()
        dispatch(
          getAllPriceForConsultSuccessAction({
            deferred: deferredPrices.data(),
            live: livePrices.data(),
          }),
        )
        const prices = {
          deferred: deferredPrices.data(),
          live: livePrices.data(),
        }
        const typeCategoryToIndex = {
          acne: 'priceDermaAcne',
          std: 'priceDermaETS',
          skincare: 'priceDermaSkincare',
          medicine: 'priceGeneralMedicine',
          nutrition: 'priceNutrition',
          psychology: 'pricePsychology',
        }
        const typeConsultToRedux =
          typeConsult === 'practi-service' ? 'deferred' : 'live'
        if (!specialty || specialty === 'derma') {
          const indexCategory = typeCategoryToIndex[category] || 'price'
          return prices[typeConsultToRedux][indexCategory]
        } else {
          const indexCategory = typeCategoryToIndex[specialty] || 'price'
          return prices[typeConsultToRedux][indexCategory]
        }
      }
    } catch (error) {
      dispatch(getAllConsultsFailureAction(error))
    }
  }
}

export default getDeferredPriceService
