import {
  AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL,
  PASSWORD,
  AUTH_CLOSE_POPUP_BY_USER,
  AUTH_USER_CANCELLED,
} from '../../utils/constants'
import firebase from 'firebase'
import getProvider from '../../utils/handles/providers'

const getMethods = async (error, provider) => {
  const { credential, email } = error
  const methods = await firebase.auth().fetchSignInMethodsForEmail(email)
  await new Promise(resolve => setTimeout(resolve, 500))
  if (methods[0] === PASSWORD) {
    return {
      credential,
      email,
      message: AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL,
    }
  }
  const { user } = await getProvider(methods[0])
  if (user) {
    const response = await user.linkWithCredential(credential)
    if (response?.error) {
      return { code: response?.error }
    }
  }
}

const handleErrors = async (error, provider) => {
  const { code } = error
  if (code === AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL) {
    const response = await getMethods(error, provider)
    return response
  }
  if (code === AUTH_CLOSE_POPUP_BY_USER || code === AUTH_USER_CANCELLED) {
    return { message: 'El usuario ha cancelado la autenticación' }
  } else return { message: 'error' }
}

export default handleErrors
