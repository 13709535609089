import { usersF } from '../../config'
import { authActions } from '../../actions'

const { signUpSuccessAction } = authActions.creators

const updateBillingInfo = (data, uid) => {
  return async dispatch => {
    try {
      await usersF.doc(uid).set(data, { merge: true })
      const user = await usersF.doc(uid).get()
      const userData = user.data()
      userData.cards = userData.cards || []
      dispatch(signUpSuccessAction(userData))
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export default updateBillingInfo
