import PropTypes from 'prop-types'
import { DiaryWithoutProducts, MyProducts } from '../../components/'

const ProductsContainer = ({
  productsList,
  increment,
  setProductsSelected,
  productsSelected,
}) => {
  return (
    <>
      {productsList ? (
        <MyProducts
          increment={increment}
          productsList={productsList}
          setProductsSelected={setProductsSelected}
          productsSelected={productsSelected}
        />
      ) : (
        <DiaryWithoutProducts increment={increment} />
      )}
    </>
  )
}

ProductsContainer.propTypes = {
  increment: PropTypes.func,
  productsList: PropTypes.array,
  setProductsSelected: PropTypes.func,
  productsSelected: PropTypes.array,
}

export default ProductsContainer
