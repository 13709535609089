// import PropTypes from 'prop-types'
import moment from 'moment'
import { TextCustom, ImageCustom } from '..'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const cardTicket = ({ tiket }) => {
  const { property, created, uri } = tiket
  moment.locale('es')
  const hoursdate = created._seconds
  const hoursdateFormat = moment(hoursdate * 1000).format('L')
  return (
    <AccordionItem className="box mx-4 mb-4 is-size-box-ticket">
      <AccordionItemHeading>
        <AccordionItemButton className="is-flex">
          <div>
            <TextCustom className="has-text-weight-semibold is-size-6">
              Establecimiento: {property}
            </TextCustom>
            <TextCustom className="has-text-weight-semibold is-size-6">
              Fecha: {hoursdateFormat}
            </TextCustom>
          </div>
          <div className="ml-6">
            {' '}
            <span className="icon">
              <i className="fas fa-plus is-color-plus-ticket is-size-5"></i>
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {uri !== null && (
          <ImageCustom
            img={uri}
            className="is-size-img-ticket-accordion mt-3"
          />
        )}
      </AccordionItemPanel>
    </AccordionItem>
  )
}

cardTicket.propTypes = {}

export default cardTicket
