import PropTypes from 'prop-types'
import { ButtonCustom, PaymentType, Paypal, TextCustom } from '../../components'
import Images from '../../assets'

const OtherPaymentMethod = ({
  createOrder,
  handleCreateOrder,
  setPaymentMethod,
  next,
  amount,
  paymentMethod,
  showPaypal,
  setShowPaypal,
}) => {
  const { paynet, paypalIcon, oxxoIcon, speiIcon } = Images
  const onClickBack = () => {
    setShowPaypal(false)
  }

  const onClick = async ({ method, details }) => {
    if (method === 'PayPal') {
      setPaymentMethod({})
      setShowPaypal(true)
    } else {
      const paymentMethod = {
        method,
        details,
      }
      setPaymentMethod(paymentMethod)
      next()
    }
  }

  return (
    <div className="white-box">
      <TextCustom className="are-color-mynd-grey has-text-weight-semibold mb-0 pb-0">
        Otras opciones de pago:
      </TextCustom>
      {showPaypal ? (
        <div>
          <hr className="line-bottom my-2"></hr>
          <Paypal
            handleCreateOrder={() => {
              setShowPaypal(false)
              handleCreateOrder()
            }}
            createOrder={createOrder}
            amount={amount}
          />
          <div className="orders buttons">
            <div className="column is-3">
              <ButtonCustom
                buttonClassName="button is-text-color is-inverted px-0 level-item"
                onClick={onClickBack}
              >
                Anterior
              </ButtonCustom>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <PaymentType
            image={paypalIcon}
            value={{ method: 'PayPal' }}
            onClick={onClick}
          />
          <PaymentType
            image={oxxoIcon}
            isSelected={paymentMethod && paymentMethod.details === 'oxxo'}
            value={{ method: 'alternative', details: 'oxxo' }}
            onClick={onClick}
          />
          <PaymentType
            image={paynet}
            isSelected={paymentMethod && paymentMethod.details === 'paynet'}
            value={{ method: 'alternative', details: 'paynet' }}
            onClick={onClick}
          />
          <PaymentType
            image={speiIcon}
            isSelected={paymentMethod && paymentMethod.details === 'spei'}
            value={{ method: 'alternative', details: 'spei' }}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  )
}

OtherPaymentMethod.propTypes = {
  next: PropTypes.func,
  setPaymentMethod: PropTypes.func,
  createOrder: PropTypes.func,
  handleCreateOrder: PropTypes.func,
  amount: PropTypes.string,
  paymentMethod: PropTypes.object,
  showPaypal: PropTypes.bool,
  setShowPaypal: PropTypes.func,
}
export default OtherPaymentMethod
