// import PropTypes from 'prop-types'

import { CardTicket, TextCustom } from '..'
import { Accordion } from 'react-accessible-accordion'

const ticketsContainer = ({
  tikets,
  modalState,
  toggleModal,
  setModalState,
  loading,
}) => {
  console.log()
  return (
    <>
      {loading && (
        <div className="waitng-amount">
          <div className="spinner" />
        </div>
      )}
      {!loading && (
        <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
          {tikets.length === 0 ? (
            <TextCustom className="has-text-centered is-size-4 mt-4">
              No se ha registrado ningun ticket
            </TextCustom>
          ) : (
            tikets.map((tiket, key) => {
              return (
                <>
                  <Accordion
                    allowZeroExpanded
                    className="is-size-box-ticket mt-3"
                  >
                    <CardTicket
                      key={key}
                      tiket={tiket}
                      modalState={modalState}
                      toggleModal={toggleModal}
                      setModalState={setModalState}
                    />
                  </Accordion>
                </>
              )
            })
          )}
        </div>
      )}
    </>
  )
}

ticketsContainer.propTypes = {}

export default ticketsContainer
