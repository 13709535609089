import PropTypes from 'prop-types'

// components
import { TextCustom, TitleCustom, ImageCustom } from '..'

import Images from '../../assets'

const { doctorDummy } = Images
function LastAppointmentBox({ increment, lastSession }) {
  const { doctor, generalNotes } = lastSession
  return (
    <>
      <TitleCustom className="are-color-mynd-grey is-title-size mt-4">
        Tu última cita
      </TitleCustom>
      <div className="box is-box-container-percentage-treatment is-flex">
        <div className="mr-5 is-size-box-doctor-last-appointment is-align-self-center mt-2">
          <ImageCustom
            img={(doctor && doctor.photoURL) || doctorDummy}
            className="is-size-img-doctor-treatment"
          />
          <TextCustom className="is-size-5 has-text-weight-semibold is-text-doctor-last-appointment mt-2 has-text-centered">
            {(doctor && doctor.fullname) || ''}
          </TextCustom>
        </div>

        <div>
          <TextCustom className="is-size-5 has-text-weight-semibold">
            Indicaciones:
          </TextCustom>
          {generalNotes ? (
            <>
              <TextCustom>
                <span className="has-text-weight-bold">
                  Hidrata la piel cada día:
                </span>{' '}
                Una crema hidratante puede ser de gran ayuda para evitar la
                sequedad de la piel. Es importante aplicar estas cremas después
                del lavado, con la piel todavía húmeda, para obtener una mayor
                absorción y que la hidratación funcione de maravilla.
              </TextCustom>
              <TextCustom className="mt-5">
                <span className="has-text-weight-bold">
                  Haz una limpieza suave:
                </span>{' '}
                Mantener el equilibrio de la piel y no atacar a la misma es muy
                importante, por eso se recomienda utilizar desmaquillantes
                fisiológicos
              </TextCustom>

              <TextCustom className="has-text-right	mt-2">
                <a
                  className="is-colour-text-see-more"
                  onClick={() => increment()}
                >
                  Ver más
                </a>{' '}
              </TextCustom>
            </>
          ) : (
            <TextCustom>
              <span className="has-text-weight-bold">
                El doctor no ha añadido ninguna nota para esta consulta.
              </span>
            </TextCustom>
          )}
        </div>
      </div>
    </>
  )
}

LastAppointmentBox.propTypes = {
  increment: PropTypes.func,
  lastSession: PropTypes.object,
}

export default LastAppointmentBox
