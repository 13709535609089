import React from 'react'
import { TagCustom } from '../../components'

const lateralBarConsults = ({ statusList, handleOnClickStatusList }) => {
  return (
    <>
      <div className="columns list-items">
        <TagCustom
          tagClassName="has-text-weight-bold mb-5"
          isSelected={statusList}
          title="En llamada"
          value="onCall"
          handleOnClick={handleOnClickStatusList}
        />
        <TagCustom
          tagClassName="has-text-weight-bold mb-5"
          isSelected={statusList}
          title="Por realizar"
          value="scheduled"
          handleOnClick={handleOnClickStatusList}
        />
      </div>
      <div className="columns list-items">
        <TagCustom
          tagClassName="has-text-weight-bold mb-5"
          isSelected={statusList}
          title="Terminadas"
          value="close"
          handleOnClick={handleOnClickStatusList}
        />
        <TagCustom
          tagClassName="has-text-weight-bold mb-5"
          isSelected={statusList}
          title="Canceladas"
          value="cancelled"
          handleOnClick={handleOnClickStatusList}
        />
      </div>
    </>
  )
}
export default lateralBarConsults
