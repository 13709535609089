import { usersF } from '../../config'

const validateDoctorCode = async code => {
  const doctorSnapshot = await usersF.where('privateCode', '==', code).get()
  if (doctorSnapshot.docs.length > 0) {
    return {
      msg: `Este código particular es del dermatologo ${
        doctorSnapshot.docs[0].data().fullname
      } esta seguro de continuar`,
      id: doctorSnapshot.docs[0].id,
      success: true,
    }
  } else {
    return { msg: 'Este código particular no existe', success: false }
  }
}

export default validateDoctorCode
