// import PropTypes from 'prop-types'

function TreatmentVideoBox(props) {
  return (
    <>
      <div className="box is-border-box-indications mt-4">
        <a
          href="https://www.youtube.com/watch?v=DVMyS9ZzoNA"
          className="is-colour-link"
        >
          https://www.youtube.com/watch?v=DVMyS9ZzoNA
        </a>
        <span>
          <i className="fas fa-copy is-colour-icon-copy"></i>
        </span>
        <iframe
          className="is-size-video-treatment mt-3"
          src="https://www.youtube.com/embed/DVMyS9ZzoNA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </>
  )
}

TreatmentVideoBox.propTypes = {}

export default TreatmentVideoBox
