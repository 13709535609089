// import PropTypes from 'prop-types'

import { TextCustom, ImageCustom } from '..'

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import ProductWhite from '../../assets/ProductWhiteackground.png'
const AccordionProductavailAble = props => {
  return (
    <AccordionItem className="box is-border-box-indications ">
      <AccordionItemHeading>
        <AccordionItemButton>
          <article className="media">
            <div className="media-left is-img-size-product-recipe">
              <ImageCustom img={ProductWhite} />
            </div>
            <div className="media-content">
              <TextCustom className="is-size-title-doctor pb-0 mb-0">
                Kelual D.S Shampoo{' '}
                <span>
                  <i className="fas fa-check-circle is-colour-icon-check"></i>
                </span>
              </TextCustom>
              <span className="tag is-rounded is-background-tag mt-2">
                Disponible
              </span>
              <TextCustom className="is-text-use-medicine mt-2">
                Uso:
                <span className="has-text-weight-light"> Cada 45 Horas</span>
              </TextCustom>
              <TextCustom className="is-text-use-medicine mt-2">
                Durante:
                <span className="has-text-weight-light"> 6 semanas</span>
              </TextCustom>
              <div className="is-flex">
                <TextCustom className="is-colour-text-price-medice-recipe mt-2">
                  $ 5,500 c/u
                </TextCustom>
                <TextCustom className="is-colour-text-see-more-recipe mt-3 ml-5 is-clickable">
                  Ver más{' '}
                  <span>
                    <i className="fas fa-angle-down"></i>
                  </span>
                </TextCustom>{' '}
              </div>
            </div>
          </article>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <TextCustom className="has-text-weight-bold mt-3">
          Modo de uso:
        </TextCustom>
        <TextCustom className="mt-2">
          Moje el cabello completamente, aplique el shampoo generosamente, luego
          enjuague completamente. Usar cada 3 a 4 días hasta por 8 semanas,
          según lo indicado por el doctor. Luego úselo solamente según sea
          necesario para controlar la caspa.
        </TextCustom>
      </AccordionItemPanel>
    </AccordionItem>
  )
}

AccordionProductavailAble.propTypes = {}

export default AccordionProductavailAble
