const CREATE_DOCTORS_REQUEST = 'CREATE_DOCTORS_REQUEST'
const CREATE_DOCTORS_SUCCESS = 'CREATE_DOCTORS_SUCCESS'
const CREATE_DOCTORS_FAILURE = 'CREATE_DOCTORS_FAILURE'

const CLEAR_DOCTORS = 'CLEAR_DOCTORS'

const createDoctorsRequestAction = () => ({
  type: CREATE_DOCTORS_REQUEST,
})

const createDoctorsSuccessAction = (payload = {}) => ({
  type: CREATE_DOCTORS_SUCCESS,
  payload,
})

const createDoctorsFailureAction = (payload = {}) => ({
  type: CREATE_DOCTORS_FAILURE,
  payload,
})

const clearDoctorsAction = () => ({
  type: CLEAR_DOCTORS,
})

export default {
  types: {
    CREATE_DOCTORS_REQUEST,
    CREATE_DOCTORS_SUCCESS,
    CREATE_DOCTORS_FAILURE,
    CLEAR_DOCTORS,
  },
  creators: {
    createDoctorsRequestAction,
    createDoctorsSuccessAction,
    createDoctorsFailureAction,
    clearDoctorsAction,
  },
}
