import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import selectors from './selectors.js'
import dispatchers from './dispatchers'

// components
import {
  TitleCustom,
  ButtonCustom,
  ContainerForm,
  PrivateCodeInput,
  SpecialitySelecter,
  ModalDeleteDoctor,
} from '..'

const personalInformation = () => {
  const dispatch = useDispatch()
  const { removeUserCode, addError, removeError, updateUserProfile } =
    dispatchers(dispatch)
  const { auth, ui } = useSelector(state => state)
  const { privateFlag, uid, user, loading } = selectors(auth, ui)

  const [modalState, setModalState] = useState(false)

  const toggleModal = () => {
    setModalState(false)
  }

  useEffect(() => {
    return () => {
      removeError()
    }
  }, [])

  const handleUpdateProfile = (fullname, data) => {
    if (fullname === '') {
      return addError('El nombre no puede estar vacio')
    }
    if (!data.country.trim()) {
      return addError('Ingrese un país')
    }
    if (!data.state.trim()) {
      return addError('Ingrese un estado')
    }
    removeError()
    const location = { fullname, location: { ...data } }
    updateUserProfile(location, uid)
  }

  const deletePrivateCode = () => {
    toggleModal()
    removeUserCode(uid)
  }

  return (
    <>
      <div className="container mt-5 ">
        <TitleCustom className="are-color-mynd-grey is-title-size has-text-centered">
          Datos personales
        </TitleCustom>
        <div className="columns  is-centered">
          <div className="column is-box-personal-information">
            {!loading ? (
              <ContainerForm
                handleUpdateProfile={handleUpdateProfile}
                data={user.data}
              />
            ) : (
              <div className="is-fullwidth is-centered columns">
                <div className="loader is-loading is-fullwidth my-5" />
              </div>
            )}
            {privateFlag ? (
              <div className="column field">
                <ButtonCustom
                  buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-address"
                  onClick={() => setModalState(true)}
                >
                  Remover mi doctor privado
                </ButtonCustom>
              </div>
            ) : (
              <div className="rows">
                <label className="label are-color-mynd-grey">
                  Código de doctor particular
                </label>
                <PrivateCodeInput />
              </div>
            )}
            {!privateFlag && (
              <div className="rows">
                <label className="label are-color-mynd-grey">
                  Especialidad
                </label>
                <SpecialitySelecter />
              </div>
            )}
          </div>
        </div>
      </div>
      {modalState && (
        <ModalDeleteDoctor
          closeModal={toggleModal}
          onPress={deletePrivateCode}
        />
      )}
    </>
  )
}

personalInformation.propTypes = {}

export default personalInformation
