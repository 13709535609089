import { phoneAuthProvider } from '../../config'

const verifyPhone = async (phone, applicationVerifier) => {
  try {
    const response = await phoneAuthProvider.verifyPhoneNumber(
      phone,
      applicationVerifier,
    )
    return { token: response }
  } catch (error) {
    return { error }
  }
}
export default verifyPhone
