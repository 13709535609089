import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// componentes
import { TextCustom, ButtonCustom, TreatmentCards } from '..'

const AllTreatmentCards = ({
  treatmentList,
  currentSession,
  treatmentConfig,
  reset,
}) => {
  const { totalConsults } = treatmentConfig
  const [treatment, setTreatment] = useState([])
  const [totalSessions, setTotalSessions] = useState(12)
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const progressBar = parseInt((100 / totalSessions) * currentSession)
    setProgress(progressBar)
    const filterClose =
      treatmentList.lenght > currentSession
        ? treatmentList.slice(0, currentSession + 1)
        : treatmentList
    const lenght = filterClose.length
    let treatments =
      lenght <= totalSessions
        ? filterClose
        : filterClose.slice(lenght - totalSessions, lenght)

    const treatmentsLength = treatments.length
    for (let i = treatmentsLength; i < totalSessions; i++) {
      const pendient = { status: 'pendient', session: i + 1 }
      treatments = treatments.concat(pendient)
    }
    setTreatment(treatments)
    setLoading(false)
  }, [totalSessions])

  useEffect(() => {
    if (totalConsults !== 'pendient') setTotalSessions(totalConsults)

    return () => {}
  }, [])

  return (
    <div className="all-sesions-container">
      <div className="progress-all-sesions">
        <TextCustom className="are-color-mynd-grey">
          {`Tu avance: ${currentSession} de ${totalSessions}`}
        </TextCustom>
        <TextCustom className="is-title-size has-text-right is-colour-percentage-treatment has-text-weight-bold">
          {`${progress || 0}%`}
        </TextCustom>
        <progress
          className="progress is-small is-info mt-2"
          value={progress}
          max="100"
        />
      </div>
      {loading ? (
        <div className="waitng-amount">
          <div className="spinner" />
        </div>
      ) : (
        treatment.map((item, index) => {
          const { session, status, date, images } = item
          return (
            <div
              className="box is-box-container-percentage-treatment all-sessions-card"
              key={index}
            >
              <TextCustom className="is-colour-number-sections mb-3">
                {session < 10 ? '0' + session : session}
              </TextCustom>
              <TreatmentCards images={images} date={date} status={status} />
            </div>
          )
        })
      )}

      <div className="is-flex is-justify-content-center">
        <ButtonCustom
          buttonClassName="button is-text-color is-invertedi is-fullwidth mt-2"
          handleOnClick={reset}
        >
          Anterior
        </ButtonCustom>
      </div>
    </div>
  )
}

AllTreatmentCards.propTypes = {
  treatmentList: PropTypes.array,
  currentSession: PropTypes.number,
  treatmentConfig: PropTypes.object,
  reset: PropTypes.func,
}

export default AllTreatmentCards
