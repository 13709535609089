import PropTypes from 'prop-types'

import {
  TypeOfTreatmentBox,
  MakeYourInquiryBox,
  MySections,
  LastAppointmentBox,
  EmptyTreatmentRoute,
} from '..'

const ContainerTreatment = ({
  increment,
  treatmentList,
  treatmentConfig,
  currentSession,
  lastSession,
  showAll,
  handleOnClickCoupon,
  isLoading,
}) => {
  return (
    <>
      {treatmentList ? (
        <div className="column is-size-container-treatment-route">
          <div className="columns">
            <TypeOfTreatmentBox treatmentConfig={treatmentConfig} />
            <MakeYourInquiryBox />
          </div>
          <MySections
            treatmentList={treatmentList}
            treatmentConfig={treatmentConfig}
            currentSession={currentSession}
            showAll={showAll}
            handleOnClickCoupon={handleOnClickCoupon}
            isLoading={isLoading}
          />
          {lastSession && (
            <LastAppointmentBox
              increment={increment}
              lastSession={lastSession}
            />
          )}
        </div>
      ) : (
        <EmptyTreatmentRoute />
      )}
    </>
  )
}

ContainerTreatment.propTypes = {
  increment: PropTypes.func,
  treatmentList: PropTypes.array,
  treatmentConfig: PropTypes.object,
  currentSession: PropTypes.number,
  lastSession: PropTypes.object,
  showAll: PropTypes.func,
  handleOnClickCoupon: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ContainerTreatment
