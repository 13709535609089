// import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStripe } from '@stripe/react-stripe-js'

// Components
import {
  TitleCustom,
  PaymentCompleted,
  PaymentMethod,
  PurchaseSummary,
} from '../../components'
import selectors from './selectors'
import dispatchers from './dispatchers'
import { useRouter } from '../../utils'

const Orders = () => {
  const { history, query } = useRouter()
  const { id } = query
  const stripe = useStripe()
  const { auth, consult, doctors, coupons, ui } = useSelector(state => state)
  const dispatch = useDispatch()
  const {
    getToken,
    schedulePractiService,
    uploadConsultImages,
    scheduleFastService,
    getPrice,
    addError,
    removeError,
    generateAsyncPayment,
  } = dispatchers(dispatch)
  const {
    cards,
    couponList,
    balance,
    photoURL,
    career,
    fullname,
    dateConsult,
    selectedHour,
    questionnaire,
    images,
    typeConsult,
    fullDate,
    email,
    typeCategory,
    specialty,
    privateFlag,
    errors,
  } = selectors(auth, consult, doctors, coupons, ui)
  const [paymentMethod, setPaymentMethod] = useState({ method: 'none' })
  const [step, setStep] = useState(1)
  const [amount, setAmount] = useState()
  const [priceWithoutCoupon, setPriceWithoutCoupon] = useState()
  const [coupon, setCoupon] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [waiting, setWaitng] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (errors && errorMessage === '') {
      setErrorMessage(errors)
      removeError()
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }, [errors])

  const goToConsults = () => {
    history.push('/user/consults')
  }
  const getServicePrice = async () => {
    let price = 0
    if (privateFlag) {
      price = await getPrice({
        typeConsult,
        category: typeCategory,
        specialty,
        privateFlag,
        doctorId: id,
      })
    } else {
      price = await getPrice({
        typeConsult,
        category: typeCategory,
        specialty,
      })
    }
    setAmount(price / 100)
    setPriceWithoutCoupon(price / 100)
  }

  const renderTotalPrice = () => {
    if (!coupon) {
      setAmount(priceWithoutCoupon)
    } else {
      const {
        discountType,
        amount: amountDiscount,
        amountDeferrent,
      } = coupon.data
      const discount =
        typeConsult === 'fast-service'
          ? amountDeferrent || amountDiscount
          : amountDiscount
      if (discountType === '-') {
        const newPrice = priceWithoutCoupon - discount
        if (newPrice < 0) setAmount('0')
        else setAmount(`${newPrice}`)
      }
      if (discountType === '=') {
        setAmount(`${parseFloat(coupon.data.amount).toFixed(2)}`)
      }
      if (discountType === '%') {
        setAmount(
          `${(
            priceWithoutCoupon -
            (priceWithoutCoupon * discount) / 100
          ).toFixed(2)}`,
        )
      }
    }
  }

  useEffect(() => {
    getServicePrice().then()
    getToken()
    return () => {}
  }, [])

  useEffect(() => {
    renderTotalPrice()
    return () => {}
  }, [coupon])

  useEffect(() => {
    if (amount >= 0) {
      /*
      setPaymentMethod({
        method: 'balance',
      })
      */
      setWaitng(false)
    }
  }, [amount])

  const practiService = async (methodSelected, cardId) => {
    const typeCategoryToIndex = {
      acne: 'priceDermaAcne',
      std: 'priceDermaETS',
      skincare: 'priceDermaSkincare',
    }
    const indexCategory = typeCategoryToIndex[typeCategory] || 'price'
    const payConsult = await schedulePractiService({
      coupon: coupon || null,
      method: {
        method: methodSelected,
        details: cardId,
      },
      questionnaire: questionnaire,
      doctor: id !== 'undefined' ? id : false,
      category: indexCategory,
    })
    if (payConsult?.data?.success) {
      await uploadConsultImages(payConsult.data.consultId, images)
      goToConsults()
    } else {
      addError(
        payConsult?.data?.message ??
          'Hubo un error al generar tu consulta. Por favor inténtalo más tarde',
      )
    }
  }

  const fastService = async (methodSelected, cardId) => {
    // build send object
    const typeCategoryToIndex = {
      acne: 'priceDermaAcne',
      std: 'priceDermaETS',
      skincare: 'priceDermaSkincare',
      medicine: 'priceGeneralMedicine',
      nutrition: 'priceNutrition',
      psychology: 'pricePsychology',
    }
    let indexCategory = ''
    if (!specialty || specialty === 'derma') {
      indexCategory = typeCategoryToIndex[typeCategory] || 'price'
    } else {
      indexCategory = typeCategoryToIndex[specialty] || 'price'
    }
    const sendObject = {
      coupon: coupon || null,
      scheduled_date: fullDate,
      method: {
        method: methodSelected,
        details: cardId,
      },
      doctor: id !== 'undefined' ? id : false,
      privateConsult: privateFlag || false,
      category: indexCategory,
      specialty: specialty || null,
    }
    if (!specialty || specialty === 'derma') {
      sendObject.questionnaire = questionnaire
    } else if (specialty === 'nutrition') {
      sendObject.questionnaire = questionnaire
    }
    const fastResponse = await scheduleFastService(sendObject)
    if (fastResponse?.data?.success) {
      if (!specialty || specialty === 'derma') {
        await uploadConsultImages(fastResponse.data.consultId, images)
      }
      goToConsults()
    } else {
      addError(
        fastResponse?.data?.message ??
          fastResponse?.data?.error ??
          'Hubo un error al generar tu consulta. Por favor inténtalo más tarde',
      )
    }
  }

  const cardOrBalanceMethod = async (methodSelected, id) => {
    if (typeConsult === 'practi-service') {
      return await practiService(methodSelected, id)
    }
    if (typeConsult === 'fast-service') {
      return await fastService(methodSelected, id)
    }
  }

  const asyncPractiService = async (methodSelected, details) => {
    try {
      const typeCategoryToIndex = {
        acne: 'priceDermaAcne',
        std: 'priceDermaETS',
        skincare: 'priceDermaSkincare',
      }
      const indexCategory = typeCategoryToIndex[typeCategory] || 'price'
      const payConsult = await schedulePractiService({
        coupon: coupon || null,
        method: {
          method: methodSelected,
        },
        questionnaire: questionnaire,
        category: indexCategory,
      })
      if (payConsult?.data?.success) {
        await uploadConsultImages(payConsult.data.consultId, images)
        if (methodSelected === 'alternative') {
          if (details !== 'oxxo') {
            const speiOrPaynentResp = await generateAsyncPayment({
              type: details,
              amount: amount,
              consultId: payConsult.data.consultId,
            })
            history.push('/user/consults')
            return speiOrPaynentResp
          }
          const asyncPayment = await generateAsyncPayment({
            type: details,
            amount: amount * 100,
            consultId: payConsult.data.consultId,
          })
          await stripe.confirmOxxoPayment(asyncPayment.data.client_secret, {
            payment_method: {
              billing_details: {
                name: fullname,
                email,
              },
            },
          })
          history.push('/user/consults')
        }
      }
    } catch (error) {
      console.log(`error`, error)
    }
  }

  const asyncFastService = async (methodSelected, details) => {
    try {
      const typeCategoryToIndex = {
        acne: 'priceDermaAcne',
        std: 'priceDermaETS',
        skincare: 'priceDermaSkincare',
        medicine: 'priceGeneralMedicine',
        nutrition: 'priceNutrition',
        psychology: 'pricePsychology',
      }
      let indexCategory = ''
      if (!specialty || specialty === 'derma') {
        indexCategory = typeCategoryToIndex[typeCategory] || 'price'
      } else {
        indexCategory = typeCategoryToIndex[specialty] || 'price'
      }
      const payConsult = await scheduleFastService({
        coupon: coupon || null,
        scheduled_date: fullDate,
        method: {
          method: methodSelected,
        },
        questionnaire: questionnaire,
        category: indexCategory,
        specialty: specialty ?? null,
      })
      if (payConsult?.data?.success) {
        if (methodSelected === 'alternative') {
          if (details !== 'oxxo') {
            const speiOrPaynentResp = await generateAsyncPayment({
              type: details,
              amount: amount,
              consultId: payConsult.data.consultId,
            })
            history.push('/user/consults')
            return speiOrPaynentResp
          }
          const asyncPayment = await generateAsyncPayment({
            type: details,
            amount: amount * 100,
            consultId: payConsult.data.consultId,
          })
          await stripe.confirmOxxoPayment(asyncPayment.data.client_secret, {
            payment_method: {
              billing_details: {
                name: fullname,
                email,
              },
            },
          })
          history.push('/user/consults')
        }
      }
    } catch (error) {
      console.log(`error`, error)
    }
  }

  const handleAsyncPayment = async (methodSelected, details) => {
    if (typeConsult === 'practi-service') {
      return await asyncPractiService(methodSelected, details)
    }
    if (typeConsult === 'fast-service') {
      return await asyncFastService(methodSelected, details)
    }
  }

  const generatePayment = async () => {
    if (paymentMethod.method !== 'none') {
      setIsLoading(true)
      const methodSelected = paymentMethod.method
      const detailsMethod = paymentMethod.details
      if (methodSelected === 'card' || methodSelected === 'balance') {
        const id = paymentMethod.id || null
        await cardOrBalanceMethod(methodSelected, id)
        setIsLoading(false)
      }
      if (methodSelected === 'alternative') {
        await handleAsyncPayment(methodSelected, detailsMethod)
        setIsLoading(false)
      }
    } else {
      setErrorMessage('Falta seleccionar un método de pago')
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  const filterCouponBySpeciality = coupon => {
    if (
      ((!coupon.data.specialty || coupon.data.specialty === 'derma') &&
        (specialty === 'derma' || !specialty)) ||
      coupon.data.specialty === specialty
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="container mt-5 mb-5">
      <div className="mx-4 mb-4">
        {errorMessage && (
          <div className="notification is-danger">{errorMessage}</div>
        )}
      </div>
      <TitleCustom className=" has-text-centered is-size-4 are-color-mynd-grey">
        Resumen de compra
      </TitleCustom>
      {waiting && (
        <div className="waitng-amount">
          <div className="spinner" />
        </div>
      )}
      {(!waiting && step === 1) || step === 3 ? (
        <PurchaseSummary
          isLoading={isLoading}
          amount={amount}
          selectMethod={() => setStep(2)}
          next={generatePayment}
          coupons={couponList.filter(coupon =>
            filterCouponBySpeciality(coupon),
          )}
          paymentMethod={paymentMethod}
          coupon={coupon}
          previous={() => setStep(1)}
          setCoupon={setCoupon}
          priceWithoutCoupon={priceWithoutCoupon}
          doctor={{ photoURL, career, fullname }}
          date={{ dateConsult, selectedHour }}
          typeConsult={typeConsult}
        />
      ) : null}
      {!waiting && step === 2 && (
        <PaymentMethod
          isOrder
          paymentMethod={paymentMethod}
          next={() => setStep(3)}
          cards={cards}
          previous={() => setStep(1)}
          setPaymentMethod={setPaymentMethod}
          balance={balance}
          amount={amount}
        />
      )}
      {step === 4 && <PaymentCompleted formbyStep={() => {}} />}
    </div>
  )
}

Orders.propTypes = {}

export default Orders
