import PropTypes from 'prop-types'
import { TitleCustom } from '../index'

const Loading = ({ title, message }) => {
  return (
    <>
      <div className="columns">
        <div className="column">
          <TitleCustom className="are-color-mynd-grey has-text-centered is-2">
            {title || '¡Es momento de empezar!'}
          </TitleCustom>
          <TitleCustom className="has-text-centered is-4 are-color-mynd-grey">
            {message || 'Estamos creando tu perfil de inicio...'}
          </TitleCustom>
        </div>
      </div>
      <div className="columns is-centered is-flex is-align-items-center">
        <div className="column is-half is-offset-one-quarter">
          <div className="loadingio-spinner-cube">
            <div className="ldio-2nntxu3738">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Loading.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

export default Loading
