import { TitleCustom, Address, Search } from '..'
import { useSelector } from 'react-redux'

const myAddresses = () => {
  const { isFetching, data } = useSelector(state => state.address)

  return (
    <>
      <TitleCustom className="has-text-centered are-color-mynd-grey is-title-size mt-6">
        Mis direcciones
      </TitleCustom>
      <div className="columns is-centered ">
        <div className="column is-box-addresses">
          <p>
            Busca tu dirección en este campo, un vez que la encuentres
            seleccionala para agregarla. Te pediremos más detalles una vez
            seleccionada.
          </p>
          <Search />
          <div className="box mt-5 ">
            {isFetching ? (
              <div className="has-text-centered">
                <div className="lds-dual-ring"></div>
              </div>
            ) : (
              <>
                {data.length > 0 ? (
                  <div>
                    {data.map((item, index) => (
                      <Address
                        key={index}
                        address={item}
                        index={index}
                        disableLine={data.length - 1 === index}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="has-text-centered">
                    <p className="has-text-grey">No tienes direcciones</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default myAddresses
