// import functions from '../functions'
import { consultActions } from '../../actions'

// const { createInitialConsult } = functions.cloudFunctions
const {
  createConsultRequestAction,
  createConsultSuccessAction,
  createConsultFailureAction,
} = consultActions.creators

const createConsultService = () => {
  return async dispatch => {
    try {
      dispatch(createConsultRequestAction())
      //  { data } = await createInitialConsult({ status: 'pre' })
      dispatch(createConsultSuccessAction('gs0Sr5bZ1RQfmMWh5r28'))
    } catch (error) {
      dispatch(createConsultFailureAction(error))
    }
  }
}

export default createConsultService
