// import PropTypes from 'prop-types'

import { TextCustom, ImageCustom } from '../../../components'
// img
import Images from '../../../assets'

const diaryCard = ({ diary }) => {
  const { uri, uriLeft, uriRight, products, date, time } = diary
  const {
    // categoryProductIcon,
    // aspirin,
    medicineIcon,
  } = Images

  return (
    <div className="box pt-0 px-0 mt-5 mx-2">
      <div className="is-size-header-box-product py-1">
        <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey">
          {date}
          <span className="is-position-hour-product">{time}</span>
        </TextCustom>
      </div>
      <div className="horizontal-scroll mb-4">
        <div className={' diary-card-images-container'}>
          <div className={' diary-card-images-container'}>
            <div className="diary-card-image">
              <img src={uriLeft} />
            </div>
          </div>
          <div className={' diary-card-images-container'}>
            <div className="diary-card-image">
              <img src={uri} />
            </div>
          </div>

          <div className={' diary-card-images-container'}>
            <div className="diary-card-image">
              <img src={uriRight} />
            </div>
          </div>
        </div>
      </div>

      <span className="icon-text ml-3 my-3">
        <span className="icon">
          <ImageCustom img={medicineIcon} className="is-icon-product-diary" />
        </span>
        {products ? (
          <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey has-text-weight-bold">
            {'Productos Registrados: '}
            <span className="has-text-weight-normal">{products.length}</span>
          </TextCustom>
        ) : (
          <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey has-text-weight-bold">
            {'Sin productos registrados.'}
          </TextCustom>
        )}
      </span>

      {/* <span className="icon-text ml-3 my-2 is-position-product-diary">
        <span className="icon">
          <ImageCustom
            img={categoryProductIcon}
            className="is-icon-product-diary"
          />
        </span>
        <span className="icon">
          <ImageCustom img={pillsIcon} className="is-icon-product-diary" />
        </span>

        <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey has-text-weight-bold">
          Producto:
          <span className="has-text-weight-normal">Shampoo Sedal</span>
        </TextCustom>
      </span> */}
      {products && (
        <>
          <hr className="line-bottom my-2"></hr>
          {/* <div className="is-flex is-justify-content-center mt-3"> */}
          <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey has-text-weight-bold">
            Estos son los productos que se registraron
          </TextCustom>
          <div className="horizontal-scroll mb-4">
            {products.map((product, index) => {
              return (
                <div className={' diary-card-images-container'} key={index}>
                  <div className="diary-card-image">
                    <img src={product.uri} />
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

diaryCard.propTypes = {}

export default diaryCard
