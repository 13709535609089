import { myAddressActions } from '../../actions'

const {
  ADD_NEW_ADDRESS_REQUEST,
  ADD_NEW_ADDRESS_SUCCESS,
  ADD_NEW_ADDRESS_FAILURE,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  SET_ADDRESS_REQUEST,
  SET_ADDRESS_SUCCESS,
  SET_ADDRESS_FAILURE,
  CLEAR_ADDRESS,
} = myAddressActions.types

const initialState = {
  isFetching: false,
  error: {},
  data: {},
}

const myAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_ADDRESS_REQUEST:
    case GET_ADDRESS_REQUEST:
    case SET_ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ADD_NEW_ADDRESS_SUCCESS:
    case GET_ADDRESS_SUCCESS:
    case SET_ADDRESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      }
    case ADD_NEW_ADDRESS_FAILURE:
    case GET_ADDRESS_FAILURE:
    case SET_ADDRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case CLEAR_ADDRESS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default myAddressReducer
