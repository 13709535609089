import moment from 'moment'
import { sessionActions } from '../../actions'
const { rememberMeAction, rememberMeSucessAction, rememberMeFailureAction } =
  sessionActions.creators
const rememberMeService = rememberMe => {
  return dispatch => {
    try {
      dispatch(rememberMeAction())
      const expiry = rememberMe
        ? moment().add(2, 'weeks').unix()
        : moment().add(8, 'hours').unix()
      dispatch(rememberMeSucessAction({ expiry }))
    } catch (error) {
      dispatch(rememberMeFailureAction(error))
    }
  }
}
export default rememberMeService
