import PropTypes from 'prop-types'

import { TextCustom, TitleCustom, ImageCustom } from '..'
import Images from '../../assets'

const TypeOfTreatmentBox = ({ treatmentConfig }) => {
  const { symptoms, totalConsults } = treatmentConfig
  const { AcnePimple } = Images
  return (
    <div className="column ">
      <TitleCustom className="are-color-mynd-grey is-title-size ">
        Ruta de Tratamiento
      </TitleCustom>
      <div className="is-box-type-treatment box is-flex ">
        <div className="is-box-information-treatment">
          <TextCustom className="has-text-white is-text-size-checkbox ">
            Ruta de tratamiendo para:
          </TextCustom>
          <TextCustom className="has-text-white is-text-size-checkbox is-text-treatment-disease mt-2">
            {symptoms || 'Acné Severo'}
          </TextCustom>
          <TextCustom className="has-text-white is-text-size-checkbox mt-4">
            Tiempo apróximado:
          </TextCustom>
          <TextCustom className="has-text-white is-text-size-checkbox is-text-treatment-disease mt-2">
            {totalConsults !== 'pendient'
              ? `${totalConsults} meses de tratamiento`
              : '1 año de tratamiento'}
          </TextCustom>
        </div>
        <ImageCustom
          img={AcnePimple}
          className="ml-5 mt-1 is-size-img-acne-treatment-route"
        />
      </div>
    </div>
  )
}

TypeOfTreatmentBox.propTypes = {
  treatmentConfig: PropTypes.object,
}

export default TypeOfTreatmentBox
