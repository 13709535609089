import { useEffect } from 'react'

const usePreventBack = action => {
  useEffect(() => {
    window.onpopstate = () => {
      if (typeof action === 'function') {
        action()
      }
      window.history.forward()
    }
    return () => {
      window.onpopstate = null
    }
  }, [])
}

export default usePreventBack
