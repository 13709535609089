import PropTypes from 'prop-types'

// components
import { ImageCustom, LabelCustom, TextAreaCustom } from '../../../components'
// img
import Images from '../../../assets'

const NotesField = ({ handleInputChange }) => {
  const { noteIcon } = Images
  return (
    <div className="box  mx-3 is-flex mb-4">
      <span className="icon mr-2">
        <ImageCustom img={noteIcon} />
      </span>
      <div className="is-size-field-nane-product">
        <LabelCustom className="label are-color-mynd-grey">Notas</LabelCustom>
        <TextAreaCustom
          placeholder="Notas sobre el producto"
          name="notes"
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

NotesField.propTypes = { handleInputChange: PropTypes.func }

export default NotesField
