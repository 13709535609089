import PropTypes from 'prop-types'

// Componts
import { TitleCustom, ButtonCustom } from '../../components'

const QuestionSun = ({ formbyStep }) => {
  return (
    <div className="columns">
      <div className="column">
        <div className="box is-box-shadow px-6">
          <TitleCustom className="has-text-centered is-5 are-color-mynd-grey pt-5">
            ¿Con qué frecuencia suele exponerse al sol?
          </TitleCustom>
          <p className="buttons is-centered are-large are-color-mynd-grey  ">
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              No me gusta el sol
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              3 veces a la semana
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              Solo fines de semana
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-fullwidth mx-4 my-4 is-button-form are-color-mynd-grey ">
              Solo fines de semana
            </ButtonCustom>
          </p>
          <p className="buttons is-centered">
            <ButtonCustom
              buttonClassName=" is-mnd-primary is-fullwidth mx-3 my-4 has-text-white  is-medium is-widescreen"
              onClick={formbyStep}
            >
              Siguiente
            </ButtonCustom>
            <ButtonCustom buttonClassName="is-text-color is-inverted mt-2 ">
              Omitir
            </ButtonCustom>
          </p>
        </div>
      </div>
    </div>
  )
}

QuestionSun.propTypes = {
  formbyStep: PropTypes.func,
}

export default QuestionSun
