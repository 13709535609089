const ADD_ERROR = 'ADD_ERROR'
const REMOVE_ERROR = 'REMOVE_ERROR'

const ADD_SUCCESS = 'ADD_SUCCESS'
const REMOVE_SUCCESS = 'REMOVE_SUCCESS'
const START_LOADING = 'START_LOADING'
const STOP_LOADING = 'STOP_LOADING'

const CLEAR_ERRORS = 'CLEAR_ERRORS'

const addError = (payload = {}) => ({ type: ADD_ERROR, payload })
const removeError = () => ({ type: REMOVE_ERROR })

const addSuccess = (payload = {}) => ({ type: ADD_SUCCESS, payload })
const removeSuccess = () => ({ type: REMOVE_SUCCESS })

const startLoading = () => ({ type: START_LOADING, payload: true })
const stopLoading = () => ({ type: STOP_LOADING })

const clearUI = () => ({ type: CLEAR_ERRORS })

export default {
  types: {
    ADD_ERROR,
    REMOVE_ERROR,
    ADD_SUCCESS,
    REMOVE_SUCCESS,
    START_LOADING,
    STOP_LOADING,
    CLEAR_ERRORS,
  },
  creators: {
    addError,
    removeError,
    addSuccess,
    removeSuccess,
    startLoading,
    stopLoading,
    clearUI,
  },
}
