import { useDispatch, useSelector } from 'react-redux'
import dispatchers from '../../components/specialitySelecter/dispatchers'
import selectors from '../../components/specialitySelecter/selectors'
import ModalSoon from '../../components/specialitySelecter/modalSoon'
import { useEffect, useState } from 'react'
import { configurationRef } from '../../config'
import SpecialitySelector from './components/SpecialitySelector'
// import { functions } from '../../config'
const Home = () => {
  const { auth } = useSelector(state => state)
  const { uid, specialty } = selectors(auth)
  const dispatch = useDispatch()

  const [specialities, setSpecialities] = useState([])
  const [cleanup, setCleanup] = useState(false)
  const [showModaSoon, setShowModalSoon] = useState(false)

  const { setUserSpecialty, getCategoriesForSpecialty } = dispatchers(dispatch)

  const getActives = async () => {
    if (!cleanup) {
      const specialitiesSnapshot = await configurationRef
        .doc('specialties')
        .get()
      setSpecialities(specialitiesSnapshot.data().active ?? [])
    }
  }

  useEffect(() => {
    getActives().then()
    if (specialty === undefined) {
      getCategoriesForSpecialty('derma')
    }
    return () => {
      setCleanup(true)
    }
  }, [])

  const validateSpecialty = specialty => {
    if (specialities.includes(specialty)) {
      setUserSpecialty(specialty, uid)
      getCategoriesForSpecialty(specialty)
    } else {
      setShowModalSoon(true)
    }
  }
  return (
    <div className="home__container my-5">
      <SpecialitySelector
        validateSpecialty={validateSpecialty}
        specialty={specialty}
      />
      {showModaSoon && <ModalSoon closeModal={() => setShowModalSoon(false)} />}
    </div>
  )
}

export default Home
