import functions from '../functions'
import { orderDrugsActions } from '../../actions'
const { getAllOrders } = functions.cloudFunctions

const {
  getAllOrdersRequestAction,
  getAllOrdersSuccessAction,
  getAllOrdersFailureAction,
} = orderDrugsActions.creators

const getAllOrderService = ({ userId, page, refreshing_mode }) => {
  return async dispatch => {
    try {
      const orders = []
      dispatch(getAllOrdersRequestAction())
      const response = await getAllOrders({
        userId,
        page,
        refreshing_mode,
      })
      const responseData = response.data
      if (responseData) {
        responseData.map(doc => orders.push(JSON.parse(doc.order)))
      }
      dispatch(getAllOrdersSuccessAction(orders))
      dispatch()
    } catch (error) {
      dispatch(getAllOrdersFailureAction(error))
    }
  }
}

export default getAllOrderService
