import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header, Loading, Footer, ReCaptcha } from '../../components'
import { useCounter, useRouter } from '../../utils'
import {
  AccountData,
  Terms,
  PostPhone,
  ValidationPhone,
} from '../../containers'
import selectors from './selectors'
import dispatchers from './dispatchers'

const UpdateUser = () => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const { updateUser, signUpSuccess, signOut } = dispatchers(dispatch)
  const { count, increment, decrement } = useCounter(1)
  const [data, setData] = useState()
  const { auth, ui } = useSelector(state => state)
  const { uid, fullname, email, phone, loading } = selectors(auth, ui)
  const [token, setToken] = useState()
  const onClick = phoneNumber => {
    setData({ ...data, phone: phoneNumber })
  }
  window.onpopstate = function () {
    if (count === 1) {
      signOut()
    } else {
      history.push({
        pathname: '/signIn',
      })
      decrement()
    }
  }

  useEffect(() => {
    if (count === 5) {
      updateUser(data, uid)
    }
  }, [count])

  const onComplete = async () => {
    if (!phone) {
      return await updateUser(data, uid)
    }
    data.phone = phone
    await updateUser(data, uid)
  }

  useEffect(() => {
    if (fullname && email && phone) {
      signUpSuccess()
    }
    return () => {}
  }, [])

  return (
    <div className="hero is-fullheight">
      <Header />
      <div className="hero-body">
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              {!loading && (
                <>
                  {count === 1 && (
                    <AccountData
                      setStep={increment}
                      setData={setData}
                      authEmail={email}
                    />
                  )}
                  {count === 2 && (
                    <Terms setStep={phone ? onComplete : increment} />
                  )}
                  {count === 3 && !phone && (
                    <PostPhone
                      isNeed={true}
                      onClick={phoneNumber => onClick(phoneNumber)}
                      setStep={increment}
                      token={token}
                    >
                      <ReCaptcha setToken={setToken} size={'normal'} />
                    </PostPhone>
                  )}
                  {count === 4 && !phone && (
                    <ValidationPhone
                      phone={data.phone}
                      setStep={onComplete}
                      setPrevious={decrement}
                    />
                  )}
                </>
              )}

              {loading && <Loading />}
            </div>
          </div>
        </div>
      </div>
      <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
    </div>
  )
}

export default UpdateUser
