import PropTypes from 'prop-types'

const ImageCustom = ({ img, ...otherProps }) => (
  <img src={img} {...otherProps} />
)

ImageCustom.propTypes = {
  img: PropTypes.string,
}

export default ImageCustom
