import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import Images from '../../assets'
import moment from 'moment'
import { ActiveMonth, CalendarDays } from '..'
// helpers
import { validatePrivateSchedule } from '../../utils/sections'
import selectors from './selectors.js'

const Calendar = ({
  specialtyScheadule,
  setSelectedDate,
  getBlockedDays,
  getBlockedDaysPrivate,
  doctorId,
  addError,
  removeError,
  reLoading,
}) => {
  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  const { arrowNext, arrowBack } = Images
  const [activeDate, setActiveDate] = useState(moment().toDate())
  const [changeCurrentMonth, setChangeCurrentMonth] = useState()
  const [changeCurrentYear, setChangeCurrentYear] = useState()
  const [debounceProgress, setDebounceProgress] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [matrix, setMatrix] = useState([])
  const [blockedMatrix, setBlockedMatrix] = useState([])
  const weekDays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
  const day = moment().format('DD')

  useEffect(() => {
    setSelectedDate(activeDate)
    renderMatrix()
    removeError()
  }, [activeDate])

  useEffect(() => {
    setSelectedDate({})
    debounce(loadBlockedDays)
  }, [changeCurrentMonth, changeCurrentYear])

  useEffect(() => {
    if (reLoading) {
      setActiveDate(moment(activeDate).add(1, 'day').toDate())
      debounce(loadBlockedDays)
    }
  }, [reLoading])

  const loadBlockedDays = async () => {
    try {
      const validatePrivate = await validatePrivateSchedule(
        privateFlag,
        doctorId,
      )
      const sendData = {
        year: moment(activeDate).format('YYYY'),
        month: moment(activeDate).format('MM'),
      }
      if (!validatePrivate) {
        sendData.specialty = specialtyScheadule || 'derma'
      }
      if (doctorId) {
        sendData.doctorId = doctorId
      }
      const response = validatePrivate
        ? await getBlockedDaysPrivate(sendData)
        : await getBlockedDays(sendData)
      let { data } = response
      if (!Array.isArray(data)) {
        data = []
      }
      const newData = data.filter(item => item !== parseInt(day))
      if (!newData.includes(day) && reLoading) {
        newData.push(parseInt(day))
      }

      getNextAvailableDate(newData)
      setBlockedMatrix(newData)
    } catch (error) {
      addError(error)
    } finally {
      setIsFetching(false)
    }
  }
  const debounce = (func, delay = 1000) => {
    clearTimeout(debounceProgress)
    setIsFetching(true)
    const timeoutProgress = setTimeout(() => {
      func()
    }, delay)
    setDebounceProgress(timeoutProgress)
  }

  const getNextAvailableDate = async data => {
    const maxDays = moment(activeDate).daysInMonth()
    for (let i = 1; i <= maxDays; i++) {
      if (!data.includes(i)) {
        const currentDay = new Date(
          activeDate.getFullYear(),
          activeDate.getMonth(),
          i,
        )
        setActiveDate(currentDay)
        break
      }
    }
  }

  const renderMatrix = () => {
    const matrix = []
    const year = moment(activeDate).format('YYYY')
    const month = activeDate.getMonth()
    const firstDay = new Date(year, month, 1).getDay()
    const maxDays = moment(activeDate).daysInMonth()

    let counter = 1
    for (let row = 1; row < 7; row++) {
      matrix[row] = []
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = -1
        if (row === 1 && col >= firstDay) {
          matrix[row][col] = counter++
        } else if (row > 1 && counter <= maxDays) {
          matrix[row][col] = counter++
        }
      }
      if (matrix[row][0] === -1 && row > 1) {
        matrix.pop()
      }
    }
    setMatrix(matrix)
  }

  const changeMonth = n => {
    const newMonth = moment(activeDate).add(n, 'month')
    setActiveDate(newMonth.toDate())
    setChangeCurrentMonth(newMonth.format('MM'))
  }

  return (
    <>
      <div className="calendar__container">
        <div className="navigation__container">
          {activeDate.getMonth() >= moment().format('MM') ||
          activeDate.getFullYear() > moment().format('YYYY') ? (
            <figure
              className="navigation__arrows"
              onClick={() => changeMonth(-1)}
            >
              <img src={arrowBack} className="" />
            </figure>
          ) : (
            <figure className="navigation__arrows"></figure>
          )}

          <div className="navigation__inputs ">
            <ActiveMonth
              activeDate={activeDate}
              setChangeCurrentMonth={setChangeCurrentMonth}
              setChangeCurrentYear={setChangeCurrentYear}
              setActiveDate={setActiveDate}
            />
          </div>
          {!(
            activeDate.getMonth() === 11 &&
            activeDate.getFullYear() ===
              parseInt(moment().add(2, 'years').format('YYYY'))
          ) ? (
            <figure
              className="container__arrowNext mr-1"
              onClick={() => changeMonth(1)}
            >
              <img src={arrowNext} className="is-size-arrow-calendar" />
            </figure>
          ) : (
            <figure className="container__arrowNext mr-1"></figure>
          )}
        </div>
        <div className="calendar__Weekdays">
          {weekDays.map((day, index) => (
            <div className="calendar__weekdays__day" key={index}>
              {day}
            </div>
          ))}
        </div>
        <CalendarDays
          matrix={matrix}
          blockedMatrix={blockedMatrix}
          setActiveDate={setActiveDate}
          isFetching={isFetching}
          activeDate={activeDate}
        />
      </div>
    </>
  )
}

Calendar.propTypes = {
  specialtyScheadule: PropTypes.string,
  setSelectedDate: PropTypes.func,
  getBlockedDays: PropTypes.func,
  getBlockedDaysPrivate: PropTypes.func,
  doctorId: PropTypes.string,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  reLoading: PropTypes.bool,
}

export default Calendar
