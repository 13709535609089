import PropTypes from 'prop-types'
import Images from '../../assets'
import { TextCustom, ImageCustom, ButtonCustom, AddTicket } from '..'

const ContainerWarranty = ({
  setModalState,
  modalState,
  toggleModal,
  addWarranty,
  uploadImageTicket,
  uid,
  increment,
}) => {
  const { warranty } = Images
  return (
    <>
      <ImageCustom img={warranty} className="is-size-logo-warranty pt-5" />
      <TextCustom className="has-text-centered are-color-mynd-grey is-size-4 my-4">
        Si despues de 6 meses de tratamiento por acne no ves mejoria
      </TextCustom>
      <TextCustom className="has-text-centered has-text-weight-bold is-color-pink is-size-3 my-3">
        TE DEVOLVDEMOS EL 100% DE TUS CONSULTAS*
      </TextCustom>
      <TextCustom className="mt-2 has-text-centered px-6">
        *El paciente deberá realizar las consultas en el tiempo indicado por el
        médico. Puede haber un máximo de 5 días de retraso en las consultas
        sobre la fecha solicitada por el médico. En caso de no haber adquirido
        el tratamiento dentro de la app, el paciente deberá demostrar que si
        adquirió el tratamiento en alguna otra farmacia mediante los tickets de
        compra. Únicamente será devuelto el total de pago por consultas, no del
        tratamiento.
      </TextCustom>
      <div className="is-flex is-justify-content-center mt-5">
        <ButtonCustom
          className="button  has-text-weight-bold is-colour-pink-diary has-text-white is-medium mr-6"
          handleOnClick={() => setModalState(true)}
        >
          Subir ticket
        </ButtonCustom>
        <ButtonCustom
          className="button has-text-weight-bold is-colour-pink-diary has-text-white is-medium"
          handleOnClick={() => increment()}
        >
          Ver tickets
        </ButtonCustom>
        {modalState && (
          <AddTicket
            closeModal={toggleModal}
            addWarranty={addWarranty}
            uploadImageTicket={uploadImageTicket}
            uid={uid}
          />
        )}
      </div>
    </>
  )
}

ContainerWarranty.propTypes = {
  setModalState: PropTypes.func,
  modalState: PropTypes.bool,
  toggleModal: PropTypes.func,
  addWarranty: PropTypes.func,
  uploadImageTicket: PropTypes.func,
  uid: PropTypes.string,
  increment: PropTypes.func,
}

export default ContainerWarranty
