import { processData } from '../../utils'
import { usersF, firestoreFiled } from '../../config'
import { authActions } from '../../actions'

const {
  updateUserRequestAction,
  updateUserSuccessAction,
  updateUserFailureAction,
} = authActions.creators

const removeUserCode = uid => {
  return async dispatch => {
    try {
      dispatch(updateUserRequestAction())
      const values = {
        privateDoctor: firestoreFiled.FieldValue.delete(),
        privateFlag: firestoreFiled.FieldValue.delete(),
      }
      await usersF.doc(uid).set(values, { merge: true })
      const user = await usersF.doc(uid).get()
      const newUserData = await processData(user)
      dispatch(updateUserSuccessAction(newUserData))
    } catch (error) {
      dispatch(updateUserFailureAction(error))
      return error
    }
  }
}

export default removeUserCode
