import functions from '../functions'

const { getAvailableHours } = functions.cloudFunctions
const getAvailableHoursService = data => {
  return async dispatch => {
    try {
      const response = await getAvailableHours(data)
      return response
    } catch (error) {
      console.log(error)
    }
  }
}

export default getAvailableHoursService
