import PropTypes from 'prop-types'

// img
import Images from '../../../assets'

import { ImageCustom, LabelCustom, Input } from '../../../components'

const productNameField = ({ handleInputChange }) => {
  const { pillsIcon } = Images
  return (
    <div className="box  mx-3 is-flex">
      <span className="icon mr-2">
        <ImageCustom img={pillsIcon} />
      </span>
      <div className="is-size-field-nane-product">
        <LabelCustom className="label are-color-mynd-grey">
          {'Nombre del producto (requerido)'}
        </LabelCustom>
        <Input
          className="input is-select-size is-fullwidth"
          type="text"
          placeholder="Escribir nombre del producto"
          name="productName"
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

productNameField.propTypes = { handleInputChange: PropTypes.func }

export default productNameField
