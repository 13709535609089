import { questionnairesActions } from '../../actions'

const {
  QUESTIONNAIRE_REQUEST,
  QUESTIONNAIRE_SUCCESS,
  QUESTIONNAIRE_FAILURE,
  CLEAR_QUESTIONNAIRES,
} = questionnairesActions.types

const initialState = {
  data: {},
  error: {},
}

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUESTIONNAIRE_REQUEST:
      return {
        ...state,
      }

    case QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }

    case QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_QUESTIONNAIRES:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default questionnaireReducer
