import PropTypes from 'prop-types'
import Images from '../../assets'
import { TextCustom, TitleCustom, Button, ImageCustom } from '..'
const QuestionService = ({
  typeConsult,
  answers,
  setAnswers,
  questions,
  addError,
  removeError,
  errors,
  decrement,
  goToOrders,
  disableBack,
  disableSkip,
}) => {
  const { arrrowDouble } = Images
  return (
    <>
      <div className="level is-mobile">
        <div className="level-left">
          <TitleCustom className="title is-size-4 are-color-mynd-grey ">
            Cuestionario
          </TitleCustom>
        </div>
        <div className="level-right">
          {typeConsult === 'fast-service' && disableSkip === false && (
            <Button
              className="button is-color-button-skip has-text-white is-border-radius"
              onClick={() => goToOrders('fast-service')}
            >
              <span className="bold-font">Omitir paso</span>
              <span className="icon">
                <ImageCustom img={arrrowDouble} />
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className="content">
        <TextCustom className="is-size-5 ">
          Es necesario responder estas simples preguntas para que el
          especialista pueda evaluar y dar un diagnóstico asertivo
        </TextCustom>
      </div>
      {errors && <div className="notification is-danger">{errors}</div>}
      <div className="is-flex is-flex-direction-column">
        {questions.map(
          (question, index) =>
            (question.type === 'text' && (
              <div className="field mt-2" key={index}>
                <label className="label">{question.text}</label>
                <div className="control">
                  <textarea
                    rows="3"
                    className="textarea"
                    type="text"
                    placeholder="Respuesta"
                    name={index}
                    onChange={({ target }) =>
                      setAnswers({
                        ...answers,
                        [target.name]: target.value,
                      })
                    }
                  />
                </div>
              </div>
            )) ||
            (question.type === 'options' && (
              <div className="field" key={index}>
                <label className="label">{question.text}</label>
                <div className="control">
                  <div className="select">
                    <select
                      onChange={({ target }) =>
                        setAnswers({
                          ...answers,
                          [target.name]: target.value,
                        })
                      }
                      name={index}
                    >
                      <option>Seleccione una opción</option>
                      {question.options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )),
        )}
      </div>
      <div className="buttons is-centered mt-4">
        {disableBack === false && (
          <Button
            className="button is-text-color is-inverted"
            onClick={decrement}
          >
            Anterior
          </Button>
        )}
        <Button
          type="submit"
          className="button is-mnd-primary has-text-white is-padding-button-next  is-size-6"
          onClick={() => goToOrders(typeConsult)}
        >
          Siguiente
        </Button>
      </div>
    </>
  )
}

QuestionService.defaultProps = {
  disableBack: false,
  disableSkip: false,
}

QuestionService.propTypes = {
  typeConsult: PropTypes.string.isRequired,
  answers: PropTypes.any,
  setAnswers: PropTypes.func,
  decrement: PropTypes.func,
  questions: PropTypes.array,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  errors: PropTypes.string,
  goToOrders: PropTypes.func,
  disableBack: PropTypes.bool,
  disableSkip: PropTypes.bool,
}

export default QuestionService
