import { useState, useEffect } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'

const useAgora = client => {
  const [localVideoTrack, setLocalVideoTrack] = useState()
  const [localAudioTrack, setLocalAudioTrack] = useState()
  const [remoteUsers, setRemoteUsers] = useState([])

  const createLocalTracks = async () => {
    const [microphoneTrack, cameraTrack] =
      await AgoraRTC.createMicrophoneAndCameraTracks()
    setLocalAudioTrack(microphoneTrack)
    setLocalVideoTrack(cameraTrack)
    return [microphoneTrack, cameraTrack]
  }

  const join = async (appid, channel) => {
    if (!client) return
    const [microphoneTrack, cameraTrack] = await createLocalTracks()
    await client.join(appid, channel, null)
    await client.publish([microphoneTrack, cameraTrack])
  }
  const mutedVideo = async isMuted => {
    if (!localVideoTrack) return
    await localVideoTrack.setEnabled(!isMuted)
  }

  const mutedAudio = async isMuted => {
    if (!localAudioTrack) return
    await localAudioTrack.setMuted(isMuted)
  }

  const leave = async () => {
    if (!client) return
    if (localAudioTrack) {
      localAudioTrack.stop()
      localAudioTrack.close()
    }
    if (localVideoTrack) {
      localVideoTrack.stop()
      localVideoTrack.close()
    }
    setRemoteUsers([])
    await client.leave()
  }

  useEffect(() => {
    if (!client) return
    setRemoteUsers(client.remoteUsers)

    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType)
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    const handleUserUnpublished = async user => {
      await client.unsubscribe(user)
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    const handleUserJoined = user => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    const handleUserLeft = user => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    client.on('user-published', handleUserPublished)
    client.on('user-unpublished', handleUserUnpublished)
    client.on('user-joined', handleUserJoined)
    client.on('user-left', handleUserLeft)

    return () => {
      client.off('user-published', handleUserPublished)
      client.off('user-unpublished', handleUserUnpublished)
      client.off('user-joined', handleUserJoined)
      client.off('user-left', handleUserLeft)
    }
  }, [client])

  return {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    remoteUsers,
    mutedVideo,
    mutedAudio,
  }
}

export default useAgora
