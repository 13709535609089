import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextCustom } from '..'

const questionnaireAnswered = ({ questionnaire }) => {
  const [questionList, setQuestionList] = useState([])

  useEffect(() => {
    const tempList = []

    Object.entries(questionnaire).map(doc =>
      tempList.push({
        question: doc[0],
        response: doc[1],
      }),
    )

    setQuestionList(tempList)
  }, [questionnaire])

  return (
    <header className="consults-container-header drug-box no-align-text">
      <div className="m-5">
        {questionList.map((item, index) => (
          <div key={index}>
            <TextCustom>
              <span>{item.question}</span>
            </TextCustom>
            <TextCustom>{item.response}</TextCustom>
          </div>
        ))}
      </div>
    </header>
  )
}

questionnaireAnswered.propTypes = {
  questionnaire: PropTypes.any,
}

export default questionnaireAnswered
