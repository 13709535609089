import { useState } from 'react'
const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const handleInputChange = ({ target }) => {
    setValues({ ...values, [target.name]: target.value })
  }

  const handleCheckedChange = ({ target }) => {
    setValues({ ...values, [target.name]: target.checked })
  }

  const handleFileInputChange = ({ target }) => {
    setValues({ ...values, [target.name]: target.files[0] })
  }

  const reset = () => {
    setValues(initialState)
  }

  return [
    values, // 0
    handleInputChange, // 1
    handleCheckedChange, // 2
    handleFileInputChange, // 3
    reset, // 4
  ]
}

export default useForm
