import { usersF } from '../../config'
import { couponsActions } from '../../actions'
import { getCoupons } from '../../utils'

const {
  getAllCouponsRequestAction,
  getAllCouponsSuccessAction,
  getAllCouponsFailureAction,
} = couponsActions.creators

const getCouponsService = uid => {
  return async dispatch => {
    dispatch(getAllCouponsRequestAction())
    try {
      const response = await usersF.doc(uid).get()
      if (response.data().coupons) {
        const coupons = await getCoupons(response.data().coupons)
        dispatch(getAllCouponsSuccessAction(coupons))
      } else {
        dispatch(getAllCouponsSuccessAction([]))
      }
    } catch (error) {
      dispatch(getAllCouponsFailureAction(error))
    }
  }
}

export default getCouponsService
