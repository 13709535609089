import { usersF, firebase } from '../../config'
import { myAddressActions } from '../../actions'

const { setAddressRequest, setAddressSuccess, setAddressFailure } =
  myAddressActions.creators

const getUser = async uid => {
  try {
    const response = await usersF.doc(uid).get()
    return response.data()
  } catch (error) {
    return error
  }
}

const setMainAddressService = (uid, description) => {
  return async dispatch => {
    try {
      dispatch(setAddressRequest())
      const user = await getUser(uid)
      const prevMainAddress = user.locations.find(address => address.isSelected)
      const prevChangeMainAddress = {
        ...prevMainAddress,
      }
      prevChangeMainAddress.isSelected = false
      await usersF.doc(uid).update({
        locations: firebase.firestore.FieldValue.arrayRemove(prevMainAddress),
      })
      await usersF.doc(uid).update({
        locations: firebase.firestore.FieldValue.arrayUnion(
          prevChangeMainAddress,
        ),
      })
      const newMainAddress = user.locations.find(
        address => address.description === description,
      )
      const changeMainAddress = {
        ...newMainAddress,
      }
      changeMainAddress.isSelected = true
      await usersF.doc(uid).update({
        locations: firebase.firestore.FieldValue.arrayRemove(newMainAddress),
      })
      await usersF.doc(uid).update({
        locations: firebase.firestore.FieldValue.arrayUnion(changeMainAddress),
      })
      const newDataFromUser = await getUser(uid)
      dispatch(setAddressSuccess(newDataFromUser.locations))
    } catch (error) {
      dispatch(setAddressFailure(error))
    }
  }
}
// TODO: Create two functions to set new main address and change prev main address
export default setMainAddressService
