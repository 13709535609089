const ADD_CURRENT_RECEIPT = 'ADD_CURRENT_RECEIPT'
const ADD_CURRENT_RECEIPT_SUCCESS = 'ADD_CURRENT_RECEIPT_SUCCESS'
const ADD_CURRENT_RECEIPT_FAILURE = 'ADD_CURRENT_RECEIPT_FAILURE'
const BUY_RECEIPT_REQUEST = 'BUY_RECEIPT_REQUEST'
const BUY_RECEIPT_SUCCESS = 'BUY_RECEIPT_SUCCESS'
const BUY_RECEIPT_FAILURE = 'BUY_RECEIPT_FAILURE'
const GET_ALL_ORDERS_REQUEST = 'GET_ALL_ORDERS_REQUEST'
const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS'
const GET_ALL_ORDERS_FAILURE = 'GET_ALL_ORDERS_FAILURE'
const CLEAR_ORDER_DRUGS = 'CLEAR_ORDER_DRUGS'

const getAllOrdersRequestAction = (payload = {}) => ({
  type: GET_ALL_ORDERS_REQUEST,
  payload,
})

const getAllOrdersSuccessAction = (payload = {}) => ({
  type: GET_ALL_ORDERS_SUCCESS,
  payload,
})

const getAllOrdersFailureAction = (payload = {}) => ({
  type: GET_ALL_ORDERS_FAILURE,
  payload,
})

const buyReceiptRequestAction = (payload = {}) => ({
  type: BUY_RECEIPT_REQUEST,
  payload,
})

const buyReceiptSuccessAction = (payload = {}) => ({
  type: BUY_RECEIPT_SUCCESS,
  payload,
})

const buyReceiptFailureAction = (payload = {}) => ({
  type: BUY_RECEIPT_FAILURE,
  payload,
})

const addCurrentReceiptAction = (payload = {}) => ({
  type: ADD_CURRENT_RECEIPT,
  payload,
})

const addCurrentReceiptSuccessAction = (payload = {}) => ({
  type: ADD_CURRENT_RECEIPT_SUCCESS,
  payload,
})

const addCurrentReceiptFailureAction = (payload = {}) => ({
  type: ADD_CURRENT_RECEIPT_FAILURE,
  payload,
})

const clearOrderDrugsAction = () => ({
  type: CLEAR_ORDER_DRUGS,
})

export default {
  types: {
    ADD_CURRENT_RECEIPT,
    ADD_CURRENT_RECEIPT_SUCCESS,
    ADD_CURRENT_RECEIPT_FAILURE,
    BUY_RECEIPT_REQUEST,
    BUY_RECEIPT_SUCCESS,
    BUY_RECEIPT_FAILURE,
    GET_ALL_ORDERS_REQUEST,
    GET_ALL_ORDERS_SUCCESS,
    GET_ALL_ORDERS_FAILURE,
    CLEAR_ORDER_DRUGS,
  },
  creators: {
    addCurrentReceiptAction,
    addCurrentReceiptSuccessAction,
    addCurrentReceiptFailureAction,
    buyReceiptRequestAction,
    buyReceiptSuccessAction,
    buyReceiptFailureAction,
    getAllOrdersRequestAction,
    getAllOrdersSuccessAction,
    getAllOrdersFailureAction,
    clearOrderDrugsAction,
  },
}
