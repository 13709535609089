import PropTypes from 'prop-types'

// components
import { ImageCustom, TextCustom } from '..'

// img
import Images from '../../assets'

const addressData = ({ send_address, phone }) => {
  const { homeIconPink } = Images
  return (
    <div className="box mt-3 is-size-box-address">
      <div className=" is-flex is-justify-content-center is-align-content-flex-start">
        <div className=" px-0 mr-3 mt-1  card-send-image">
          <ImageCustom img={homeIconPink} className="is-size-img-Home" />
        </div>
        <div className=" ml-3 card-send">
          <TextCustom className="are-color-mynd-grey is-size-text-address">
            Enviado al domicilio
          </TextCustom>
          <TextCustom className="is-mynd-gray-opaque is-size-text-address">
            {send_address.street}, {send_address.number}, Col.{' '}
            {send_address.vicinity}, <br /> CP {send_address.postal_code},{' '}
            {send_address.state}, {send_address.country}
          </TextCustom>
          <TextCustom className="is-mynd-gray-opaque is-size-text-address">
            Teléfono: {phone}
          </TextCustom>
        </div>
      </div>
    </div>
  )
}

addressData.propTypes = {
  send_address: PropTypes.instanceOf(Object).isRequired,
  phone: PropTypes.string.isRequired,
}

export default addressData
