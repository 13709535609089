import PropTypes from 'prop-types'
import { TitleCustom, TextCustom, ImageCustom } from '../../components'
import Images from '../../assets'

import { useState } from 'react'
const DoctorCard = ({ image, fullname, career }) => {
  const [favorite, setFavorite] = useState(false)
  const onChangeFavorite = () => {
    setFavorite(!favorite)
  }
  const { comentIcon } = Images
  return (
    <div className="doctor">
      <ImageCustom img={image} className="doctor-photo" />
      <div className="doctor-info">
        <TitleCustom className="is-size-5 mt-5 are-color-mynd-grey doctor-name">
          {`${fullname || 'Dr. Guillermo Alfredo De la Cruz Pacheco'}`}
        </TitleCustom>
        <a
          className={`icon ${favorite ? 'active' : ''}`}
          id="favorite"
          onClick={onChangeFavorite}
        >
          <i className={`fa fa-heart${!favorite ? '-o' : ''}`}></i>
        </a>
        <TextCustom className="has-text-weight-bold">
          {'Especialista: '}
          <span className="has-text-weight-light">
            {`${career || 'El área de Piel y Cabello.'}`}
          </span>
        </TextCustom>

        <span className="icon" id="reviews">
          <ImageCustom img={comentIcon} />
        </span>
        <a className="has-text-weight-light">20 comentarios</a>
      </div>
    </div>
  )
}

DoctorCard.propTypes = {
  image: PropTypes.string,
  fullname: PropTypes.string,
  career: PropTypes.string,
}

export default DoctorCard
