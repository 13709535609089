import PropTypes from 'prop-types'
import { TextCustom, ProgressCustom } from '..'
import { PRACTIC_SERVICE, FAST_SERVICE } from './../../utils/constants'
const index = ({ typeService, step }) => {
  return (
    <>
      {typeService === PRACTIC_SERVICE && (
        <>
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start">
            {step === 1 && (
              <TextCustom className="is-text-part-question">
                1/4 Tipo de tratamiento
              </TextCustom>
            )}
            {step === 2 && (
              <TextCustom className="is-text-part-question">
                2/4 Escoge un horario
              </TextCustom>
            )}
            {step === 3 && (
              <TextCustom className="is-text-part-question">
                3/4 agendar una consulta
              </TextCustom>
            )}
            {step === 4 && (
              <p className="are-color-mynd-grey  is-text-part-question ml-2">
                4/4 Preguntas de consulta
              </p>
            )}
          </div>
          <div className="is-flex is-flex-direction-row is-justify-content-space-around">
            <ProgressCustom step={step} step_number={1} />
            <ProgressCustom step={step} step_number={2} />
            <ProgressCustom step={step} step_number={3} />
            <ProgressCustom step={step} step_number={4} />
          </div>
        </>
      )}
      {typeService === FAST_SERVICE && (
        <div className="is-flex is-flex-direction-column">
          <TextCustom className="is-text-part-question">
            1/1 Escoge un horario
          </TextCustom>
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start mb-5">
            <ProgressCustom step={step} step_number={1} />
          </div>
        </div>
      )}
    </>
  )
}

index.propTypes = {
  typeService: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
}

export default index
