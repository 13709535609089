import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import selectors from './selectors'
import dispatchers from './dispatchers'
import { useRouter } from '../../utils'

const SuccessHandler = () => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const { removeSuccess } = dispatchers(dispatch)
  const { ui } = useSelector(state => state)
  const { success } = selectors(ui)
  const [successMessage, setSuccessMessage] = useState('')
  const [cleanup, setCleanup] = useState(false)

  useEffect(() => {
    removeSuccess()
    setCleanup(true)
  }, [history.location])

  useEffect(() => {
    if (success && successMessage === '' && !cleanup) {
      setSuccessMessage(success)
      removeSuccess()
      setTimeout(() => {
        setSuccessMessage('')
      }, 3000)
    }
    if (cleanup) {
      setSuccessMessage('')
      setCleanup(false)
    }
  }, [success, cleanup])

  if (successMessage) {
    return (
      <div className="mx-4 mb-4 mt-5">
        <div className="notification is-success">{successMessage}</div>
      </div>
    )
  }

  return <></>
}

export default SuccessHandler
