import { storage } from '../../config'

const uploadFiles = (file, uid) => {
  return async dispatch => {
    try {
      const fileRef = await storage
        .ref(`profile/users/${uid}/expedient/${file.name}`)
        .put(file)
      const url = await fileRef.ref.getDownloadURL()
      return url
    } catch (error) {
      return error
    }
  }
}

export default uploadFiles
