import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  RegisterBox,
  TitleCustom,
  ImageCustom,
  ButtonCustom,
  VerificationCode,
  CustomButton,
} from '../../components'
import { sendSMS, verifyCode } from '../../services'
import dispatchers from './dispatchers'
import selectors from './selectors'
import Images from '../../assets'

const ValidationPhoneNumber = ({
  setStep,
  phone,
  setPrevious,
  maxLength,
  onClick,
}) => {
  const dispatch = useDispatch()
  const { addError, removeError } = dispatchers(dispatch)
  const { auth, ui } = useSelector(state => state)
  const { uid, errors } = selectors(ui, auth)
  const [code, setCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { cardVerifyIconWhite } = Images

  const verifyNumber = async () => {
    removeError()
    await sendSMS(phone)
  }

  const onComplete = value => {
    setCode(value)
  }
  const previous = () => {
    removeError()
    setPrevious()
  }

  const verification = async () => {
    const response = await verifyCode(code, uid)
    if (response === true) {
      setStep()
    } else {
      return addError('Código incorrecto')
    }
  }

  const register = async () => {
    setIsLoading(true)
    removeError()
    if (!code) {
      return addError('Ingresa El código Correctamente')
    }
    if (onClick) {
      await onClick(code)
    } else await verification()
    setIsLoading(false)
  }

  return (
    <RegisterBox className="px-6">
      <TitleCustom className="title is-5 has-text-centered are-color-text">
        Validar número de celular
      </TitleCustom>
      <figure className="image  is-item-centered mt-2 mb-4 position-img-verify">
        <ImageCustom img={cardVerifyIconWhite} />
      </figure>
      <TitleCustom className="title is-5 has-text-centered are-color-text">
        Coloque el código recibido
      </TitleCustom>
      <h6 className="subtitle is-6 has-text-centered mt-1 are-color-text">
        {`Código enviado al teléfono: ${phone}`}
      </h6>
      {errors && <div className="notification is-danger">{errors}</div>}
      <VerificationCode
        onComplete={value => onComplete(value)}
        maxLength={maxLength}
      />

      <div
        className={`${
          onClick ? 'is-auth-with-phone' : 'level is-pacing-button mt-5'
        }`}
      >
        {!onClick && (
          <div className="level-left is-tablet">
            <ButtonCustom
              buttonClassName="button is-color-button-phone"
              onClick={verifyNumber}
            >
              Reenviar sms
            </ButtonCustom>
          </div>
        )}

        <div className="level-right is-tablet">
          <ButtonCustom
            buttonClassName="button is-color-button-phone"
            onClick={previous}
          >
            {onClick ? 'Reenviar sms o cambiar número' : 'Cambiar número'}
          </ButtonCustom>
        </div>
      </div>
      <div className="buttons is-centered mb-2">
        <CustomButton isPrimary isLoading={isLoading} handleClick={register}>
          Validar número
        </CustomButton>
      </div>
    </RegisterBox>
  )
}

ValidationPhoneNumber.propTypes = {
  phone: PropTypes.string,
  setPrevious: PropTypes.func,
  maxLength: PropTypes.number,
  onClick: PropTypes.func,
  setStep: PropTypes.func,
}

export default ValidationPhoneNumber
