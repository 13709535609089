export const AUTH_USER_NOT_FOUND = 'AUTH_USER_NOT_FOUND'
export const AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL =
  'auth/account-exists-with-different-credential'
export const AUTH_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use'
export const AUTH_CLOSE_POPUP_BY_USER = 'auth/popup-closed-by-user'
export const AUTH_USER_CANCELLED = 'auth/user-cancelled'
export const AUTH_WRONG_PASSWORD = 'auth/wrong-password'
export const PASSWORD = 'password'
export const GOOGLE_PROVIDER = 'google.com'
export const FACEBOOK_PROVIDER = 'facebook.com'
export const APPLE_PROVIDER = 'apple.com'
export const APPLE = 'Code flow is not enabled for Apple.'
export const PRACTIC_SERVICE = 1
export const FAST_SERVICE = 2
export const countries = {
  MX: 'México',
  CO: 'Colombia',
  USA: 'United States',
}

export const mxStates = {
  AGS: 'Aguascalientes',
  BC: 'Baja California',
  BCS: 'Baja California Sur',
  CAMP: 'Campeche',
  CHIS: 'Chiapas',
  CHIH: 'Chihuahua',
  CDMX: 'Ciudad de México',
  COAH: 'Coahuila',
  COL: 'Colima',
  DGO: 'Durango',
  Gto: 'Guanajuato',
  GRO: 'Guerrero',
  HGO: 'Hidalgo',
  JAL: 'Jalisco',
  EDOMEX: 'Estado de México',
  MiCH: 'Michoacán',
  MOR: 'Morelos',
  NAY: 'Nayarit',
  NL: 'Nuevo León',
  OAX: 'Oaxaca',
  PUE: 'Puebla',
  QRO: 'Querétaro',
  QROO: 'Quintana Roo',
  SLP: 'San Luis Potosí',
  SIN: 'Sinaloa',
  SON: 'Sonora',
  TAB: 'Tabasco',
  TAMPS: 'Tamaulipas',
  TLAX: 'Tlaxcala',
  VER: 'Veracruz',
  YUC: 'Yucatán',
  ZAC: 'Zacatecas',
}

export const usaStates = {
  CAL: 'California',
  ALB: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'Columbia District',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina ',
  ND: 'North Dakota ',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania ',
  RI: 'Rhode Island',
  SC: 'South Carolina ',
  SD: 'South Dakota ',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const coStates = {
  CO_AMA: 'Amazonas',
  CO_ANT: 'Antioquia',
  CO_ARA: 'Arauca',
  CO_ATL: 'Atlántico',
  CO_BOL: 'Bolívar',
  CO_BOY: 'Boyacá',
  CO_CAL: 'Caldas',
  CO_CAQ: 'Caquetá',
  CO_CAS: 'Casanare',
  CO_CAU: 'Cauca',
  CO_CES: 'Cesar',
  CO_CHO: 'Chocó',
  CO_COR: 'Córdoba',
  CO_CUN: 'Cundinamarca',
  CO_GUA: 'Guainía',
  CO_GUV: 'Guaviare',
  CO_HUI: 'Huila',
  CO_LAG: 'La Guajira',
  CO_MAG: 'Magdalena',
  CO_MET: 'Meta',
  CO_NAR: 'Nariño',
  CO_NSA: 'Norte de Santander',
  CO_PUT: 'Putumayo',
  CO_QUI: 'Quindío',
  CO_RIS: 'Risaralda',
  CO_SAP: 'San Andrés y Providencia',
  CO_SAN: 'Santander',
  CO_SUC: 'Sucre',
  CO_TOL: 'Tolima',
  CO_VAC: 'Valle del Cauca',
  CO_VAU: 'Vaupés',
  CO_VID: 'Vichada',
}

export const questionsdata = [
  {
    id: 1,
    title:
      '¿Qué pasa si mi padecimiento no se puede tratar por vídeo consulta?',
    info: 'No te preocupes, te recomendaremos asistir a una clínica cercana y te devolveremos tu dinero.',
  },
  {
    id: 2,
    title: '¿Puedo verificar que los dermatólogos estan certificados?',
    info: 'Si, en la web del CONACEM, solo necesitas el nombre completo y la especialidad. ',
    link: 'https://conacem.org.mx/',
  },
  {
    id: 3,
    title: '¿Me puedo consultar si soy menor de edad?',
    info: 'Sí, podemos consultar a personas menores de edad, sin embargo recomendamos la asistencia de un adulto durante la vídeo llamada, este puede ser tu padre o tutor e identificarse también.',
  },
  {
    id: 4,
    title: '¿Dónde puedo comprar mi tratamiento?',
    info: 'Puedes comprar tu tratamiento directamente en la web, siempre buscaremos ofrecer los precios más competitivos.',
  },
  {
    id: 5,
    title: '¿Dónde aplico mi cupón?',
    info: 'Ingresa al menú lateral de la pagina y en el apartado de cupones puedes añadir un cupón de descuento o compartir tu código para que un amigo y tu reciban un descuento.',
  },
  {
    id: 6,
    title: '¿Cúal es el costo de la consulta?',
    info: 'El costo es de $799 MXN, puedes conseguir un descuento de $600 MXN por cada amigo tuyo que utilice tu cupón de descuento.',
  },
  {
    id: 7,
    title: 'Si tengo una duda ¿Dónde me puedo comunicar?',
    info: 'Puedes encontrar soporte en nuestras redes sociales: Facebook e Instagram o en el menú principal de ayuda te enlazaremos a nuestro correo:contacto@mynextderma.com',
  },
]

export const cfdi = {
  D07: 'D07 Primas por seguros de gastos médicos.',
  D02: 'D02 Gastos médicos por incapacidad o discapacidad',
  D01: 'D01 Honorarios médicos, dentales y gastos hospitalarios.',
  G03: 'G03 Gastos en general',
}

export const filterBlocks = {
  0: 'Cualquiera',
  1: '15 m',
  2: '30 m',
  3: '45 m',
  4: '1 h',
  5: '1.15 m',
  6: '1.30 m',
  7: '1.45 m',
  8: '2 h',
}

export const divisas = {
  Dolar: 'Dolar',
  Peso_MX: 'Peso mexicano',
  Peso_CO: 'Peso colombiano',
}
