const QUESTIONNAIRE_REQUEST = 'QUESTIONNAIRE_REQUEST'
const QUESTIONNAIRE_SUCCESS = 'QUESTIONNAIRE_SUCCESS'
const QUESTIONNAIRE_FAILURE = 'QUESTIONNAIRE_FAILURE'

const CLEAR_QUESTIONNAIRES = 'CLEAR_QUESTIONNAIRES'

const questionnaireRequest = () => ({
  type: QUESTIONNAIRE_REQUEST,
})

const questionnaireSuccess = (payload = {}) => ({
  type: QUESTIONNAIRE_SUCCESS,
  payload,
})

const questionnaireFailure = (payload = {}) => ({
  type: QUESTIONNAIRE_FAILURE,
  payload,
})

const clearQuestionnairesAction = () => ({
  type: CLEAR_QUESTIONNAIRES,
})

export default {
  types: {
    QUESTIONNAIRE_REQUEST,
    QUESTIONNAIRE_SUCCESS,
    QUESTIONNAIRE_FAILURE,
    CLEAR_QUESTIONNAIRES,
  },
  creators: {
    questionnaireRequest,
    questionnaireSuccess,
    questionnaireFailure,
    clearQuestionnairesAction,
  },
}
