import PropTypes from 'prop-types'
import { TitleCustom, CardMyFavourites } from '..'

const myFavourites = ({ docsFavs, deleteDoctorFav, userId }) => {
  return (
    <>
      <TitleCustom className="are-color-mynd-grey has-text-centered is-size-title- mt-5">
        Mis Favoritos
      </TitleCustom>
      <div className="doctor-list-view">
        <div className={'doctor-list-view list-full'}>
          <div className="doctor-cards-list mt-4">
            {docsFavs.map((doctorFav, key) => {
              return (
                <CardMyFavourites
                  reviews={doctorFav.reviews}
                  doctorFav={doctorFav.doctor}
                  key={key}
                  deleteDoctorFav={deleteDoctorFav}
                  userId={userId}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

myFavourites.propTypes = {
  deleteDoctorFav: PropTypes.func,
}

export default myFavourites
