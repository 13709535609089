// import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dispatchers from './dispatchers'

// Components
import { MyFavourites, WiithoutFavorites } from '../../components'
import selectors from './selectors'

// helpers
import { validateFavoriteBySpecialty } from '../../utils/sections'

const Favourites = () => {
  const [docsFavs, setDocsFavs] = useState([])
  const state = useSelector(state => state)
  const { userId, specialty } = selectors(state)
  const dispatch = useDispatch()
  const { getAllDoctorFavs, deleteDoctorFav } = dispatchers(dispatch)

  useEffect(() => {
    const getDoctorsfav = async () => {
      const response = await getAllDoctorFavs({ userId })
      setDocsFavs(
        response.filter(item =>
          validateFavoriteBySpecialty(specialty || 'derma', item.doctor),
        ),
      )
    }
    getDoctorsfav()
  }, [getAllDoctorFavs])

  return (
    <div className="container is-margin-container mt-5">
      {docsFavs.length > 0 ? (
        <MyFavourites
          docsFavs={docsFavs}
          deleteDoctorFav={deleteDoctorFav}
          userId={userId}
        />
      ) : (
        <WiithoutFavorites />
      )}
    </div>
  )
}

Favourites.propTypes = {}

export default Favourites
