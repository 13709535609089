import PropTypes from 'prop-types'
import {
  ButtonCustom,
  ImageCustom,
  TextCustom,
  TitleCustom,
} from '../../../components'
import {
  validateConsultFastService,
  validateConsultPractiService,
} from '../../../utils/sections'
import { Link } from 'react-router-dom'
import Images from '../../../assets'

const SpecialitySelector = ({ validateSpecialty, specialty }) => {
  const serviceRoute = serviceType => {
    return `/user/service/${serviceType}-service`
  }

  const {
    HoursService,
    LiveService,
    dermaIcon,
    psicologyIcon,
    nutririonIcon,
    generalDoctorIcon,
  } = Images

  const specialtyText = {
    derma: 'Dermatológia',
    psychology: 'Psicológia',
    nutrition: 'Nutricion',
    medicine: 'General',
  }

  let specialityType

  switch (specialty) {
    case undefined:
    case null:
      specialityType = 'is-background-derma'
      break
    case 'psychology':
      specialityType = 'is-background-psychology'
      break
    case 'nutrition':
      specialityType = 'is-background-nutrition'
      break
    case 'medicine':
      specialityType = 'is-background-medicine'
      break
    default:
      break
  }

  return (
    <div>
      <div className="my-6 ">
        <TextCustom className="title has-text-centered	">
          ¿En qué te podemos ayudar hoy?
        </TextCustom>
      </div>
      <div className="field">
        <div className="columns">
          <div className="speciality-select-item mb-6">
            <img src={dermaIcon} width="100" />
            <ButtonCustom
              buttonClassName="button is-medium has-text-white is-button-address mt-2 is-background-derma"
              onClick={() => validateSpecialty('derma')}
            >
              Dermatológica
            </ButtonCustom>
          </div>
          <div className="speciality-select-item mb-6">
            <img src={psicologyIcon} width="100" />
            <ButtonCustom
              buttonClassName="button is-medium has-text-white is-button-address mt-2 is-background-psychology"
              onClick={() => validateSpecialty('psychology')}
            >
              Psicológica
            </ButtonCustom>
          </div>

          <div className="speciality-select-item mb-6">
            <img src={nutririonIcon} width="100" />
            <ButtonCustom
              buttonClassName="button is-medium has-text-white is-button-address mt-2 is-background-nutrition"
              onClick={() => validateSpecialty('nutrition')}
            >
              Nutricional
            </ButtonCustom>
          </div>
          <div className="speciality-select-item mb-6">
            <img src={generalDoctorIcon} width="100" />
            <ButtonCustom
              buttonClassName="button is-medium has-text-white is-button-address mt-2 is-background-medicine"
              onClick={() => validateSpecialty('medicine')}
            >
              General
            </ButtonCustom>
          </div>
        </div>
      </div>
      <section className="modal-card-body">
        <div className="container">
          <div
            className={`icon-text p-4 mb-5 is-size-box-speciality ${specialityType}`}
          >
            <span className="icon has-text-warning"></span>
            <span className="regular-font">
              {' '}
              {specialty === undefined || specialty === null
                ? 'Actualmente estás agendando consultas de Dermatológia'
                : `Actualmente estás agendando consultas de especialidad ${
                    specialtyText[specialty || 'derma']
                  }`}
            </span>
          </div>

          <TitleCustom className="are-color-mynd-grey has-text-weight-semibold has-text-centered is-size-5 mb-3">
            Realiza tu consulta
          </TitleCustom>
          <TextCustom className="are-color-mynd-grey  has-text-centered mb-4 mt-0">
            Tenemos esta opciones disponibles con la especialidad que
            seleccionaste
          </TextCustom>

          <div className="columns is-mobile is-centered mb-4">
            {validateConsultFastService() && (
              <div className="column is-5  is-margin-consult-modal mr-2">
                <div className="is-size-box-modal-consult">
                  <ImageCustom img={HoursService} className="is-img-modal" />
                  <TitleCustom className="is-fastservice-modal has-text-centered p-0 mb-0 mt-2">
                    Live
                  </TitleCustom>
                  <hr className="mt-2 is-hr-fastservice mt-4 "></hr>
                  <TextCustom className="is-modal-text-fastservice mt-4">
                    Realiza una consulta <br /> con un especialista de
                    <br />
                    <span className="has-text-weight-semibold">
                      forma online.
                    </span>
                  </TextCustom>
                  <div className="columns mt-2">
                    <div className="column mx-5">
                      <Link
                        className="button is-fullwidth is-modal-button-fastservice "
                        to={serviceRoute('fast')}
                      >
                        Elegir
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {validateConsultPractiService() && (
              <div className="column is-5">
                <div className="primary-box is-size-box-modal-consult regular-padding">
                  <ImageCustom img={LiveService} className="is-img-modal" />
                  <TitleCustom className="is-practiservice-modal has-text-centered p-0 mb-0 mt-2">
                    45 horas
                  </TitleCustom>
                  <hr className="mt-2 is-hr-practiservice mt-4 "></hr>
                  <TextCustom className="is-modal-text-fastservice mt-4">
                    Envía tu consulta y <br /> recibe un diagnóstico
                    <br />
                    <span className="has-text-weight-semibold">
                      dentro 45 hrs.
                    </span>
                  </TextCustom>
                  <div className="columns mt-2">
                    <div className="column mx-5">
                      <Link
                        className="button is-fullwidth is-modal-button-practiservice "
                        to={serviceRoute('practi')}
                      >
                        Elegir
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

SpecialitySelector.propTypes = {
  validateSpecialty: PropTypes.func,
  specialty: PropTypes.string,
}
export default SpecialitySelector
