// import PropTypes from 'prop-types'

// Components
import { PersonalInformation } from '../../components'

const PersonalInfo = () => {
  return (
    <div className="container mt-6 mb-5">
      <PersonalInformation />
    </div>
  )
}

PersonalInfo.propTypes = {}

export default PersonalInfo
