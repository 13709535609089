import PropTypes from 'prop-types'

// components
import { ProgressCustom } from '../../components'

const PogressContainer = ({ specialty, step, typeConsult }) => {
  // Sections by specialty
  // diseble for psychology & medicine
  if (specialty === 'psychology' || specialty === 'medicine') {
    return null
  }
  // nutrition for (fast-service)
  if (specialty === 'nutrition') {
    return (
      <div className="columns ml-1">
        <div className="columns is-progress-inactive">
          <div className="column">
            <ProgressCustom step={step} step_number={1} />
          </div>
          <div className="column">
            <ProgressCustom step={step} step_number={2} />
          </div>
        </div>
      </div>
    )
  }
  // derma for (practi-service & fast-service)
  return (
    <div className="columns ml-1">
      <div className="columns is-progress-inactive">
        <div className="column">
          <ProgressCustom step={step} step_number={1} />
        </div>
        <div className="column">
          <ProgressCustom step={step} step_number={2} />
        </div>
        <div className="column">
          <ProgressCustom step={step} step_number={3} />
        </div>
        {typeConsult !== 'practi-service' && (
          <div className="column">
            <ProgressCustom step={step} step_number={4} />
          </div>
        )}
      </div>
    </div>
  )
}

PogressContainer.propTypes = {
  specialty: PropTypes.string,
  step: PropTypes.number,
  typeConsult: PropTypes.string,
}

export default PogressContainer
