import { useState } from 'react'
import { Header, Footer, Loading, ReCaptcha } from '../../components'
import { useCounter } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { PostPhone, ValidationPhone } from '../../containers'
import dispatchers from './dispatchers'
import selectors from './selectors'
import { verifyPhone } from '../../services'

const PhoneAuth = () => {
  const dispatch = useDispatch()
  const { signInWhitPhone } = dispatchers(dispatch)
  const { ui, auth } = useSelector(state => state)
  const { loading } = selectors(ui, auth)
  const [phone, setPhone] = useState()
  const { count, increment, decrement } = useCounter(1)
  const [verifier, setVerifier] = useState()
  const [token, setToken] = useState()
  const [verificationId, setVerificationId] = useState()

  const onClickSend = async number => {
    setPhone(number)
    const verify = await verifyPhone(number, verifier)
    const { error, token } = verify
    if (!error) {
      setVerificationId(token)
      increment()
    }
  }

  const onClickCompare = async code => {
    signInWhitPhone(verificationId, code)
  }

  return (
    <div className="hero is-fullheight">
      <Header />
      <div className="hero-body">
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              {loading && <Loading />}
              {count === 1 && !loading && (
                <PostPhone
                  setStep={increment}
                  token={token}
                  onClick={phone => onClickSend(phone)}
                >
                  <ReCaptcha
                    setToken={setToken}
                    setVerifier={setVerifier}
                    size={'normal'}
                  />
                </PostPhone>
              )}
              {count === 2 && !loading && (
                <ValidationPhone
                  phone={phone}
                  setStep={increment}
                  setPrevious={decrement}
                  maxLength={6}
                  onClick={code => onClickCompare(code)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer title="© 2020 My NextDerma. Todos los derechos reservados" />
    </div>
  )
}

export default PhoneAuth
