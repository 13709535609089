// components
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useField, getBlogArticles, useRouter } from '../../utils'
import {
  Input,
  TitleCustom,
  TextCustom,
  Button,
  LabelCustom,
  ArticleItem,
} from '../../components'
import dispatchers from './dispatchers'
import selectors from '../Blog/selectors'
import { Link } from 'react-router-dom'

const BlogDetail = ({ location }) => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const { saveArticle, removeArticle } = dispatchers(dispatch)

  const { category, post } = location.state.article
  const { blog } = useSelector(state => state)
  const { savedArticles, categoryOfInterest } = selectors(blog)

  const [searchText, setSearchText] = useState('')
  const [similarNews, setSimilarNews] = useState([])

  const getArticles = async () => {
    const list = await getBlogArticles({
      limit: 5,
      parm: `categories=${category.id}&exclude=${post.id}`,
    })
    setSimilarNews(list)
  }

  useEffect(() => {
    getArticles()
  }, [])

  const ifArticleIsSaved = () => {
    try {
      if (savedArticles.indexOf(post.id) === -1) {
        return false
      } else {
        return true
      }
    } catch (error) {
      return false
    }
  }

  const btnActionArticle = () => {
    if (ifArticleIsSaved()) {
      removeArticle({
        savedArticles,
        categoryOfInterest,
        blogId: post.id,
      })
    } else {
      saveArticle({
        savedArticles,
        categoryOfInterest,
        blogId: post.id,
        category: category.id,
      })
    }
  }

  const articlesBtn = useField({
    icon: ifArticleIsSaved()
      ? 'fa fa-bookmark fa-lg'
      : 'fa fa-bookmark-o fa-lg',
    iconClassName: 'is-medium',
    className: 'button is-success article-btn-mr10',
    onClick: () => btnActionArticle(),
  })

  const shareBtn = useField({
    icon: 'fa fa-share-alt fa-lg',
    iconClassName: 'is-medium',
    className: 'button is-share article-btn-mr50',
    onClick: () => {
      navigator.clipboard.writeText(post.link)
      alert('URL del articulo copiado: ' + post.link)
    },
  })

  return (
    <div className="container is-margin-container mt-5">
      <TitleCustom className="has-text-centered are-color-mynd-grey is-size-title">
        My derma blog
      </TitleCustom>
      <div className="field is-centered search-container">
        <TextCustom className="control has-icons-left has-icons-right search-size">
          <>
            <Input
              className="input is-fullwidth are-color-mynd-grey"
              type="text"
              placeholder="Buscar en my derma blog (enter para buscar)"
              value={searchText}
              onChange={({ target }) => setSearchText(target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchText) {
                  history.push({
                    pathname: '/user/blog',
                    state: { search: searchText },
                  })
                }
              }}
            />
            <span className="icon is-small is-right">
              <i className="fas fa-search"></i>
            </span>
          </>
        </TextCustom>
      </div>
      <div className="field">
        <Button {...articlesBtn} />
        <Button {...shareBtn} />
        <span className="status status-tag article-btn-mr10">
          {category.name}
        </span>
        <label>{moment(post.date).format('MMMM D, YYYY').toString()}</label>
      </div>
      <div
        className="article-html"
        dangerouslySetInnerHTML={{ __html: post.content.rendered }}
      ></div>
      <div>
        <LabelCustom className="label are-color-mynd-grey">
          Noticias similares
        </LabelCustom>
        <div className="horizontal-scroll">
          {similarNews.map((item, index) => (
            <Link
              key={`${index}_similarNews`}
              to={{ pathname: '/user/blogDetail', state: { article: item } }}
            >
              <ArticleItem
                size="small"
                category={item.category.name}
                title={item.post.title.rendered}
                description={item.post.excerpt.rendered}
                date={item.post.date}
                content={item.image}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

BlogDetail.propTypes = {
  location: PropTypes.any,
}

export default BlogDetail
