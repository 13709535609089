import { processData } from '../../utils'
import { usersF } from '../../config'

const verifyIsNew = async (result, provider) => {
  try {
    const { user, additionalUserInfo } = result
    const { uid, email, photoURL, phoneNumber } = user
    const { isNewUser } = additionalUserInfo
    const dataUser = {
      email,
      type: 'patient',
      provider,
      phone: phoneNumber,
      photoURL: photoURL,
      // isNewUser,
    }
    if (isNewUser) {
      await usersF.doc(uid).set(dataUser, { merge: true })
      const data = await usersF.doc(uid).get()
      return await processData(data)
    }
    const userFound = await usersF.doc(uid).get()
    if (userFound.exists) {
      userFound.photoURL = photoURL
      userFound.phoneAuth = phoneNumber
      userFound.emailAuth = email
      const data = await processData(userFound)
      return data
    }
  } catch (error) {
    return { error }
  }
}

export default verifyIsNew
