import { getAllOrdersService } from '../../services'

export default dispatch => {
  return {
    getAllOrders: ({ userId, page, refreshing_mode }) => {
      dispatch(
        getAllOrdersService({
          userId,
          page,
          refreshing_mode,
        }),
      )
    },
  }
}
