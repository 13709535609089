import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewAddressService } from '../../services'
import Geocode from 'react-geocode'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

const search = () => {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)
  const dispatch = useDispatch()
  const { uid } = useSelector(state => state.auth.user)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })
  const [addressData, setAddressData] = useState({})
  const [interiorNumber, setInteriorNumber] = useState('')

  const handleInput = e => {
    setValue(e.target.value)
  }

  const selectAddress =
    ({ description }) =>
    () => {
      setValue(description, false)
      clearSuggestions()
      getGeocode({ address: description })
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          Geocode.fromLatLng(lat, lng).then(response => {
            const address = response.results[0].formatted_address
            let street,
              number,
              int_number,
              vicinity,
              postal_code,
              locality,
              state,
              country
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case 'route':
                    street = response.results[0].address_components[i].long_name
                    break
                  case 'street_number':
                    number = response.results[0].address_components[i].long_name
                    break
                  case 'internal_street_number':
                    int_number =
                      response.results[0].address_components[i].long_name
                    break
                  case 'sublocality':
                    vicinity =
                      response.results[0].address_components[i].long_name
                    break
                  case 'postal_code':
                    postal_code =
                      response.results[0].address_components[i].long_name
                    break
                  case 'locality':
                    locality =
                      response.results[0].address_components[i].long_name
                    break
                  case 'administrative_area_level_1':
                    state = response.results[0].address_components[i].long_name
                    break
                  case 'country':
                    country =
                      response.results[0].address_components[i].long_name
                    break
                }
              }
            }
            const newAddress = {
              description: address,
              street,
              number,
              int_number,
              vicinity,
              postal_code,
              locality,
              state,
              country,
              coords: { lat, lng },
              isSelected: true,
            }
            setAddressData(newAddress)
          })
        })
        .catch(error => {
          console.log('😱 Error: ', error)
        })
    }

  const saveAddress = () => {
    const finalAddressData = addressData
    finalAddressData.int_number = interiorNumber
    dispatch(addNewAddressService(uid, addressData))
    setAddressData({})
    setInteriorNumber('')
  }

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion
      return (
        <div
          className="card-footer"
          key={place_id}
          onClick={selectAddress(suggestion)}
        >
          <p className="p-2">
            <strong>{main_text + ' '}</strong>
            <small>{secondary_text}</small>
          </p>
        </div>
      )
    })

  return (
    <>
      <div className="control has-icons-right is-size-search">
        <input
          className="input"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Introduce tu nueva direccion"
        />
        {status === 'OK' && <div className="card">{renderSuggestions()}</div>}
        <span className="icon is-right">
          <i className="fas fa-search"></i>
        </span>
        {Object.keys(addressData).length > 0 && (
          <div className="mt-5">
            <p>
              <strong>¿Desea guardar esta dirección?</strong>
            </p>
            <p>{addressData.description}</p>
            <input
              className="input"
              value={interiorNumber}
              onChange={e => setInteriorNumber(e.target.value)}
              placeholder="Introduce tu numero de interior"
            />
            <button
              className={'button is-colour-button-coupon is-medium mt-5'}
              onClick={() => saveAddress()}
            >
              Guardar
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default search
