import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { firebase, usersF } from '../../config/index'

// Components
import {
  ImageCustom,
  TextCustom,
  TitleCustom,
  ButtonCustom,
  Ratings,
  ModalQuey,
  Button,
  Tabs,
  CommentDoctorProfile,
  InfoSectionList,
} from '../../components'
import { useRouter } from '../../utils'
import Images from '../../assets'

const doctorprofile = ({
  uid,
  fullname,
  photoURL,
  consult_tracker,
  profesionalIds,
  awards,
  lastJob,
  specialty,
  extraInfo,
  getCategoriesForSpecialty,
  reviewsCount,
  reviews,
  score,
  about,
}) => {
  const [showAllReviews, setShowAllReviews] = useState(false)
  const [modalState, setModalState] = useState()
  const [stars, setStars] = useState([])
  const [fiveStarts, setFiveStart] = useState([])
  const [forStarts, setForStarts] = useState([])
  const [threStarts, setThreeStarts] = useState([])
  const [twoStarts, setTwoStarts] = useState([])
  const [oneStarts, setOneStarts] = useState([])

  const { Comment, Score, Heart, user_default, peopleIcon, arrowComentsIcon } =
    Images

  const toggleModal = () => {
    setModalState(false)
  }
  const { query } = useRouter()
  const { id } = query

  const [favoriteDoctors, setFavoriteDoctors] = useState([])

  useEffect(() => {
    const unsuscribe = usersF.doc(uid).onSnapshot(result => {
      const { favorities } = result.data()
      setFavoriteDoctors(favorities)
    })

    return () => {
      unsuscribe()
    }
  }, [])

  useEffect(() => {
    const getDoctorReviewStart = async () => {
      const starts = reviews.map(r => r.score)
      setStars(starts)

      // filtrado de estrellas
      const fiveStarts = starts.filter(s => s === 5)
      setFiveStart(fiveStarts)
      const forStarts = starts.filter(star => star === 4)
      setForStarts(forStarts)
      const threeStarts = starts.filter(star => star === 3)
      setThreeStarts(threeStarts)
      const twoStarts = starts.filter(star => star === 2)
      setTwoStarts(twoStarts)
      const oneStarts = starts.filter(star => star === 1)
      setOneStarts(oneStarts)
    }

    getDoctorReviewStart()
  }, [])

  const showPsychologyExtraInformation = () => {
    if (specialty === 'psychology') {
      if (!(Object.keys(extraInfo).length === 0)) {
        return (
          <div className="box">
            <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mb-2">
              Información del psicólogo
            </TextCustom>

            <InfoSectionList
              title={'Modelo de trabajo:'}
              list={extraInfo.workModel}
            />

            <InfoSectionList
              title={'Areas de atención:'}
              list={extraInfo.areasOfAttention}
            />

            <InfoSectionList
              title={'Formación académica:'}
              list={extraInfo.academicFormation}
            />

            <InfoSectionList
              title={'Experiencia profesional:'}
              list={extraInfo.professionalExperience}
            />

            <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mt-2">
              Acerca de psicólogo:
            </TextCustom>
            <TextCustom>{extraInfo.aboutMe}</TextCustom>
          </div>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const isInFavorites = () => {
    return favoriteDoctors && favoriteDoctors.includes(id)
  }

  const handleOnFavoriteClick = () => {
    addDoctorFav()
  }

  const addDoctorFav = async () => {
    const field = firebase.firestore.FieldValue
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .update(
          {
            favorities: isInFavorites()
              ? field.arrayRemove(id)
              : field.arrayUnion(id), // arrayUnion=> si el doctor no es favorito, arrayRemove=> si el doctor ya fue seleccionado
          },
          { merge: true },
        )
    } catch (error) {
      console.log(error)
    }
  }

  const showPreviewNumber = () => {
    let enable = true
    let currentComments = 0
    if (showAllReviews) {
      currentComments = reviews.length
    } else {
      if (reviews.length <= 3) {
        enable = false
        currentComments = reviews.length
      } else {
        currentComments = 3
      }
    }
    return {
      enable,
      currentComments,
    }
  }

  const showListComments = () => {
    if (showAllReviews) {
      return reviews
    } else {
      if (reviews.length <= 3) {
        return reviews
      } else {
        return reviews.slice(0, 3)
      }
    }
  }

  return (
    <div className="columns is-centered mb-3">
      <div className="column is-size-profile-doctor">
        <div className="box">
          <article className="media is-position-mobile-card-doctor">
            <div className="media-left is-img-size-doctor-profile ">
              <ImageCustom
                img={photoURL || user_default}
                className="is-border-radius"
              />
            </div>
            <div className="media-content">
              <div className="is-flex mt-4 is-center-content-profile is-justify-content-space-between ">
                <TextCustom className="is-size-title-doctor mt-1 ">
                  {fullname}
                </TextCustom>
                <Button
                  onClick={handleOnFavoriteClick}
                  className="button is-buttom-favorite"
                >
                  <span className="is-button-favorite-span">
                    {isInFavorites()
                      ? 'Quitar de favoritos'
                      : 'Añadir a favoritos'}
                  </span>
                  <span
                    className={
                      isInFavorites()
                        ? 'heart-selected full-center ml-2'
                        : 'heart full-center ml-2'
                    }
                  >
                    <Heart />
                  </span>
                </Button>
              </div>
              <TextCustom className="are-color-mynd-grey has-text-weight-bold mt-5 is-text-position">
                Cargo:{' '}
                <span className="has-text-weight-light">Especialista</span>
              </TextCustom>
              <div className="is-flex is-justify-content-space-between">
                <div className="is-box-experience">
                  <span className="icon is-icon-size has-text-centered pt-2">
                    <Score />
                  </span>
                  <TextCustom className="is-text-experience has-text-centered">
                    Calificación {score}
                  </TextCustom>
                </div>
                <div className="is-box-experience">
                  <span className="icon is-icon-size has-text-centered pt-2">
                    <Comment />
                  </span>
                  <TextCustom className="is-text-experience has-text-centered">
                    {reviewsCount} comentarios
                  </TextCustom>
                </div>
                <div className="is-box-experience">
                  <span className="icon is-icon-size has-text-centered pt-2">
                    <ImageCustom
                      img={peopleIcon}
                      className="is-size-icons-doctor"
                    />
                  </span>
                  <TextCustom className="is-text-experience has-text-centered">
                    {consult_tracker} pacientes
                  </TextCustom>
                </div>
              </div>
            </div>
          </article>
        </div>

        <Tabs
          profesionalIds={profesionalIds}
          awards={awards}
          lastJob={lastJob}
        />
        {showPsychologyExtraInformation()}
        <div className="box">
          {about && (
            <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mb-2">
              Acerca de:
            </TextCustom>
          )}
          {about && (
            <TextCustom className="are-color-mynd-grey is-size-6">
              {about}
            </TextCustom>
          )}

          <TextCustom className="are-color-mynd-grey is-size-6 has-text-weight-bold mb-2 mt-3">
            Mayor experiencia en:
          </TextCustom>
          <TextCustom>{specialty}</TextCustom>
        </div>
        <div className="box">
          <TitleCustom className="has-text-centered are-color-mynd-grey is-size-4 pb-4">
            Total de valoraciones
          </TitleCustom>
          <Ratings
            stars={stars}
            fiveStarts={fiveStarts}
            forStarts={forStarts}
            threStarts={threStarts}
            twoStarts={twoStarts}
            oneStarts={oneStarts}
          />
          <div className="level is-mobile mt-5">
            <div className="level-left ">
              <TextCustom className="has-text-weight-bold is-size-6 are-color-mynd-grey">
                Comentarios ({showPreviewNumber().currentComments})
              </TextCustom>
            </div>
            {showPreviewNumber().enable && (
              <div className="level-right mr-6">
                <span className="icon-text  is-colour-see-more">
                  <span
                    className="is-size-7 has-text-weight-bold is-clickable"
                    onClick={() => setShowAllReviews(!showAllReviews)}
                  >
                    {showAllReviews ? 'Ver menos' : 'Ver más'}
                  </span>
                  <span className="icon">
                    <ImageCustom img={arrowComentsIcon} />
                  </span>
                </span>
              </div>
            )}
          </div>
          {showListComments().map((review, key) => {
            return <CommentDoctorProfile review={review} key={key} />
          })}
        </div>
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <ButtonCustom
              buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary"
              handleOnClick={() => {
                setModalState(true)
                getCategoriesForSpecialty('derma')
              }}
            >
              Agendar cita
            </ButtonCustom>
            {modalState && <ModalQuey closeModal={toggleModal} doctor={id} />}
          </div>
        </div>
      </div>
    </div>
  )
}

doctorprofile.propTypes = {
  fullname: PropTypes.string,
  photoURL: PropTypes.string,
  career: PropTypes.string,
  profesionalIds: PropTypes.array,
  awards: PropTypes.array,
  getCategoriesForSpecialty: PropTypes.func,
  getDeferredPrice: PropTypes.func,
  specialty: PropTypes.string,
  extraInfo: PropTypes.any,
  uid: PropTypes.any,
  reviewsCount: PropTypes.number,
  reviews: PropTypes.array,
  score: PropTypes.number,
}

export default doctorprofile
