export default state => {
  const { address, orderDrugs, auth } = state
  const { data: allAddress } = address
  const { currentReceipt, consultId, isLoading, recipe_date } = orderDrugs
  const { user } = auth
  const { data, uid } = user
  const { cards } = data || {}
  const { balance } = data || {}
  const { email, phone, fullname } = data || {}
  return {
    isLoading,
    uid,
    consultId,
    allAddress,
    currentReceipt,
    cards,
    balance,
    email,
    phone,
    fullname,
    recipe_date,
  }
}
