import PropTypes from 'prop-types'
import Images from '../../assets'
import dispatchers from './dispatchers'
import { useRouter, markNotificationSeen } from '../../utils'
import { useDispatch } from 'react-redux'
import { consultRef } from '../../config'
import moment from 'moment'

const NotificationItem = ({ data, onPressClose }) => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const { addCurrentReceipt } = dispatchers(dispatch)
  const { Sessions, DoctorsNotification } = Images

  const notificationAction = async () => {
    onPressClose()
    markNotificationSeen(data.id)
    const consult = await consultRef
      .doc(data.source_id)
      .get()
      .then(consult => consult.data())
    if (data.source === 'recipe') {
      addCurrentReceipt(
        consult.recipe,
        data.source_id,
        moment(new Date(consult.recipe_date.seconds * 1000)),
      )
      history.push('/user/order-drugs/')
    } else if (data.source === 'session') {
      if (consult.status === 'onCall') {
        history.push(`/user/live_service?id=${data.source_id}`)
      }
    }
  }

  return (
    <div className="notify_item" onClick={() => notificationAction()}>
      <div className="notify_img">
        {data.source === 'session' && <Sessions />}
        {data.source === 'recipe' && <DoctorsNotification />}
      </div>
      <div className="notify_info">
        <p>{data.text}</p>
        <span className="notify_time">
          {moment(data.created_at.toDate())
            .format('DD/MM/YYYY hh:mm A')
            .toString()}
        </span>
      </div>
      <div className="notify_status">
        {!data.readed && <i className="fas fa-circle visible"></i>}
      </div>
    </div>
  )
}

NotificationItem.propTypes = {
  data: PropTypes.any,
  onPressClose: PropTypes.any,
}

export default NotificationItem
