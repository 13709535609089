import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import validator from 'validator'
import { useState, useEffect, memo } from 'react'
import {
  TextCustom,
  TitleCustom,
  Button,
  Calendar,
  Hours,
} from '../../components'
import { format } from 'date-fns'
import moment from 'moment-timezone'
import selectors from './selectors.js'

// helpers
import { validatePrivateSchedule } from '../../utils/sections'

const ScheduleService = ({
  specialtyScheadule,
  typeConsult,
  getAvailableHours,
  getAvailableHoursPrivate,
  getBlockedDays,
  getBlockedDaysPrivate,
  increment,
  addError,
  removeError,
  errors,
  decrement,
  setScheadule,
  doctorId,
  goToOrders,
  disableBack,
}) => {
  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  const [selectedHour, setSelectedHour] = useState('')
  const [selectedDate, setSelectedDate] = useState({})
  const [availableHours, setAvailableHours] = useState([])
  const [cleanup, setCleanup] = useState(false)
  const [reLoading, setReLoading] = useState(false)
  const currentHour = moment().format('HH:mm')
  useEffect(() => {
    return () => {
      setCleanup(true)
    }
  }, [])

  useEffect(() => {
    setAvailableHours([])
    loadAvailableHours()
  }, [selectedDate])

  const loadAvailableHours = async () => {
    if (!cleanup) {
      const temp = []
      try {
        if (Object.prototype.toString.call(selectedDate) === '[object Date]') {
          const validatePrivate = await validatePrivateSchedule(
            privateFlag,
            doctorId,
          )
          const date = format(selectedDate, 'yyyy-MM-dd')
          const time = format(selectedDate, 'HH:mm:ss')
          const sendData = {
            date,
            time,
            timezone: moment.tz.guess(),
          }
          if (!validatePrivate) {
            sendData.specialty = specialtyScheadule || 'derma'
          }
          if (doctorId) {
            sendData.doctorId = doctorId
          }
          const { data } = validatePrivate
            ? await getAvailableHoursPrivate(sendData)
            : await getAvailableHours(sendData)
          const available =
            moment(selectedDate).format('L') === moment().format('L')
              ? data.filter(
                  item =>
                    moment(item, ['h:mm a']).format('HH:mm') > currentHour,
                )
              : data

          if (available.length === 0) {
            setReLoading(true)
            return
          }
          while (available.length) temp.push(available.splice(0, 3))
          setAvailableHours(temp)
        }
      } catch (error) {
        addError(error)
      }
    }
  }

  const handleOnClick = () => {
    if (validator.isDate(selectedHour) || validator.isEmpty(selectedHour)) {
      return addError('Debe seleccionar una fecha y/o un hora')
    }
    setScheadule({
      selectedDate,
      selectedHour,
    })
    removeError()
    if (goToOrders) {
      goToOrders(typeConsult)
    } else {
      increment()
    }
  }

  return (
    <>
      <div className="level">
        <div className="level-left">
          <TitleCustom className="title is-size-5 are-color-mynd-grey ml-1">
            Elige una fecha disponible
          </TitleCustom>
        </div>
      </div>
      {errors && <div className="notification is-danger mt-3">{errors}</div>}
      <TextCustom className="are-color-mynd-grey is-size-6">
        Puede no estar disponibles todos los horarios y fechas
      </TextCustom>
      <div className="columns">
        <div className="column is-full mt-2">
          <Calendar
            specialtyScheadule={specialtyScheadule}
            getBlockedDays={getBlockedDays}
            getBlockedDaysPrivate={getBlockedDaysPrivate}
            setSelectedDate={setSelectedDate}
            doctorId={doctorId}
            addError={addError}
            removeError={removeError}
            reLoading={reLoading}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextCustom className="are-color-mynd-grey is-size-6 ml-5 mb-3 has-text-weight-bold ml-1">
            Elige una horario disponible
          </TextCustom>
          <Hours
            selectedHour={selectedHour}
            availableHours={availableHours}
            setSelectedHour={setSelectedHour}
          />
        </div>
      </div>
      <div className="buttons is-centered">
        {disableBack === false && (
          <Button
            className="button is-text-color is-inverted"
            onClick={decrement}
          >
            Anterior
          </Button>
        )}
        <Button
          type="submit"
          className="button is-mnd-primary has-text-white is-padding-button-next  is-size-6"
          onClick={() => handleOnClick()}
        >
          Siguiente
        </Button>
      </div>
    </>
  )
}

ScheduleService.defaultProps = {
  disableBack: false,
}

ScheduleService.propTypes = {
  specialtyScheadule: PropTypes.string,
  typeConsult: PropTypes.string.isRequired,
  getBlockedDays: PropTypes.func,
  getBlockedDaysPrivate: PropTypes.func,
  setScheadule: PropTypes.func,
  increment: PropTypes.func,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  errors: PropTypes.string,
  getAvailableHours: PropTypes.func,
  getAvailableHoursPrivate: PropTypes.func,
  decrement: PropTypes.func,
  doctorId: PropTypes.string,
  goToOrders: PropTypes.func,
  disableBack: PropTypes.bool,
}

export default memo(ScheduleService)
