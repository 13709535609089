import PropTypes from 'prop-types'

import {
  ButtonCustom,
  TextCustom,
  TitleCustom,
  CardDoctorLastAppointment,
  AccordionProductavailAble,
  AccordionProductControlled,
  DatingDateBox,
  IndicationsRecipeBox,
  TreatmentVideoBox,
} from '..'
import { Accordion } from 'react-accessible-accordion'

const LastAppointmentContainer = ({ decrement, lastSession }) => {
  return (
    <>
      {lastSession && (
        <div className="column is-size-last-box-appointment">
          <TitleCustom className="are-color-mynd-grey is-title-size mt-4 has-text-centered">
            Tu última cita
          </TitleCustom>
          <DatingDateBox />
          <div className="is-flex ">
            <ButtonCustom buttonClassName="button is-fullwidth has-text-weight-bold mr-4 is-colour-button-schedule-appointments">
              Reagendar Cita
            </ButtonCustom>
            <ButtonCustom buttonClassName="button is-fullwidth has-text-weight-bold is-colour-button-start-appointment">
              Iniciar cita
            </ButtonCustom>
          </div>
          <CardDoctorLastAppointment />
          <IndicationsRecipeBox />
          <TextCustom className="is-size-5 has-text-weight-bold">
            Recetas
          </TextCustom>
          <Accordion allowZeroExpanded className="Acordion-1 mt-3">
            <AccordionProductavailAble />
            <AccordionProductControlled />
          </Accordion>
          <TextCustom className="is-size-5 has-text-weight-bold mt-4">
            Conoce mas sobre tu tratamiento:
          </TextCustom>
          <TreatmentVideoBox />
          <div className="is-flex is-justify-content-center">
            <ButtonCustom
              buttonClassName="button is-text-color is-invertedi is-fullwidth mt-2"
              handleOnClick={decrement}
            >
              Anterior
            </ButtonCustom>
            <ButtonCustom buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary">
              Surtir medicina
            </ButtonCustom>
          </div>
        </div>
      )}
    </>
  )
}

LastAppointmentContainer.propTypes = {
  decrement: PropTypes.func,
  lastSession: PropTypes.object,
}

export default LastAppointmentContainer
