import functions from '../functions'

const { getWarranties } = functions.cloudFunctions

const getTickets = async ({ uid, values }) => {
  try {
    const result = await getWarranties({ uid, values })
    return result
  } catch (error) {
    console.log(error)
  }
}

export default getTickets
