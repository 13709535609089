export default state => {
  const { user } = state.auth
  const { uid } = user
  const { doctors } = state.doctors
  const { specialty } = user.data
  return {
    specialty,
    userId: uid,
    doctors: doctors,
  }
}
