import PropTypes from 'prop-types'
import { TextCustom } from '..'

const LatestWorksBox = ({ Job }) => {
  const { companyName, dateStart, jobTitle, dateEnd } = Job
  return (
    <>
      <div className="block my-3">
        <TextCustom className="are-color-mynd-grey has-text-weight-bold  mb-0">
          {companyName}
        </TextCustom>
        <TextCustom className="are-color-mynd-grey my-0">{jobTitle}</TextCustom>
        <TextCustom className="are-color-mynd-grey my-0">
          {dateStart} - {dateEnd}
        </TextCustom>
      </div>
    </>
  )
}

LatestWorksBox.propTypes = {
  Job: PropTypes.object,
}

export default LatestWorksBox
