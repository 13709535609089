import {
  authActions,
  uiActions,
  consultActions,
  questionnairesActions,
  doctorsActions,
  blogActions,
  clinicalRecordActions,
  myAddressActions,
  orderDrugsActions,
  couponsActions,
  sessionActions,
} from '../../actions'
import {
  clinicalRecordService,
  getAllOrdersService,
  sendProfileImage,
  uploadImageProfile,
  rememberMeService,
  refreshUserService,
  getPrivateDoctor,
} from '../../services'
const { removeAllArticlesAction } = blogActions.creators
const { signOutAction } = authActions.creators
const { clearUI } = uiActions.creators
const { clearQuestionnairesAction } = questionnairesActions.creators
const { clearDoctorsAction } = doctorsActions.creators
const { clearClinicalRecords } = clinicalRecordActions.creators
const { clearAddressAction } = myAddressActions.creators
const { clearOrderDrugsAction } = orderDrugsActions.creators
const { clearCouponsAction } = couponsActions.creators
const { clearRememberMeAction } = sessionActions.creators
const { clearConsultsAction } = consultActions.creators
export default dispatch => {
  return {
    removeAllArticles: () => dispatch(removeAllArticlesAction()),
    signOut: () => {
      dispatch(signOutAction())
      dispatch(clearUI())
      dispatch(clearQuestionnairesAction())
      dispatch(clearDoctorsAction())
      dispatch(clearClinicalRecords())
      dispatch(clearAddressAction())
      dispatch(clearOrderDrugsAction())
      dispatch(clearCouponsAction())
      dispatch(clearRememberMeAction())
      dispatch(clearConsultsAction())
    },
    refresh: uid => dispatch(refreshUserService(uid)),
    clinicalRecord: data => dispatch(clinicalRecordService(data)),
    getAllOrders: ({ userId, page, refreshing_mode }) =>
      dispatch(getAllOrdersService({ userId, page, refreshing_mode })),
    sendProfileImage: (uid, image) => sendProfileImage(uid, image),
    uploadImageProfile: (image, collection, id) =>
      uploadImageProfile(image, collection, id),
    rememberMe: remember => dispatch(rememberMeService(remember)),
    getPrivateDoctor: privateDoctor =>
      dispatch(getPrivateDoctor(privateDoctor)),
  }
}
