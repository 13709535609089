import PropTypes from 'prop-types'

// components
import { LabelCustom, ImageCustom, Input } from '../../../components'

// img
import Images from '../../../assets'

const ProductCategory = ({ handleInputChange }) => {
  const { categoryProductIcon } = Images
  return (
    <div className="box  mx-3 is-flex">
      <span className="icon mr-2">
        <ImageCustom img={categoryProductIcon} />
      </span>
      <div className="is-size-field-nane-product">
        <LabelCustom className="label are-color-mynd-grey">
          Categoría del producto
        </LabelCustom>
        <Input
          className="input is-select-size is-fullwidth"
          type="text"
          placeholder="Categoría del producto"
          name="categories"
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

ProductCategory.propTypes = { handleInputChange: PropTypes.func }

export default ProductCategory
