import PropTypes from 'prop-types'
import { CustomButton } from '../../components/'

const DiaryButtonsContainer = ({
  handleBack,
  handleNext,
  disabled,
  isLoading,
  backLabel,
  nextLabel,
}) => {
  return (
    <div className="diary-buttons">
      <div className="diary-buttons-divs">
        <CustomButton isPrimary handleClick={handleBack}>
          {backLabel}
        </CustomButton>
      </div>
      <div className="diary-buttons-divs">
        <CustomButton
          isPrimary
          isLoading={isLoading}
          isSubmit
          handleClick={handleNext}
          disabled={disabled}
        >
          {nextLabel}
        </CustomButton>
      </div>
    </div>
  )
}

DiaryButtonsContainer.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  backLabel: PropTypes.string,
  nextLabel: PropTypes.string,
}

export default DiaryButtonsContainer
