//* TYPES - REMEMBER ME
const REMEMBER_ME = 'REMEMBER_ME'
const REMEMBER_ME_SUCCESS = 'REMEMBER_ME_SUCCESS'
const REMEMBER_ME_FAILURE = 'REMEMBER_ME_FAILURE'
const CLEAR_REMEMBER_ME = 'CLEAR_REMEMBER_ME'
const rememberMeSucessAction = (payload = {}) => ({
  type: REMEMBER_ME_SUCCESS,
  payload,
})

const rememberMeAction = () => ({
  type: REMEMBER_ME,
})

const rememberMeFailureAction = (payload = {}) => ({
  type: REMEMBER_ME_FAILURE,
  payload,
})

const clearRememberMeAction = () => ({
  type: CLEAR_REMEMBER_ME,
})

export default {
  types: {
    REMEMBER_ME,
    REMEMBER_ME_SUCCESS,
    REMEMBER_ME_FAILURE,
    CLEAR_REMEMBER_ME,
  },
  creators: {
    rememberMeSucessAction,
    rememberMeFailureAction,
    rememberMeAction,
    clearRememberMeAction,
  },
}
