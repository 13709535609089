import PropTypes from 'prop-types'
import {
  TextCustom,
  ImageCustom,
  ButtonCustom,
  CardForm,
  CardsList,
} from '../../components'

import Images from '../../assets'
import { useToggle } from '../../utils'

const CardsPM = ({
  cards,
  paymentMethod,
  setPaymentMethod,
  isLoading,
  isReceiptOrConsult,
  setShowPaypal,
}) => {
  const { cardIcon } = Images
  const [isOpen, setIsOpen] = useToggle()
  const onClick = () => {
    setIsOpen()
    setShowPaypal(false)
  }
  return (
    <div className="white-box">
      <TextCustom className="are-color-mynd-grey has-text-weight-semibold border-bottom ">
        Tus tarjetas de crédito y débito:
      </TextCustom>
      <CardsList
        isReceiptOrConsult
        cards={cards}
        name="card"
        paymentMethod={paymentMethod}
        onClick={setPaymentMethod}
      />
      <hr className="line-bottom my-2"></hr>
      <div className="level mb-0 pb-0 is-mobile" onClick={onClick}>
        <div className="level-left">
          <ImageCustom img={cardIcon} className="card-images" />
          <span>Añadir tarjeta</span>
        </div>
        <div className="level-right">
          <ButtonCustom
            buttonClassName="button is-inverted is-text-color"
            iconClassName="are-color-mynd-grey is-size-5"
            icon="fas fa-plus"
          ></ButtonCustom>
        </div>
        {isOpen && <CardForm closeModal={setIsOpen} isLoading={isLoading} />}
      </div>
    </div>
  )
}

CardsPM.propTypes = {
  isReceiptOrConsult: PropTypes.bool,
  cards: PropTypes.any,
  setPaymentMethod: PropTypes.func,
  isLoading: PropTypes.bool,
  paymentMethod: PropTypes.object,
  setShowPaypal: PropTypes.func,
}

export default CardsPM
