import functions from '../functions'

const { scheduleFastConsult } = functions.cloudFunctions
const scheduleFastServiceService = async data => {
  try {
    return scheduleFastConsult(data)
  } catch (error) {
    return error
  }
}

export default scheduleFastServiceService
