import { getBlockedDaysService, getAvailableHoursService } from '../../services'

import { uiActions } from '../../actions'

const { addError, removeError } = uiActions.creators

export default dispatch => ({
  getAvailableHoursService: data => dispatch(getAvailableHoursService(data)),
  getBlockedDaysService: (year, month) =>
    dispatch(getBlockedDaysService(year, month)),
  addError: error => dispatch(addError(error)),
  removeError: () => dispatch(removeError()),
})
