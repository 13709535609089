import PropTypes from 'prop-types'
import moment from 'moment'

import { TextCustom, ImageCustom } from '../../../components'
// img
import Images from '../../../assets'
import { useEffect, useState } from 'react'

const cardProductDiary = ({
  product,
  setProductsSelected,
  productsSelected,
}) => {
  const { productName, images, created, uri } = product
  const dateProduct = created
    ? moment(new Date(created)).format('LL')
    : moment(new Date()).format('LL')
  const timeDate = created
    ? moment(new Date(created)).format('LT')
    : moment().format('LT')

  const { pillsIcon } = Images
  const [isSelected, setIsSelected] = useState(false)

  const selectProduct = () => {
    if (productsSelected.some(product => product.uri === uri)) {
      const products = productsSelected.filter(product => product.uri !== uri)
      setProductsSelected(products)
      setIsSelected(false)
      return
    }
    const allSelected = [...productsSelected, { uri }]
    setProductsSelected(allSelected)
    setIsSelected(true)
  }

  useEffect(() => {
    if (productsSelected.some(product => product.uri === uri)) {
      setIsSelected(true)
    }
  }, [])

  return (
    <a
      className={`box pt-0 px-0 mt-5 ${isSelected ? 'card-selected' : ''} m-4`}
      onClick={selectProduct}
    >
      <div className="is-size-header-box-product py-1">
        <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey">
          {dateProduct}
          <span className="is-position-hour-product">{timeDate}</span>
        </TextCustom>
      </div>
      <span className="icon-text ml-3 my-3">
        <span className="icon">
          <ImageCustom img={pillsIcon} className="is-icon-product-diary" />
        </span>
        <TextCustom className="has-text-centered is-date-product-diary are-color-mynd-grey has-text-weight-bold">
          {'Producto: '}
          <span className="has-text-weight-normal">{productName}</span>
        </TextCustom>
      </span>

      <hr className="line-bottom my-2"></hr>
      <div className="is-flex is-justify-content-center mt-3">
        <div className="horizontal-scroll mb-4">
          {images && images.uri && (
            <div className={' diary-card-images-container'}>
              <div className="diary-card-image">
                <img src={images.uri} />
              </div>
            </div>
          )}
          <div className={' diary-card-images-container'}>
            <div className="diary-card-image">
              {images && images.productUri && <img src={images.productUri} />}
            </div>
          </div>
          <div className={' diary-card-images-container'}>
            <div className="diary-card-image">
              {images && images.aditionalUri && (
                <img src={images.aditionalUri} />
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

cardProductDiary.propTypes = {
  product: PropTypes.object,
  setProductsSelected: PropTypes.func,
  productsSelected: PropTypes.array,
}

export default cardProductDiary
