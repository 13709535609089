import { questionnairesRef } from '../../config'
import { questionnairesActions } from '../../actions'
const { questionnaireRequest, questionnaireFailure, questionnaireSuccess } =
  questionnairesActions.creators

const getQuestionnaires = (key, lang = 'es') => {
  return async dispatch => {
    try {
      dispatch(questionnaireRequest())
      // Get all questionnaires collection from firebase
      const querySnapShot = await questionnairesRef
        .orderBy('priority', 'asc')
        .get()
      // Map all questionnaires to an array
      const questionnaires = querySnapShot.docs.map(doc => doc.data())
      // Filter questionnaires by General and Selected key
      const selectQuestionnaires = questionnaires.filter(
        questionnaire =>
          questionnaire.key === key || questionnaire.key === 'general',
      )
      // Filter questionnaires by language
      const selectQuestionsByEn = selectQuestionnaires.map(
        questionnaire => questionnaire.questions.en,
      )
      const selectQuestionsByEs = selectQuestionnaires.map(
        questionnaire => questionnaire.questions.es,
      )
      // assign the selected language to the selected questionnaires
      const selectQuestions =
        lang === 'es' ? selectQuestionsByEs : selectQuestionsByEn
      // reduce the array of questions to a single object
      const unionQuestions = selectQuestions.reduce((acc, curr) => {
        return [...curr, ...acc]
      }, [])
      // Dispatch success action
      return dispatch(questionnaireSuccess(unionQuestions))
    } catch (err) {
      return dispatch(questionnaireFailure(err))
    }
  }
}

export default getQuestionnaires
