// import PropTypes from 'prop-types'
import { TitleCustom, ButtonCustom, CustomButton } from '..'

const modalSoon = ({ closeModal }) => {
  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card is-modal-border-radius">
        <section className="modal-card-body">
          <div className="level-right mb-2 is-mobile">
            <ButtonCustom
              icon="far fa-times-circle"
              buttonClassName="button is-inverted is-border-button-modal"
              iconClassName="are-color-mynd-grey is-size-4"
              onClick={closeModal}
            />
          </div>

          <div className="container">
            <TitleCustom className="are-color-mynd-grey has-text-weight-semibold has-text-centered is-size-5 mb-3">
              No hay especialistas disponibles en este momento (Proximamente)
            </TitleCustom>
            <CustomButton isPrimary handleClick={closeModal}>
              Aceptar
            </CustomButton>
          </div>
        </section>
      </div>
    </div>
  )
}

modalSoon.propTypes = {}

export default modalSoon
