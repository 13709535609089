import moment from 'moment'

const getPromotion = (discounts, recipe_date) => {
  const discount = discounts.map(discount => {
    const { time, unit } = discount
    return (
      moment(recipe_date)
        .add(time.toString(), unit)
        .format('YYYY-MM-DD hh:mm:ss A') >
      moment().format('YYYY-MM-DD hh:mm:ss A')
    )
  })

  if (discount.includes(true)) {
    const nowDate = moment().unix()
    const discountDate = moment(recipe_date)
      .add(
        discounts[discount.indexOf(true)].time.toString(),
        discounts[discount.indexOf(true)].unit,
      )
      .unix()

    return {
      percentage: discounts[discount.indexOf(true)].percentage,
      exp: Math.abs(discountDate - nowDate),
    }
  }
}

export default getPromotion
