import { authActions } from '../../actions'

const initialSatate = {
  user: {
    uid: '',
    isNewUser: null,
    data: {
      cards: [],
    },
  },
  isSignIn: false,
  error: {
    message: '',
  },
  sendResetPasswordEmail: false,
  sendResetPasswordEmailError: '',
  resetPassword: false,
  resetPasswordError: '',
}

const {
  CREATE_USER_WITH_EMAIL_AND_PASSWORD,
  CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE,
  FETCH_SIGNIN_ACCOUNTS,
  FETCH_SIGNIN_ACCOUNTS_SUCCESS,
  FETCH_SIGNIN_ACCOUNTS_FAILURE,
  SIGNIN_WITH_EMAIL_AND_PASSWORD,
  SIGNIN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  SIGNIN_WITH_EMAIL_AND_PASSWORD_FAILURE,
  SIGNIN_WITH_SOCIAL_PROVIDER,
  SIGNIN_WITH_SOCIAL_PROVIDER_SUCCESS,
  SIGNIN_WITH_SOCIAL_PROVIDER_FAILURE,
  SIGNIN_WITH_PHONE,
  SIGNIN_WITH_PHONE_SUCCESS,
  SIGNIN_WITH_PHONE_FAILURE,
  SEND_RESET_PASSWORD_EMAIL,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  UNSET_SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  UNSET_CHANGE_PASSWORD_SUCCESS,
  SIGNOUT,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_FAILURE,
  ADD_BALANCE_REQUEST,
  ADD_BALANCE_SUCCESS,
  ADD_BALANCE_FAILURE,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE,
  ADD_CARD_REQUEST,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  REMOVE_AUTH_ERRORS,
} = authActions.types

const authReducer = (state = initialSatate, action) => {
  switch (action.type) {
    case CREATE_USER_WITH_EMAIL_AND_PASSWORD:
    case SEND_RESET_PASSWORD_EMAIL:
    case SIGNIN_WITH_EMAIL_AND_PASSWORD:
    case SIGNIN_WITH_SOCIAL_PROVIDER:
    case SIGNIN_WITH_PHONE:
    case CHANGE_PASSWORD:
    case FETCH_SIGNIN_ACCOUNTS:
    case REFRESH_USER:
    case ADD_BALANCE_REQUEST:
    case ADD_CARD_REQUEST:
    case UPDATE_USER:
      return {
        ...state,
      }
    case CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS:
    case SIGNIN_WITH_EMAIL_AND_PASSWORD_SUCCESS:
    case SIGNIN_WITH_SOCIAL_PROVIDER_SUCCESS:
    case SIGNIN_WITH_PHONE_SUCCESS:
    case FETCH_SIGNIN_ACCOUNTS_SUCCESS:
    case REFRESH_USER_SUCCESS:
      return {
        ...state,
        user: {
          uid: action.payload.uid,
          isNewUser: action.payload.isNewUser,
          data: action.payload.data,
        },
        error: {
          message: '',
        },
        sendResetPasswordEmail: false,
        sendResetPasswordEmailError: '',
        resetPassword: false,
        resetPasswordError: '',
      }
    case CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case SIGNIN_WITH_EMAIL_AND_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case SIGNIN_WITH_SOCIAL_PROVIDER_FAILURE:
    case SIGNIN_WITH_PHONE_FAILURE:
    case FETCH_SIGNIN_ACCOUNTS_FAILURE:
    case SEND_RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        sendResetPasswordEmailError: action.payload,
      }
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordError: action.payload,
      }
    case REFRESH_USER_FAILURE:
    case ADD_BALANCE_FAILURE:
    case ADD_CARD_FAILURE:
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case SIGNOUT:
      return {
        user: {
          uid: '',
          isNewUser: null,
          data: {},
        },
        isSignIn: false,
        error: {
          message: '',
        },
        sendResetPasswordEmail: false,
        sendResetPasswordEmailError: '',
        resetPassword: false,
        resetPasswordError: '',
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          uid: state.user.uid,
          isNewUser: false,
          data: action.payload,
        },
        sendResetPasswordEmail: false,
        sendResetPasswordEmailError: '',
        resetPassword: false,
        resetPasswordError: '',
      }
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        sendResetPasswordEmailError: '',
        sendResetPasswordEmail: action.payload,
      }
    case UNSET_SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        sendResetPasswordEmailError: '',
        sendResetPasswordEmail: action.payload,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: '',
        resetPassword: action.payload,
      }
    case UNSET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        sendResetPasswordEmail: false,
        sendResetPasswordEmailError: '',
        resetPassword: false,
        resetPasswordError: '',
      }
    case ADD_BALANCE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            balance: action.payload,
          },
        },
      }
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            cards: [...state.user.data.cards, action.payload],
          },
        },
      }
    case SIGNUP:
    case SIGNUP_FAILURE:
      return {
        ...state,
        isSignIn: true,
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSignIn: false,
      }
    case REMOVE_AUTH_ERRORS:
      return {
        ...state,
        error: {
          message: '',
        },
      }
    default:
      return state
  }
}

export default authReducer
