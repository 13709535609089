// import PropTypes from 'prop-types'

// Components
import { PurchaseDetail } from '../../components'

const Proceedings = () => {
  return (
    <div className="container mt-5 mb-5">
      <PurchaseDetail />
    </div>
  )
}

Proceedings.propTypes = {}

export default Proceedings
