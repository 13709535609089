const Home = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1231 12.6785C28.1222 12.6778 28.1216 12.677 28.1209 12.6763L16.698 1.25383C16.2111 0.76672 15.5637 0.498596 14.8752 0.498596C14.1866 0.498596 13.5392 0.76672 13.0521 1.25383L1.63518 12.6706C1.63133 12.6744 1.62727 12.6785 1.62364 12.6823C0.623783 13.6879 0.625492 15.3196 1.62855 16.3226C2.08682 16.7811 2.69186 17.0464 3.33899 17.0744C3.36548 17.077 3.39198 17.0783 3.41868 17.0783H3.87374V25.4843C3.87374 27.148 5.2274 28.5014 6.89105 28.5014H11.3601C11.8132 28.5014 12.1805 28.134 12.1805 27.681V21.0905C12.1805 20.3314 12.7981 19.714 13.5572 19.714H16.1931C16.9522 19.714 17.5696 20.3314 17.5696 21.0905V27.681C17.5696 28.134 17.9369 28.5014 18.39 28.5014H22.8591C24.5229 28.5014 25.8764 27.148 25.8764 25.4843V17.0783H26.2985C26.9869 17.0783 27.6342 16.8102 28.1216 16.3228C29.1257 15.3183 29.1261 13.6837 28.1231 12.6785ZM26.9613 15.1627C26.7841 15.3398 26.5487 15.4375 26.2985 15.4375H25.056C24.6028 15.4375 24.2356 15.8047 24.2356 16.2579V25.4843C24.2356 26.2432 23.6181 26.8606 22.8591 26.8606H19.2104V21.0905C19.2104 19.4269 17.857 18.0732 16.1931 18.0732H13.5572C11.8933 18.0732 10.5397 19.4269 10.5397 21.0905V26.8606H6.89105C6.13218 26.8606 5.51454 26.2432 5.51454 25.4843V16.2579C5.51454 15.8047 5.14728 15.4375 4.69414 15.4375H3.47295C3.46013 15.4366 3.44752 15.436 3.43449 15.4358C3.19008 15.4315 2.96084 15.3345 2.78907 15.1625C2.42374 14.7972 2.42374 14.2026 2.78907 13.8371C2.78928 13.8371 2.78928 13.8369 2.7895 13.8366L2.79014 13.836L14.2126 2.41392C14.3895 2.23681 14.6248 2.13939 14.8752 2.13939C15.1253 2.13939 15.3606 2.23681 15.5377 2.41392L26.9576 13.8337C26.9593 13.8354 26.9613 13.8371 26.963 13.8388C27.3264 14.2048 27.3257 14.798 26.9613 15.1627Z"
        // fill="#3A536B"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1"
          y1="8.93277"
          x2="20"
          y2="8.93277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F658B1" />
          <stop offset="1" stopColor="#FD9456" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default Home
