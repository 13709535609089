import PropTypes from 'prop-types'
import { ImageCustom, TitleCustom } from '../../components'

const FeatureHithIcon = ({ image, title, onClick }) => {
  return (
    <a className="three-features" id="profile_picture" onClick={onClick}>
      <ImageCustom img={image} className="image-three-features" />
      <TitleCustom className="is-size-7 has-text-weight-bold">
        {title}
      </TitleCustom>
    </a>
  )
}

FeatureHithIcon.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default FeatureHithIcon
