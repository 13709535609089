import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'

const MediaPlayer = ({ audioTrack, videoTrack, width, height }) => {
  const container = useRef(null)
  useEffect(() => {
    if (!container.current) return
    videoTrack?.play(container.current)
    return () => {
      videoTrack?.stop()
    }
  }, [container, videoTrack])
  useEffect(() => {
    if (audioTrack) {
      audioTrack?.play()
    }
    return () => {
      audioTrack?.stop()
    }
  }, [audioTrack])
  return <div ref={container} className="video-player"></div>
}

MediaPlayer.propTypes = {
  audioTrack: PropTypes.object,
  videoTrack: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default MediaPlayer
