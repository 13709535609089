import functions from '../functions'
const { getLastConsult } = functions.cloudFunctions

const getLastConsultService = async id => {
  try {
    const response = await getLastConsult({ id })
    const { data, error } = response
    if (!error) {
      return data
    }
  } catch (error) {
    return { error }
  }
}

export default getLastConsultService
