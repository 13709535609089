import {
  getTreatmentRouteService,
  getTreatmentConfigService,
  getLastConsultService,
  generateRouteCouponService,
  getCategoriesForSpecialtyService,
} from '../../services'

export default dispatch => {
  return {
    getTreatmentRoute: () => getTreatmentRouteService(),
    getTreatmentConfig: () => getTreatmentConfigService(),
    getLastConsult: id => getLastConsultService(id),
    generateRouteCoupon: (price, current) =>
      generateRouteCouponService(price, current),
    getCategoriesForSpecialty: specialty =>
      dispatch(getCategoriesForSpecialtyService(specialty)),
  }
}
