import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ButtonCustom,
  TextCustom,
  ConsultCardInfo,
  RecipeContainer,
  SessionDoctorInfo,
  SessionsQuestionarie,
  CancelSession,
} from '..'
import { useToggle, useRouter } from '../../utils'
import selectors from './selectors.js'
import moment from 'moment'

// helpers
import { validateVideos } from '../../utils/sections'

const consultCard = ({
  consult,
  loading,
  getDrugs,
  handleOnCLickBuyDrugs,
  handleOnClickInvoice,
  handleOnClickSendInvoice,
  toggleModal,
  modalState,
  setModalState,
  getCancelReasons,
  uid,
  cancelSessionService,
}) => {
  const {
    questionnaire,
    doctor,
    recipe,
    recipe_date,
    id: consultId,
    status,
    old_recipe,
    order,
    isReScheduled,
    isCancellable,
    specialty,
    images,
  } = consult

  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  const pendingPayReference = consult?.pendingPayReference
  const { history } = useRouter()
  const [showDetails, setShowDetails] = useToggle()
  const [showButtons, setShowButtons] = useState(true)
  const isFast = questionnaire && (specialty === 'derma' || !specialty)
  const [isLoadingCancel, setIsLoadingCancel] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)
  const [cancelReasons, setCancelReasons] = useState()
  useEffect(() => {
    if (
      status === 'close' ||
      status === 'onCall' ||
      status === 'canceled' ||
      status === 'ready'
    )
      setShowButtons(false)
    return () => {}
  }, [])

  const downloadInvoiceOption = () => {
    if (consult.isBillingConsult && consult.invoiceConsultID) {
      if (loading) {
        return (
          <div className="is-fullwidth is-centered columns">
            <div className="loader is-loading is-fullwidth my-5" />
          </div>
        )
      }
      return (
        <ButtonCustom
          buttonClassName="button is-button-form m-5"
          onClick={resendInvoice}
        >
          Reenviar factura
        </ButtonCustom>
      )
    }
  }

  const showInvoiceOption = () => {
    const createdMonth = moment(consult.created_date).toDate().getMonth() + 1
    const createdYear = moment(consult.created_date).toDate().getFullYear()
    const currentMonth = moment(new Date()).toDate().getMonth() + 1
    const currentYear = moment(new Date()).toDate().getFullYear()
    if (
      !consult.isBillingConsult &&
      status === 'close' &&
      currentMonth === createdMonth &&
      currentYear === createdYear
    ) {
      if (loading) {
        return (
          <div className="is-fullwidth is-centered columns">
            <div className="loader is-loading is-fullwidth my-5" />
          </div>
        )
      }
      return (
        <ButtonCustom
          buttonClassName="button is-button-form m-5"
          onClick={generateInvoice}
        >
          Generar factura
        </ButtonCustom>
      )
    }
  }

  const generateInvoice = () => {
    handleOnClickInvoice(consultId)
  }

  const resendInvoice = () => {
    handleOnClickSendInvoice(consult.invoiceConsultID)
  }

  const getReasons = async () => {
    setIsLoadingCancel(true)
    const response = await getCancelReasons()
    setCancelReasons(response)
    setModalCancel(true)
  }

  const toggleCancelModal = () => {
    setModalCancel(false)
    setIsLoadingCancel(false)
  }

  const handleOnClickPage = () => {
    history.push(
      `/user/reschedule/${specialty || 'derma'}/${consultId}/${doctor.id}`,
    )
  }

  return (
    <article className="box consult-card consults-container-article bottom-gray-border">
      <ConsultCardInfo
        consult={consult}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        toggleModal={toggleModal}
        modalState={modalState}
        setModalState={setModalState}
      />
      {showDetails && (
        <>
          {doctor && <SessionDoctorInfo doctor={doctor} />}
          {status !== 'close' && (
            <div className="icon-text is-background-disclaimer p-4 mt-2">
              <span className="icon has-text-warning">
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              <span>
                {' '}
                24 hrs de anticipación para cancelar o reagendar una consulta
              </span>
            </div>
          )}

          {showButtons && (
            <div className="is-flex buttons-reschedule">
              {!isFast && (
                <ButtonCustom
                  buttonClassName={`button  is-button-form m-5 `}
                  onClick={handleOnClickPage}
                  disabled={!isReScheduled}
                >
                  Reagendar Cita
                </ButtonCustom>
              )}
              <ButtonCustom
                buttonClassName={`button  is-button-form m-5 ${
                  isLoadingCancel && 'is-loading'
                }`}
                onClick={getReasons}
                disabled={!isCancellable}
              >
                Cancelar Cita
              </ButtonCustom>

              {pendingPayReference && (
                <a
                  target="_blank"
                  className="button is-button-form m-5"
                  href={pendingPayReference.charge?.instructions}
                  rel="noreferrer noopener"
                >
                  Pagar
                </a>
              )}
            </div>
          )}
          {showInvoiceOption() && (
            <ButtonCustom
              buttonClassName="button is-button-form m-5"
              onClick={generateInvoice}
            >
              Generar factura
            </ButtonCustom>
          )}

          {downloadInvoiceOption()}

          {(questionnaire || images) && (
            <SessionsQuestionarie
              questionnaire={questionnaire}
              id={consultId}
              images={images}
            />
          )}

          {recipe && (
            <>
              <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <TextCustom className="mx-6 is-flex my-4">
                  {'Receta'}
                </TextCustom>
                {order?.pendingPayReference && (
                  <a
                    target="_blank"
                    className="button is-button-form m-5"
                    href={order?.pendingPayReference?.charge?.instructions}
                    rel="noreferrer noopener"
                  >
                    Pagar
                  </a>
                )}
              </div>
              {old_recipe
                ? old_recipe.map(drug => {
                    return (
                      <RecipeContainer
                        drug={drug}
                        getDrugs={getDrugs}
                        key={drug.drugID}
                      />
                    )
                  })
                : recipe.map(drug => {
                    return (
                      <RecipeContainer
                        drug={drug}
                        getDrugs={getDrugs}
                        key={drug.drugID}
                      />
                    )
                  })}
              <TextCustom className='className="mx-4 is-flex my-4"'>
                Indicaciones:
              </TextCustom>
              <header className="consults-container-header drug-box">
                <div className="m-5">
                  <TextCustom>
                    Hidrata la piel cada día:
                    <span>
                      Una crema hidratante puede ser de gran ayuda para evitar
                      la sequedad de la piel. Es importante aplicar estas cremas
                      después del lavado, con la piel todavía húmeda, para
                      obtener una mayor absorción y que la hidratación funcione
                      de maravilla.
                    </span>
                  </TextCustom>
                  <TextCustom>
                    Haz una limpieza suave:
                    <span>
                      Mantener el equilibrio de la piel y no atacar a la misma
                      es muy importante, por eso se recomienda utilizar
                      desmaquillantes fisiológicos
                    </span>
                  </TextCustom>
                </div>
              </header>

              {consult.videos && validateVideos(privateFlag) && (
                <div>
                  <TextCustom className='className="mx-4 is-flex my-4"'>
                    Videos:
                  </TextCustom>
                  <header className="consults-container-header drug-box">
                    <div className="m-5">
                      {consult.videos.map((item, index) => {
                        return (
                          <div key={index}>
                            <TextCustom>{item.title}</TextCustom>
                            <iframe
                              width="420"
                              height="315"
                              src={item.url}
                            ></iframe>
                          </div>
                        )
                      })}
                    </div>
                  </header>
                </div>
              )}

              {!old_recipe && (
                <ButtonCustom
                  buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-form"
                  onClick={() =>
                    handleOnCLickBuyDrugs(recipe, consultId, recipe_date)
                  }
                >
                  Surtir receta
                </ButtonCustom>
              )}
            </>
          )}
        </>
      )}
      {modalCancel && (
        <CancelSession
          toggleCancelModal={toggleCancelModal}
          cancelReasons={cancelReasons}
          uid={uid}
          id={consultId}
          cancelSessionService={cancelSessionService}
        />
      )}
    </article>
  )
}

export default consultCard
