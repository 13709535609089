import { authActions } from '../../actions'
import { changePasswordServce } from '../../services'
const { unsetChangePasswordSuccessAction, changePasswordFailureAction } =
  authActions.creators

export default dispatch => {
  return {
    changePasswordFailure: error =>
      dispatch(changePasswordFailureAction(error)),
    changePasswordSuccess: () => dispatch(unsetChangePasswordSuccessAction()),
    changePassword: (oobCode, password) =>
      dispatch(changePasswordServce(oobCode, password)),
  }
}
