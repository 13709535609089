import PropTypes from 'prop-types'
import { FeatureRow, ImageCustom } from '../../components'
import Images from '../../assets'
import { useRouter } from '../../utils'

// helpers
import {
  validateMenuDaily,
  validateMenuTreatmentRoute,
  validateMenuCoupons,
  validateMenuFavorites,
  validateMenuWarranty,
} from '../../utils/sections'

const Features = ({
  removeAllArticles,
  signOut,
  uid,
  getAllOrders,
  activePage,
}) => {
  const {
    Billing,
    Close,
    Config,
    Cuppons,
    Dairy,
    Favorites,
    Personal,
    Pharma,
    Questions,
    Shopping,
    Treatment,
    Wa,
    warrantyIcon,
  } = Images

  const { history } = useRouter()
  const goTo = async direction => {
    // await refresh(uid)
    history.push(direction)
  }

  const onClose = () => {
    history.push('/user')
    signOut()
  }

  const goToShopping = () => {
    getAllOrders({ userId: uid, page: 1, refreshing_mode: false })
    goTo('/user/shopping')
  }
  return (
    <>
      <div className="white-box bottom-gray-border">
        {validateMenuDaily() && (
          <FeatureRow
            title={'Mi diario'}
            onClick={() => goTo('/user/mydiaryScreen')}
            activePage={activePage}
            rowName={'/user/mydiaryScreen'}
          >
            <Dairy />
          </FeatureRow>
        )}
        {validateMenuTreatmentRoute() && (
          <FeatureRow
            title={'Mi ruta del tratamiento'}
            onClick={() => goTo('/user/treatment_route')}
            activePage={activePage}
            rowName={'/user/treatment_route'}
          >
            <Treatment />
          </FeatureRow>
        )}
        <FeatureRow
          title={'Mis compras'}
          onClick={() => goToShopping()}
          activePage={activePage}
          rowName={'/user/shopping'}
        >
          <Shopping />
        </FeatureRow>
        <FeatureRow
          title={'Farmacia'}
          onClick={() => {
            window.open('https://mynextderma.com/farmacia/', '_blank').focus()
          }}
        >
          <Pharma />
        </FeatureRow>
      </div>
      <div className="white-box bottom-gray-border">
        <FeatureRow
          title={'Datos personales'}
          onClick={() => goTo('/user/personal_information')}
          activePage={activePage}
          rowName={'/user/personal_information'}
        >
          <Personal />
        </FeatureRow>
        {validateMenuCoupons() && (
          <FeatureRow
            title={'Mis cupones'}
            onClick={() => {
              goTo('/user/coupon_screen')
            }}
            activePage={activePage}
            rowName={'/user/coupon_screen'}
          >
            <Cuppons />
          </FeatureRow>
        )}
        {validateMenuFavorites() && (
          <FeatureRow
            title={'Mis favoritos'}
            onClick={() => goTo('/user/my_favourites')}
            activePage={activePage}
            rowName={'/user/my_favourites'}
          >
            <Favorites />
          </FeatureRow>
        )}
        <FeatureRow
          title={'Facturación'}
          onClick={() => goTo('/user/billing')}
          activePage={activePage}
          rowName={'/user/billing'}
        >
          <Billing />
        </FeatureRow>
        {validateMenuWarranty() && (
          <FeatureRow
            title={'Garantia'}
            onClick={() => goTo('/user/warranty')}
            activePage={activePage}
            rowName={'/user/warranty'}
          >
            <ImageCustom img={warrantyIcon} />
          </FeatureRow>
        )}
      </div>
      <div className="white-box bottom-gray-border">
        <FeatureRow
          title={'Sobre nosotros'}
          onClick={() => goTo('/user/about-us')}
          activePage={activePage}
          rowName={'/user/about-us'}
        >
          <Config />
        </FeatureRow>
        <FeatureRow
          title={'Soporte whatsapp'}
          onClick={() => window.open('https://wa.me/+523341600804', '_blank')}
          activePage={activePage}
          rowName={'/user/support'}
        >
          <Wa />
        </FeatureRow>
        <FeatureRow
          title={'Preguntas frecuentes'}
          onClick={() => goTo('/user/frecuent_questions')}
          activePage={activePage}
          rowName={'/user/frecuent_questions'}
        >
          <Questions />
        </FeatureRow>
      </div>
      <div className="white-box bottom-gray-border">
        <FeatureRow
          title={'Cerrar Sesión'}
          onClick={() => {
            removeAllArticles()
            onClose()
          }}
        >
          <Close />
        </FeatureRow>
      </div>
    </>
  )
}

Features.propTypes = {
  removeAllArticles: PropTypes.func,
  signOut: PropTypes.func,
  uid: PropTypes.string,
  refresh: PropTypes.func,
  getAllOrders: PropTypes.func,
  activePage: PropTypes.string,
}

export default Features
