import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const useIsLoggedIn = ({ uid, isSignIn }) => {
  const dispatch = useDispatch()
  const [checking, setChecking] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    if (uid) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
    setChecking(false)
  }, [uid, dispatch, setChecking, setIsLoggedIn])

  return [checking, isLoggedIn, isSignIn]
}

export default useIsLoggedIn
