import { couponsActions } from '../../actions'

const {
  GET_ALL_COUPONS_REQUEST,
  GET_ALL_COUPONS_SUCCESS,
  GET_ALL_COUPONS_FAILURE,
  CLEAR_COUPONS,
} = couponsActions.types

const initialState = {
  data: [],
  error: '',
}

const couponsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUPONS_REQUEST:
      return {
        ...state,
      }

    case GET_ALL_COUPONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case CLEAR_COUPONS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default couponsReducer
