import { v4 } from 'uuid'
const Heart = () => {
  const random = v4()
  const random2 = v4()
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2953 1C12.0833 1 10.5 3.90908 10.5 3.90908C10.5 3.90908 8.91675 1 5.70474 1C3.09435 1 1.02722 3.00636 1.00051 5.40044C0.946082 10.37 5.29161 13.904 10.0547 16.874C10.186 16.9561 10.3412 17 10.5 17C10.6588 17 10.814 16.9561 10.9453 16.874C15.7079 13.904 20.0534 10.37 19.9995 5.40044C19.9728 3.00636 17.9057 1 15.2953 1Z"
        fill={`url(#${random2})`}
        stroke={`url(#${random})`}
        stroke-strokelinecap="round"
        stroke-strokelinejoin="round"
      />
      <defs>
        <linearGradient
          id={random2}
          x1="1"
          y1="8.93277"
          x2="20"
          y2="8.93277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F658B1" />
          <stop offset="1" stopColor="#FD9456" />
        </linearGradient>
        <linearGradient
          id={random}
          x1="1"
          y1="8.93277"
          x2="20"
          y2="8.93277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F658B1" />
          <stop offset="1" stopColor="#FD9456" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default Heart
