import PropTypes from 'prop-types'

import { ButtonCustom, TextCustom, ImageCustom } from '../../../components'
import Cropper from 'react-easy-crop'

// img
import Images from '../../../assets'
import { useCallback, useState } from 'react'
import { getCroppedImg } from '../../../utils'

const modalProductPhoto = ({ closeModal, onHandle, name }) => {
  const { imgIcon } = Images
  const [image, setImage] = useState()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onSelect = ({ target }) => {
    const imageFile = target.files[0]
    const imageURL = window.URL.createObjectURL(imageFile)
    setImage(imageURL)
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0)
      setCroppedImage(croppedImage.uri)
      onHandle(name, croppedImage)
      closeModal()
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  return (
    <>
      {image && !croppedImage && (
        <div className="modal is-absolute">
          <div className="modal-background"></div>
          <div className="modal-card  modal-cropper">
            <section className="modal-card-body">
              <div className="cropper-space">
                <>
                  <Cropper
                    image={image}
                    crop={crop}
                    rotation={0}
                    zoom={zoom}
                    aspect={3 / 4}
                    onCropChange={setCrop}
                    onRotationChange={() => {}}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </>
                <div className="cropper-container">
                  <ButtonCustom
                    icon="fas fa-times"
                    buttonClassName="button cropper-buttons mx-3"
                    handleOnClick={closeModal}
                  />
                  <ButtonCustom
                    icon="fas fa-check"
                    buttonClassName="button cropper-buttons mx-3"
                    handleOnClick={showCroppedImage}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
      {!image && (
        <div className="modal is-absolute">
          <div className="modal-background"></div>
          <div className="modal-card ">
            <section className="modal-card-body is-border-radius">
              <div className="level is-mobile">
                <div className="level-left">
                  <TextCustom className="are-color-mynd-grey has-text-weight-bold">
                    Agregar foto
                  </TextCustom>
                </div>
                <div className="level-right">
                  <ButtonCustom
                    icon="far fa-times-circle"
                    buttonClassName="button is-inverted is-border-button-modal"
                    iconClassName="are-color-mynd-grey is-size-4"
                    onClick={closeModal}
                  />
                </div>
              </div>
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  hidden={true}
                  name={name}
                  onChange={event => onSelect(event)}
                />
                <span className="file-cta is-size-input-add-pictur">
                  <span className="file-icon">
                    <ImageCustom
                      img={imgIcon}
                      className="is-size-icon-modal-diary"
                    />
                  </span>
                  <span className="file-label are-color-mynd-grey ml-3">
                    Escoger de mi dispositivo
                  </span>
                </span>
              </label>
            </section>
          </div>
        </div>
      )}
      {croppedImage && (
        <div className="modal">
          <div className="modal-background"></div>
          <div className="modal-card is-modal-border-radius-diary">
            <section className="modal-card-body">
              <div className="level is-mobile">
                <ImageCustom
                  img={croppedImage}
                  className="is-size-icon-modal-diary"
                />
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

modalProductPhoto.propTypes = {
  closeModal: PropTypes.func,
  onHandle: PropTypes.func,
  name: PropTypes.string,
}

export default modalProductPhoto
