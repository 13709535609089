import PropTypes from 'prop-types'
import { TitleCustom, RegisterBox, CustomButton } from '../../components'

const TermsConditions = ({ setStep }) => {
  return (
    <RegisterBox className="px-6  mt-6">
      <div className="content mt-4 px-4">
        <TitleCustom className="title is-4 are-color-text">
          Términos y condiciones
        </TitleCustom>
        <p className="has-text-justified is-text-height are-color-text">
          Para completar tu registro, debes aceptar los
          <strong> términos de uso</strong> y el procesamiento, tratamiento y
          transferencia de tus datos personales y recibir boletines, promociones
          y otra información relevante de MyNextDerma y sus compañías afiliadas,
          conforme a lo dispuesto en las
          <strong> políticas de privacidad.</strong>
        </p>
        <div className="buttons is-centered mb-2 ">
          <CustomButton isPrimary handleClick={setStep}>
            Si, acepto
          </CustomButton>
        </div>
      </div>
    </RegisterBox>
  )
}

TermsConditions.propTypes = {
  setStep: PropTypes.func,
}

export default TermsConditions
