import PropTypes from 'prop-types'
import { TextCustom } from '..'

const AwardsBox = ({ award }) => {
  const { name, institution, date } = award
  return (
    <div className="block my-3">
      <TextCustom className="are-color-mynd-grey has-text-weight-bold  mb-0">
        {name}
      </TextCustom>
      <TextCustom className="are-color-mynd-grey my-0">
        {institution}
      </TextCustom>
      <TextCustom className="are-color-mynd-grey my-0">{date}</TextCustom>
    </div>
  )
}

AwardsBox.propTypes = {
  award: PropTypes.object,
}

export default AwardsBox
