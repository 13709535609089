import { combineReducers } from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'

import authReducer from './auth'
import uiReducer from './ui'
import consultReducer from './consult'
import questionnaireReducer from './questionnaires'
import doctorsReducer from './doctors'
import blogReducer from './blog'
import clinicalRecordReducer from './clinicalRedord'
import myAddressReducer from './myAddress'
import orderDrugsReducer from './orderDrugs'
import couponsReducer from './coupons'
import sessionReducer from './session'

const authPersistConfig = {
  key: 'auth',
  storage,
}

const doctorsPersistConfig = {
  key: 'doctors',
  storage,
}
const blogPersistConfig = {
  key: 'blog',
  storage,
}
const couponsPersistConfig = {
  key: 'coupons',
  storage,
}
const sessionPersistConfig = {
  key: 'session',
  storage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  consult: consultReducer,
  coupons: persistReducer(couponsPersistConfig, couponsReducer),
  doctors: persistReducer(doctorsPersistConfig, doctorsReducer),
  blog: persistReducer(blogPersistConfig, blogReducer),
  ui: uiReducer,
  questionnaire: questionnaireReducer,
  clinicalRecord: clinicalRecordReducer,
  address: myAddressReducer,
  orderDrugs: orderDrugsReducer,
  session: persistReducer(sessionPersistConfig, sessionReducer),
})

export default rootReducer
