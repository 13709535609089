import { usersF } from '../../config'

const verifyEmail = async email => {
  try {
    const userFound = await usersF.where('email', '==', email).get()

    return { isNew: userFound.empty }
  } catch (error) {
    return { error }
  }
}

export default verifyEmail
