import PropTypes from 'prop-types'

// accordion
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { useForm } from '../../utils'
// components
import { ImageCustom, LabelCustom, Input } from '../../components'
import Images from '../../assets'
const AccordionSmoke = ({ smokeProp, uid, medicalRecord }) => {
  const [{ smoke }, handleInputChange] = useForm({
    smoke: smokeProp,
  })
  console.log('smoke :>> ', smoke)
  const { cigarIcon, arrowDown } = Images

  const handleSmoke = () => {
    if (!isValid) return false
    const data = {
      smoke: smoke,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const isValid = () => {
    if (smoke >= 0) {
      return true
    }
    return false
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom img={cigarIcon} className="is-size-cigar-icon mr-3" />
            <span className="are-color-mynd-grey ">Fumar</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="py-3">
        <LabelCustom className="has-text-weight-light are-color-mynd-grey mt-5">
          Número de cigarros al día:
        </LabelCustom>
        <Input
          min="0"
          className="input mt-2"
          type="number"
          name="smoke"
          placeholder="ej: 5, en caso de no fumar ponga 0"
          onChange={handleInputChange}
          value={smoke}
        />
        <button
          className="button has-text-white is-mnd-primary mt-2"
          onClick={handleSmoke}
        >
          Guardar
        </button>
      </AccordionItemPanel>
    </>
  )
}

AccordionSmoke.propTypes = {
  smokeProp: PropTypes.object,
  uid: PropTypes.string,
  medicalRecord: PropTypes.func,
}

export default AccordionSmoke
