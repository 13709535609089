import { productsFC, storage, usersF } from '../../config'
const bucket = process.env.REACT_APP_STORAGE_BUCKET

const getImage = async ({ idRef, uri }) => {
  try {
    const ref = storage.refFromURL(
      'gs://' + bucket + '/products/' + idRef + '/' + uri,
    )
    return await ref.getDownloadURL()
  } catch (error) {
    return { error }
  }
}

const getProductService = async uid => {
  const userRef = usersF.doc(uid)
  const productsDocs = await productsFC.where('patient', '==', userRef).get()
  const { docs } = productsDocs
  if (docs.length === 0) {
    return
  }
  const filter = docs.filter(products => products.data().product)
  if (filter.length === 0) {
    return
  }
  const products = docs[0].data().product
  if (products) {
    const productsList = await Promise.all(
      products.map(async (product, i) => {
        const tempData = product
        tempData.images = []
        if (product.uri) {
          const url = await getImage({ idRef: uid, uri: product.uri })
          if (!url?.error) {
            tempData.images.uri = url
          }
        }
        if (product.ingredients && product.ingredients.productUri) {
          const productUrl = await getImage({
            idRef: uid,
            uri: product.ingredients.productUri,
          })
          if (!productUrl?.error) {
            tempData.images.productUri = productUrl
          }
        }
        if (product.ingredients && product.ingredients.aditionalUri) {
          const aditionalUri = await getImage({
            idRef: uid,
            uri: product.ingredients.aditionalUri,
          })
          if (!aditionalUri?.error) {
            tempData.images.aditionalUri = aditionalUri
          }
        }
        return tempData
      }),
    )
    return productsList
  }
}
export default getProductService
