import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import Images from '../../assets'
import { NotificationItem } from '..'
import { notificationsRef } from '../../config'

const NotificationsBox = ({ uid }) => {
  const notificationBoxRef = useRef(null)
  const notificationIconRef = useRef(null)

  const [showNotifications, setShowNotifications] = useState(false)
  const [notificationsList, setNotificationsList] = useState([])

  const { Notifications } = Images

  useEffect(() => {
    notificationsRef
      .where('user_id', '==', uid)
      .orderBy('created_at', 'desc')
      .limit(10)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          const notifications = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }))
          setNotificationsList(notifications)
        }
      })
    // click outside function
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = event => {
    if (
      notificationBoxRef.current &&
      !notificationBoxRef.current.contains(event.target) &&
      !(
        notificationIconRef.current &&
        notificationIconRef.current.contains(event.target)
      )
    ) {
      closeNotificationBox()
    }
  }

  const showNoReaded = () => {
    return notificationsList.filter(item => !item.readed).length
  }

  const toggleNotificationBox = () => {
    setShowNotifications(!showNotifications)
  }

  const closeNotificationBox = () => {
    setShowNotifications(false)
  }

  return (
    <>
      <div
        ref={notificationIconRef}
        className="navi-button"
        onClick={() => toggleNotificationBox()}
      >
        <Notifications />
        {showNoReaded() > 0 && (
          <div className="notifications_counter">{showNoReaded()}</div>
        )}
      </div>
      {showNotifications && (
        <div className="wrapper" ref={notificationBoxRef}>
          <div className="notification_wrap">
            <div className="dropdown">
              <div className="notification_scroll">
                {notificationsList.map((item, index) => (
                  <NotificationItem
                    key={index}
                    data={item}
                    onPressClose={closeNotificationBox}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

NotificationsBox.propTypes = {
  uid: PropTypes.string,
}

export default NotificationsBox
