import PropTypes from 'prop-types'
import { useState } from 'react'

// componets
import { ButtonCustom, StudiesBox, AwardsBox, LatestWorksBox } from '..'

const tabs = ({ profesionalIds, awards, lastJob }) => {
  const [toggleState, setToggleState] = useState(1)
  const toggleTab = index => {
    setToggleState(index)
  }
  return (
    <div className="container-tabs">
      <div className="is-flex is-justify-content-space-between mt-5">
        <ButtonCustom
          buttonClassName={
            toggleState === 1
              ? 'button is-fullwidth is-button-tabs has-text-weight-bold are-color-mynd-grey active-tabs'
              : 'button is-fullwidth has-text-weight-bold are-color-mynd-grey is-background-button'
          }
          onClick={() => toggleTab(1)}
        >
          Estudios
        </ButtonCustom>

        <ButtonCustom
          buttonClassName={
            toggleState === 2
              ? 'button is-fullwidth has-text-weight-bold are-color-mynd-grey active-tabs'
              : 'button is-fullwidth has-text-weight-bold are-color-mynd-grey is-background-button'
          }
          onClick={() => toggleTab(2)}
        >
          Premios
        </ButtonCustom>

        <ButtonCustom
          buttonClassName={
            toggleState === 3
              ? 'button is-fullwidth has-text-weight-bold are-color-mynd-grey active-tabs'
              : 'button is-fullwidth has-text-weight-bold are-color-mynd-grey is-background-button '
          }
          onClick={() => toggleTab(3)}
        >
          Ultimos Trabajos
        </ButtonCustom>
      </div>
      <div className="">
        {profesionalIds ? (
          <div
            className={
              toggleState === 1
                ? 'content-tabs  active-content'
                : 'content-tabs'
            }
          >
            {profesionalIds.map((studie, key) => {
              return <StudiesBox studie={studie} key={key} />
            })}
          </div>
        ) : (
          ''
        )}

        {awards ? (
          <div
            className={
              toggleState === 2
                ? 'content-tabs  active-content'
                : 'content-tabs'
            }
          >
            {awards.map((award, key) => {
              return <AwardsBox award={award} key={key} />
            })}
          </div>
        ) : (
          ''
        )}
        {lastJob ? (
          <div
            className={
              toggleState === 3
                ? 'content-tabs  active-content'
                : 'content-tabs'
            }
          >
            {lastJob.map((Job, key) => {
              return <LatestWorksBox Job={Job} key={key} />
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

tabs.propTypes = {
  profesionalIds: PropTypes.array,
  awards: PropTypes.array,
}

export default tabs
