import PropTypes from 'prop-types'

import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import {
  ImageCustom,
  LabelCustom,
  ButtonCustom,
  Input,
  TextCustom,
} from '../../components'
import { useForm } from '../../utils'
import Images from '../../assets'
import { useState } from 'react'

const AccordionContacts = ({ emergencyContactsProp, uid, medicalRecord }) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const [addNew, setAddNew] = useState(false)
  const [{ name, relation, phone }, handleInputChange] = useForm({
    name: '',
    relation: '',
    phone: '',
  })

  const deleteItem = index => {
    const data = {
      emergencyContacts: [],
    }

    if (emergencyContactsProp.length > 1) {
      const listItems = [...emergencyContactsProp]
      listItems.splice(index, 1)
      data.emergencyContacts = listItems
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleEmergencyContact = () => {
    if (phone.length !== 10 || name === '' || relation === '') {
      setErrorAlert({
        show: true,
        message: 'Hay que errores o faltan campos en el contacto',
      })
    } else {
      const newContact = [...emergencyContactsProp, { name, relation, phone }]
      medicalRecord({
        method: 'POST',
        uid,
        data: { emergencyContacts: newContact },
      })
    }
  }

  const { personPlusIcon, arrowDown } = Images

  const optionsRelation = [
    { value: '', label: 'Seleccione una opción' },
    { value: 'Padre', label: 'Padre' },
    { value: 'Madre', label: 'Madre' },
    { value: 'Hermano', label: 'Hermano' },
    { value: 'Hermana', label: 'Hermana' },
    { value: 'Abuelo', label: 'Abuelo' },
    { value: 'Abuela', label: 'Abuela' },
    { value: 'Tio', label: 'Tio' },
    { value: 'Tia', label: 'Tia' },
    { value: 'Primo', label: 'Primo' },
    { value: 'Prima', label: 'Prima' },
    { value: 'Sobrino', label: 'Sobrino' },
    { value: 'Sobrina', label: 'Sobrina' },
    { value: 'Otro', label: 'Otro' },
  ]

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={personPlusIcon}
              className="is-size-personPlus-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Mis contactos</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        {emergencyContactsProp &&
          emergencyContactsProp.map((contact, index) => (
            <div
              className="is-background-box-allergies mt-3  px-3 "
              key={index}
            >
              <div className="level mb-0 is-mobile">
                <TextCustom className="are-color-mynd-grey is-button-close px-1 is-size-6"></TextCustom>

                <div className="level-right mx-0 px-0">
                  <ButtonCustom
                    buttonClassName="button is-button-close"
                    icon="fas fa-times"
                    onClick={() => deleteItem(index)}
                  />
                </div>
              </div>
              <div className="control">
                <TextCustom className="are-color-mynd-grey is-button-close is-size-6">
                  <span>Nombre:</span> {contact.name}
                </TextCustom>
              </div>
              <div className="control">
                <LabelCustom className="has-text-weight-light mt-2">
                  Relación: {contact.relation}
                </LabelCustom>
              </div>
              <div className="control">
                <LabelCustom className="mt-2">
                  Celular: {contact.phone}
                </LabelCustom>
              </div>
            </div>
          ))}

        {addNew && (
          <div className="is-background-box-allergies mt-3  px-3 ">
            {errorAlert.show && (
              <div className="notification is-danger mt-3">
                {errorAlert.message}
              </div>
            )}
            <div className="level mb-0 is-mobile">
              <div className="level-left">
                <TextCustom className="are-color-mynd-grey is-button-close px-1 is-size-6">
                  Contacto{' '}
                  {emergencyContactsProp ? emergencyContactsProp.length + 1 : 1}
                </TextCustom>
              </div>
              <div className="level-right mx-0 px-0">
                <ButtonCustom
                  buttonClassName="button is-button-close"
                  icon="fas fa-times"
                  onClick={() => {
                    setErrorAlert({ show: false, message: '' })
                    setAddNew(false)
                  }}
                />
              </div>
            </div>
            <Input
              className="input mb-3"
              type="text"
              placeholder="Ej: Roberto"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
            <LabelCustom className="has-text-weight-light mt-2">
              Relación
            </LabelCustom>
            <div className="control">
              <div className="select">
                <select name="relation" onChange={handleInputChange}>
                  {optionsRelation.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <LabelCustom className="mt-2">Celular</LabelCustom>
            <Input
              className="input"
              type="number"
              placeholder="ej: 33- 18 - 36- 3655"
              onChange={handleInputChange}
              name="phone"
              value={phone}
            />
            <ButtonCustom
              buttonClassName="button has-text-white is-mnd-primary mt-3"
              handleOnClick={handleEmergencyContact}
            >
              Guardar
            </ButtonCustom>
          </div>
        )}

        {!addNew && (
          <ButtonCustom
            buttonClassName="button has-text-white is-mnd-primary mt-3"
            handleOnClick={() => setAddNew(true)}
          >
            Añadir contacto
          </ButtonCustom>
        )}
      </AccordionItemPanel>
    </>
  )
}

AccordionContacts.propTypes = {
  emergencyContactsProp: PropTypes.array,
  uid: PropTypes.string,
  medicalRecord: PropTypes.func,
}

export default AccordionContacts
