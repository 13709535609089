import PropTypes from 'prop-types'

const RegisterBox = ({ children, className = '' }) => {
  return (
    <div className={`box is-box-shadow is-border-radius ${className}`}>
      {children}
    </div>
  )
}

RegisterBox.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default RegisterBox
