import PropTypes from 'prop-types'

import { ImageCustom } from '..'

// Img
import ProductWhiteackground from '../../assets/ProductWhiteackground.png'

const product = ({ drug }) => {
  return (
    <div className="box is-size-box-product my-2 mx-3 py-0 pr-0 pl-5">
      <ImageCustom
        img={drug.img && drug.img !== '' ? drug.img : ProductWhiteackground}
        className="is-size-img-product mt-2"
      />
      <span className="number-product p-1">{drug.quantity}x</span>
    </div>
  )
}

product.propTypes = {
  drug: PropTypes.object.isRequired,
}

export default product
