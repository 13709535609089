import { processData } from '../../utils'
import { usersF, firestoreFiled } from '../../config'
import { authActions } from '../../actions'

const {
  updateUserRequestAction,
  updateUserSuccessAction,
  updateUserFailureAction,
} = authActions.creators

const setUserSpecialty = (specialty, uid) => {
  return async dispatch => {
    try {
      dispatch(updateUserRequestAction())
      await usersF.doc(uid).set(
        {
          specialty:
            specialty === 'derma'
              ? firestoreFiled.FieldValue.delete()
              : specialty,
        },
        { merge: true },
      )
      const user = await usersF.doc(uid).get()
      const newUserData = await processData(user)
      dispatch(updateUserSuccessAction(newUserData))
    } catch (error) {
      dispatch(updateUserFailureAction(error))
      return error
    }
  }
}

export default setUserSpecialty
