import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usersF } from '../../config'

const useOnSnapshot = () => {
  const { uid } = useSelector(state => state.auth.user)
  const [cards, setCards] = useState([])
  const [balance, setBalance] = useState(0)
  const [tokens, setTokens] = useState(false)

  useEffect(() => {
    const unsuscribe = usersF.doc(uid).onSnapshot(result => {
      if (result) {
        const { cards, balance, customer_token, customer_token_openpay } =
          result.data()
        const hasTokens = (customer_token && customer_token_openpay) || false
        const userCards = cards || []
        const userBalance = balance || 0
        setBalance(userBalance)
        setCards(userCards)
        setTokens(hasTokens)
      }
    })

    return () => {
      unsuscribe()
    }
  }, [])
  return [cards, balance, tokens]
}

export default useOnSnapshot
