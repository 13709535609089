import PropTypes from 'prop-types'

import { ButtonCustom, ImageCustom, TextCustom } from '../../../components'
// img
import Images from '../../../assets'
import { useCounter } from '../../../utils'
const FirstDairyPresentation = ({ setshowModal }) => {
  const { count, increment } = useCounter(0)
  const { scheduleTransparent, productosDiaryTransparent, cleanFace } = Images
  const steps = [
    {
      title: 'Bienvenido a tu diario',
      message: `Registra la rutina de cuidado de tu piel y mantente al tanto de la
      evolución de tus tratamientos`,
      image: scheduleTransparent,
    },
    {
      title: 'Agrega tus productos',
      message: `Agrega los productos que utilices en el cuidado diario de tu piel`,
      image: productosDiaryTransparent,
    },
    {
      title: 'Captura tu tratamiento',
      message: `Comprueba el cambio y mejora de tu piel viendo las fotos que subiste
      el primer día de tratamiento`,
      image: cleanFace,
    },
  ]

  return (
    <div className="modal is-absolute z-index-0">
      <div className="modal-background"></div>
      <div className="modal-card p-4">
        <section className="modal-card-body is-border-radius">
          <div className="columns is-centered intro-step">
            <div className="box is-size-box-daily py-6">
              <ImageCustom
                img={steps[count].image}
                className="is-size-img-shedule-my-diary"
              />
              <TextCustom className="has-text-weight-semibold has-text-centered is-size-5 mt-4">
                {steps[count].title}
              </TextCustom>

              <TextCustom className="has-text-centered are-color-mynd-grey mt-5">
                {steps[count].message}
              </TextCustom>
              <ButtonCustom
                buttonClassName="button is-colour-pink-diary has-text-white mt-5 px-6"
                handleOnClick={() =>
                  count === 2 ? setshowModal() : increment()
                }
              >
                Siguiente
              </ButtonCustom>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

FirstDairyPresentation.propTypes = {
  setshowModal: PropTypes.func,
}

export default FirstDairyPresentation
