import { orderDrugsActions } from '../../actions'

const {
  addCurrentReceiptAction,
  addCurrentReceiptSuccessAction,
  addCurrentReceiptFailureAction,
} = orderDrugsActions.creators

const addCurrentReceipt = (currentReceipt, consultId, recipe_date) => {
  return async dispatch => {
    try {
      dispatch(addCurrentReceiptAction())
      dispatch(
        addCurrentReceiptSuccessAction({
          currentReceipt,
          consultId,
          recipe_date,
        }),
      )
    } catch (error) {
      dispatch(addCurrentReceiptFailureAction(error))
    }
  }
}

export default addCurrentReceipt
