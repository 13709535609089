const Sessions = () => {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9615 0.892688L27.9619 0.892926C28.3095 1.07847 28.525 1.43877 28.525 1.83371V18.167C28.525 18.5615 28.3076 18.9219 27.9621 19.1077C27.8036 19.1911 27.6307 19.2337 27.4583 19.2337C27.2511 19.2337 27.0444 19.1739 26.8679 19.0548L26.8675 19.0545L21.6805 15.5965L21.525 15.4929V15.6797V18.167C21.525 18.7558 21.0471 19.2337 20.4583 19.2337H4.125C3.32346 19.2337 2.70653 18.7426 2.22831 17.9575C1.74963 17.1717 1.42127 16.1078 1.19725 15.005C0.749557 12.801 0.725 10.4758 0.725 10.0003C0.725 9.52478 0.749557 7.1996 1.19725 4.9957C1.42127 3.8929 1.74963 2.82897 2.22831 2.04316C2.70653 1.25811 3.32346 0.767004 4.125 0.767004H20.4583C21.0471 0.767004 21.525 1.24494 21.525 1.83371V4.321V4.50786L21.6805 4.40421L26.8675 0.94621L26.8678 0.945962C27.1939 0.7265 27.6184 0.7073 27.9615 0.892688ZM26.2362 16.0709L26.3917 16.1746V15.9877V4.01311V3.82626L26.2362 3.92991L21.0492 7.38791C20.7228 7.60556 20.3003 7.62672 19.955 7.44131L19.9548 7.44119C19.6072 7.25565 19.3917 6.89535 19.3917 6.50041V3.00041V2.90041H19.2917H4.125H4.0805L4.05071 2.93347C4.00856 2.98024 3.96137 3.06133 3.91158 3.16567C3.86045 3.27281 3.80267 3.41294 3.74122 3.58468C3.61826 3.92831 3.47926 4.40207 3.34856 4.99851C3.08713 6.19156 2.8583 7.87817 2.8583 10.0004C2.8583 12.1225 3.08712 13.8098 3.3753 15.0106C3.51934 15.6109 3.67859 16.0911 3.83234 16.444C3.90918 16.6204 3.98531 16.7665 4.05836 16.8804C4.13035 16.9927 4.20351 17.0804 4.27664 17.1329L4.30294 17.1518L4.33533 17.1517L19.292 17.1027L19.3917 17.1024V17.0027V13.5004C19.3917 13.1059 19.6092 12.7454 19.9548 12.5596C20.3006 12.3761 20.7232 12.3955 21.0492 12.6129L26.2362 16.0709Z"
        stroke="#e80175"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.625"
          y1="9.92156"
          x2="28.625"
          y2="9.92156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F658B1" />
          <stop offset="1" stopColor="#FD9456" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Sessions
