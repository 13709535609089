import PropTypes from 'prop-types'

const CommentStars = ({ score }) => {
  return <></>
}

CommentStars.propTypes = {
  score: PropTypes.number,
}

export default CommentStars
