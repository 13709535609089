import React from 'react'
import Images from '../../assets'

import { Button, ImageCustom, TextCustom, InformationWarranty } from '..'
import { Link } from 'react-router-dom'

const consultCardInfo = ({
  consult,
  showDetails,
  setShowDetails,
  modalState,
  toggleModal,
  setModalState,
}) => {
  const { HoursService, LiveService, warranty, arrowDown } = Images
  const {
    created,
    status,
    scheduled_date,
    questionnaire,
    specialty,
    hours_backup,
  } = consult
  const specialtyText = {
    derma: 'Dermatológica',
    psychology: 'Psicológica',
    nutrition: 'Nutricional',
    medicine: 'General',
  }
  const isPracti = questionnaire && (specialty === 'derma' || !specialty)
  let statusType
  switch (status) {
    case 'onCall':
      statusType = 'En llamada'
      break
    case 'close':
      statusType = 'Terminada'
      break
    case 'ready':
      statusType = 'Disponible'
      break
    case 'deferred':
    case 'scheduled':
    case 'waiting':
      statusType = 'En espera'
      break
    case 'canceled':
      statusType = 'Cancelada'
      break
    default:
      break
  }

  const date = !isPracti && scheduled_date ? scheduled_date : created
  return (
    <header className="consults-container-header">
      <div className="columns session-card">
        <div className="type-service-container is-centered">
          <ImageCustom
            img={isPracti ? HoursService : LiveService}
            className="is-size-icon-consult"
          />
          <span
            className={
              isPracti
                ? 'is-practi has-text-centered'
                : 'is-fast has-text-centered'
            }
          >
            {isPracti ? '45 horas' : 'Live'}
          </span>
        </div>
        <div className="consult-card-info">
          <span className={`status status-${status}`}>{statusType}</span>
          {status === 'onCall' && (
            <Link to={`/user/live_service?id=${consult.id}`}>
              Unirse a llamada
            </Link>
          )}
          <TextCustom>
            {'Fecha: '}
            <span>{date}</span>
            {!isPracti && (
              <span>{` ${
                hours_backup.scheduled_date || hours_backup.created
              }`}</span>
            )}
          </TextCustom>
          {isPracti && (
            <TextCustom>
              {'Tiempo máx. espera: '} <span> 45 hrs</span>
            </TextCustom>
          )}
          <TextCustom>
            {'Especialidad: '}{' '}
            <span>{specialtyText[specialty || 'derma']}</span>
          </TextCustom>
        </div>
        <div className="type-service-container">
          <ImageCustom
            img={warranty}
            className="is-size-icon-warranty is-clickable"
            onClick={() => setModalState(true)}
          />
        </div>
        <Button
          className="button-show-info-container"
          onClick={setShowDetails}
          img={arrowDown}
          imgClassName={showDetails ? 'is-rotation-arrow-up' : ''}
        />
      </div>
      {modalState && <InformationWarranty closeModal={toggleModal} />}
    </header>
  )
}

export default consultCardInfo
