import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  TitleCustom,
  TextCustom,
  ContainerBoxPicture,
  ImageCustom,
} from '../../components'
import Button from '../buttons/Button'
import Images from '../../assets'

const ConsulationService = ({
  evidences,
  setEvidences,
  increment,
  addError,
  removeError,
  errors,
  decrement,
}) => {
  const [fileURL, setFileURL] = useState([])
  const { length } = fileURL
  const { imgIcon } = Images
  const handleRegister = e => {
    e.preventDefault()
    if (length === 0) {
      addError('Agregue una foto por favor')
      return
    }
    removeError()
    increment()
  }
  const postimage = event => {
    const imageFile = event.target.files[0]
    const imageUrl = window.URL.createObjectURL(imageFile)
    setFileURL([...fileURL, imageUrl])
    setEvidences([...evidences, imageFile])
  }
  return (
    <>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start">
        <TitleCustom className="title is-size-5 are-color-mynd-grey">
          Fotos para analisis
        </TitleCustom>
      </div>
      {errors && <div className="notification is-danger">{errors}</div>}
      <TextCustom className="are-color-mynd-grey is-size-6 ">
        Recomendamos tomar 10 fotos de{' '}
        <span className="bold-font">diferentes angulos</span> para mejor
        analisis
      </TextCustom>

      <form onSubmit={handleRegister}>
        <div className="is-flex is-flex-direction-column is-align-content-center is-align-self-center">
          <label className="label-input-file" alt="actual-btn">
            <input
              type="file"
              id="actual-btn"
              hidden={true}
              onChange={postimage}
            />
            <span className="icon-text is-centered">
              <span className="icon">
                <ImageCustom img={imgIcon} />
              </span>
              <TextCustom className="are-color-mynd-grey has-text-weight-bold">
                Escoger de mi dispositivo
              </TextCustom>
            </span>
          </label>
        </div>
        <ContainerBoxPicture fileUrl={fileURL} />
        <TextCustom className="are-color-mynd-grey bold-font mt-3">
          Fotos subidas: {length}
        </TextCustom>
        <div className="buttons is-centered mt-5">
          <Button className="button is-text-color" onClick={decrement}>
            Anterior
          </Button>
          <Button className="button is-mnd-primary has-text-white is-medium is-padding-button-next">
            Siguiente
          </Button>
        </div>
      </form>
    </>
  )
}

ConsulationService.propTypes = {
  evidences: PropTypes.array,
  setEvidences: PropTypes.func,
  increment: PropTypes.func,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  errors: PropTypes.string,
  decrement: PropTypes.func,
}

export default ConsulationService
