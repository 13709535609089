export default auth => {
  const { error, user } = auth
  const { message } = error
  const { uid } = user
  return {
    message,
    user,
    uid,
  }
}
