import PropTypes from 'prop-types'
import { TextCustom, MyAddresses } from '..'
import { useToggle } from '../../utils'
import { formatToMoney } from '../../utils/functions/currencyFormat'
const receiptSummary = ({
  count,
  selectMethod,
  isOrderDrug,
  increment,
  totalCost,
  allAddress,
  shippingMethod,
  selectAddress,
  selectPlan,
  paymentMethod,
  setSelectAddress,
  setSelectPlan,
  setShippingMethod,
  shippingPlans,
}) => {
  const [isOpen, toggle] = useToggle()
  const now = new Date()
  const month = Intl.DateTimeFormat('es-MX', { month: 'long' }).format(now)
  const day = Intl.DateTimeFormat('es-MX', { day: '2-digit' }).format(now)
  const currentHour = `${now.getHours()}:${now.getMinutes()}`
  const currentDate = `${day} de ${month}`
  const plans = [
    { label: '1 solo pago', months: 0, min: 1 },
    { label: '3 meses sin intereses', months: 3, min: 300 },
    { label: '6 meses sin intereses', months: 6, min: 600 },
    { label: '9 meses sin intereses', months: 9, min: 900 },
    { label: '12 meses sin intereses', months: 12, min: 1200 },
  ]

  return (
    <div className="is-flex is-flex-direction-column px-3 mnd-gray-border is-border-radius mb-5">
      <p className="has-text-centered my-3">
        <strong>Detalles de la compra</strong>
      </p>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between">
        <p>
          <strong>Fecha: </strong>
          {currentDate}
        </p>
        <p>
          <strong>Hora: </strong>
          {currentHour}
        </p>
      </div>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between py-4 my-3 is-align-content-center">
        <TextCustom className="has-text-weight-bold">Pagar con:</TextCustom>
        <a className="has-text-weight-light" onClick={() => increment()}>
          {paymentMethod
            ? paymentMethod.method === 'balance' ||
              paymentMethod.method === 'card'
              ? paymentMethod.value
              : paymentMethod.details
            : 'Elija un método de pago'}
        </a>
        <span className="icon are-color-mynd-grey order-arrow">
          <i className="fas fa-angle-right"></i>
        </span>
      </div>

      {selectMethod !== '' && count === 2 && (
        <>
          {paymentMethod &&
            paymentMethod.method === 'card' &&
            paymentMethod.funding === 'credit' && (
              <div className="field">
                <label className="label">Selecciona tu pago a plazos</label>
                <div className="control is-expanded">
                  <div className="select select is-fullwidth">
                    <select
                      value={selectPlan}
                      onChange={({ target }) => setSelectPlan(target.value)}
                      name="plan"
                    >
                      <option value={''}>Selecciona tu pago a plazos</option>
                      {plans
                        .filter(plan => plan.min <= totalCost)
                        .map(plan => (
                          <option key={plan.label} value={plan.months}>
                            {plan.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            )}

          <div className="field">
            <label className="label">Direccion de entrega</label>
            <div className="control is-expanded">
              <div className="select select is-fullwidth">
                <select
                  value={selectAddress}
                  onChange={({ target }) => setSelectAddress(target.value)}
                  name="address"
                >
                  <option value={''}>Selecciona tu direccion</option>
                  {allAddress.map(({ description }, index) => (
                    <option key={index} value={description}>
                      {description}
                    </option>
                  ))}
                </select>
              </div>
              <a onClick={toggle}>Anadir direccion</a>
            </div>
          </div>
          <div className="field">
            <label className="label">Metodo de envio</label>
            <div className="control is-expanded">
              <div className="select select is-fullwidth">
                <select
                  value={shippingMethod}
                  onChange={({ target }) => setShippingMethod(target.value)}
                  name="shipping"
                >
                  <option value={''}>Selecciona tu envio</option>
                  {shippingPlans.map(({ label }, index) => (
                    <option key={index} value={index}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {isOpen && (
            <div className="modal">
              <div className="modal-background"></div>
              <div
                style={{ height: '769px' }}
                className="modal-card is-modal-border-radius"
              >
                <section className="modal-card-body">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    onClick={toggle}
                  >
                    <button
                      style={{ borderRadius: '50%' }}
                      className="button is-mnd-primary"
                    >
                      <span className="has-text-white">X</span>
                    </button>
                  </div>
                  <MyAddresses />
                </section>
              </div>
            </div>
          )}
        </>
      )}

      {!isOrderDrug && (
        <div className="field">
          <label className="label">Seleccionar cupon</label>
          <div className="control is-expanded">
            <div className="select select is-fullwidth">
              <select>
                <option value="sexo">Selecciona tu cupon</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <TextCustom className="has-text-weight-bold has-text-weight-bold">
        Resumen de compra
      </TextCustom>
      <div style={{ borderBottom: '2px dashed #cacaca' }}></div>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between py-4">
        <TextCustom className="has-text-weight-bold">Envío:</TextCustom>
        <TextCustom className="has-text-weight-light">
          {200 - totalCost <= 0 ? 'Gratis' : formatToMoney(120)}
        </TextCustom>
      </div>
      <h1 className="subtitle is-size-7">
        Las compras en medicamentos mayores a MXN $200.00 tienen envio gratis
      </h1>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between py-4">
        <TextCustom className="has-text-weight-bold">
          Total en medicamentos:
        </TextCustom>
        <TextCustom className="has-text-weight-light">
          {formatToMoney(totalCost)}
        </TextCustom>
      </div>
      <div className="is-flex is-flex-direction-row is-justify-content-space-between py-4">
        <TextCustom className="has-text-weight-bold">Total a pagar</TextCustom>
        <TextCustom className="has-text-weight-bold">
          {200 - totalCost <= 0
            ? formatToMoney(totalCost)
            : formatToMoney(120 + totalCost)}{' '}
        </TextCustom>
      </div>
    </div>
  )
}

receiptSummary.propTypes = {
  increment: PropTypes.func.isRequired,
  totalCost: PropTypes.number.isRequired,
  allAddress: PropTypes.instanceOf(Array).isRequired,
  paymentMethod:
    PropTypes.string.isRequired || PropTypes.instanceOf(Object).isRequired,
  setSelectAddress: PropTypes.func.isRequired,
  isOrderDrug: PropTypes.bool,
  shippingPlans: PropTypes.instanceOf(Array).isRequired,
  count: PropTypes.number.isRequired,
  setShippingMethod: PropTypes.any.isRequired,
  shippingMethod: PropTypes.any,
  selectAddress: PropTypes.any,
  selectPlan: PropTypes.any,
}

export default receiptSummary
