// import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { questionsdata } from '../../utils/constants'

import { TitleCustom, Accordion } from '..'

const FrequentQuestions = props => {
  const [questions] = useState(questionsdata)

  return (
    <div className="content-questions">
      <TitleCustom className="are-color-mynd-grey has-text-centered is-size-title-">
        Preguntas frecuentes
      </TitleCustom>
      {questions.map(question => (
        <Accordion key={question.id} {...question} />
      ))}
    </div>
  )
}

FrequentQuestions.propTypes = {}

export default FrequentQuestions
