import moment from 'moment'
import 'moment/locale/es-mx'
import { consultActions } from '../../actions'
import { consultRef, usersF } from '../../config'

const {
  getAllConsultsRequestAction,
  // getAllConsultsSuccessAction,
  getAllConsultsFailureAction,
} = consultActions.creators

const sessions = async sessionsSlice => {
  const sessionsSliceMap = await Promise.all(
    sessionsSlice.map(async item => {
      const session = item.data()
      const tempSession = session
      tempSession.id = item.id

      const doctor = session.doctor
        ? await session.doctor.get().then(doctor => {
            return doctor
          })
        : null
      const clinic = session.clinic
        ? await session.clinic.get().then(clinic => {
            return clinic
          })
        : null
      const coupon = session.coupon
        ? await session.coupon.get().then(coupon => {
            return coupon
          })
        : null
      if (session.doctor) {
        tempSession.doctor = doctor.data()
      }
      if (session.clinic) {
        tempSession.clinic = clinic.data()
        tempSession.clinic.id = clinic.id
      }
      if (session.coupon) {
        tempSession.coupon = coupon.data()
      }
      session.created_date = moment(
        new Date(session.created.seconds * 1000),
      ).format()
      session.created = moment(new Date(session.created.seconds * 1000)).format(
        'DD/MMMM/YYYY',
      )
      if (session.scheduled_date) {
        session.scheduled_date = moment(
          new Date(session.scheduled_date.seconds * 1000),
        ).format('DD/MMMM/YYYY')
      }
      return tempSession
    }),
  )
  return sessionsSliceMap
}

const getConsultsService = (uid, page, filter) => {
  return async dispatch => {
    dispatch(getAllConsultsRequestAction())
    try {
      const userRef = usersF.doc(uid)
      const sessionSnapshot = await consultRef
        .where('patient', '==', userRef)
        .orderBy('created', 'desc')
        .get()

      const limit = page * 10
      const endOffset =
        sessionSnapshot.docs.length >= limit
          ? limit
          : sessionSnapshot.docs.length
      const sessionsSlice = sessionSnapshot.docs.slice(limit - 10, endOffset)

      return sessions(sessionsSlice)
    } catch (error) {
      dispatch(getAllConsultsFailureAction(error))
      return []
    }
  }
}

export default getConsultsService
