import PropTypes from 'prop-types'

const RegisterForm = ({ handleOnSubmit, title, children }) => {
  return (
    <>
      {title}
      <form onSubmit={handleOnSubmit} autoComplete="off">
        {children}
      </form>
    </>
  )
}

RegisterForm.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  title: PropTypes.element,
  children: PropTypes.array,
}

export default RegisterForm
