import PropTypes from 'prop-types'

const Input = ({ ...inputProps }) => {
  return <input {...inputProps} />
}

Input.propTypes = {
  inputProps: PropTypes.object,
}

export default Input
