import PropTypes from 'prop-types'
import { ButtonCustom, TextCustom } from '../../components'
import 'react-credit-cards/es/styles-compiled.css'

const SpeiResult = ({ closeModal, data }) => {
  const handleSubmit = async e => {
    closeModal()
  }
  console.log('data', data)
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card m-0 p-0" onClick={closeModal}>
        <section className="modal-body">
          <div key="Payment" className="App-payment">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <TextCustom className="has-text-grey is-size-6 ">
                  {data.description}
                </TextCustom>
                <TextCustom className="has-text-grey is-size-6 mt-2">
                  {`Cuenta CLABE: ${data.payment_method.clabe}`}
                </TextCustom>
                <TextCustom className="has-text-grey is-size-6 mt-2">
                  {` Banco: ${data.payment_method.bank}`}
                </TextCustom>
                <TextCustom className="has-text-grey is-size-6 mt-2">
                  {` Nombre: ${data.payment_method.name}`}
                </TextCustom>
                <TextCustom className="has-text-grey is-size-6 mt-2">
                  {` Cantidad: ${data.amount}`}
                </TextCustom>
              </div>
              <div className="mx-3 mt-5">
                <ButtonCustom buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary">
                  Regresar al menú
                </ButtonCustom>
              </div>
            </form>
          </div>
        </section>
      </div>
      <button className="modal-close is-large" aria-label="close"></button>
    </div>
  )
}
SpeiResult.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
}

export default SpeiResult
