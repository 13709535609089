import PropTypes from 'prop-types'

// accordion
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

// components
import {
  ImageCustom,
  LabelCustom,
  Input,
  TextCustom,
  TextAreaCustom,
  ButtonCustom,
} from '../../components'
import { useForm } from '../../utils'
import Images from '../../assets'
import { storageRef } from '../../config'

const AccordionAdditionalInformation = ({
  uploadFiles,
  uid,
  medicalRecord,
  additionalInformationProp,
  expedientFilesProp,
}) => {
  const finalAdditionalInformation = additionalInformationProp || ''
  const [
    { file, additionalInformation },
    handleInputChange,
    ,
    handleFileInputChange,
  ] = useForm({
    file: null,
    additionalInformation: finalAdditionalInformation,
  })
  const { exclamationMarkIcon, arrowDown } = Images

  const removeFile = (item, index) => {
    storageRef
      .child(`profile/users/${uid}/expedient/${item.name}`)
      .delete()
      .then(() => {
        const data = { expedientFiles: [...expedientFilesProp] }
        if (data.expedientFiles.length === 1) {
          data.expedientFiles = []
        } else {
          data.expedientFiles.splice(index, 1)
        }

        medicalRecord({
          method: 'POST',
          uid,
          data,
        })
      })
  }

  const handleFile = async () => {
    const url = await uploadFiles(file, uid)

    const data = {
      expedientFiles: [
        ...expedientFilesProp,
        {
          name: file.name,
          url,
        },
      ],
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const saveAdditional = () => {
    if (additionalInformation !== '') {
      const data = {
        additionalInformation,
      }

      medicalRecord({
        method: 'POST',
        uid,
        data,
      })
    }
  }

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={exclamationMarkIcon}
              className="is-size-exclamationmark-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Información adicional</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        <TextCustom>
          Puedes subir documentos, que ayudará al especialista a conocerte mejor
        </TextCustom>
        {file === null ? (
          <LabelCustom className="file-label mt-3 mb-3">
            <Input
              className="file-input is-mnd-primary is-background-button-information"
              type="file"
              name="file"
              onChange={handleFileInputChange}
            />
            <span className="file-cta is-background-button-information">
              <span className="file-icon ">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">Subir archivo</span>
            </span>
          </LabelCustom>
        ) : (
          <div className="row">
            <button
              className="button has-text-white is-mnd-primary mt-2 mr-2"
              onClick={handleFile}
            >
              <span className="file-icon ">
                <i className="fas fa-upload"></i>
              </span>
              Confirmar subir {file.name}
            </button>
          </div>
        )}

        {expedientFilesProp &&
          expedientFilesProp.map(({ name, url }, index) => (
            <div className="is-background-box-allergies mt-3 px-3" key={index}>
              <div className="level mb-1 is-mobile">
                <div className="level-left">
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {name}
                  </a>
                </div>
                <div className="level-right ">
                  <ButtonCustom
                    buttonClassName="button is-button-close"
                    icon="fas fa-times"
                    onClick={() => removeFile({ name, url }, index)}
                  />
                </div>
              </div>
            </div>
          ))}

        <LabelCustom>Añadir información adicional:</LabelCustom>
        <TextAreaCustom
          onChange={handleInputChange}
          name="additionalInformation"
          value={additionalInformation}
        />
        <button
          className="button has-text-white is-mnd-primary mt-2"
          onClick={saveAdditional}
        >
          Guardar
        </button>
      </AccordionItemPanel>
    </>
  )
}

AccordionAdditionalInformation.propTypes = {
  uploadFiles: PropTypes.func,
  uid: PropTypes.string,
  medicalRecord: PropTypes.func,
  additionalInformationProp: PropTypes.string,
  expedientFilesProp: PropTypes.any,
}

export default AccordionAdditionalInformation
