import PropTypes from 'prop-types'
import moment from 'moment'
// components
import { TextCustom, ImageCustom } from '..'
// img
import Images from '../../assets'

const onthewayCard = ({ order, handleSelectOrder }) => {
  const { truckIcon } = Images
  const { total, requestedItems, status, automatic_shipping_data, order_date } =
    order

  const sizeProduct = Object.keys(requestedItems).length
  const showStatus =
    (status === 'new' && 'Orden Creada') ||
    (status === 'processing' && 'En Proceso') ||
    (status === 'sent' && 'En camino') ||
    (status === 'received' && 'Entregada')
  const showDate = order_date
    ? moment(moment(new Date(order_date._seconds * 1000)).toDate()).format(
        'DD/MM/YY',
      )
    : ''
  // const showTime = moment(
  //   moment(new Date(order_date._seconds * 1000)).toDate(),
  // ).format('hh:mm a')
  // console.log(`showTime`, showTime)
  // console.log(`showDate`, showDate)
  return (
    <div className="box is-size-box" onClick={() => handleSelectOrder(order)}>
      <div className="level mb-2 is-mobile">
        <div className="level-left">
          <TextCustom className="are-color-mynd-grey">Nº 454346</TextCustom>
        </div>
        <div className="level-right">
          <TextCustom className="is-text-color-card has-text-weight-bold">
            ${total}
          </TextCustom>
        </div>
      </div>
      <hr className="line-colour my-2"></hr>
      <div className="columns is-mobile">
        <div className="column">
          <TextCustom className="are-color-mynd-grey has-text-weight-bold">
            Productos My NextDerma <br /> para tratamiento derma...
          </TextCustom>
          <TextCustom className="are-color-mynd-grey mt-1">
            Productos: ({sizeProduct})
          </TextCustom>
        </div>
        <div className="column">
          <figure className="image is-size-img-trunk-shopping ">
            <ImageCustom img={truckIcon} />
          </figure>
          <TextCustom className="has-text-right is-text-trunk are-color-mynd-grey mt-1 mr-4">
            {showStatus}
          </TextCustom>
          <TextCustom className="has-text-right is-text-trunk are-color-mynd-grey mt-1 mr-3">
            {automatic_shipping_data ? automatic_shipping_data.date : showDate}
          </TextCustom>
        </div>
      </div>
    </div>
  )
}

onthewayCard.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  handleSelectOrder: PropTypes.func.isRequired,
}

export default onthewayCard
