import PropTypes from 'prop-types'

const ButtonCustom = ({
  buttonClassName,
  handleOnClick,
  icon,
  iconClassName,
  children,
  ...otherProps
}) => (
  <button className={buttonClassName} onClick={handleOnClick} {...otherProps}>
    {icon ? (
      <span className={`icon ${iconClassName}`}>
        <i className={icon}></i>
      </span>
    ) : (
      <div className="is-flex">
        <span className="icon is-medium">
          <i className={iconClassName}></i>
        </span>
        <span className="bold-font">{children}</span>
        <span className="icon is-medium" />
      </div>
    )}
  </button>
)

ButtonCustom.propTypes = {
  buttonClassName: PropTypes.string.isRequired,
  handleOnClick: PropTypes.any,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.string,
}

export default ButtonCustom
