export default (clinicalRecord, auth) => {
  const { user } = auth
  const { uid } = user
  const { data, error, isFetching } = clinicalRecord
  return {
    data,
    error,
    uid,
    isFetching,
  }
}
