import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RecipeBuying, ModalPromotions } from '../../components'
import { useRouter } from '../../utils'
import dispatchers from './dispatchers'
import selectors from './selectors'

const OrderDrugs = () => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const {
    getDrugs,
    buyDrug,
    generateAsyncBuyDrugList,
    generateAsynchronousPaymentReceipt,
  } = dispatchers(dispatch)
  const state = useSelector(state => state)
  const {
    allAddress,
    currentReceipt,
    cards,
    balance,
    email,
    phone,
    consultId,
    isLoading,
    fullname,
    recipe_date,
  } = selectors(state)
  const [showModal, setShowModal] = useState(true)
  const [num, setNum] = useState()
  const [expired, setExpired] = useState(false)
  const intervalRef = useRef()
  const decreaseNum = () => setNum(prev => prev - 1)

  const handleOnClick = () => {
    if (!expired) setShowModal(false)
    else
      history.push({
        pathname: '/user/order-drugs/',
      })
  }

  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    if (num === 0) {
      setExpired(true)
      setShowModal(true)
      setNum()
    }
  }, [num])

  return (
    <div className="container-drugs">
      <div className="is-flex is-justify-content-center is-align-items-center orders">
        <RecipeBuying
          fullname={fullname}
          isLoading={isLoading}
          consultId={consultId}
          buyDrug={buyDrug}
          getDrugs={getDrugs}
          drugsList={currentReceipt}
          allAddress={allAddress}
          cards={cards}
          balance={balance}
          email={email}
          phone={phone}
          generateAsyncBuyDrugList={generateAsyncBuyDrugList}
          generateAsynchronousPaymentReceipt={
            generateAsynchronousPaymentReceipt
          }
          recipe_date={recipe_date}
          num={num}
          setNum={setNum}
        />
      </div>
      {showModal && (
        <ModalPromotions handleOnClick={handleOnClick} expired={expired} />
      )}
    </div>
  )
}

export default OrderDrugs
