const en = {
  buttonSignIn: 'Sign in',
  title: 'Sign in with:',
  google: 'Google',
  facebook: 'Facebook',
  phone: 'Cellphone',
  password: 'Password',
  email: 'Email',
  subtitle: 'Also can sign in with:',
  recoveryPassword: 'Forgot your Password?',
  checkbox: 'Remember me',
  getAccount: 'Dont have a account?',
  register: 'Sign Up Now',
  titleSlider: 'Diagnostic on 48h',
  subtitleSlider: 'If after 6 months of',
  subtitleSlider2: 'treatment there is no improvement',
  subtitleSlider3: 'We refund 100% of your inquiries.',
  titleFooter: '© 2020 My NextDerma. All rights reserved',
  'policy-privacy': 'Privacy Policy',
}

export default en
