//* TYPES - EMAIL AND PASSWORD
const SIGNIN_WITH_EMAIL_AND_PASSWORD = 'SIGNIN_WITH_EMAIL_AND_PASSWORD'
const SIGNIN_WITH_EMAIL_AND_PASSWORD_SUCCESS =
  'SIGNIN_WITH_EMAIL_AND_PASSWORD_SUCCESS'
const SIGNIN_WITH_EMAIL_AND_PASSWORD_FAILURE =
  'SIGNIN_WITH_EMAIL_AND_PASSWORD_FAILURE'
//* TYPES - CREATE USER WITH EMAIL AND PASSWORD
const CREATE_USER_WITH_EMAIL_AND_PASSWORD =
  'CREATE_USER_WITH_EMAIL_AND_PASSWORD'
const CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS =
  'CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS'
const CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE =
  'CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE'
//* TYPES - SOCIAL_PROVIDERS
const SIGNIN_WITH_SOCIAL_PROVIDER = 'SIGNIN_WITH_SOCIAL_PROVIDER'
const SIGNIN_WITH_SOCIAL_PROVIDER_SUCCESS =
  'SIGNIN_WITH_SOCIAL_PROVIDER_SUCCESS'
const SIGNIN_WITH_SOCIAL_PROVIDER_FAILURE =
  'SIGNIN_WITH_SOCIAL_PROVIDER_FAILURE'
//* TYPES - PHONE
const SIGNIN_WITH_PHONE = 'SIGNIN_WITH_PHONE'
const SIGNIN_WITH_PHONE_SUCCESS = 'SIGNIN_WITH_PHONE_SUCCESS'
const SIGNIN_WITH_PHONE_FAILURE = 'SIGNIN_WITH_PHONE_FAILURE'
//*  TYPES - EMAIL PASSWORD RESET
const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL'
const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS'
const UNSET_SEND_RESET_PASSWORD_EMAIL_SUCCESS =
  'UNSET_SEND_RESET_PASSWORD_EMAIL_SUCCESS'
const SEND_RESET_PASSWORD_EMAIL_FAILURE = 'SEND_RESET_PASSWORD_EMAIL_FAILURE'
//* TYPES - CHANGE PASSSWORD
const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
const UNSET_CHANGE_PASSWORD_SUCCESS = 'UNSET_CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
//* TYPES - SIGNOUT
const SIGNOUT = 'SIGNOUT'
//* TYPES - FETCH_SIGNIN_ACCOUNTS
const FETCH_SIGNIN_ACCOUNTS = 'FETCH_SIGNIN_ACCOUNTS'
const FETCH_SIGNIN_ACCOUNTS_SUCCESS = 'FETCH_SIGNIN_ACCOUNTS_SUCCESS'
const FETCH_SIGNIN_ACCOUNTS_FAILURE = 'FETCH_SIGNIN_ACCOUNTS_FAILURE'
//* TYPES - SIGNUP
const SIGNUP = 'SIGNUP'
const SIGNUP_SUCCESS = 'SINGUP_SUCCESS'
const SIGNUP_FAILURE = 'SINGUP_FAILURE'
//* TYPES- REFRESH USER DATA
const REFRESH_USER = 'REFRESH_USER'
const REFRESH_USER_SUCCESS = 'REFRESH_SUCCESS'
const REFRESH_USER_FAILURE = 'REFRESH_FAILURE'
// * TYPES - ADD BALANCE
const ADD_BALANCE_REQUEST = 'ADD_BALANCE_REQUEST'
const ADD_BALANCE_SUCCESS = 'ADD_BALANCE_SUCCESS'
const ADD_BALANCE_FAILURE = 'ADD_BALANCE_FAILURE'
// * TYPES - ADD CARD
const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST'
const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS'
const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE'
//* TYPES - UPDATE USER
const UPDATE_USER = 'UPDATE_USER '
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
//* TYPES - REMOVE ERRORS
const REMOVE_AUTH_ERRORS = 'REMOVE_AUTH_ERRORS'

//* ACTIONS EMAIL AND PASSWORD
const signInWithEmailAndPasswordAction = () => ({
  type: SIGNIN_WITH_EMAIL_AND_PASSWORD,
})
const signInWithEmailAndPasswordSuccessAction = (payload = {}) => ({
  type: SIGNIN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  payload,
})
const signInWithEmailAndPasswordFailureAction = (payload = {}) => ({
  type: SIGNIN_WITH_EMAIL_AND_PASSWORD_FAILURE,
  payload,
})
//* ACTIONS CREATE USER WITH EMAIL AND PASSWORD
const createUserWithEmailAndPasswordAction = () => ({
  type: CREATE_USER_WITH_EMAIL_AND_PASSWORD,
})
const createUserWithEmailAndPasswordSuccessAction = (payload = {}) => ({
  type: CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  payload,
})

const createUserWithEmailAndPasswordFailureAction = (payload = {}) => ({
  type: CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE,
  payload,
})

//* ACTIONS GOOGLE
const signInWithSocialProviderAction = (payload = {}) => ({
  type: SIGNIN_WITH_SOCIAL_PROVIDER,
  payload,
})

const signInWithSocialProviderSuccessAction = (payload = {}) => ({
  type: SIGNIN_WITH_SOCIAL_PROVIDER_SUCCESS,
  payload,
})

const signInWithSocialProviderFailureAction = (payload = {}) => ({
  type: SIGNIN_WITH_SOCIAL_PROVIDER_FAILURE,
  payload,
})

//* ACTIONS PHONE
const signInWithPhoneAction = () => ({
  type: SIGNIN_WITH_PHONE,
})

const signInWithPhoneSuccessAction = (payload = {}) => ({
  type: SIGNIN_WITH_PHONE_SUCCESS,
  payload,
})

const signInWithPhoneFailureAction = (payload = {}) => ({
  type: SIGNIN_WITH_PHONE_FAILURE,
  payload,
})

//* SIGNOUT
const signOutAction = () => ({
  type: SIGNOUT,
})

//* ACTIONS FETCH_SIGNIN_ACCOUNTS
const fetchSignInAccountsAction = () => ({
  type: FETCH_SIGNIN_ACCOUNTS,
})
const fetchSignInAccountsSuccessAction = (payload = {}) => ({
  type: FETCH_SIGNIN_ACCOUNTS_SUCCESS,
  payload,
})
const fetchSignInAccountsFailureAction = (payload = {}) => ({
  type: FETCH_SIGNIN_ACCOUNTS_FAILURE,
  payload,
})

//* ACTIONS SEND_RESET_PASSWORD_EMAIL
const sendResetPasswordEmailAction = () => ({
  type: SEND_RESET_PASSWORD_EMAIL,
})
const sendResetPasswordEmailSuccessAction = () => ({
  type: SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  payload: true,
})

const unsetSendResetPasswordEmailSuccessAction = () => ({
  type: UNSET_SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  payload: false,
})

const sendResetPasswordEmailFailureAction = (payload = {}) => ({
  type: SEND_RESET_PASSWORD_EMAIL_FAILURE,
  payload,
})

//* ACTIONS CHANGE_PASSWORD
const changePasswordAction = () => ({
  type: CHANGE_PASSWORD,
})

const changePasswordSuccessAction = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: true,
})

const unsetChangePasswordSuccessAction = () => ({
  type: UNSET_CHANGE_PASSWORD_SUCCESS,
  payload: false,
})

const changePasswordFailureAction = (payload = {}) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
})

//* ACTIONS SIGNUP
const signUpRequestAction = () => ({
  type: SIGNUP,
})

const signUpSuccessAction = (payload = {}) => ({
  type: SIGNUP_SUCCESS,
})

const signUpFailureAction = (payload = {}) => ({
  type: SIGNUP_FAILURE,
})

//* ACTIONS REFRESH USER DATA
const refreshUser = (payload = {}) => ({
  type: REFRESH_USER,
  payload,
})

const refreshUserSuccessAction = (payload = {}) => ({
  type: REFRESH_USER_SUCCESS,
  payload,
})

const refreshUserFailureAction = (payload = {}) => ({
  type: REFRESH_USER_SUCCESS,
  payload,
})

// * ACTIONS ADD BALANCE

const addBalanceRequestAction = (payload = {}) => ({
  type: ADD_BALANCE_REQUEST,
  payload,
})

const addBalanceSuccessAction = (payload = {}) => ({
  type: ADD_BALANCE_SUCCESS,
  payload,
})

const addBalanceFailureAction = (payload = {}) => ({
  type: ADD_BALANCE_FAILURE,
  payload,
})

// * ACTIONS ADD CARD
const addCardRequestAction = (payload = {}) => ({
  type: ADD_CARD_REQUEST,
  payload,
})

const addCardSuccessAction = (payload = {}) => ({
  type: ADD_CARD_SUCCESS,
  payload,
})

const addCardFailureAction = (payload = {}) => ({
  type: ADD_CARD_FAILURE,
  payload,
})

//* ACTIONS SIGNUP
const updateUserRequestAction = () => ({
  type: UPDATE_USER,
})

const updateUserSuccessAction = (payload = {}) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
})

const updateUserFailureAction = (payload = {}) => ({
  type: UPDATE_USER_FAILURE,
  payload,
})

const removeAuthErrorsAction = () => ({
  type: REMOVE_AUTH_ERRORS,
})

export default {
  types: {
    CREATE_USER_WITH_EMAIL_AND_PASSWORD,
    CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS,
    CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE,
    FETCH_SIGNIN_ACCOUNTS,
    FETCH_SIGNIN_ACCOUNTS_SUCCESS,
    FETCH_SIGNIN_ACCOUNTS_FAILURE,
    SIGNIN_WITH_EMAIL_AND_PASSWORD,
    SIGNIN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
    SIGNIN_WITH_EMAIL_AND_PASSWORD_FAILURE,
    SIGNIN_WITH_SOCIAL_PROVIDER,
    SIGNIN_WITH_SOCIAL_PROVIDER_SUCCESS,
    SIGNIN_WITH_SOCIAL_PROVIDER_FAILURE,
    SIGNIN_WITH_PHONE,
    SIGNIN_WITH_PHONE_SUCCESS,
    SIGNIN_WITH_PHONE_FAILURE,
    SEND_RESET_PASSWORD_EMAIL,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    UNSET_SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_FAILURE,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    UNSET_CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    SIGNOUT,
    SIGNUP,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    REFRESH_USER,
    REFRESH_USER_SUCCESS,
    REFRESH_USER_FAILURE,
    ADD_BALANCE_REQUEST,
    ADD_BALANCE_SUCCESS,
    ADD_BALANCE_FAILURE,
    ADD_CARD_REQUEST,
    ADD_CARD_SUCCESS,
    ADD_CARD_FAILURE,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    REMOVE_AUTH_ERRORS,
  },
  creators: {
    createUserWithEmailAndPasswordAction,
    createUserWithEmailAndPasswordSuccessAction,
    createUserWithEmailAndPasswordFailureAction,
    fetchSignInAccountsAction,
    fetchSignInAccountsSuccessAction,
    fetchSignInAccountsFailureAction,
    signInWithEmailAndPasswordAction,
    signInWithEmailAndPasswordSuccessAction,
    signInWithEmailAndPasswordFailureAction,
    signInWithSocialProviderAction,
    signInWithSocialProviderSuccessAction,
    signInWithSocialProviderFailureAction,
    signInWithPhoneAction,
    signInWithPhoneSuccessAction,
    signInWithPhoneFailureAction,
    signOutAction,
    signUpRequestAction,
    signUpSuccessAction,
    signUpFailureAction,
    sendResetPasswordEmailAction,
    sendResetPasswordEmailSuccessAction,
    unsetSendResetPasswordEmailSuccessAction,
    sendResetPasswordEmailFailureAction,
    changePasswordAction,
    changePasswordSuccessAction,
    unsetChangePasswordSuccessAction,
    changePasswordFailureAction,
    refreshUser,
    refreshUserSuccessAction,
    refreshUserFailureAction,
    addBalanceRequestAction,
    addBalanceSuccessAction,
    addBalanceFailureAction,
    addCardRequestAction,
    addCardSuccessAction,
    addCardFailureAction,
    updateUserRequestAction,
    updateUserSuccessAction,
    updateUserFailureAction,
    removeAuthErrorsAction,
  },
}
