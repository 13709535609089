import PropTypes from 'prop-types'

const CreditCardForm = ({ placeholder, issuer, name, last4 }) => {
  return (
    <div key="Cards" className="rccs">
      <div className={['rccs__card', `rccs__card--${issuer}`].join(' ').trim()}>
        <div className="rccs__card--front">
          <div className="rccs__card__background" />
          <div className="rccs__issuer" />
          <div className={'rccs__number'}>{`•••• •••• •••• ${
            last4 || '••••'
          }`}</div>
          <div className={'rccs__name'}>{name || placeholder}</div>
        </div>
      </div>
    </div>
  )
}

CreditCardForm.propTypes = {
  placeholder: PropTypes.string.isRequired,
  issuer: PropTypes.string,
  name: PropTypes.string,
  last4: PropTypes.string,
}

export default CreditCardForm
