import functions from '../functions'
import { orderDrugsActions } from '../../actions'
const {
  buyReceiptRequestAction,
  buyReceiptSuccessAction,
  buyReceiptFailureAction,
} = orderDrugsActions.creators
const { buyDrugList } = functions.cloudFunctions
const buyDrugListService = (uid, values) => {
  return async dispatch => {
    try {
      console.log('values', values)
      console.log('uid', uid)
      dispatch(buyReceiptRequestAction())
      const response = await buyDrugList({
        uid,
        values,
      })
      console.log('buyDrugListService response', response)
      dispatch(buyReceiptSuccessAction())
      return response
    } catch (error) {
      console.log('buyDrugListService error', error)
      dispatch(buyReceiptFailureAction(error))
    }
  }
}

export default buyDrugListService
