import functions from '../functions'

const { generateRouteCoupon } = functions.cloudFunctions
const generateRouteCouponService = async (price, current) => {
  try {
    const response = await generateRouteCoupon({ price, current })
    const { error } = response
    if (!error) {
      return { status: 'success' }
    }
    return { error }
  } catch (error) {
    return { error }
  }
}

export default generateRouteCouponService
