import PropTypes from 'prop-types'

import { TextCustom, CardProductDiary, ButtonCustom } from '../../../components'

const myProducts = ({
  increment,
  productsList,
  setProductsSelected,
  productsSelected,
}) => {
  return (
    <div className="columns  is-centered">
      <div className="column is-size-container-products-diary">
        <TextCustom className="are-color-mynd-grey has-text-weight-semibold is-size-5">
          Mis productos
        </TextCustom>
        {productsList.map((product, index) => {
          return (
            <CardProductDiary
              product={product}
              key={index}
              setProductsSelected={setProductsSelected}
              productsSelected={productsSelected}
            />
          )
        })}
        <div className="is-position-button-flotante ">
          <ButtonCustom
            icon="fas fa-plus"
            buttonClassName="button is-large is-colour-button-add-product "
            handleOnClick={increment}
          />
        </div>
      </div>
    </div>
  )
}

myProducts.propTypes = {
  increment: PropTypes.func,
  productsList: PropTypes.array,
  setProductsSelected: PropTypes.func,
  productsSelected: PropTypes.array,
}

export default myProducts
