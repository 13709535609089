import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { useEffect } from 'react'
import { useRouter } from '../utils'

const PublicRoute = ({
  isAuth,
  forgottenToken,
  component: Component,
  ...rest
}) => {
  const { history } = useRouter()

  useEffect(() => {
    if (forgottenToken !== '') {
      history.push(forgottenToken)
    }
    return () => {}
  }, [forgottenToken])

  return (
    <Route
      {...rest}
      component={props =>
        !isAuth ? <Component {...props} /> : <Redirect to={'/auth/login'} />
      }
    ></Route>
  )
}

PublicRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  forgottenToken: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
}

export default PublicRoute
