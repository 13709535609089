import { usersF } from '../config'
import { useSelector } from 'react-redux'
import selectors from './sections-selectors.js'

/*
import { useSelector } from 'react-redux'

// helpers
import {  } from '../../utils/sections'

const { auth } = useSelector(state => state)
const { specialty, privateFlag } = selectors(auth)
*/

// general
export const validatePrivateFlag = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  // conditions
  if (privateFlag) {
    return true
  } else {
    return false
  }
}

// specifics
export const validateConsultFastService = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { specialty } = selectors(auth)
  // conditions
  if (specialty === 'medicine') {
    return false
  }
  // default
  return true
}

export const validateConsultPractiService = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { specialty, privateFlag } = selectors(auth)
  // conditions
  if (specialty === 'psychology' || specialty === 'nutrition') {
    return false
  }
  if (privateFlag) {
    return false
  }
  // default
  return true
}

export const validateFilterDoctor = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { specialty } = selectors(auth)
  // conditions
  if (specialty === 'psychology') {
    return true
  }
  // default
  return false
}

// menu
export const validateMenuExpediente = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  // conditions
  if (privateFlag) {
    return false
  }
  // default
  return true
}

export const validateMenuDaily = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { specialty, privateFlag } = selectors(auth)
  // conditions
  if (
    specialty === 'psychology' ||
    specialty === 'nutrition' ||
    specialty === 'medicine'
  ) {
    return false
  }
  if (privateFlag) {
    return false
  }
  // default
  return true
}

export const validateMenuTreatmentRoute = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { specialty } = selectors(auth)
  // conditions
  if (
    specialty === 'psychology' ||
    specialty === 'nutrition' ||
    specialty === 'medicine'
  ) {
    return false
  }
  // default
  return true
}

export const validateMenuCoupons = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  // conditions
  if (privateFlag) {
    return false
  }
  // default
  return true
}

export const validateMenuFavorites = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { privateFlag } = selectors(auth)
  // conditions
  if (privateFlag) {
    return false
  }
  // default
  return true
}

export const validateMenuWarranty = () => {
  // variables
  const { auth } = useSelector(state => state)
  const { specialty, privateFlag } = selectors(auth)
  // conditions
  if (
    specialty === 'psychology' ||
    specialty === 'nutrition' ||
    specialty === 'medicine'
  ) {
    return false
  }
  if (privateFlag) {
    return false
  }
  // default
  return true
}

// filters functions (require to sen user data)
export const validatePrivateSchedule = async (privateFlag, doctorId) => {
  const doctor = await usersF.doc(doctorId).get()
  // conditions
  if (
    privateFlag &&
    (doctor.data().privateDoctorFlag || doctor.data().mixedDoctorFlag)
  ) {
    return true
  }
  // default
  return false
}

export const validateDoctorItem = (specialty, doctorItem, filters = null) => {
  // conditions reject
  if (doctorItem.hiden) {
    return false
  }
  if (doctorItem.privateDoctorFlag) {
    return false
  }

  // conditions accept
  // speciality
  if (specialty === 'psychology' && specialty === doctorItem.specialty) {
    // if (!filters) {
    return true
    // } else {
    //   let sexFilter = false
    //   let workModel = false
    //   let consultDuration = false

    //   // validation sex filter
    //   if (
    //     filters.sex.masculine &&
    //     filters.sex.feminine &&
    //     !filters.sex.masculine &&
    //     !filters.sex.feminine &&
    //     doctorItem.sex
    //   ) {
    //     sexFilter = true
    //   } else if (
    //     (doctorItem.sex === 'm' && filters.sex.masculine) ||
    //     (doctorItem.sex === 'f' && filters.sex.feminine)
    //   ) {
    //     sexFilter = true
    //   }

    //   // validation work model
    //   if (doctorItem.extraInfo && doctorItem.extraInfo.workModel) {
    //     for (let i = 0; i < doctorItem.extraInfo.workModel.length; i++) {
    //       if (
    //         (doctorItem.extraInfo.workModel[i] === 'couple' &&
    //           filters.workModel.couple) ||
    //         (doctorItem.extraInfo.workModel[i] === 'individual' &&
    //           filters.workModel.individual)
    //       ) {
    //         workModel = true
    //       }
    //     }
    //   }

    //   // validation consult duration
    //   if (doctorItem.consultBlocks) {
    //     if (parseInt(filters.consultDuration) === 0) {
    //       consultDuration = true
    //     } else if (
    //       parseInt(filters.consultDuration) === doctorItem.consultBlocks
    //     ) {
    //       consultDuration = true
    //     }
    //   }

    //   if (sexFilter && workModel && consultDuration) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  } else if (
    (specialty === 'medicine' || specialty === 'nutrition') &&
    specialty === doctorItem.specialty
  ) {
    return true
  }
  // derma
  if (!specialty && doctorItem.specialty === 'derma') {
    return true
  }
  // default
  return false
}

export const validateFavoriteBySpecialty = (specialty, doctorItem) => {
  // conditions
  if (specialty && specialty === doctorItem.specialty) {
    return true
  } else if (!specialty && doctorItem.specialty === 'derma') {
    return true
  } else {
    return false
  }
}

export const validateVideos = privateFlag => {
  // conditions
  if (privateFlag) {
    return false
  }
  // default
  return true
}
