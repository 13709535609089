import { usersF } from '../../config'
import sendSMS from './sendSMS'

const checkPhoneNumber = async phoneNumber => {
  try {
    const response = await usersF.where('phone', '==', phoneNumber).get()
    return response.docs.length === 0
  } catch (error) {
    return error
  }
}

const sendCode = async phone => {
  try {
    const respCheckNumber = await checkPhoneNumber(phone)
    if (!respCheckNumber) {
      return true
    }
    await sendSMS(phone)
  } catch (error) {
    return error
  }
}
export default sendCode
