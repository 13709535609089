import { useRef } from 'react'
import PropTypes from 'prop-types'

const VerificationCode = ({ onComplete, maxLength }) => {
  const refFirstNumber = useRef()
  const refSecondNumber = useRef()
  const refThirdNumber = useRef()
  const refFourtNumber = useRef()
  const refFifthNumber = useRef()
  const refSixthNumber = useRef()

  const handleOnChange = () => {
    if (refFirstNumber.current.value.length === 1) {
      refSecondNumber.current.focus()
    }
    if (refSecondNumber.current.value.length === 1) {
      refThirdNumber.current.focus()
    }
    if (refThirdNumber.current.value.length === 1) {
      refFourtNumber.current.focus()
    }
    if (refFourtNumber.current.value.length === 1 && maxLength === 6) {
      refFifthNumber.current.focus()
    }
    if (refFourtNumber.current.value.length === 1 && maxLength !== 6) {
      const code =
        refFirstNumber.current.value +
        refSecondNumber.current.value +
        refThirdNumber.current.value +
        refFourtNumber.current.value
      onComplete(code)
    }
    if (maxLength === 6) {
      if (refFifthNumber.current.value.length === 1) {
        refSixthNumber.current.focus()
      }
      if (refSixthNumber.current.value.length === 1) {
        const code =
          refFirstNumber.current.value +
          refSecondNumber.current.value +
          refThirdNumber.current.value +
          refFourtNumber.current.value +
          refFifthNumber.current.value +
          refSixthNumber.current.value
        onComplete(code)
      }
    }
  }
  return (
    <div className="field is-grouped ">
      <p className="control is-expanded ">
        <input
          className="input px-0 has-text-centered"
          ref={refFirstNumber}
          onChange={handleOnChange}
          maxLength={1}
        />
      </p>
      <p className="control is-expanded ">
        <input
          className="input px-0 has-text-centered"
          ref={refSecondNumber}
          onChange={handleOnChange}
          maxLength={1}
        />
      </p>
      <p className="control is-expanded ">
        <input
          className="input px-0 has-text-centered"
          ref={refThirdNumber}
          onChange={handleOnChange}
          maxLength={1}
        />
      </p>
      <p className="control is-expanded ">
        <input
          className="input px-0 has-text-centered"
          ref={refFourtNumber}
          onChange={handleOnChange}
          maxLength={1}
        />
      </p>
      {maxLength === 6 && (
        <p className="control is-expanded ">
          <input
            className="input px-0 has-text-centered"
            ref={refFifthNumber}
            onChange={handleOnChange}
            maxLength={1}
          />
        </p>
      )}
      {maxLength === 6 && (
        <p className="control is-expanded ">
          <input
            className="input px-0 has-text-centered"
            ref={refSixthNumber}
            onChange={handleOnChange}
            maxLength={1}
          />
        </p>
      )}
    </div>
  )
}

VerificationCode.propTypes = {
  onComplete: PropTypes.func,
  maxLength: PropTypes.number,
}

export default VerificationCode
