import PropTypes from 'prop-types'
import { useField, useForm } from '../../utils'
import { LabelCustom, CustomButton } from '../../components'
import { useTranslation } from 'react-i18next'

const ConfirmLinkAccount = ({ fetchMethods, credential, email }) => {
  const { t } = useTranslation()
  const [{ password }, handleInputChange] = useForm({
    password: '',
  })
  const onSubmit = async () => {
    await fetchMethods({ credential, email, password })
  }

  const passwordField = useField({
    className: 'input',
    type: 'password',
    name: 'password',
    value: password,
    onChange: handleInputChange,
    placeholder: t('password'),
  })

  return (
    <div className="box is-margin-box-log-in is-box-shadow">
      <h4 className="title is-4 has-text-dark has-text-centered">¡Atención!</h4>
      <p className="has-text-justified">
        Hemos detectado que tienes una cuenta asociada con esta cuenta de
        correo: <strong>{email}</strong>,
        <br /> <br />
        Necesitamos que vincules tu cuenta con esta nueva red social, para ello
        debes ingresar la contraseña con la que te registraste. Si no cuentas
        con ella, puedes solicitar una nueva en la pantalla anterior.
      </p>

      <div className="field">
        <LabelCustom className="are-color-text">Contraseña</LabelCustom>
        <p className="control">
          <input {...passwordField} />
        </p>
      </div>

      <div className="is-align-self-center mx-6 my-4">
        <CustomButton
          id="button-add-balance"
          isPrimary
          handleClick={onSubmit}
          disabled={!password || password.length < 6}
        >
          Vincular
        </CustomButton>
      </div>

      <div className="buttons mx-6"></div>
    </div>
  )
}

ConfirmLinkAccount.propTypes = {
  fetchMethods: PropTypes.func,
  credential: PropTypes.object,
  email: PropTypes.string,
}

export default ConfirmLinkAccount
