import PropTypes from 'prop-types'

const ProgressCustom = ({ step, step_number }) => (
  <div
    className={
      step >= step_number
        ? 'is-progress-active'
        : 'is-progress-active is-progress-inactive-color'
    }
  ></div>
)

ProgressCustom.propTypes = {
  step: PropTypes.number,
  step_number: PropTypes.number,
}

export default ProgressCustom
