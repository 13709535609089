import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { TextCustom, CustomButton, ButtonCustom } from '../../components'
import { useField } from '../../utils'

const AddBalance = ({
  next,
  setShowAddBalance,
  setAmount,
  amount,
  onAddBalance,
  isLoading,
  setVisiblePayments,
  paymentMethod,
}) => {
  const balanceField = useField({
    className: 'input',
    type: 'number',
    name: 'addBalance',
    value: amount,
    onChange: ({ target }) => {
      setAmount(target.value)
    },
    placeholder: '$',
    min: '0',
  })

  const handleOnClick = () => {
    setVisiblePayments?.(true)
    onAddBalance()
    next()
  }

  const show = () => {
    setShowAddBalance?.(false)
  }

  const isValidated = useCallback(
    () => Object.keys(paymentMethod)?.length === 0 || amount < 50,
    [amount, paymentMethod],
  )

  return (
    <div className="field">
      <hr className="line-bottom my-2"></hr>
      <TextCustom className=" has-text-weight-semibold border-bottom is-size-7 mb-4">
        Ingresa la cantidad a añadir, selecciona un método de pago y finalmente
        presiona añadir saldo. La cantidad mínima es de $50
      </TextCustom>
      <div className="control">
        <input {...balanceField} />
      </div>
      <div className="orders buttons">
        <div className="column is-3">
          <ButtonCustom
            buttonClassName="button is-text-color is-inverted px-0 level-item"
            onClick={show}
          >
            Cancelar
          </ButtonCustom>
        </div>
        <div className="column is-9">
          <CustomButton
            id="button-add-balance"
            isPrimary
            isLoading={isLoading}
            handleClick={handleOnClick}
            disabled={isValidated()}
          >
            Añadir Saldo
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

AddBalance.propTypes = {
  next: PropTypes.func,
  setShowAddBalance: PropTypes.func,
  amount: PropTypes.string,
  setAmount: PropTypes.func,
  onAddBalance: PropTypes.func,
  isLoading: PropTypes.bool,
  setVisiblePayments: PropTypes.func,
  paymentMethod: PropTypes.object,
}

export default AddBalance
