import Images from '../../assets'

const Header = () => {
  const { logoHorizontal } = Images
  return (
    <div className="hero-head p-5">
      <div>
        <figure>
          <img src={logoHorizontal} />
        </figure>
      </div>
    </div>
  )
}

export default Header
