import { firebase, usersF } from '../../config'

const getAllDoctorFavs = ({ userId }) => {
  return async dispatch => {
    try {
      const response = await usersF.doc(userId).get()
      if (response.data()?.favorities) {
        const docsFav = response.data().favorities
        const favourites = await Promise.all(
          // sin Promise.all retorna array de promise, una vez que se obtiene la data del map, y se resuelven las promesas se asigna el valor a favorites
          await docsFav.map(async doc => {
            // await retorna promise , una vez que se obtiene la data retorna la data
            const getDataDoc = await usersF.doc(doc).get() // await espera el documento
            const comments = await firebase
              .firestore()
              .collection('doctor_review')
              .where('idDoc', '==', doc)
              .get()
            return {
              reviews: comments.docs.length,
              doctor: getDataDoc.data(),
            } // retornas la data y retorna
          }),
        )
        return favourites // retorna la data
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export default getAllDoctorFavs
