// import PropTypes from 'prop-types'

// components
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TitleCustom,
  AddProducts,
  AddDiary,
  DiaryContainer,
  ProductsContainer,
  DiaryButtonsContainer,
} from '../../components/'

// hooks
import { useCounter, useImageForm } from '../../utils'
import dispatchers from './dispatchers'
import selectors from './selectors'

const MyDiaryScreen = () => {
  const dispatch = useDispatch()
  const {
    createDiary,
    uploadImage,
    updateDiary,
    createProduct,
    updateProduct,
    getProducts,
    getDiary,
  } = dispatchers(dispatch)

  const { auth } = useSelector(state => state)
  const { uid } = selectors(auth)
  const { count, increment, decrement, reset } = useCounter(1)
  const [showModal, setshowModal] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [productsList, setProductsList] = useState()
  const [diaryList, setDiaryList] = useState()
  const [productsSelected, setProductsSelected] = useState([])

  const [
    { imageLeft, imageRight, imageFront },
    handleDiaryImageInputChange,
    resetDiaryImages,
  ] = useImageForm({
    imageLeft: '',
    imageRight: '',
    imageFront: '',
  })

  const onCreateDiary = async () => {
    setIsLoading(true)
    const diaryId = await createDiary()
    if (!diaryId?.error) {
      const { id } = diaryId
      const uriLeft = await uploadImage(imageLeft.file, 'daily', id)
      const uri = await uploadImage(imageFront.file, 'daily', id)
      const uriRight = await uploadImage(imageRight.file, 'daily', id)
      if (uri.status && uriLeft.status && uriRight.status !== 'error') {
        const values = {
          products: productsSelected,
          uri: uri.fileName,
          uriLeft: uriLeft.fileName,
          uriRight: uriRight.fileName,
        }
        await updateDiary(values, id)
        setIsLoading(false)
        reset()
        resetDiaryImages()
        setProductsSelected([])
        list()
      } else {
        setIsLoading(false)
      }
    }
  }

  const getListProducts = async () => {
    setLoading(true)
    const products = await getProducts(uid)
    if (products !== []) {
      setProductsList(products)
    }
    setLoading(false)
  }

  const list = async () => {
    setLoading(true)
    const diary = await getDiary(uid)
    if (diary) {
      setDiaryList(diary)
      setshowModal(false)
    }
    setLoading(false)
  }

  const onCancel = () => {
    resetDiaryImages()
    decrement()
  }

  useEffect(() => {
    count === 3 && getListProducts()
    return () => {}
  }, [count])

  useEffect(() => {
    list()
    return () => {}
  }, [])

  return (
    <div className={`diary-container ${showModal && 'lock-modal'}`}>
      {loading && (
        <div className="waitng-amount">
          <div className="spinner" />
        </div>
      )}
      {!loading && (
        <>
          <TitleCustom className="are-color-mynd-grey has-text-centered mt-6">
            Mi diario
          </TitleCustom>

          {count === 1 && (
            <DiaryContainer
              diaryList={diaryList}
              handleOnClick={increment}
              setshowModal={setshowModal}
              showModal={showModal}
            />
          )}
          {count === 2 && (
            <AddDiary
              increment={increment}
              onHandle={handleDiaryImageInputChange}
              imageLeft={imageLeft}
              imageRight={imageRight}
              imageFront={imageFront}
            />
          )}
          {count === 3 && (
            <ProductsContainer
              productsList={productsList}
              increment={increment}
              setProductsSelected={setProductsSelected}
              productsSelected={productsSelected}
            />
          )}

          {count === 4 && (
            <AddProducts
              uid={uid}
              uploadImage={uploadImage}
              createProduct={createProduct}
              updateProduct={updateProduct}
              decrement={decrement}
              setProductsSelected={setProductsSelected}
              productsSelected={productsSelected}
            />
          )}

          {count >= 2 && count < 4 && (
            <DiaryButtonsContainer
              handleBack={count === 2 ? onCancel : decrement}
              handleNext={count === 3 ? onCreateDiary : increment}
              backLabel={count === 2 ? 'Cancelar' : 'Anterior'}
              nextLabel={count === 3 ? 'Subir diario' : 'Siguente'}
              disabled={!(imageLeft && imageRight && imageFront)}
              isLoading={count === 3 ? isLoading : false}
            />
          )}
        </>
      )}
    </div>
  )
}

MyDiaryScreen.propTypes = {}

export default MyDiaryScreen
