import PropTypes from 'prop-types'
import moment from 'moment'

const ArticleItem = ({ size, category, title, description, date, content }) => {
  const limitText = (value, limit) => {
    const regex = /(<([^>]+)>)/gi
    const result = value.replace(regex, '')
    const resultFinal =
      result.length <= limit ? result : `${result.substring(0, limit)} ...`
    return resultFinal
  }

  const setContainer = () => {
    if (size === 'large') {
      return 'article__preview__large'
    } else if (size === 'small') {
      return 'article__preview__small'
    } else {
      return 'article__preview__list'
    }
  }

  return (
    <div className={setContainer()}>
      <div
        className={
          size === 'list'
            ? 'article__preview__info__container__list'
            : 'article__preview__info__container'
        }
      >
        {category !== '' && (
          <span className="status status-tag">{category}</span>
        )}
        <p className="article__preview__title">
          {limitText(title, size === 'list' ? 50 : 25)}
        </p>
        {size !== 'small' && (
          <p className="article__preview__text">
            {limitText(description, size === 'list' ? 100 : 50)}
          </p>
        )}
        <p className="article__preview__text">
          {moment(date).format('MMMM D, YYYY').toString()}
        </p>
      </div>
      {content && (
        <img
          className={
            size === 'list'
              ? 'article__preview__image__list'
              : 'article__preview__image'
          }
          src={content}
        />
      )}
    </div>
  )
}

ArticleItem.propTypes = {
  size: PropTypes.string,
  category: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.any,
}

ArticleItem.defaultProps = {
  size: 'small',
}

export default ArticleItem
