import { usersF } from '../../config'
import { myAddressActions } from '../../actions'

const { getAddressRequest, getAddressSuccess, getAddressFailure } =
  myAddressActions.creators

const findOrCreateFieldLocation = async uid => {
  try {
    const user = await usersF.doc(uid).get()
    const { locations } = user.data()
    if (locations) return locations
    if (!locations) {
      await usersF.doc(uid).update({ locations: [] }, { merge: true })
      const user = await usersF.doc(uid).get()
      return user.data().locations
    }
  } catch (error) {
    return 'error'
  }
}

const getAddressService = uid => {
  return async dispatch => {
    try {
      dispatch(getAddressRequest())
      const locations = await findOrCreateFieldLocation(uid)
      dispatch(getAddressSuccess(locations))
    } catch (error) {
      dispatch(getAddressFailure(error))
    }
  }
}

export default getAddressService
