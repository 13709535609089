import PropTypes from 'prop-types'
import { TextCustom, QuestionnaireAnswered, ConsultImages } from '..'
import { useState } from 'react'

const sessionsQuestionarie = ({ questionnaire, images, id }) => {
  const [showPhotos, setShowPhotos] = useState(false)
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  return (
    <>
      <TextCustom className=" my-4">Fotos y Cuestionario</TextCustom>
      <div className="consult-card-questionarie">
        <span>
          Para una mejor atención adjunte las fotos donde presente la lesión o
          desea que sea revisada y responda el cuestionario.
        </span>
        <div className="consult-card-questionarie-buttons">
          {images && (
            <button
              className="button yellow-buttons"
              onClick={() => setShowPhotos(!showPhotos)}
            >
              {showPhotos ? 'Ocultar Fotos' : 'Ver Fotos'}
            </button>
          )}
          {questionnaire && (
            <button
              className="button yellow-buttons"
              onClick={() => setShowQuestionnaire(!showQuestionnaire)}
            >
              {showQuestionnaire ? 'Ocultar Respuestas' : 'Respuestas'}
            </button>
          )}
        </div>
      </div>
      {questionnaire && showQuestionnaire && (
        <div>
          <TextCustom className='className="mx-4 is-flex my-4"'>
            Respuestas del cuestionario:
          </TextCustom>
          <QuestionnaireAnswered questionnaire={questionnaire} />
        </div>
      )}
      {images && showPhotos && (
        <div>
          <TextCustom className='className="mx-4 is-flex my-4"'>
            Imagenes:
          </TextCustom>
          <ConsultImages id={id} images={images} />
        </div>
      )}
    </>
  )
}

sessionsQuestionarie.propTypes = {
  questionnaire: PropTypes.any,
  images: PropTypes.any,
  id: PropTypes.string,
}

export default sessionsQuestionarie
