import functions from '../functions'
import { uiActions } from '../../actions'

const { getBilling } = functions.cloudFunctions
const { startLoading, stopLoading, addError, addSuccess } = uiActions.creators

const resendInvoice = data => {
  return async dispatch => {
    try {
      dispatch(startLoading())
      await getBilling({
        invoiceID: data.invoiceId,
      })
      dispatch(addSuccess('Factura generada con éxito'))
    } catch (error) {
      dispatch(
        addError(
          'Ocurrió un error generando tu factura, por favor inténtalo más tarde',
        ),
      )
      return error
    } finally {
      dispatch(stopLoading())
    }
  }
}

export default resendInvoice
