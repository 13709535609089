import { consultActions } from '../../actions'

const {
  CREATE_CONSULT_REQUEST,
  CREATE_CONSULT_SUCCESS,
  CREATE_CONSULT_FAILURE,
  GET_ALL_CONSULTS_REQUEST,
  GET_ALL_CONSULTS_SUCCESS,
  GET_ALL_CONSULTS_FAILURE,
  GET_ALL_PRICE_FOR_CONSULT_REQUEST,
  GET_ALL_PRICE_FOR_CONSULT_SUCCESS,
  GET_ALL_PRICE_FOR_CONSULT_FAILURE,
  GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST,
  GET_ALL_CATEGORIES_FOR_SPECIALTY_SUCCESS,
  GET_ALL_CATEGORIES_FOR_SPECIALTY_FAILURE,
} = consultActions.types

const initialState = {
  data: [],
  currentConsult: {},
  prices: [],
  categories: [],
  error: {},
}

const consultReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONSULT_REQUEST:
    case GET_ALL_CONSULTS_REQUEST:
    case GET_ALL_PRICE_FOR_CONSULT_REQUEST:
    case GET_ALL_CATEGORIES_FOR_SPECIALTY_REQUEST:
      return {
        ...state,
      }
    case CREATE_CONSULT_SUCCESS:
      return {
        ...state,
        currentConsult: action.payload,
      }
    case GET_ALL_PRICE_FOR_CONSULT_SUCCESS:
      return {
        ...state,
        prices: action.payload,
      }
    case GET_ALL_CATEGORIES_FOR_SPECIALTY_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }
    case CREATE_CONSULT_FAILURE:
    case GET_ALL_CONSULTS_FAILURE:
    case GET_ALL_PRICE_FOR_CONSULT_FAILURE:
    case GET_ALL_CATEGORIES_FOR_SPECIALTY_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_CONSULTS_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    case 'CLEAR_CONSULTS':
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default consultReducer
