import { firebase } from '../../config'

const getDoctorReviews = () => {
  return async () => {
    const doctor = await firebase.firestore().collection('doctor_review').get()
    return doctor.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  }
}

export default getDoctorReviews
