export default (auth, orderDrugs) => {
  const { uid, isNewUser } = auth.user
  const { isLoading, allOrders } = orderDrugs
  const orders = allOrders ?? []
  return {
    uid,
    isNewUser,
    isLoading,
    orders,
  }
}
