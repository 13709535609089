import PropTypes from 'prop-types'
import { useState } from 'react'

import {
  TitleCustom,
  OnthewayContainer,
  DeliveredContainer,
  TabsShopping,
} from '..'

const myshopping = ({ orders, handleSelectOrder }) => {
  const [toggleState, setToggleState] = useState(1)

  const onTheWayOrders = orders.filter(order => order.status !== 'received')
  const deliveredOrders = orders.filter(order => order.status === 'received')
  const toggleTab = index => {
    setToggleState(index)
  }
  return (
    <>
      <div className="container mt-6">
        <TitleCustom className="has-text-centered is-size-3 are-color-mynd-grey ">
          Mis compras
        </TitleCustom>
        <div className="columns">
          <div className="column is-size-tabs-shopping ">
            <TabsShopping toggleTab={toggleTab} toggleState={toggleState} />
          </div>
        </div>
        {toggleState === 1 && (
          <OnthewayContainer
            onTheWayOrders={onTheWayOrders}
            handleSelectOrder={handleSelectOrder}
          />
        )}
        {toggleState === 2 && (
          <DeliveredContainer
            deliveredOrders={deliveredOrders}
            handleSelectOrder={handleSelectOrder}
          />
        )}
      </div>
    </>
  )
}

myshopping.propTypes = {
  orders: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getAllOrders: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  handleSelectOrder: PropTypes.func.isRequired,
}

export default myshopping
