import functions from '../functions'

const { createProducts } = functions.cloudFunctions
const createProductService = async () => {
  try {
    const response = await createProducts({})
    return response.data
  } catch (e) {
    return e
  }
}
export default createProductService
