import PropTypes from 'prop-types'
import { useState } from 'react'

// components
import { ButtonCustom, TextCustom, TitleCustom } from '..'

const Question = ({ title, info, link, linkCorreo }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <article className="question">
      <header>
        <TitleCustom
          onClick={() => setExpanded(!expanded)}
          className="is-size-6 m-0 has-text-weight-light"
        >
          {title}
        </TitleCustom>
        <ButtonCustom
          buttonClassName="btn"
          onClick={() => setExpanded(!expanded)}
          iconClassName={
            expanded
              ? 'fas fa-angle-up is-size-5'
              : 'fas fa-angle-down is-size-5'
          }
        ></ButtonCustom>
      </header>

      {expanded && (
        <div>
          <hr className="is-colour-hr mt-2 mb-4" />
          <TextCustom>
            {info}
            <a
              className="are-color-text"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
          </TextCustom>
        </div>
      )}
    </article>
  )
}

Question.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  link: PropTypes.string,
  linkCorreo: PropTypes.string,
}

export default Question
