import { useState } from 'react'

const useCounter = (initialState = 1) => {
  const [count, setCount] = useState(initialState)

  const increment = () => setCount(prevCount => prevCount + 1)

  const decrement = () => setCount(prevCount => prevCount - 1)
  const handleOnChangeCount = ({ value }) => setCount(value)
  const reset = () => {
    setCount(prevCount => (prevCount = initialState))
  }
  return { count, increment, decrement, reset, handleOnChangeCount }
}

export default useCounter
