import functions from '../functions'

const { warrantyCreate } = functions.cloudFunctions
const addWarranty = async values => {
  try {
    console.log('xd 789', values)
    const result = await warrantyCreate({
      values: values,
    })
    console.log('xd 456', result)
    return result
  } catch (error) {
    console.log(error)
  }
}

export default addWarranty
