import { firestore } from '../../config'
import { consultActions } from '../../actions'

const {
  getAllCategoriesForSpecialtyRequestAction,
  getAllCategoriesForSpecialtySuccessAction,
  getAllCategoriesForSpecialtyFailureAction,
} = consultActions.creators
const getCategoriesForSpecialtyService = specialty => {
  return async dispatch => {
    try {
      dispatch(getAllCategoriesForSpecialtyRequestAction())
      const categories = await firestore
        .collection('questionnaires')
        .where('specialty', '==', specialty)
        .get()

      const categoriesArray = categories.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      })

      dispatch(getAllCategoriesForSpecialtySuccessAction(categoriesArray))
    } catch (error) {
      dispatch(getAllCategoriesForSpecialtyFailureAction(error))
    }
  }
}

export default getCategoriesForSpecialtyService
