import PropTypes from 'prop-types'

// components
import { ImageCustom, LabelCustom, Input } from '../../../components'

// img
import Images from '../../../assets'
const StoreNameField = ({ handleInputChange }) => {
  const { storeIcon } = Images
  return (
    <div className="box  mx-3 is-flex">
      <span className="icon mr-2">
        <ImageCustom img={storeIcon} />
      </span>
      <div className="is-size-field-nane-product">
        <LabelCustom className="label are-color-mynd-grey">
          ¿Dónde lo compraste?
        </LabelCustom>
        <Input
          className="input is-select-size is-fullwidth"
          type="text"
          placeholder="Lugar donde se compró"
          name="placeOfPurchase"
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

StoreNameField.propTypes = { handleInputChange: PropTypes.func }

export default StoreNameField
