import { firebase } from '../../config/index'

const SendProfileImage = async (uid, img) => {
  try {
    const response = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .set(
        {
          photoURL: img,
        },
        { merge: true },
      )
    return response
  } catch (error) {
    console.log(error)
  }
}

export default SendProfileImage
