import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// componentes
import { TextCustom, ImageCustom, CustomButton } from '..'

import Images from '../../assets'

const MySections = ({
  treatmentList,
  currentSession,
  treatmentConfig,
  showAll,
  handleOnClickCoupon,
  isLoading,
}) => {
  const { RoundArrow, MagnifyingGlassOnDocument } = Images
  const { totalConsults } = treatmentConfig
  const [treatment, setTreatment] = useState([])
  const [progress, setProgress] = useState(0)
  const [totalSessions, setTotalSessions] = useState(12)
  const [loading, setLoading] = useState(true)

  const getProgress = () => {
    if (totalConsults !== 'pendient') setTotalSessions(totalConsults)
    const progressBar = parseInt((100 / totalSessions) * currentSession)
    setProgress(progressBar)
  }
  const getItems = () => {
    setLoading(true)
    const isCurrent = treatmentList.some(t => t.status === 'scheduled')
    if (isCurrent && treatmentList[currentSession]) {
      treatmentList[currentSession].status = 'current'
    }
    const lenght = treatmentList.lenght
    let treatments =
      lenght <= 3 ? treatmentList : treatmentList.slice(lenght - 3, lenght)

    const treatmentsLength = treatments.length
    for (let i = treatmentsLength; i < 5; i++) {
      const pendient = { status: 'pendient', session: i + 1 }
      treatments = treatments.concat(pendient)
    }
    setTreatment(treatments)
    setLoading(false)
  }

  useEffect(() => {
    getProgress()
    setTreatment()
    getItems()

    return () => {}
  }, [treatmentList, currentSession, treatmentConfig])

  return (
    <>
      <div className="box is-box-container-percentage-treatment">
        <TextCustom className="are-color-mynd-grey">
          !Ya falta poco, sigamos el tratamiento¡
        </TextCustom>
        <TextCustom className="is-title-size has-text-right is-colour-percentage-treatment has-text-weight-bold">
          {`${progress || 0}%`}
        </TextCustom>
        <progress
          className="progress is-small is-info mt-2"
          value={progress || '0'}
          max="100"
        />
        <TextCustom className="are-color-mynd-grey has-text-weight-bold ml-5">
          Mis sesiones
        </TextCustom>
        <div className="is-flex is-justify-content-space-between mt-2 mx-5">
          <TextCustom className="are-color-mynd-grey">
            Total de sesiones apróximadas:
            <span className="has-text-weight-bold"> {totalSessions}</span>
          </TextCustom>
          <a className="is-colour-text-see-more" onClick={showAll}>
            Ver todas
          </a>
        </div>
        <div className="is-flex mt-2 is-justify-content-space-between  mt-4 is-box-my-sections">
          {loading ? (
            <div className="waiting-treatment">
              <div className="spinner" />
            </div>
          ) : (
            treatment.map((item, index) => {
              const { day, session, month, status } = item
              return (
                <div key={index}>
                  <TextCustom className="is-colour-number-sections ml-3">
                    {session < 10 ? '0' + session : session}
                  </TextCustom>
                  <>
                    {status === 'close' && (
                      <div className="is-box-detail-section mt-3">
                        <TextCustom className="is-colour-text-detail-section has-text-centered">
                          {`${day} / ${month}`}
                        </TextCustom>
                        <TextCustom className="is-colour-text-detail-section has-text-centered mt-4">
                          Sesión anterior
                        </TextCustom>
                      </div>
                    )}
                    {status === 'current' && (
                      <div className="is-box-detail-section-current mt-3">
                        <ImageCustom
                          img={RoundArrow}
                          className="is-size-img-arrow-sections"
                        />
                        <TextCustom className="is-colour-text-detail-section-current has-text-centered mt-3 ">
                          En curso
                        </TextCustom>
                      </div>
                    )}
                    {status === 'scheduled' && (
                      <div className="is-box-detail-section mt-3">
                        <TextCustom className="is-colour-text-detail-section has-text-centered">
                          {`${day} / ${month}`}
                        </TextCustom>
                        <TextCustom className="is-colour-text-detail-section has-text-centered mt-4">
                          Próxima sesión
                        </TextCustom>
                      </div>
                    )}
                    {status === 'pendient' && (
                      <div className="is-box-detail-section-define mt-3">
                        <ImageCustom
                          img={MagnifyingGlassOnDocument}
                          className="is-size-img-MagnifyingGlassOnDocument"
                        />
                        <TextCustom className="is-colour-text-detail-section-next has-text-centered has-text-weight-bold mt-3">
                          Por definir
                        </TextCustom>
                      </div>
                    )}
                  </>
                  {index === currentSession ? (
                    <div className="button-cuppon-container">
                      <CustomButton
                        isPrimary
                        isLoading={isLoading}
                        handleClick={handleOnClickCoupon}
                      >
                        Cupón
                      </CustomButton>
                    </div>
                  ) : (
                    <div className="is-size-box-medicine-section"></div>
                  )}
                </div>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

MySections.propTypes = {
  treatmentList: PropTypes.array,
  currentSession: PropTypes.number,
  treatmentConfig: PropTypes.object,
  showAll: PropTypes.func,
  handleOnClickCoupon: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default MySections
