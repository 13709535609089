import { uiActions } from '../../actions'
import { removeUserCode, updateUserProfile } from '../../services'
const { addError, removeError } = uiActions.creators

export default dispatch => {
  return {
    removeUserCode: uid => dispatch(removeUserCode(uid)),
    addError: error => dispatch(addError(error)),
    removeError: () => dispatch(removeError()),
    updateUserProfile: (data, uid) => dispatch(updateUserProfile(data, uid)),
  }
}
