import { usersF } from '../../config'
import { authActions, uiActions } from '../../actions'
import { updatePhoneAuth } from '../../services'

const {
  updateUserRequestAction,
  updateUserSuccessAction,
  updateUserFailureAction,
  signUpRequestAction,
  signUpSuccessAction,
  signUpFailureAction,
} = authActions.creators
const { startLoading, stopLoading, addError } = uiActions.creators

const updateUser = (data, uid) => {
  return async dispatch => {
    try {
      dispatch(startLoading())
      dispatch(updateUserRequestAction())
      dispatch(signUpRequestAction())
      const values = {
        location: data.location,
        email: data.email,
        phone: data.phone,
        fullname: data.name,
        birthday: new Date(data.birthday + 'T00:00:00'),
        isNewUser: false,
      }
      await usersF.doc(uid).set(values, { merge: true })
      await updatePhoneAuth(data.phone, uid)
      const user = await usersF.doc(uid).get()
      const userData = user.data()
      userData.cards = userData.cards || []
      dispatch(updateUserSuccessAction(userData))
      dispatch(signUpSuccessAction())
      dispatch(stopLoading())
    } catch (error) {
      dispatch(stopLoading())
      dispatch(updateUserFailureAction(error))
      dispatch(signUpFailureAction())
      dispatch(addError(error))
      return error
    }
  }
}

export default updateUser
