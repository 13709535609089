// import PropTypes from 'prop-types'
import { TextCustom, TitleCustom } from '..'

const wiithoutFavorites = () => {
  return (
    <>
      <TitleCustom className="are-color-mynd-grey has-text-centered is-size-title- mt-6">
        Mis Favoritos
      </TitleCustom>
      <TextCustom className="are-color-mynd-grey has-text-centered  mt-5">
        Agrega aquí los doctores que te gustaron para agendar despues.
      </TextCustom>
    </>
  )
}

wiithoutFavorites.propTypes = {}

export default wiithoutFavorites
