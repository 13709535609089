import functions from '../functions'

const { usuarioAddCoupon } = functions.cloudFunctions
const addCouponsService = code => {
  return async dispatch => {
    try {
      const response = await usuarioAddCoupon({
        code: code.toUpperCase(),
      })
      return response.data
    } catch (error) {
      return { error }
    }
  }
}

export default addCouponsService
