import PropTypes from 'prop-types'
import Images from '../../assets'

import { ImageCustom } from '../../components'
const { mastercard, americanExpress, visaIcon } = Images
const CardsList = ({ cards, onClick, isReceiptOrConsult, paymentMethod }) => {
  const onChange = (target, funding, customer) => {
    const card = {
      method: 'card',
      value: `Tarjeta terminación: ${target.value}`,
      id: target.id,
      last4: target.value,
      funding: funding,
      customer: customer,
    }
    onClick(card)
  }
  const cardImage = image => {
    switch (image) {
      case 'Visa':
        return visaIcon
      case 'MasterCard':
        return mastercard
      case 'American Express':
        return americanExpress
      default:
        return visaIcon
    }
  }
  return (
    <div>
      {cards.map((card, id) => {
        return (
          <div key={id}>
            <hr className="line-bottom my-2" />
            <div className="level  mb-3 mt-3 is-mobile">
              <div className="level-left">
                <ImageCustom
                  img={cardImage(card.brand)}
                  className="card-images"
                />
                {'  '}
                <span>{`${card.brand} XXXX XXXX XXXX ${card.last4}`}</span>
              </div>
              <div className="level-right-cards">
                <input
                  type="radio"
                  id={isReceiptOrConsult ? card.id : card.customer}
                  className="is-radio-input mr-3"
                  name="card"
                  value={card.last4}
                  checked={paymentMethod?.id === card.id}
                  onChange={({ target }) =>
                    onChange(target, card.funding, card.customer)
                  }
                />
                <label
                  htmlFor={isReceiptOrConsult ? card.id : card.customer}
                ></label>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

CardsList.propTypes = {
  isReceiptOrConsult: PropTypes.bool,
  cards: PropTypes.array,
  onClick: PropTypes.func,
  paymentMethod: PropTypes.object,
}
export default CardsList
