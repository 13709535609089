// import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  TitleCustom,
  TabsCoupon,
  AddCoupons,
  CouponsUsedContainer,
} from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import dispatchers from './dispatchers'
import selectors from './selectors.js'

const CouponScreen = props => {
  const dispatch = useDispatch()
  const { addCoupons, getCoupons, addError, removeError, addSuccess } =
    dispatchers(dispatch)
  const { auth, coupons, ui } = useSelector(state => state)
  const { couponList, uid, errors } = selectors(auth, coupons, ui)
  const [toggleState, setToggleState] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const toggleTab = index => {
    setToggleState(index)
  }

  useEffect(() => {
    return () => {
      removeError()
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      getCoupons(uid)
      setIsLoading(false)
    }
  }, [isLoading, couponList])

  return (
    <div className="container mt-6 mb-6">
      <TitleCustom className="has-text-centered is-size-3 are-color-mynd-grey">
        Cuponera
      </TitleCustom>
      <div className="columns is-centered">
        <div className="column is-size-box-cuponera">
          <TabsCoupon toggleTab={toggleTab} toggleState={toggleState} />
          {errors && <div className="notification is-danger">{errors}</div>}
          {toggleState === 1 && (
            <AddCoupons
              addError={addError}
              removeError={removeError}
              coupons={couponList}
              addCoupons={addCoupons}
              getCoupons={getCoupons}
              uid={uid}
              addSuccess={addSuccess}
              setLoading={setIsLoading}
            />
          )}
          {toggleState === 2 && <CouponsUsedContainer coupons={couponList} />}
        </div>
      </div>
    </div>
  )
}

CouponScreen.propTypes = {}

export default CouponScreen
