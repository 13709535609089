import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import moment from 'moment'

const ActiveMonth = ({
  activeDate,
  setChangeCurrentMonth,
  setChangeCurrentYear,
  setActiveDate,
}) => {
  const today = moment()
  const [activeMonth, setActiveMonth] = useState('01')
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  const years = [
    today.format('YYYY'),
    today.add(1, 'year').format('YYYY'),
    today.add(1, 'year').format('YYYY'),
  ]

  useEffect(() => {
    setActiveMonth(moment(activeDate).format('MM'))
  }, [activeDate])

  const onChangeMonth = ({ target }) => {
    setChangeCurrentMonth(target.value)
    const currentDay = new Date(activeDate.getFullYear(), target.value, 1)
    setActiveDate(currentDay)
  }

  const onChangeYear = ({ target }) => {
    setChangeCurrentYear(target.value)
    const currentDay = new Date(target.value, activeDate.getMonth(), 1)
    setActiveDate(currentDay)
  }

  return (
    <>
      <select
        className="select input__select mr-2"
        value={parseInt(activeMonth) - 1}
        onChange={onChangeMonth}
      >
        {months.map((month, index) => (
          <option key={month} value={parseInt(index)}>
            {month}
          </option>
        ))}
      </select>

      <select
        className="select input__select "
        value={activeDate.getFullYear()}
        onChange={onChangeYear}
      >
        {years.map((year, index) => (
          <option key={index} value={year}>
            {year}
          </option>
        ))}
      </select>
    </>
  )
}

ActiveMonth.propTypes = {
  activeDate: PropTypes.instanceOf(Date),
  setActiveDate: PropTypes.func,
  setChangeCurrentMonth: PropTypes.func,
  setChangeCurrentYear: PropTypes.func,
}

export default ActiveMonth
