import { functions } from '../../config'

const generatePasswordResetLink = functions.httpsCallable(
  'generatePasswordResetLink',
)
const sendEmailPasswordReset = functions.httpsCallable('sendEmailPasswordReset')
const createInitialConsult = functions.httpsCallable('createInitialConsult')
const getBlockedDays = functions.httpsCallable('getNotAvailableDays')
const getBlockedDaysPrivate = functions.httpsCallable(
  'getNotAvailableDaysPrivate',
)
const getAvailableHours = functions.httpsCallable('getAvailableHours')
const getAvailableHoursPrivate = functions.httpsCallable(
  'getAvailableHoursPrivate',
)
const addCard = functions.httpsCallable('addCard')
const oxxoPayment = functions.httpsCallable('generateOXXOPayment')
const speiPayment = functions.httpsCallable('generateSpeiPayment')
const paynetPayment = functions.httpsCallable('generateBarCodeOpenPay')
const getTokenOpenPay = functions.httpsCallable('openPayToken')
const addBalance = functions.httpsCallable('addBalance')
const scheduleFastConsult = functions.httpsCallable('scheduleFastConsult')
const schedulePractiService = functions.httpsCallable('schedulePractiConsult')
const rescheduleConsult = functions.httpsCallable('rescheduleConsultWeb')
const setImages = functions.httpsCallable('setImages')
const getAllSessions = functions.httpsCallable('getAllSessions')
const buyDrugList = functions.httpsCallable('buyDrugList')
const getPrice = functions.httpsCallable('getPrice')
const usuarioAddCoupon = functions.httpsCallable('usuarioAddCoupon')
const generateAsynchronousPayment = functions.httpsCallable(
  'generateAsynchronousPayment',
)
const generateAsyncBuyDrugList = functions.httpsCallable(
  'generateAsyncBuyDrugList',
)
const generateAsynchronousPaymentReceipt = functions.httpsCallable(
  'generateAsynchronousPaymentReceipt',
)
const getAllOrders = functions.httpsCallable('getAllOrders')
const createDiary = functions.httpsCallable('createDiary')
const diaryUpdate = functions.httpsCallable('diaryUpdate')
const createProducts = functions.httpsCallable('createProducts')
const updateProduct = functions.httpsCallable('updateProduct')
const getTreatmentRoute = functions.httpsCallable('getTreatmentRoute')
const getUserTreatment = functions.httpsCallable('getUserTreatment')
const getLastConsult = functions.httpsCallable('getLastConsult')
const generateRouteCoupon = functions.httpsCallable('generateRouteCoupon')
const billingCreate = functions.httpsCallable('billingCreate')
const addToCollection = functions.httpsCallable('addToCollection')
const getBilling = functions.httpsCallable('getBilling')
const getAvailableDoctors = functions.httpsCallable('getAvailableDoctors')
const getMyPrivateDoctor = functions.httpsCallable('getMyPrivateDoctor')
const warrantyCreate = functions.httpsCallable('warrantyCreate')
const getWarranties = functions.httpsCallable('getWarranties')
const cancelComment = functions.httpsCallable('cancelComment')
const consultUpdate = functions.httpsCallable('consultUpdate')
const handleOrderPaypalBalanceRechargeFunction = functions.httpsCallable(
  'handleOrderPaypalBalanceRecharge',
)
const createOrderPaypalBalanceRechargeFunction = functions.httpsCallable(
  'createOrderPaypalBalanceRecharge',
)

export default {
  cloudFunctions: {
    getAllOrders,
    billingCreate,
    addToCollection,
    getBilling,
    getAvailableDoctors,
    getMyPrivateDoctor,
    generateAsynchronousPayment,
    sendEmailPasswordReset,
    generatePasswordResetLink,
    createInitialConsult,
    getAvailableHours,
    getAvailableHoursPrivate,
    getBlockedDays,
    getBlockedDaysPrivate,
    addCard,
    oxxoPayment,
    speiPayment,
    paynetPayment,
    getTokenOpenPay,
    addBalance,
    scheduleFastConsult,
    schedulePractiService,
    rescheduleConsult,
    setImages,
    getAllSessions,
    buyDrugList,
    getPrice,
    usuarioAddCoupon,
    generateAsyncBuyDrugList,
    generateAsynchronousPaymentReceipt,
    createDiary,
    diaryUpdate,
    createProducts,
    updateProduct,
    getTreatmentRoute,
    getUserTreatment,
    getLastConsult,
    generateRouteCoupon,
    warrantyCreate,
    getWarranties,
    cancelComment,
    consultUpdate,
    createOrderPaypalBalanceRechargeFunction,
    handleOrderPaypalBalanceRechargeFunction,
  },
}
