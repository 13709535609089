import { blogActions } from '../../actions'
const { removeArticleAction } = blogActions.creators

const removeArticle = ({ savedArticles, categoryOfInterest, blogId }) => {
  return async dispatch => {
    let tempSavedArticles = [...savedArticles]
    let tempCategoryOfInterest = [...categoryOfInterest]

    if (tempSavedArticles.length === 1) {
      tempSavedArticles = []
      tempCategoryOfInterest = []
    } else {
      tempSavedArticles.splice(tempSavedArticles.indexOf(blogId), 1)
    }

    dispatch(
      removeArticleAction({
        savedArticles: tempSavedArticles,
        categoryOfInterest: tempCategoryOfInterest,
      }),
    )
  }
}

export default removeArticle
