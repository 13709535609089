import { useState } from 'react'
const useImageForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const handleFileInputChange = (name, blob) => {
    setValues({ ...values, [name]: blob })
  }

  const reset = () => {
    setValues(initialState)
  }

  return [
    values,
    handleFileInputChange, // 3
    reset, // 4
  ]
}

export default useImageForm
