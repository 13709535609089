import PropTypes from 'prop-types'
import moment from 'moment'
import {
  TextCustom,
  TitleCustom,
  ContainerProducts,
  ShippingProcess,
  AddressData,
} from '..'

const PurchaseDetail = ({ selectedOrder, handleGoBack }) => {
  const {
    requestedItems,
    send_address,
    phone,
    automatic_shipping_data,
    order_date,
  } = selectedOrder
  const orderDate = order_date
    ? moment(moment(new Date(order_date._seconds * 1000)).toDate()).format(
        'DD/MM/YY',
      )
    : ''
  console.log(`automatic_shipping_data`, automatic_shipping_data)
  return (
    <div className="is-size-box">
      <TitleCustom className="are-color-mynd-grey has-text-centered is-size-title-">
        Detalle de pedido
      </TitleCustom>
      <button className="button ml-2" onClick={handleGoBack}>
        Atras
      </button>
      <TextCustom className="is-size-5 ml-2">Detalle de compra</TextCustom>
      <ContainerProducts requestedItems={requestedItems} />
      <TextCustom className="are-color-mynd-grey ml-5 mt-3">
        Entrega entre:
        <span className="has-text-weight-semibold">
          {automatic_shipping_data && automatic_shipping_data.estimatedDelivery}
        </span>
      </TextCustom>
      <ShippingProcess
        automatic_shipping_data={automatic_shipping_data}
        orderDate={orderDate}
      />
      <AddressData send_address={send_address} phone={phone} />
    </div>
  )
}

PurchaseDetail.propTypes = {
  selectedOrder: PropTypes.instanceOf(Object).isRequired,
  handleGoBack: PropTypes.func,
}

export default PurchaseDetail
