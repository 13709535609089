const ADD_NEW_ADDRESS_REQUEST = 'ADD_NEW_ADDRESS'
const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS'
const ADD_NEW_ADDRESS_FAILURE = 'ADD_NEW_ADDRESS_FAILURE'

const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST'
const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS'
const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE'

const SET_ADDRESS_REQUEST = 'SET_ADDRESS_REQUEST'
const SET_ADDRESS_SUCCESS = 'SET_ADDRESS_SUCCESS'
const SET_ADDRESS_FAILURE = 'SET_ADDRESS_FAILURE'

const CLEAR_ADDRESS = 'CLEAR_ADDRESS'

const getAddressRequest = () => ({
  type: GET_ADDRESS_REQUEST,
})

const getAddressSuccess = (payload = {}) => ({
  type: GET_ADDRESS_SUCCESS,
  payload,
})

const getAddressFailure = (payload = {}) => ({
  type: GET_ADDRESS_FAILURE,
  payload,
})

const addNewAddressRequest = () => ({
  type: ADD_NEW_ADDRESS_REQUEST,
})

const addNewAddressSuccess = (payload = {}) => ({
  type: ADD_NEW_ADDRESS_SUCCESS,
  payload,
})

const addNewAddressFailure = (payload = {}) => ({
  type: ADD_NEW_ADDRESS_FAILURE,
  payload,
})

const setAddressRequest = () => ({
  type: SET_ADDRESS_REQUEST,
})

const setAddressSuccess = (payload = {}) => ({
  type: SET_ADDRESS_SUCCESS,
  payload,
})

const setAddressFailure = (payload = {}) => ({
  type: SET_ADDRESS_FAILURE,
  payload,
})

const clearAddressAction = () => ({
  type: CLEAR_ADDRESS,
})

export default {
  types: {
    ADD_NEW_ADDRESS_REQUEST,
    ADD_NEW_ADDRESS_SUCCESS,
    ADD_NEW_ADDRESS_FAILURE,
    GET_ADDRESS_REQUEST,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAILURE,
    SET_ADDRESS_REQUEST,
    SET_ADDRESS_SUCCESS,
    SET_ADDRESS_FAILURE,
    CLEAR_ADDRESS,
  },
  creators: {
    addNewAddressRequest,
    addNewAddressSuccess,
    addNewAddressFailure,
    getAddressRequest,
    getAddressSuccess,
    getAddressFailure,
    setAddressRequest,
    setAddressSuccess,
    setAddressFailure,
    clearAddressAction,
  },
}
