import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { validateDoctorCode } from '../../utils'
import dispatchers from './dispatchers'
import selectors from './selectors.js'

// components
import {
  ButtonCustom,
  TextCustom,
  TitleCustom,
  Input,
  ModalCodeConfirmation,
} from '..'

const PrivateCodeInput = ({ alwaysOpen }) => {
  const dispatch = useDispatch()
  const { setUserCode } = dispatchers(dispatch)
  const { auth } = useSelector(state => state)
  const { uid } = selectors(auth)

  const [expanded, setExpanded] = useState(false)
  const [code, setCode] = useState('')
  const [modalState, setModalState] = useState(false)
  const [codeSearchResult, setCodeSearchResult] = useState({})

  useEffect(() => {
    if (alwaysOpen) {
      setExpanded(true)
    }
  }, [])

  const searchCode = async () => {
    const result = await validateDoctorCode(code)
    setCodeSearchResult(result)
    setModalState(true)
  }

  const toggleModal = () => {
    setModalState(false)
  }

  const savePrivateCode = () => {
    toggleModal()
    setUserCode(codeSearchResult.id, uid)
  }

  return (
    <article className="question doctor-code-article column is-full-desktop">
      <header>
        <TitleCustom
          onClick={() => setExpanded(!expanded)}
          className="is-size-6 m-0 are-color-mynd-grey"
        >
          Ingresa código del particular
        </TitleCustom>
        {!alwaysOpen && (
          <ButtonCustom
            buttonClassName="btn xd"
            onClick={() => setExpanded(!expanded)}
            iconClassName={
              expanded
                ? 'fas fa-angle-up is-size-5'
                : 'fas fa-angle-down is-size-5'
            }
          >
            {}
          </ButtonCustom>
        )}
      </header>

      {expanded && (
        <div className="lol">
          <hr className="is-colour-hr mt-2 mb-4" />
          <TextCustom>
            Si tienes el Código/ID de un profesional ingrésalo para acceder a su
            perfil
          </TextCustom>
          <div className="field ">
            <Input
              className="input is-select-size"
              type="text"
              placeholder="Código particular"
              value={code}
              onChange={({ target }) => setCode(target.value)}
            />
            <ButtonCustom
              buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-address mt-2"
              onClick={() => searchCode()}
            >
              Guardar
            </ButtonCustom>
          </div>
        </div>
      )}
      {modalState && (
        <ModalCodeConfirmation
          data={codeSearchResult}
          closeModal={toggleModal}
          onPress={savePrivateCode}
        />
      )}
    </article>
  )
}

PrivateCodeInput.propTypes = {
  alwaysOpen: PropTypes.bool,
}

PrivateCodeInput.defaultProps = {
  alwaysOpen: false,
}

export default PrivateCodeInput
