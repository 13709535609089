import { sessionActions } from '../../actions'

const initialSatate = {
  expiry: '',
  error: { message: '' },
}

const {
  REMEMBER_ME,
  REMEMBER_ME_SUCCESS,
  REMEMBER_ME_FAILURE,
  CLEAR_REMEMBER_ME,
} = sessionActions.types

const sessionReducer = (state = initialSatate, action) => {
  switch (action.type) {
    case REMEMBER_ME:
      return {
        ...state,
      }
    case REMEMBER_ME_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case REMEMBER_ME_SUCCESS: {
      return {
        ...state,
        expiry: action.payload.expiry,
        error: { message: '' },
      }
    }
    case CLEAR_REMEMBER_ME:
      return {
        ...initialSatate,
      }
    default:
      return state
  }
}

export default sessionReducer
