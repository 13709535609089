import PropTypes from 'prop-types'

const CustomButton = ({ children, isPrimary, isLoading, handleClick, isSubmit, ...otherProps}) => {
    return (
        <button className={`button is-fullwidth is-medium ${isPrimary && 'has-text-white is-mnd-primary'} ${isLoading && 'is-loading'}`} onClick={handleClick} type={isSubmit ? 'submit' : 'button'} {...otherProps}>
            {children}
        </button>
    )
}

CustomButton.propTypes = {
    children: PropTypes.node.isRequired,
    isPrimary: PropTypes.bool,
    isLoading: PropTypes.bool,
    handleClick: PropTypes.func,
    isSubmit: PropTypes.bool
}

export default CustomButton

