import React, { useState } from 'react'

import {
  TextCustom,
  TitleCustom,
  LabelCustom,
  Input,
  ButtonCustom,
  InputSelectCustom,
} from '..'
import { cfdi } from '../../utils/constants'
import ModalFail from './components/modalFail'
import validator from 'validator/es'

const billingForm = props => {
  const cfdiList = Object.values(cfdi)
  const [name, setName] = useState(props.legal_name)
  const [rfc, setRfc] = useState(props.rfc)
  const [address, setAddress] = useState(props.street)
  const [externalNumber, setExternalNumber] = useState(props.ext)
  const [internalNumber, setInternalNumber] = useState(props.int)
  const [colony, setColony] = useState(props.neighborhood)
  const [zipCode, setZipCode] = useState(props.zip)
  const [email, setEmail] = useState(props.email)
  const [phone, setPhone] = useState(props.phone)
  const [cfdiType, setCfdiType] = useState(props.use)
  const [showModal, setShowModal] = useState(false)

  const handleSendBillingData = () => {
    try {
      validateData()
      props.handleEditBillingInformation({
        name,
        rfc,
        address,
        externalNumber,
        internalNumber,
        colony,
        zipCode,
        email,
        phone,
        cfdiType,
      })
    } catch (error) {
      setShowModal(true)
    }
  }

  const validateData = () => {
    if (name.trim() === '') {
      throw new Error('El nombre o razón social es requerido')
    }
    if (rfc.trim() === '') {
      throw new Error('El RFC es requerido')
    }
    if (rfc.trim().length !== 13) {
      throw new Error('Ingresa un RFC válido por favor')
    }
    if (email.trim() === '') {
      throw new Error('El correo electrónico es requerido')
    }
    if (validator.isEmail(email) === false) {
      throw new Error('Ingresa un córreo electrónico válido')
    }
    if (address.trim() === '') {
      throw new Error('La dirección es requerida')
    }
    if (externalNumber.trim() === '') {
      throw new Error('El número exterior es requerido')
    }
    if (colony.trim() === '') {
      throw new Error('La colonia es requerida')
    }
    if (zipCode.trim() === '') {
      throw new Error('El código postal es requerido')
    }
    if (validator.isPostalCode(zipCode, 'MX') === false) {
      throw new Error('Ingresa un código postal válido por favor')
    }
    if (phone === '') {
      throw new Error('El teléfono es requerido')
    }
    if (validator.isMobilePhone(phone) === false) {
      throw new Error('Ingresa un número de teléfono válido')
    }
    if (cfdiType === '') {
      throw new Error('El uso del CFDI es requerido')
    }
  }

  return (
    <div className="columns is-centered">
      <div className="column is-size-box-billing">
        <div className="mb-5">
          <TitleCustom className="are-color-mynd-grey has-text-centered is-size-title-">
            Facturación
          </TitleCustom>
          <TextCustom>Llene los datos correspondientes:</TextCustom>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Nombre o razón social
              <span className="are-color-mynd-grey has-text-weight-light">
                (Completo y sin faltas ortográficas)
              </span>
            </LabelCustom>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="Nombre o razón social"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              RFC
            </LabelCustom>
            <Input
              value={rfc}
              onChange={e => setRfc(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="RFC"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Correo para facturación
            </LabelCustom>
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="input is-medium is-size-text-input"
              type="email"
              placeholder="Correo para facturación"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Dirección
            </LabelCustom>
            <Input
              value={address}
              onChange={e => setAddress(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="Dirección"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Número exterior
            </LabelCustom>
            <Input
              value={externalNumber}
              onChange={e => setExternalNumber(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="Número exterior"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Número interior
            </LabelCustom>
            <Input
              value={internalNumber}
              onChange={e => setInternalNumber(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="Número interior"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Colonia
            </LabelCustom>
            <Input
              value={colony}
              onChange={e => setColony(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="Colonia"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Código postal
            </LabelCustom>
            <Input
              value={zipCode}
              onChange={e => setZipCode(e.target.value)}
              className="input is-medium is-size-text-input"
              type="number"
              placeholder="Código postal"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Teléfono
            </LabelCustom>
            <Input
              value={phone}
              onChange={e => setPhone(e.target.value)}
              className="input is-medium is-size-text-input"
              type="text"
              placeholder="Teléfono"
            />
          </div>

          <div className="field mt-5">
            <LabelCustom className="label are-color-mynd-grey has-text-weight-bold">
              Concepto de facturación o uso CFDI:
            </LabelCustom>
            <div className="select is-fullwidth">
              <InputSelectCustom
                value={cfdiType}
                handleInputChange={e => setCfdiType(e.target.value)}
                className="are-color-text"
                name="country"
              >
                {cfdiList}
              </InputSelectCustom>
            </div>
          </div>
          <ButtonCustom
            handleOnClick={handleSendBillingData}
            buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary is-button-form"
          >
            Guardar cambios
          </ButtonCustom>
        </div>
      </div>
      {showModal && <ModalFail closeModal={() => setShowModal(false)} />}
    </div>
  )
}

billingForm.propTypes = {}

export default billingForm
