import PropTypes from 'prop-types'

const LabelCustom = ({ children, ...otherProps }) => (
  <label className="label" {...otherProps}>
    {children}
  </label>
)

LabelCustom.propTypes = {
  children: PropTypes.any,
}

export default LabelCustom
