import PropTypes from 'prop-types'

const ButtonCustomWithBorder = ({
  buttonClassName,
  handleOnClick,
  icon,
  iconClassName,
  label,
  img,
  imgClassName,
  labelClassName,
  ...otherProps
}) => (
  <button className={buttonClassName} onClick={handleOnClick}>
    {img ? (
      <div className="wh100">
        <img src={img} className={imgClassName} />
      </div>
    ) : (
      <>
        <img src={icon} className={iconClassName} />
        <p className={`are-color-mynd-grey ${labelClassName}`}>{label}</p>
      </>
    )}
  </button>
)

ButtonCustomWithBorder.propTypes = {
  buttonClassName: PropTypes.string.isRequired,
  handleOnClick: PropTypes.any,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  img: PropTypes.string,
  imgClassName: PropTypes.string,
  labelClassName: PropTypes.string,
}

export default ButtonCustomWithBorder
