import { useEffect, useState } from 'react'
import {
  LateralBarConsults,
  SessionsContainer,
  TextCustom,
} from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import dispatchers from './dispatchers'
import selectors from './selectors.js'
import { useOnSnapshotConsults, useRouter } from '../../utils'

const ConsultsScreen = () => {
  const { history } = useRouter()
  const dispatch = useDispatch()
  const {
    getDrugs,
    addCurrentReceipt,
    generateInvoice,
    resendInvoice,
    getCancelReasons,
    cancelSessionService,
    removeError,
  } = dispatchers(dispatch)
  const { auth, doctors, address, ui } = useSelector(state => state)
  const { user, loading, errors, specialty } = selectors(
    auth,
    doctors,
    address,
    ui,
  )
  const [lastSessions, isLoading] = useOnSnapshotConsults()
  const [statusList, setStatusList] = useState('scheduled')
  const [modalState, setModalState] = useState()
  const [sessions, setSessions] = useState()
  const [page, setPage] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [enableShowMessage, setEnableShowMessage] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const specialtyText = {
    derma: 'dermatólogos',
    psychology: 'psicólogos',
    nutrition: 'nutriólogos',
    medicine: 'médicos',
  }

  useEffect(() => {
    return () => {
      removeError()
      setEnableShowMessage(true)
    }
  }, [])

  useEffect(() => {
    if (errors && errorMessage === '' && enableShowMessage) {
      setErrorMessage(errors)
      removeError()
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }, [errors, enableShowMessage])

  const toggleModal = () => {
    setModalState(false)
  }

  const handleOnClickStatusList = value => {
    setStatusList(value)
  }

  const handleOnClickPage = () => {
    setPage(page + 1)
  }

  const handleOnCLickBuyDrugs = (recipe, consultId, recipe_date) => {
    addCurrentReceipt(recipe, consultId, recipe_date)
    history.push('/user/order-drugs/')
  }

  const handleOnClickSendInvoice = invoiceId => {
    resendInvoice({ invoiceId })
  }

  const handleOnClickInvoice = consultId => {
    generateInvoice({ consultId, billing: user.data.billing })
  }
  useEffect(() => {
    setPage(0)
  }, [statusList])

  useEffect(() => {
    if (lastSessions && lastSessions.empty !== true && statusList) {
      const length = lastSessions[statusList].length
      const tempSessions = lastSessions[statusList]
      if (length > 0) {
        const start = page * 10
        const end = length >= start + 10 ? start + 10 : length
        const tenSessions = tempSessions.slice(0, end)
        setShowMore(length > end)
        setSessions(tenSessions)
      } else {
        setSessions(tempSessions)
      }
    }
  }, [lastSessions, page, statusList])

  return (
    <div className="container my-6">
      <div className="mx-4 mb-4">
        {errorMessage && (
          <div className="notification is-danger">{errorMessage}</div>
        )}
      </div>
      <div className="columns">
        {isLoading && (
          <div className="waitng-amount">
            <div className="spinner" />
          </div>
        )}
        {!isLoading && !sessions && (
          <div className="is-size-text-none-consult">
            <TextCustom className="has-text-centered">
              Sin consultas registradas, te invitamos a agendar una cita con
              cualquiera de nuestros {specialtyText[specialty || 'derma']}
            </TextCustom>
          </div>
        )}
        {sessions && !isLoading && (
          <>
            <div className="column consult-lateral-space mt-5 mx-5">
              <LateralBarConsults
                handleOnClickStatusList={handleOnClickStatusList}
                statusList={statusList}
              />
            </div>
            <div className="column row mx-3">
              <SessionsContainer
                loading={loading}
                consultList={sessions}
                getDrugs={getDrugs}
                handleOnClickInvoice={handleOnClickInvoice}
                handleOnCLickBuyDrugs={handleOnCLickBuyDrugs}
                handleOnClickPage={handleOnClickPage}
                handleOnClickSendInvoice={handleOnClickSendInvoice}
                toggleModal={toggleModal}
                modalState={modalState}
                setModalState={setModalState}
                showMore={showMore}
                getCancelReasons={getCancelReasons}
                uid={user.uid}
                cancelSessionService={cancelSessionService}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

ConsultsScreen.propTypes = {}

export default ConsultsScreen
