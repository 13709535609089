import PropTypes from 'prop-types'
import { Button, Input, TextCustom, ImageCustom, CustomButton } from '..'
import Images from '../../assets'
import { useForm } from '../../utils'
import { useState } from 'react'
const addTicket = ({ closeModal, addWarranty, uploadImageTicket, uid }) => {
  const [image, setImage] = useState('')
  const [fileImg, setFile] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { imgIcon, closeIcon } = Images

  const [{ establishment }, handleInputChange] = useForm({
    establishment: '',
  })

  const onPress = async () => {
    try {
      if (!establishment.trim()) {
        setError('Ingrese el nombre del  establecimiento')
        return
      }
      if (image === '') {
        setError('Ingrese una imagen de su galeria')
        return
      }
      setIsLoading(true)
      setError(null)
      const file = await uploadImageTicket(fileImg, 'warranty', uid)
      const imageUrl = await file.getDownloadURL()
      const result = await addWarranty({
        property: establishment,
        uri: imageUrl,
      })
      setIsLoading(false)
      closeModal()
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const onSelect = ({ target }) => {
    const imageFile = target.files[0]
    const imageURL = window.URL.createObjectURL(imageFile)
    setFile(imageFile)
    setImage(imageURL)
  }

  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card is-modal-border-radius">
        <section className="modal-card-body">
          <div className="level">
            <div className="level-left">
              <TextCustom className="are-color-mynd-grey has-text-weight-bold is-size-5">
                Subir Ticket
              </TextCustom>
            </div>
            <div className="level-right">
              <Button
                img={closeIcon}
                className="button is-inverted is-border-button-modal"
                imgClassName="are-color-mynd-grey image  is-24x24"
                onClick={closeModal}
              />
            </div>
          </div>
          {error && <div className="notification is-danger">{error}</div>}
          <div className="field">
            <div className="control">
              <Input
                className="input is-border-input-establishment input is-medium"
                type="text"
                name="establishment"
                value={establishment}
                onChange={handleInputChange}
                placeholder="Nombre del establecimiento"
                maxLength="100"
              />
            </div>
          </div>
          {image !== null && (
            <ImageCustom img={image} className="is-size-img-ticket" />
          )}
          <label className="file-label my-5 ">
            <Input
              className="file-input"
              type="file"
              accept="image/png, image/jpg"
              name="fileurl"
              onChange={onSelect}
            />
            <span className="file-cta is-size-input-add-pictur-warranty">
              <span className="file-icon">
                <ImageCustom
                  img={imgIcon}
                  className="is-size-icon-modal-diary"
                />
              </span>
              <span className="file-label are-color-mynd-grey ml-3">
                Escoger de mi dispositivo
              </span>
            </span>
          </label>
          <CustomButton isPrimary handleClick={onPress} isLoading={isLoading}>
            Guardar
          </CustomButton>
        </section>
      </div>
    </div>
  )
}

addTicket.propTypes = {
  closeModal: PropTypes.func,
  addTicket: PropTypes.func,
  uploadImageTicket: PropTypes.func,
  errors: PropTypes.bool,
}

export default addTicket
