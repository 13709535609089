import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonCustom, BuyControls, DrugCard, DrugAditionalInfo } from '..'
import {
  formatTimePromotions,
  getPromotion,
  useCounter,
  useToggle,
} from '../../utils'
import Images from '../../assets'

const recipeContainer = ({
  drug,
  getDrugs,
  isBuying,
  handleOrder,
  recipe_date,
  num,
  setNum,
}) => {
  const { count, increment, decrement, handleOnChangeCount, reset } =
    useCounter(0)
  const [showDetails, setShowDetails] = useToggle()
  const [drugInfo, setDrugInfo] = useState()
  const { drugID, items, discounts } = drug
  const [checkBox, setCheckBox] = useToggle(false)
  const { SingleCheck } = Images
  const [percentage, setPercentage] = useState()

  const getdrugInfo = async () => {
    const drugItem = await getDrugs(drugID)
    setDrugInfo(drugItem)
  }

  const getOrder = () => {
    const order = {
      id: drugID,
      order_quantity: count,
      clientPrice: percentage
        ? (drugInfo.clientPrice * (100 - percentage)) / 100
        : drugInfo.clientPrice,
      isSold: drugInfo.isSold,
    }
    handleOrder(order)
  }

  useEffect(() => {
    getdrugInfo()
    return () => {
      setDrugInfo()
    }
  }, [])

  useEffect(() => {
    if (checkBox === false) reset()
    else increment()
  }, [checkBox])

  useEffect(() => {
    if (count === 0 && checkBox === true) {
      setCheckBox()
    }
  }, [count])

  useEffect(() => {
    if (drugInfo && handleOrder) {
      getOrder()
    }
  }, [count, checkBox])

  useEffect(() => {
    if (discounts && recipe_date) {
      const response = getPromotion(discounts, recipe_date)
      if (response) {
        setNum(response.exp)
        setPercentage(response.percentage)
      }
    }
  }, [discounts, recipe_date])

  return (
    <div className="drug-box" key={drugID}>
      <header className="consults-container-header">
        {isBuying && drugInfo?.isSold && (
          <div className="drugs-checkbox">
            <div
              className="mnd-pink-border custom-checkbox"
              onClick={setCheckBox}
            >
              {checkBox && (
                <SingleCheck style={{ width: 20, margin: 0, padding: 0 }} />
              )}
            </div>
          </div>
        )}
        <DrugCard drug={drug} drugInfo={drugInfo} percentage={percentage} />
        {!isBuying && (
          <ButtonCustom
            buttonClassName="btn xd mx-5"
            onClick={setShowDetails}
            iconClassName={
              showDetails
                ? 'fas fa-angle-up is-size-5'
                : 'fas fa-angle-down is-size-5'
            }
          />
        )}
      </header>
      {discounts && discounts !== 0 && num ? (
        <div className="promotion-container">
          <div>Aprovecha el {percentage}% de descuento. </div>
          <div>Te quedan: {formatTimePromotions(num)}</div>
        </div>
      ) : !discounts || discounts === 0 ? (
        <div className="promotion-container">
          <div>Este producto no tiene descuento. </div>
        </div>
      ) : (
        <div className="promotion-container">
          <div>El descuento ha expirado. </div>
        </div>
      )}
      {showDetails && !isBuying && <DrugAditionalInfo />}
      {isBuying && checkBox && (
        <BuyControls
          drugInfo={drugInfo}
          count={count}
          decrement={decrement}
          increment={increment}
          handleOnChangeCount={handleOnChangeCount}
          items={parseInt(items)}
        />
      )}
    </div>
  )
}

recipeContainer.propTypes = {
  drug: PropTypes.object,
  getDrugs: PropTypes.func,
  isBuying: PropTypes.bool,
  handleOrder: PropTypes.func,
  recipe_date: PropTypes.string,
  num: PropTypes.number,
  setNum: PropTypes.func,
}

export default recipeContainer
