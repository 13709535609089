import functions from '../functions'
const { getUserTreatment } = functions.cloudFunctions

const getTreatmentConfigService = async code => {
  try {
    const response = await getUserTreatment({})
    if (!response.data?.error) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

export default getTreatmentConfigService
