// ! Get medical Record
const GET_MEDICAL_RECORD = 'GET_MEDICAL_RECORD'
const GET_MEDICAL_RECORD_SUCCESS = 'GET_MEDICAL_RECORD_SUCCESS'
const GET_MEDICAL_RECORD_FAILURE = 'GET_MEDICAL_RECORD_FAILURE'

// const CREATE_CLINICAL_RECORD_REQUEST = 'CREATE_CLINICAL_RECORD_REQUEST'
// const CREATE_CLINICAL_RECORD_SUCCESS = 'CREATE_CLINICAL_RECORD_SUCCESS'
// const CREATE_CLINICAL_RECORD_FAILURE = 'CREATE_CLINICAL_RECORD_FAILURE'
const CLEAR_CLINICAL_RECORDS = 'CLEAR_CLINICAL_RECORDS'

const getMedicalRecordAction = (payload = {}) => ({
  type: GET_MEDICAL_RECORD,
  payload,
})

const getMedicalRecordSuccessAction = (payload = {}) => ({
  type: GET_MEDICAL_RECORD_SUCCESS,
  payload,
})

const getMedicalRecordFailureAction = (payload = {}) => ({
  type: GET_MEDICAL_RECORD_FAILURE,
  payload,
})
//  SET MEDICAL RECORD
const SET_MEDICAL_RECORD = 'SET_MEDICAL_RECORD'
const SET_MEDICAL_RECORD_SUCCESS = 'SET_MEDICAL_RECORD_SUCCESS'
const SET_MEDICAL_RECORD_FAILURE = 'SET_MEDICAL_RECORD_FAILURE'

const setMedicalRecordAction = (payload = {}) => ({
  type: SET_MEDICAL_RECORD,
  payload,
})

const setMedicalRecordSuccessAction = (payload = {}) => ({
  type: SET_MEDICAL_RECORD_SUCCESS,
  payload,
})

const setMedicalRecordFailureAction = (payload = {}) => ({
  type: SET_MEDICAL_RECORD_FAILURE,
  payload,
})

const UPDATE_MEDICAL_RECORD = 'UPDATE_MEDICAL_RECORD'
const UPDATE_MEDICAL_RECORD_SUCCESS = 'UPDATE_MEDICAL_RECORD_SUCCESS'
const UPDATE_MEDICAL_RECORD_FAILURE = 'UPDATE_MEDICAL_RECORD_FAILURE'

const updateMedicalRecordAction = (payload = {}) => ({
  type: UPDATE_MEDICAL_RECORD,
  payload,
})

const updateMedicalRecordSuccessAction = (payload = {}) => ({
  type: UPDATE_MEDICAL_RECORD_SUCCESS,
  payload,
})

const updateMedicalRecordFailureAction = (payload = {}) => ({
  type: UPDATE_MEDICAL_RECORD_FAILURE,
  payload,
})

const DELETE_MEDICAL_RECORD = 'DELETE_MEDICAL_RECORD'
const DELETE_MEDICAL_RECORD_SUCCESS = 'DELETE_MEDICAL_RECORD_SUCCESS'
const DELETE_MEDICAL_RECORD_FAILURE = 'DELETE_MEDICAL_RECORD_FAILURE'

const deleteMedicalRecordAction = (payload = {}) => ({
  type: DELETE_MEDICAL_RECORD,
  payload,
})

const deleteMedicalRecordSuccessAction = (payload = {}) => ({
  type: DELETE_MEDICAL_RECORD_SUCCESS,
  payload,
})

const deleteMedicalRecordFailureAction = (payload = {}) => ({
  type: DELETE_MEDICAL_RECORD_FAILURE,
  payload,
})

const clearClinicalRecords = () => ({
  type: CLEAR_CLINICAL_RECORDS,
})

export default {
  types: {
    GET_MEDICAL_RECORD,
    GET_MEDICAL_RECORD_SUCCESS,
    GET_MEDICAL_RECORD_FAILURE,
    SET_MEDICAL_RECORD,
    SET_MEDICAL_RECORD_SUCCESS,
    SET_MEDICAL_RECORD_FAILURE,
    UPDATE_MEDICAL_RECORD,
    UPDATE_MEDICAL_RECORD_SUCCESS,
    UPDATE_MEDICAL_RECORD_FAILURE,
    DELETE_MEDICAL_RECORD,
    DELETE_MEDICAL_RECORD_SUCCESS,
    DELETE_MEDICAL_RECORD_FAILURE,
    CLEAR_CLINICAL_RECORDS,
  },
  creators: {
    getMedicalRecordAction,
    getMedicalRecordSuccessAction,
    getMedicalRecordFailureAction,
    setMedicalRecordAction,
    setMedicalRecordSuccessAction,
    setMedicalRecordFailureAction,
    updateMedicalRecordAction,
    updateMedicalRecordSuccessAction,
    updateMedicalRecordFailureAction,
    deleteMedicalRecordAction,
    deleteMedicalRecordSuccessAction,
    deleteMedicalRecordFailureAction,
    clearClinicalRecords,
  },
}
