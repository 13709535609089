import PropTypes from 'prop-types'
import validator from 'validator'
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import {
  ImageCustom,
  LabelCustom,
  TextCustom,
  ButtonCustom,
  Input,
} from '../../components'
import { useForm } from '../../utils'
import Images from '../../assets'
import { useState } from 'react'

const AccordionSurgeries = ({
  withoutSurgeryProp,
  surgeriesProp,
  medicalRecord,
  uid,
}) => {
  const [errorAlert, setErrorAlert] = useState({ show: false, message: '' })
  const [addNew, setAddNew] = useState(false)
  const { bisturiShearsIcon, arrowDown } = Images
  const isSurgery = withoutSurgeryProp
  const [{ name, date, withoutSurgery }, handleInputChange] = useForm({
    name: '',
    date: '',
    withoutSurgery: isSurgery ? 'true' : 'false',
  })

  const optionSurgery = [
    {
      value: true,
      label: 'Sin cirugías',
      name: 'withoutSurgery',
    },
    {
      value: false,
      label: 'Con cirugías',
      name: 'withoutSurgery',
    },
  ]

  const deleteItem = index => {
    const data = {
      suregies: [],
    }

    if (surgeriesProp.length > 1) {
      const listItems = [...surgeriesProp]
      listItems.splice(index, 1)
      data.suregies = listItems
    }
    if (surgeriesProp.length === 1) {
      data.withoutSurgery = true
    }

    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (validator.isEmpty(name)) {
      setErrorAlert({ show: true, message: 'Ingresa tu cirugía' })
      return false
    }
    if (validator.isEmpty(date)) {
      setErrorAlert({
        show: true,
        message: 'Hay que ingresar una fecha',
      })
      return false
    }
    console.log('surgeriesProp', surgeriesProp)
    const data = {
      suregies: [
        ...(surgeriesProp ?? []),
        {
          name,
          date,
        },
      ],
      withoutSurgery: false,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const handleOnClick = () => {
    const data = {
      suregies: [],
      withoutSurgery: true,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={bisturiShearsIcon}
              className="is-size-bisturishears-icon mr-3"
            />
            <span className="are-color-mynd-grey ">Cirugías</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        <div className="control">
          {optionSurgery.map(({ value, label, name }, index) => (
            <LabelCustom className="radio mr-3 ml-0 is-size-6" key={index}>
              <input
                type="radio"
                className="is-radio-input-drop mr-3"
                name={name}
                value={value}
                onChange={handleInputChange}
                defaultChecked={isSurgery === value}
              />
              {label}
            </LabelCustom>
          ))}
        </div>
        {withoutSurgery === 'false' && (
          <>
            {surgeriesProp &&
              surgeriesProp.map((value, index) => (
                <div
                  className="is-background-box-allergies mt-3  px-3 "
                  key={index}
                >
                  <div className="level mb-1 is-mobile">
                    <div className="level-left">
                      <TextCustom className="are-color-mynd-grey  px-1 is-size-6"></TextCustom>
                    </div>
                    <div className="level-right ">
                      <ButtonCustom
                        buttonClassName="button is-button-close"
                        icon="fas fa-times"
                        onClick={() => deleteItem(index)}
                      />
                    </div>
                  </div>
                  <Input
                    className="input mb-2"
                    type="text"
                    name="name"
                    value={value.name}
                    placeholder="Describir cirugía"
                    onChange={handleInputChange}
                    readOnly
                  />
                  <LabelCustom className="has-text-weight-light are-color-mynd-grey">
                    Fecha de cirugía
                  </LabelCustom>
                  <Input
                    className="input mb-3 mt-2"
                    type="date"
                    name="date"
                    value={value.date}
                    onChange={handleInputChange}
                    readOnly
                  />
                </div>
              ))}
          </>
        )}
        {withoutSurgery === 'false' && addNew && (
          <form onSubmit={handleOnSubmit}>
            <div className="is-background-box-allergies mt-3 px-3">
              {errorAlert.show && (
                <div className="notification is-danger mt-3">
                  {errorAlert.message}
                </div>
              )}
              <div className="level mb-1 is-mobile">
                <div className="level-left">
                  <TextCustom className="are-color-mynd-grey  px-1 is-size-6"></TextCustom>
                </div>
                <div className="level-right ">
                  <ButtonCustom
                    buttonClassName="button is-button-close"
                    icon="fas fa-times"
                    onClick={() => {
                      setErrorAlert({ show: false, message: '' })
                      setAddNew(false)
                    }}
                  />
                </div>
              </div>
              <Input
                className="input mb-2"
                type="text"
                name="name"
                value={name}
                placeholder="Describir cirugías"
                onChange={handleInputChange}
              />
              <LabelCustom className="has-text-weight-light are-color-mynd-grey">
                Fecha de cirugía
              </LabelCustom>
              <Input
                className="input mb-3 mt-2"
                type="date"
                placeholder="Describir cirugías"
                name="date"
                value={date}
                onChange={handleInputChange}
              />
              <button className="button has-text-white is-mnd-primary mt-2">
                Guardar
              </button>
            </div>
          </form>
        )}
        {withoutSurgery === 'false' && !addNew && (
          <button
            className="button has-text-white is-mnd-primary mt-2 mr-2"
            onClick={() => setAddNew(true)}
          >
            Agregar cirugía
          </button>
        )}
        {withoutSurgery === 'true' && (
          <button
            className="button has-text-white is-mnd-primary mt-2"
            onClick={handleOnClick}
          >
            Guardar
          </button>
        )}
      </AccordionItemPanel>
    </>
  )
}

AccordionSurgeries.propTypes = {
  withoutSurgeryProp: PropTypes.bool,
  surgeriesProp: PropTypes.Array,
  medicalRecord: PropTypes.func,
  uid: PropTypes.string,
  withoutSurgery: PropTypes.bool,
}

export default AccordionSurgeries
