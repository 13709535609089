import PropTypes from 'prop-types'
import { Product } from '..'
import dispatchers from './dispatchers'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

const containerProducts = ({ requestedItems }) => {
  const dispatch = useDispatch()

  const [orderDrugs, setOrderDrugs] = useState([])

  const { getDrugData } = dispatchers(dispatch)

  const getOrderDrugsData = async () => {
    const drugs = []
    for (const drugID in requestedItems) {
      const drug = await getDrugData(drugID)
      drugs.push({
        ...drug,
        id: drugID,
        quantity: requestedItems[drugID],
      })
    }
    setOrderDrugs(drugs)
  }

  useEffect(() => {
    getOrderDrugsData().then()
  }, [])

  return (
    <div className="is-flex is-flex-wrap-wrap mb-5 mt-3">
      {orderDrugs.map(drug => (
        <Product key={drug.id} drug={drug} />
      ))}
    </div>
  )
}

containerProducts.propTypes = {
  requestedItems: PropTypes.instanceOf(Array).isRequired,
}

export default containerProducts
