import getImage from '../../utils/functions/getImages'
import functions from '../functions'
import moment from 'moment'
const { getTreatmentRoute } = functions.cloudFunctions
const getTreatmentRouteService = async code => {
  try {
    const response = await getTreatmentRoute({})
    if (!response.data?.error && response.data.length > 0) {
      const listTreatment = await Promise.all(
        response.data.map(async treatment => {
          const { images, ref, time } = treatment
          const hour = moment(new Date(time * 1000)).format('h:mm a')
          const date = moment(new Date(time * 1000)).format(
            'MMMM D YYYY, h:mm a',
          )
          const tempTreatment = treatment
          tempTreatment.date = date
          tempTreatment.time = hour
          let urls = []
          if (images && images.length > 0) {
            await Promise.all(
              images.map(async image => {
                const response = await getImage('consults', ref, image.url)
                if (!response?.error) {
                  urls = urls.concat(response)
                  return urls
                }
              }),
            )
            tempTreatment.images = urls
          }

          return tempTreatment
        }),
      )
      return listTreatment
    }
  } catch (error) {
    console.log(error)
  }
}

export default getTreatmentRouteService
