// import PropTypes from 'prop-types'

import {
  AllTreatmentCards,
  ContainerTreatment,
  LastAppointmentContainer,
} from '../../components'
import { useDispatch } from 'react-redux'
import dispatchers from './dispatchers'

// hooks
import { useCounter, useRouter } from '../../utils'
import { useEffect, useState } from 'react'

const TreatmentRoute = props => {
  const { count, increment, decrement, handleOnChangeCount, reset } =
    useCounter(1)
  const dispatch = useDispatch()
  const {
    getTreatmentRoute,
    getTreatmentConfig,
    getLastConsult,
    generateRouteCoupon,
    getCategoriesForSpecialty,
  } = dispatchers(dispatch)
  const { history } = useRouter()
  const [treatmentConfig, setTreatmentConfig] = useState()
  const [treatmentList, setTreatmentList] = useState()
  const [loading, setLoading] = useState(true)
  const [currentSession, setCurrentSession] = useState()
  const [lastSession, setLastSession] = useState()
  const [cleanup, setCleanup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getItems = async () => {
    if (!cleanup) {
      const config = await getTreatmentConfig()
      const response = await getTreatmentRoute()
      if (response) {
        await lastIndexOf(response)
        setTreatmentList(response)
      }
      setTreatmentConfig(config)
      setLoading(false)
    }
  }

  const showAll = () => {
    handleOnChangeCount({ value: 3 })
  }

  const lastIndexOf = async arr => {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].status === 'close') {
        const last = await getLastConsult(arr[i].ref)
        setCurrentSession(i + 1)
        setLastSession(last)
        return
      }
    }
  }

  const handleOnClickCoupon = async () => {
    setIsLoading(true)
    const { price } = treatmentConfig
    const response = await generateRouteCoupon(price || 799, currentSession)
    const { error, status } = response
    if ((error && error === 'Cupón usado anteriormente') || status) {
      history.push(`/user/service/fast-service`)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getCategoriesForSpecialty('derma')
    getItems()
    return () => {
      setCleanup(true)
    }
  }, [])

  return (
    <div className="container my-6 mx-4 ">
      {loading ? (
        <div className="waitng-amount">
          <div className="spinner" />
        </div>
      ) : (
        <div className="columns is-centered">
          {count === 1 && (
            <ContainerTreatment
              increment={increment}
              treatmentList={treatmentList}
              treatmentConfig={treatmentConfig}
              currentSession={currentSession}
              lastSession={lastSession}
              showAll={showAll}
              reset={reset}
              handleOnClickCoupon={handleOnClickCoupon}
              isLoading={isLoading}
            />
          )}
          {count === 2 && (
            <LastAppointmentContainer
              decrement={decrement}
              lastSession={lastSession}
            />
          )}
          {count === 3 && (
            <AllTreatmentCards
              treatmentList={treatmentList}
              treatmentConfig={treatmentConfig}
              currentSession={currentSession}
              reset={reset}
            />
          )}
        </div>
      )}
    </div>
  )
}

TreatmentRoute.propTypes = {}

export default TreatmentRoute
