import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import { TitleCustom, CreditCardForm, CustomButton } from '../../components'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import functions from '../../services/functions'
import 'react-credit-cards/es/styles-compiled.css'
const { addCard } = functions.cloudFunctions
const PaymentForm = ({ closeModal }) => {
  const stripe = useStripe()
  const elements = useElements()
  const form = useRef()
  const [name, setName] = useState()
  const [brand, setBrand] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isCardComplete, setIsCardComplete] = useState(false)
  const [errors, setErrors] = useState()
  const [token, setToken] = useState()
  const [last4, setLast4] = useState()

  const onComplete = async () => {
    const cardElement = elements.getElement(CardElement)
    const token = await stripe.createToken(cardElement, { name: name || '' })
    setToken(token)
    setLast4(token.token.card.last4)
  }

  const update = async () => {
    if (!token) return
    token.token.card.name = name || ''
    setToken(token)
  }
  useEffect(() => {
    if (isCardComplete) onComplete()
  }, [isCardComplete])

  useEffect(() => {
    update()
  }, [name])

  const onChange = async event => {
    setErrors()
    setBrand(event.brand)
    if (event.complete) {
      setIsCardComplete(true)
    } else {
      setIsCardComplete(false)
      setLast4()
    }
  }

  const handleInputChange = ({ target }) => {
    setName(target.value)
    setErrors()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    setErrors()
    if (!stripe || !elements) {
      return
    }
    if (token) {
      token.tokenId = token.token.id
      const callable = await addCard({ token: token })
      if (callable) {
        closeModal()
      } else {
        setErrors('No se pudo guardar la tarjeta')
        setIsLoading(false)
      }
    }
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card m-0 p-0" onClick={closeModal}>
        <section className="modal-body">
          <div key="Payment" className="App-payment">
            <TitleCustom className=" has-text-centered is-size-4 are-color-mynd-grey">
              Añadir Tarjeta
            </TitleCustom>
            {errors && <div className="notification is-danger">{errors}</div>}
            <CreditCardForm
              placeholder={'Nombre'}
              issuer={brand}
              name={name}
              last4={last4}
            />
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-group">
                <p className="card-labels mb-0">Datos de Tarjeta</p>
                <CardElement
                  className="form-control"
                  onChange={onChange}
                  options={{ hidePostalCode: true }}
                />
              </div>
              <div className="form-group">
                <p className="card-labels mb-0">Nombre del títular</p>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Nombre"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="mx-3 mt-5">
                <CustomButton
                  isPrimary
                  isLoading={isLoading}
                  isSubmit
                  disabled={!name || !isCardComplete}
                >
                  Añadir tarjeta
                </CustomButton>
              </div>
            </form>
          </div>
        </section>
      </div>
      <button className="modal-close is-large" aria-label="close"></button>
    </div>
  )
}
PaymentForm.propTypes = {
  closeModal: PropTypes.func,
}

export default PaymentForm
