import { notificationsRef } from '../../config'

const markNotificationSeen = async id => {
  try {
    const status = await notificationsRef
      .doc(id)
      .set({ readed: true }, { merge: true })
      .then(() => true)
    return status
  } catch (error) {
    return error
  }
}

export default markNotificationSeen
