import PropTypes from 'prop-types'

import { TextCustom, ImageCustom, ButtonCustom } from '..'

// img
import Images from '../../assets'

const diaryWithoutProducts = ({ increment }) => {
  const { medicineTransparentGray } = Images
  return (
    <div className="without-products-container">
      <TextCustom className="is-size-5 are-color-mynd-grey has-text-weight-bold has-text-centered">
        Mis productos
      </TextCustom>

      <ImageCustom
        img={medicineTransparentGray}
        className="is-size-img-medice-diary mt-5"
      />
      <TextCustom className="is-size-5 are-color-mynd-grey has-text-weight-bold has-text-centered mt-5">
        Aún no has añadido productos
      </TextCustom>
      <TextCustom className="are-color-mynd-grey  has-text-centered mt-5 has-text-weight-medium">
        Comienza a añadirlos pulsando el el boton de abajo
      </TextCustom>
      <div className="is-position-button-flotante ">
        <ButtonCustom
          icon="fas fa-plus"
          buttonClassName="button is-large is-colour-button-add-product "
          handleOnClick={increment}
        />
      </div>
    </div>
  )
}

diaryWithoutProducts.propTypes = {
  increment: PropTypes.func,
}

export default diaryWithoutProducts
