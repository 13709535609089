import PropTypes from 'prop-types'

import {
  DiaryItems,
  EmptyDiary,
  FirstDairyPresentation,
} from '../../components/'

const DiaryContainer = ({
  diaryList,
  handleOnClick,
  showModal,
  setshowModal,
}) => {
  const closePresentation = () => {
    setshowModal(false)
  }

  return (
    <>
      {diaryList ? (
        <DiaryItems diaryList={diaryList} handleOnClick={handleOnClick} />
      ) : (
        <>
          <EmptyDiary handleOnClick={handleOnClick} />
          {showModal && (
            <FirstDairyPresentation setshowModal={closePresentation} />
          )}
        </>
      )}
    </>
  )
}

DiaryContainer.propTypes = {
  handleOnClick: PropTypes.func,
  diaryList: PropTypes.array,
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
}

export default DiaryContainer
