import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  TextCustom,
  TitleCustom,
  ButtonCustom,
  OtherPM,
  CardsPM,
  AddBalance,
} from '../../components'
import { useOnSnapshot } from '../../utils'
import { formatToMoney } from '../../utils/functions/currencyFormat'
// import createOrderPaypalBalanceRecharge from '../../services/balance/createOrderPaypalBalanceRecharge'

const PaymentMethod = ({
  isOrder,
  isReceiptOrConsult,
  next,
  previous,
  paymentMethod,
  setPaymentMethod,
  type,
  amount,
  setAmount,
  onAddBalance,
  isLoading,
  showAddBalance,
  setShowAddBalance,
}) => {
  const [cards, balance] = useOnSnapshot()
  const [waiting, setWaiting] = useState(true)
  const [showPaypal, setShowPaypal] = useState(false)
  const [cleanup, setCleanup] = useState(false)

  const waitingBalance = () => {
    if (!cleanup) {
      setTimeout(() => setWaiting(false), 500)
    }
  }

  useEffect(() => {
    waitingBalance()
    return () => {
      setCleanup(true)
    }
  }, [balance])

  const onChange = () => {
    const currentBalance = {
      method: 'balance',
      value: `Saldo actual: ${formatToMoney(balance)}`,
    }
    setPaymentMethod(currentBalance)
  }
  const showPM = parseInt(amount) >= 50
  return (
    <>
      {waiting && (
        <div className="waitng-amount">
          <div className="spinner" />
        </div>
      )}
      {!waiting && (
        <div className="orders">
          <TitleCustom className="are-color-mynd-grey is-align-self-center">
            Selecciona el método de pago
          </TitleCustom>
          <div className="white-box is-mobile ">
            <TextCustom className="are-color-mynd-grey has-text-weight-semibold border-bottom ">
              Tu saldo
            </TextCustom>
            {!showAddBalance ? (
              <>
                <hr className="line-bottom my-2"></hr>
                <div className="level is-mobile mb-0">
                  <TextCustom className="are-color-mynd-grey">
                    {`Saldo actual ${formatToMoney(balance)}`}
                  </TextCustom>
                  <div className="level-right-cards">
                    <input
                      type="radio"
                      id="current_balance"
                      className="is-radio-input mr-3"
                      name="card"
                      value="balance"
                      onClick={() => onChange()}
                    />
                    <label htmlFor={'current_balance'}></label>
                  </div>
                </div>
                {type ? (
                  <>
                    <hr className="line-bottom my-2"></hr>
                    <a onClick={() => setShowAddBalance(true)}>Añadir saldo</a>
                  </>
                ) : null}
              </>
            ) : (
              <AddBalance
                amount={amount}
                next={next}
                paymentMethod={paymentMethod}
                setShowAddBalance={setShowAddBalance}
                setAmount={setAmount}
                onAddBalance={onAddBalance}
                isLoading={isLoading}
              />
            )}
          </div>

          {showAddBalance && showPM && (
            <>
              <CardsPM
                setShowPaypal={setShowPaypal}
                isReceiptOrConsult
                paymentMethod={paymentMethod}
                cards={cards}
                setPaymentMethod={setPaymentMethod}
              />

              <OtherPM
                showPaypal={showPaypal}
                setShowPaypal={setShowPaypal}
                handleCreateOrder={() => {
                  setShowAddBalance(false)
                }}
                createOrder={async data => {}}
                paymentMethod={paymentMethod}
                next={next}
                amount={amount}
                setPaymentMethod={setPaymentMethod}
              />
            </>
          )}

          {isReceiptOrConsult && showPM && (
            <>
              <CardsPM
                paymentMethod={paymentMethod}
                setShowPaypal={setShowPaypal}
                isReceiptOrConsult
                cards={cards}
                setPaymentMethod={setPaymentMethod}
              />

              <OtherPM
                showPaypal={showPaypal}
                setShowPaypal={setShowPaypal}
                paymentMethod={paymentMethod}
                next={next}
                amount={amount}
                setPaymentMethod={setPaymentMethod}
              />
            </>
          )}

          {isOrder && showPM && (
            <>
              <CardsPM
                paymentMethod={paymentMethod}
                setShowPaypal={setShowPaypal}
                isReceiptOrConsult
                cards={cards}
                setPaymentMethod={setPaymentMethod}
              />

              <OtherPM
                showPaypal={showPaypal}
                paymentMethod={paymentMethod}
                setShowPaypal={setShowPaypal}
                next={next}
                amount={amount}
                setPaymentMethod={setPaymentMethod}
              />
            </>
          )}

          {!type && (
            <div className="orders buttons">
              <div className="column is-9">
                <ButtonCustom
                  buttonClassName="button is-fullwidth is-medium has-text-white is-mnd-primary"
                  onClick={next}
                >
                  Seleccionar
                </ButtonCustom>
              </div>
              <div className="column is-3">
                <ButtonCustom
                  buttonClassName="button is-text-color is-inverted px-0 level-item"
                  onClick={previous}
                >
                  Anterior
                </ButtonCustom>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

PaymentMethod.propTypes = {
  isReceiptOrConsult: PropTypes.bool,
  next: PropTypes.func,
  previous: PropTypes.func,
  setPaymentMethod: PropTypes.func,
  type: PropTypes.string,
  amount: PropTypes.string,
  setAmount: PropTypes.func,
  onAddBalance: PropTypes.func,
  isLoading: PropTypes.bool,
  showAddBalance: PropTypes.bool,
  setShowAddBalance: PropTypes.func,
  isOrder: PropTypes.bool,
  paymentMethod: PropTypes.object,
}

export default PaymentMethod
