import PropTypes from 'prop-types'

// accordion
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

// components
import { ImageCustom, LabelCustom, Input } from '../../components'

import Images from '../../assets'
import { useForm } from '../../utils'

const AccordionWine = ({ drinkProp, uid, medicalRecord }) => {
  const [{ drink }, handleInputChange] = useForm({
    drink: drinkProp,
  })

  const handleDrink = () => {
    if (!isValid) return false
    const data = {
      drink,
    }
    medicalRecord({
      method: 'POST',
      uid,
      data,
    })
  }

  const isValid = () => {
    return drink >= 0
  }

  const { bottleIcon, arrowDown } = Images
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="accordion-visible">
            <ImageCustom
              img={bottleIcon}
              className="is-size-bottle-icon mr-5 ml-3"
            />
            <span className="are-color-mynd-grey ">Tomar</span>
            <span className="icon is-position-arrow-icon">
              <ImageCustom img={arrowDown} />
            </span>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="pb-5 pt-2">
        <LabelCustom className="has-text-weight-light are-color-mynd-grey">
          Número de vasos de alcohol a la semana:
        </LabelCustom>
        <Input
          className="input  mt-2"
          type="number"
          placeholder="ej: 5"
          value={drink}
          name="drink"
          onChange={handleInputChange}
        />
        <button
          className="button has-text-white is-mnd-primary mt-2"
          onClick={handleDrink}
        >
          Guardar
        </button>
      </AccordionItemPanel>
    </>
  )
}

AccordionWine.propTypes = {
  drinkProp: PropTypes.string,
  uid: PropTypes.string,
  medicalRecord: PropTypes.func,
}

export default AccordionWine
