import functions from '../../services/functions'
const { addBalance } = functions.cloudFunctions

const addBalanceService = ({ token, amount, uid }) => {
  return async dispatch => {
    try {
      const response = await addBalance({
        values: {
          token,
          amount,
        },
      })
      return response.data
    } catch (error) {
      return error
    }
  }
}

export default addBalanceService
